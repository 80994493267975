import {
    savingsEliminatingTheLeakagesTable,
    savingsShuttingOffMainAirSupplyAtNonProductionTimeTable,
    savingsReducingThePressureDropTable,
    savingsReducingThePressureLevelOfTheSystemTable,
    savingsOptimizingTheAirConsumptionAtSingleApplicationsTable,
    cumulativeHighestSavingsPerYearTable
} from '../Constants/Constants'

const tables = [savingsEliminatingTheLeakagesTable, savingsShuttingOffMainAirSupplyAtNonProductionTimeTable, savingsReducingThePressureDropTable, savingsReducingThePressureLevelOfTheSystemTable, savingsOptimizingTheAirConsumptionAtSingleApplicationsTable, cumulativeHighestSavingsPerYearTable]

export const SavingsBarChartModelFactory = (data, t) => {
    if (!data) return [];

    var chartBarModel = [];

    tables.forEach(table => {
        if (data.some(x => x.costSavingType == table)) {
            var costSaving = data.find(x => x.costSavingMeasure == 'Savings' && x.costSavingType == table)
            chartBarModel.push(
                { name: t(table), total: costSaving.totalCosts }
            )
        }

    })

    return chartBarModel;
}