
export const QrCodesAuditModel = {
    id: { value: 0, isValid: true, validationError: "", validators: [], disabled: true },
    measurementId: { value: 0, isValid: true, validationError: "", validators: [], disabled: true },
    no: { value: 0, isValid: true, validationError: "", validators: [], disabled: true },
    reason: { tkey: "", value: '', isValid: true, validationError: "", validators: [], disabled: true },
    service: { tkey: "", value: '', isValid: true, validationError: "", validators: [], disabled: true },
    type: { value: 0, isValid: true, validationError: "", validators: [], disabled: true }
}

export const QrCodesAuditModelHeadings = ['Service', 'Nr', 'MeasurementId', 'Reason']