
export const ReportCategoryModel = (t) => ({
    isExecutiveSummarySelected: { label: t("ExecutiveSummary"), value: true, isValid: true, validationError: "", validators: [] },
    isGeneralInformationSelected: { label: t("GeneralInformation"), value: true, isValid: true, validationError: "", validators: [] },
    isAnalysisOfTheCompressedAirGenerationSelected: { label: t("CompressedAirGeneration"), value: true, isValid: true, validationError: "", validators: [] },
    isCompressedAirQualityAnalysisSelected: { label: t("AirQuality"), value: true, isValid: true, validationError: "", validators: [] },
    isAirNetworkAnalysisSelected: { label: t("AirNetwork"), value: true, isValid: true, validationError: "", validators: [] },
    isMachineAnalysisForEnergyEfficiencySelected: { label: t("MA/CACTabName"), value: true, isValid: true, validationError: "", validators: [] },
    isLeakageAnalysisSelected: { label: t("LeakageAnalysis"), value: true, isValid: true, validationError: "", validators: [] },
    isLeakageDetectionAndDocumentationSelected: { label: t("LeakageDetectionAndDocumentation"), value: true, isValid: true, validationError: "", validators: [] },
    isCostSavingsSelected: { label: t("CostSavings"), value: true, isValid: true, validationError: "", validators: [] },
    isReplacementPartsSelected: { label: t("ReplacementParts"), value: true, isValid: true, validationError: "", validators: [] },
    isPdfAttachmentsSelected: { label: t("PdfAttachments"), value: true, isValid: true, validationError: "", validators: [] }
})
