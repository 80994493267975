import React from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { usePdfStyles } from './PdfStyles';
import * as Fmt from '../../../helper/Formatters';

export const AirNetworkPressureDropPdf = (props) => {
    const { t } = useTranslation();
    var PdfStyles = usePdfStyles();

    return (
        <Page size={props.pageSize} style={PdfStyles.page}>
            <View style={PdfStyles.header}>
                <View style={PdfStyles.logoWrapper}>
                    {props.logo && <Image style={PdfStyles.logo} src={props.logo} />}
                </View>
            </View>
            <View style={PdfStyles.pageBody}>
                <Text style={PdfStyles.heading}>{t('PressureDropAnalysis')}</Text>
                <View style={PdfStyles.inputContainer}>
                    <Text style={PdfStyles.inputLabel}>{t('NameOfTheAirNetwork')}</Text>
                </View>
                <Text style={PdfStyles.heading}>{t('PressureLevelAnalysis')}</Text>
                <View style={PdfStyles.table}>
                    <View style={PdfStyles.tableRow}>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('SetpointPressureAtOutput')}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('MaximalSuggested') + Fmt.getUnitInBracket("pressure", t)}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('MinimalPressure') + Fmt.getUnitInBracket("pressure", t)}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('MaximalPressure') + Fmt.getUnitInBracket("pressure", t)}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('AveragePressure') + Fmt.getUnitInBracket("pressure", t)}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('PressureLevel') + Fmt.getUnitInBracket("pressure", t)}</Text>
                        </View>
                    </View>
                    <View style={PdfStyles.tableRow}>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('SetpointPressureRel')}</Text>
                        </View>
                        <View style={PdfStyles.tableColFlexEnd}>
                            <Text style={PdfStyles.tableCell}></Text>
                        </View>
                        <View style={PdfStyles.tableColFlexEnd}>
                            <Text style={PdfStyles.tableCell}></Text>
                        </View>
                        <View style={PdfStyles.tableColFlexEnd}>
                            <Text style={PdfStyles.tableCell}></Text>
                        </View>
                        <View style={PdfStyles.tableColFlexEnd}>
                            <Text style={PdfStyles.tableCellInput}></Text>
                        </View>
                        <View style={PdfStyles.tableColFlexEnd}>
                            <Text style={PdfStyles.tableCell}></Text>
                        </View>
                    </View>
                    <View style={PdfStyles.tableRow}>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('BeforeDryerAndFilterAbsolute')}</Text>
                        </View>
                        <View style={PdfStyles.tableColFlexEnd}>
                            <Text style={PdfStyles.tableCell}></Text>
                        </View>
                        <View style={PdfStyles.tableColFlexEnd}>
                            <Text style={PdfStyles.tableCellInput}></Text>
                        </View>
                        <View style={PdfStyles.tableColFlexEnd}>
                            <Text style={PdfStyles.tableCellInput}></Text>
                        </View>
                        <View style={PdfStyles.tableColFlexEnd}>
                            <Text style={PdfStyles.tableCellInput}></Text>
                        </View>
                        <View style={PdfStyles.tableColFlexEnd}>
                            <Text style={PdfStyles.tableCell}></Text>
                        </View>
                    </View>
                    <View style={PdfStyles.tableRow}>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('AfterDryerAndFilterAbsulute')}</Text>
                        </View>
                        <View style={PdfStyles.tableColFlexEnd}>
                            <Text style={PdfStyles.tableCellInput}></Text>
                        </View>
                        <View style={PdfStyles.tableColFlexEnd}>
                            <Text style={PdfStyles.tableCellInput}></Text>
                        </View>
                        <View style={PdfStyles.tableColFlexEnd}>
                            <Text style={PdfStyles.tableCellInput}></Text>
                        </View>
                        <View style={PdfStyles.tableColFlexEnd}>
                            <Text style={PdfStyles.tableCellInput}></Text>
                        </View>
                        <View style={PdfStyles.tableColFlexEnd}>
                            <Text style={PdfStyles.tableCell}></Text>
                        </View>
                    </View>
                    <View style={PdfStyles.tableRow}>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('ProductionAbsolute')}</Text>
                        </View>
                        <View style={PdfStyles.tableColFlexEnd}>
                            <Text style={PdfStyles.tableCellInput}></Text>
                        </View>
                        <View style={PdfStyles.tableColFlexEnd}>
                            <Text style={PdfStyles.tableCellInput}></Text>
                        </View>
                        <View style={PdfStyles.tableColFlexEnd}>
                            <Text style={PdfStyles.tableCellInput}></Text>
                        </View>
                        <View style={PdfStyles.tableColFlexEnd}>
                            <Text style={PdfStyles.tableCellInput}></Text>
                        </View>
                        <View style={PdfStyles.tableColFlexEnd}>
                            <Text style={PdfStyles.tableCell}></Text>
                        </View>
                    </View>
                </View>
                <Text style={PdfStyles.heading}>{t('PressureDropInsideThePiping')}</Text>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('MaximalSuggested')}</Text>
                    </View>
                    <View style={PdfStyles.flexItem}>
                        <Text style={PdfStyles.inputLabel}></Text>
                    </View>
                </View>
                <Text style={PdfStyles.heading}>{t('SavingsReducingThePressureLevel')}</Text>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('SetpointPressureRel')}</Text>
                    </View>
                    <View style={PdfStyles.flexItem}>
                        <Text style={PdfStyles.inputLabel}></Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('SuggestedSetpointPressureRel')}</Text>
                    </View>
                    <View style={PdfStyles.flexItem}>
                        <Text style={PdfStyles.inputLabel}></Text>
                    </View>
                </View>
                <Text style={PdfStyles.heading}>{t('Comment')}</Text>
                <View style={{ height: 100, border: "1px solid rgba(0, 0, 0, 1)" }} ></View>
            </View>
        </Page>
    );
}