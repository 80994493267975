import React from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { usePdfStyles } from './PdfStyles';
import * as Fmt from '../../../helper/Formatters';

export const CompressorPdf = (props) => {
    const { t } = useTranslation();
    var PdfStyles = usePdfStyles();

    return (
        <Page size={props.pageSize} style={PdfStyles.page}>
            <View style={PdfStyles.header}>
                <View style={PdfStyles.logoWrapper}>
                    {props.logo && <Image style={PdfStyles.logo} src={props.logo} />}
                </View>
            </View>
            <View style={PdfStyles.pageBody}>
                <Text style={PdfStyles.heading}>{t('IndividualCompressorInformation')}</Text>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('EntryDate')}</Text>
                    </View>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('DetectedBy')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRowStart}>
                    <View style={PdfStyles.flexItemNoGrow}>
                        <View style={PdfStyles.checkbox}></View>
                    </View>
                    <View style={PdfStyles.flexItemNoGrow}>
                        <Text style={PdfStyles.label}>{t('OptimizationIsDone')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('OptimizationDate')}</Text>
                    </View>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('OptimizedBy')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('MeasurementId')}</Text>
                    </View>
                    <View style={PdfStyles.flexItem}>
                        <Text style={PdfStyles.inputLabel}></Text>
                    </View>
                </View>
                <Text style={PdfStyles.heading}>{t('Location')}</Text>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('CompressorRoomNo')}</Text>
                    </View>
                    <View style={PdfStyles.flexItem}>
                    </View>
                </View>
                <Text style={PdfStyles.heading}>{t('Details')}</Text>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Manufacturer')}</Text>
                    </View>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('ModelSerialNumber')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Year')}</Text>
                    </View>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('CompressorType')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('ControlType')}</Text>
                    </View>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t("NominalPowerKW") + Fmt.getUnitInBracket("power", t)}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t("FreeAirDelivery") + Fmt.getUnitInBracket("volumePerTime", t)}</Text>
                    </View>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t("NominalPressureCompression") + Fmt.getUnitInBracket("pressure", t)}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t("SetpointPressure") + Fmt.getUnitInBracket("pressure", t)}</Text>
                    </View>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('LastMaintenanceLabel')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRowStart}>
                    <View style={PdfStyles.flexItemNoGrow}>
                        <View style={PdfStyles.checkbox}></View>
                    </View>
                    <View style={PdfStyles.flexItemNoGrow}>
                        <Text style={PdfStyles.label}>{t('HeatRecovery')}</Text>
                    </View>
                </View>
                <Text style={PdfStyles.heading}>{t('WorkloadAirFlow')}</Text>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('MondayToFriday')}</Text>
                    </View>
                    <View style={PdfStyles.flexItem}>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Saturday')}</Text>
                    </View>
                    <View style={PdfStyles.flexItem}>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Sunday')}</Text>
                    </View>
                    <View style={PdfStyles.flexItem}>
                    </View>
                </View>
                <Text style={PdfStyles.heading}>{t('Comment')}</Text>
                <View style={{ height: 50, border: "1px solid rgba(0, 0, 0, 1)" }} ></View>
            </View>
        </Page>
    );
}