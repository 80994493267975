import React, { useState, useEffect } from 'react'
import { Route } from "react-router-dom";
import { ErrorPage } from '../base/ErrorPage'
import { UserStore } from '../../store/'
import InitializeMessage from '../InitializeMessage/InitializeMessage';
import LoginMessage from '../LoginMessage/LoginMessage';
import { HasUserRole } from '../../helper/AuthorizationHelper/AuthHelper';

export const PrivateRoute = ({ component: Component, denyEdit, showContent, handleSideBar, menuData, allowedRoles, ...rest }) => {
    const [user, setUser] = useState({})
    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        let userStore = UserStore.user.subscribe(user => setUser(user));
        let userIsActiveStore = UserStore.userLoggedIn.subscribe(userLoggedIn => setIsActive(userLoggedIn));
        UserStore.loginUser();
        return () => {
            userIsActiveStore.unsubscribe();
            userStore.unsubscribe();
        }
    }, [])

    return (
        <Route
            {...rest}
            render={() => (
                !isActive && <LoginMessage />
                || Object.keys(user).length == 0 && <InitializeMessage title="GettingUserRights"></InitializeMessage>
                || !HasUserRole(allowedRoles, user) && <ErrorPage errorCode="401" errorMessage="Unauthorized" />
                || <Component
                    {...rest}
                    denyEdit={denyEdit}
                    menuData={menuData}
                    handleSideBar={handleSideBar}
                    showContent={showContent}
                />
            )}
        />
    )
}