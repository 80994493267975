import React, { useState } from "react";
import { ExcelFile, ExcelSheet } from 'react-xlsx-wrapper';
import { ButtonComponent, DatePickerComponent } from '../../../Reusable';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
// @ts-expect-error not converted yet
import { checkIsDate } from "../../../../helper/Validator";
import _ from 'lodash';
import * as DateHelper from '../../../../helper/DateHelper';
// @ts-expect-error not converted yet
import { GenerateTopHeader } from '../../../../helper/ExcelHelpers/ExcelHelper';
import * as borders from '../../../../helper/ExcelHelpers/Constants';
import { isAfter } from "date-fns";
import { InternalLicenseTableEntry } from "./LicenseRequests";

interface IProps {
    licenseRequests: InternalLicenseTableEntry[]
}

export default function LicenseRequestReport(props: IProps) {
    const { licenseRequests } = props;

    const { t } = useTranslation();

    const [today] = useState(new Date());
    const [defautFrom] = useState(new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()));
    const [toDate, setToDate] = useState({ label: t("ToDate"), idkey: "To-Date", value: today, isValid: true, validationError: t("Error") });
    const [fromDate, setFromDate] = useState({ label: t("FromDate"), idkey: "From-Date", value: defautFrom, isValid: true, validationError: t("Error") });

    const getReportMultiDataSet = () => {
        const licenseRequestsData = _.cloneDeep(licenseRequests)
            .filter(x => (fromDate.value == defautFrom || isAfter(new Date(x.createdDate), fromDate.value)) && isAfter(toDate.value, new Date(x.createdDate)))
            .map(x => [
                x.id.toString(),
                DateHelper.FormatDate(x.createdDate),
                x.executorRequestorName,
                x.executorReceiverName,
                x.createdBy,
                x.license.name,
                x.quantity,
                x.customerPurchaseOrderNumber,
                x.licenseRequestStatus?.value,
                x.approvedBy,
            ]);

        const multiData = [
            GenerateTopHeader("LicenseRequests", t),
            {
                ySteps: 2,
                columns: [
                    { title: t("OrderNr"), width: { wch: 15 }, style: { border: borders.bottomThick } },
                    { title: t("Date"), width: { wch: 15 }, style: { border: borders.bottomThick } },
                    { title: t("ExecutorRequestorName"), width: { wch: 15 }, style: { border: borders.bottomThick } },
                    { title: t("ExecutorReceiverName"), width: { wch: 15 }, style: { border: borders.bottomThick } },
                    { title: t("OrderedBy"), width: { wch: 15 }, style: { border: borders.bottomThick } },
                    { title: t("License"), width: { wch: 15 }, style: { border: borders.bottomThick } },
                    { title: t("Quantity"), width: { wch: 15 }, style: { border: borders.bottomThick } },
                    { title: t("CustomerPurchaseOrderNumber"), width: { wch: 15 }, style: { border: borders.bottomThick } },
                    { title: t("Status"), width: { wch: 15 }, style: { border: borders.bottomThick } },
                    { title: t("ApprovedBy"), width: { wch: 15 }, style: { border: borders.bottomRightThick } },
                ],
                data: licenseRequestsData
            }
        ]

        return multiData;
    }

    return (
        <Grid container spacing={3} direction="row" mb={5}>
            <Grid item>
                <DatePickerComponent
                    t={t}
                    model={fromDate}
                    onChange={(e: any) => setFromDate({ ...fromDate, value: e, isValid: checkIsDate(e) })}
                />
            </Grid>
            <Grid item>
                <DatePickerComponent
                    t={t}
                    model={toDate}
                    onChange={(e: any) => setToDate({ ...toDate, value: e, isValid: checkIsDate(e) })}
                />
            </Grid>
            <Grid item>
                <ExcelFile
                    filename="License requests"
                    element={
                        <ButtonComponent id="license-request-export" value={t("GenerateCO2Report")} color="primary"></ButtonComponent>
                    }
                >
                    <ExcelSheet
                        dataSet={getReportMultiDataSet()}
                        name={t("LicenseRequests")}
                    />
                </ExcelFile>
            </Grid>
        </Grid>

    );
}