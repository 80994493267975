import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@mui/material';
import { HeadingComponent, ButtonComponent, TableHeadingContent, FilterModel, TableHeadCells, SimpleCheckbox, CheckboxComponent } from '../../../components/Reusable/';
import { Close } from '@mui/icons-material';
import { OpenGlobalSnackbar } from '../../../helper/GlobalVariables';
import { API } from '../../../helper/ApiHelper';
import { FormatDate } from '../../../helper/DateHelper';
import ServiceQrModel from './ServiceQrModel';
import QRCode from 'qrcode';
import { getExecutorLogo } from '../../../helper/Pdf';
import { Document, pdf } from '@react-pdf/renderer';
import QrCodes from '../../LandingPageView/QrCodes';
import { saveAs } from 'file-saver';
import { ExecutorStore } from '../../../store';

export default function ProjectQrCodePrint(props) {
    const qrCodesUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "qr-code/project/";
    const { open, setOpen } = props;
    let { id } = useParams();
    const { t } = useTranslation();

    const serviceQrModel = ServiceQrModel(t);

    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(5);
    const [tableData, setTableData] = useState([]);
    const [tableFilterData, setTableFilterData] = useState([]);
    const [selectedQrCodes, setSelectedQrCodes] = useState([]);

    useEffect(() => {
        if (open === true)
            getQrCodes();
    }, [open]);

    const getQrCodes = async () => {
        let qrCodes = await API.get(qrCodesUrl + id);
        setTableData(qrCodes);
        setTableFilterData(qrCodes);
        setAllQrCodesSelected(qrCodes);
    }

    const setAllQrCodesSelected = (qrCodes) => setSelectedQrCodes(qrCodes.map(x => x.measurementId));

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const changeRows = (e) => {
        setRows(e.target.value);
        setPage(0);
    }

    const filterFunction = filteredData => {
        setPage(0);
        setTableData(filteredData)
    }

    const markMeasurementId = measurementId => {
        if (selectedQrCodes.includes(measurementId))
            setSelectedQrCodes(selectedQrCodes.filter(x => x != measurementId));
        else
            setSelectedQrCodes([...selectedQrCodes, measurementId]);
    }

    const printPdf = () => {
        if (selectedQrCodes.length == 0)
            OpenGlobalSnackbar("danger", t('PleaseSelectAtleastOneEntry'));
        else
            generateQrPdf();
    }

    const generateQrPdf = async () => {
        let qrCodeArray = [];
        for (let qrCode of selectedQrCodes) {
            let qcImageData = await QRCode.toDataURL(qrCode.toString());
            qrCodeArray.push({
                id: qrCode,
                qrCodeDataUri: qcImageData
            });
        }

        let logo = await getExecutorLogo();
        let qrDocument = (
            <Document>
                <QrCodes
                    logo={logo}
                    QrCodes={qrCodeArray}
                    size={ExecutorStore.executorSettings.value.printSize?.value ?? "A4"}
                />
            </Document>
        )
        const blobPdf = await pdf(qrDocument);
        blobPdf.updateContainer(qrDocument);
        let blob = await blobPdf.toBlob();
        saveAs(blob, "ProjectQrCodes.pdf");
    }

    return (
        <Dialog open={open} fullWidth maxWidth="lg">
            <DialogTitle>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <HeadingComponent value={t("GenerateQrCode")} size="h6" />
                    </Grid>
                    <Grid item>
                        <IconButton
                            color="inherit"
                            onClick={() => setOpen(false)}
                            id="btn_print-qr-codes-close"
                            size="large"
                        >
                            <Close fontSize="small" />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={3} direction="column" className="ReplacementPartMaterialSearch">
                    <Grid item xs={12}>
                        <CheckboxComponent
                            t={t}
                            model={{ label: t("SelectAll"), value: selectedQrCodes.length === tableFilterData.length }}
                            onChange={e => e.target.checked ? setAllQrCodesSelected(tableFilterData) : setSelectedQrCodes([])}
                        />
                    </Grid>
                    <Grid item>
                        <TableHeadingContent
                            defaultData={tableData}
                            filterData={tableFilterData}
                            onFilterFunction={filterFunction}
                            filterModel={FilterModel.QrCodeProjectPrint}
                            tableId={"QrCodePrint"}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper} >
                            <Table style={{ tableLayout: "fixed" }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ width: "5%" }}></TableCell>
                                        <TableHeadCells
                                            model={serviceQrModel}
                                            propertyList={["measurementId", "service", "dateOfDetection", "detectedBy"]}
                                            tableName="QrCodePrint"
                                            data={tableData}
                                            setData={setTableData}
                                        />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        tableData.slice(page * rows, rows + (page * rows)).map((item, index) =>
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <SimpleCheckbox
                                                        id={"QrCodePrint-checkbox" + index}
                                                        key={Math.random()}
                                                        defaultChecked={selectedQrCodes.includes(item.measurementId)}
                                                        color="default"
                                                        onChange={() => markMeasurementId(item.measurementId)}>
                                                    </SimpleCheckbox>
                                                </TableCell>
                                                <TableCell>{item.measurementId}</TableCell>
                                                <TableCell>{t(item.service)}</TableCell>
                                                <TableCell>{FormatDate(item.dateOfDetection)}</TableCell>
                                                <TableCell>{item.detectedBy}</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                            <TablePagination
                                component="div"
                                rowsPerPageOptions={[5, 10, 15]}
                                count={tableData && tableData.length > 0 ? tableData.length : 0}
                                rowsPerPage={rows}
                                onRowsPerPageChange={changeRows}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        </TableContainer>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item>
                        <ButtonComponent value={t('Cancel')} color="secondary" onChange={() => setOpen(false)}></ButtonComponent>
                    </Grid>
                    <Grid item>
                        <ButtonComponent value={t('Print')} color="primary" onChange={() => printPdf()} id="print-qr-codes"></ButtonComponent>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}