import { BehaviorSubject } from "rxjs";

const DirtyModel = new BehaviorSubject<boolean>(false);
const ConfirmNavigationModel = new BehaviorSubject<boolean>(false);

export const DirtyStore = {
    DirtyModel,
    ConfirmNavigationModel,
    setIsDirty: (val: boolean) => DirtyModel.next(val),
    setConfirmNavigation: (val: boolean) => ConfirmNavigationModel.next(val),
}