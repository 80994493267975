import React from 'react';
import {
    BarChart, Bar, Text, XAxis, YAxis, CartesianGrid, LabelList, Tooltip, ResponsiveContainer, Legend,
} from 'recharts';
import * as Fmt from '../../../helper/Formatters'

const SavingsBarChartComponent = (props) => {

    const { data } = props

    return (
        <ResponsiveContainer id="savings-bar-chart" height={600} className="chart-Container">
            <BarChart
                width={1500}
                height={400}
                data={data}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" tick={<CustomizedAxisTick />} height={100} interval={0} />
                <YAxis unit={" " + Fmt.getCurrency()} />
                <Tooltip />
                <Legend verticalAlign="top" />
                <Bar dataKey="total" fill="#0091dc">
                    <LabelList dataKey="savings" position="top" />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    )
}

const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
        <Text x={x} y={y} width={150} dy={16} textAnchor="middle" verticalAnchor="start">{payload.value}</Text>
    )
}

export default SavingsBarChartComponent
