import React from 'react';
import { Page, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { usePdfStyles, StyleTable } from './PdfStyles';
import Watermark from './Watermark';
import { SplitText } from '../../../helper/PdfTested';

export const ReplacementPartsPdf = (props) => {
    const { projectData } = props;

    const { t } = useTranslation();
    var PdfStyles = usePdfStyles();

    return (
        <Page size={props.pageSize} style={PdfStyles.page}>
            <Watermark projectData={projectData} />
            <Text style={PdfStyles.pageNumbers} render={({ pageNumber, totalPages }) => (pageNumber + ' ' + t('of') + ' ' + totalPages)} fixed />
            <View wrap={false}>
                <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["replacementParts"] = pageNumber }} />
                <Text style={PdfStyles.boldHeading}>{props.tocValues.tocNumbering.replacementParts}. {t('ReplacementParts')}</Text>
                <Text style={PdfStyles.spacer}></Text>
            </View>
            <View>
                <View style={StyleTable.table}>
                    <View style={{ display: "flex", flexDirection: "row", borderBottom: "1px solid black", borderRight: "1px solid black" }}>
                        <View style={{ flexBasis: 0, flexGrow: 1.3 }}>
                        </View>
                        <View style={{ flexBasis: 0, flexGrow: 1 }}>
                            <Text style={StyleTable.tableCell}>{t('DefectedDevice')}</Text>
                        </View>
                    </View>
                    <View style={StyleTable.tableRow} wrap={false} fixed>
                        <View style={[StyleTable.tableColRowZero, { flexGrow: 1.5 }]}>
                            <Text style={StyleTable.tableCell}>{t('Service')}</Text>
                        </View>
                        <View style={[StyleTable.tableColRowZero, { flexGrow: 0.3 }]}>
                            <Text style={StyleTable.tableCell}>{t('Nr')}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{t('MeasurementId')}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{t('PartNumber')}</Text>
                        </View>
                        <View style={[StyleTable.tableColRowZero, { flexGrow: 1.2 }]}>
                            <Text style={StyleTable.tableCell}>{t('TypeCode')}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{t('Component')}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{SplitText(t('Manufacturer'), 10)}</Text>
                        </View>
                    </View>
                    {props.data.map((el, i) =>
                        <View key={'ReplacementPart' + i} style={StyleTable.tableRow} wrap={false}>
                            <View style={i == props.data.length - 1 ? [StyleTable.tableColRowZero, { flexGrow: 1.5 }] : [StyleTable.tableCol, { flexGrow: 1.5 }]}>
                                <Text style={StyleTable.tableCell}>{t(el.service)}</Text>
                            </View>
                            <View style={i == props.data.length - 1 ? [StyleTable.tableColRowZero, { flexGrow: 0.3 }] : [StyleTable.tableCol, { flexGrow: 0.3 }]}>
                                <Text style={StyleTable.tableCell}>{el.no}</Text>
                            </View>
                            <View style={i == props.data.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{el.measurementId}</Text>
                            </View>
                            <View style={i == props.data.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{SplitText(el.partNumber, 10)}</Text>
                            </View>
                            <View style={i == props.data.length - 1 ? [StyleTable.tableColRowZero, { flexGrow: 1.2 }] : [StyleTable.tableCol, { flexGrow: 1.2 }]}>
                                <Text style={StyleTable.tableCell}>{SplitText(el.typeCode, 14)}</Text>
                            </View>
                            <View style={i == props.data.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{SplitText(t(el.component), 10)}</Text>
                            </View>
                            <View style={i == props.data.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{SplitText(el.manufacturer, 10)}</Text>
                            </View>
                        </View>
                    )}
                </View>
                <Text style={PdfStyles.spacer}></Text>
            </View>
            <View>
                <View style={StyleTable.table}>
                    <View style={{ display: "flex", flexDirection: "row", borderBottom: "1px solid black", borderRight: "1px solid black" }}>
                        <View style={{ flexBasis: 0, flexGrow: 1.3 }}>
                        </View>
                        <View style={{ flexBasis: 0, flexGrow: 1 }}>
                            <Text style={StyleTable.tableCell}>{t('ReplacementDevice')}</Text>
                        </View>
                    </View>
                    <View style={StyleTable.tableRow} wrap={false} fixed>
                        <View style={[StyleTable.tableColRowZero, { flexGrow: 1.2 }]}>
                            <Text style={StyleTable.tableCell}>{t('Service')}</Text>
                        </View>
                        <View style={[StyleTable.tableColRowZero, { flexGrow: 0.3 }]}>
                            <Text style={StyleTable.tableCell}>{t('Nr')}</Text>
                        </View>
                        <View style={[StyleTable.tableColRowZero, { flexGrow: 0.6 }]}>
                            <Text style={StyleTable.tableCell}>{t('MeasurementId')}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{t('PartNumber')}</Text>
                        </View>
                        <View style={[StyleTable.tableColRowZero, { flexGrow: 1.2 }]}>
                            <Text style={StyleTable.tableCell}>{t('TypeCode')}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{SplitText(t('Component'), 10)}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{SplitText(t('Manufacturer'), 10)}</Text>
                        </View>
                        <View style={[StyleTable.tableColRowZero, { flexGrow: 0.7 }]}>
                            <Text style={StyleTable.tableCell}>{SplitText(t('Quantity'), 6)}</Text>
                        </View>
                        <View style={[StyleTable.tableColRowZero, { flexGrow: 0.6 }]}>
                            <Text style={StyleTable.tableCell}>{SplitText(t('OrderSparePart'), 5)}</Text>
                        </View>
                    </View>
                    {props.data.map((el, i) =>
                        <View key={'ReplacementPart' + i} style={StyleTable.tableRow} wrap={false}>
                            <View style={i == props.data.length - 1 ? [StyleTable.tableColRowZero, { flexGrow: 1.2 }] : [StyleTable.tableCol, { flexGrow: 1.2 }]}>
                                <Text style={StyleTable.tableCell}>{t(el.service)}</Text>
                            </View>
                            <View style={i == props.data.length - 1 ? [StyleTable.tableColRowZero, { flexGrow: 0.3 }] : [StyleTable.tableCol, { flexGrow: 0.3 }]}>
                                <Text style={StyleTable.tableCell}>{el.no}</Text>
                            </View>
                            <View style={i == props.data.length - 1 ? [StyleTable.tableColRowZero, { flexGrow: 0.6 }] : [StyleTable.tableCol, { flexGrow: 0.6 }]}>
                                <Text style={StyleTable.tableCell}>{el.measurementId}</Text>
                            </View>
                            <View style={i == props.data.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{el.replaceWithDifferent ? SplitText(el.newPartNumber, 10) : SplitText(el.partNumber, 10)}</Text>
                            </View>
                            <View style={i == props.data.length - 1 ? [StyleTable.tableColRowZero, { flexGrow: 1.2 }] : [StyleTable.tableCol, { flexGrow: 1.2 }]}>
                                <Text style={StyleTable.tableCell}>{el.replaceWithDifferent ? SplitText(el.newTypeCode, 13) : SplitText(el.typeCode, 13)}</Text>
                            </View>
                            <View style={i == props.data.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{SplitText(t(el.component), 10)}</Text>
                            </View>
                            <View style={i == props.data.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{el.replaceWithDifferent ? SplitText(el.newManufacturer, 11) : SplitText(el.manufacturer, 13)}</Text>
                            </View>
                            <View style={i == props.data.length - 1 ? [StyleTable.tableColRowZero, { flexGrow: 0.7 }] : [StyleTable.tableCol, { flexGrow: 0.7 }]}>
                                <Text style={StyleTable.tableCell}>{el.quantity}</Text>
                            </View>
                            <View style={i == props.data.length - 1 ? [StyleTable.tableColRowZero, { flexGrow: 0.6 }] : [StyleTable.tableCol, { flexGrow: 0.6 }]}>
                                <Text style={StyleTable.tableCell}>{el.orderSparePart ? t("Yes") : t("No")}</Text>
                            </View>
                        </View>
                    )}
                </View>
            </View>
        </Page>
    );
}