export const GetConstant = (key: 'SspLink' | 'BuySparePartManufacturer') => {
	switch (key) {
		case "SspLink":
			return "https://ssp.festo.com/addtobasket/wrap?";
		case "BuySparePartManufacturer":
			return "festo";
		default:
	}
}

//project status
export const RUNNING = 1;
export const FINISHED = 2;
export const EXPIRED = 3;
export const LOCKED = 4;

//licence project type
export const LeakageDetection = 1;
export const FullAudit = 2;

//available licenses
export const StandardLeakageDetection = 1;
export const StandardFullAudit = 2;
export const ProfessionalLeakageDetection = 3;
export const ProfessionalFullAudit = 4;

//licence price and period type
export const Standard = 1;
export const Pro = 2;

//Executor Selection Types
export const AllExecutors = -1;
export const Reader = -2;
export const ExecutorNotSelected = 0;
export const ExecutorSelectionNotInitialized = undefined;

export const ReplacementPartOriginal = 1;
export const ReplacementPartDifferent = 2;

//Project form import views
export const ProjectImport = "project";
export const ProjectImportDryer = "dryer";
export const ProjectImportCompressor = "compressor";
export const ImportConfirmation = "confirmation";

export const NavigationOption = {
    Reload: 'reload',
    Create: 'create',
    Copy: 'copy'
};