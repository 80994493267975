import { checkIsNotNullOrWhitespace, checkIsInteger, isRepairedByFilledValidator, checkIsDecimal, checkIsDateInPastUTC, isRepairedDateFilledAndPast } from "../../../helper/Validator";
import * as Fmt from '../../../helper/Formatters';
import { nowUTC } from '../../../helper/DateHelper';
import { UserStore } from '../../../store'

let user = {};
UserStore.user.subscribe(usr => {
    user = usr;
})

export const ApplicationModelFactory = (t) => ({
    id: { value: 0, isValid: true, validationError: "", validators: [], disabled: true },
    no: { label: t('Nr'), idkey: "MCACEDIT-No", value: 0, isValid: true, validationError: "", validators: [], disabled: true },
    guid: { value: 0, isValid: true, validationError: "", validators: [], disabled: true },
    measurementId: { label: t("MeasurementId"), idkey: "MCACEDIT-measurementId", value: "", isValid: true, validationError: "", validators: [checkIsInteger] },
    detectedDate: { label: t("EntryDate"), idkey: "MCACEDIT-EntryDate", value: nowUTC(), isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDateInPastUTC] },
    detectedBy: { label: t("DetectedBy"), idkey: "MCACEDIT-detectedBy", value: user.firstName + " " + user.lastName, isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace] },
    isRepairDone: { label: t("OptimizationIsDone"), idkey: "AIRRECEIV-RepairIsDone", value: false, isValid: true, validationError: "", validators: [] },
    repairedBy: { label: t("OptimizedBy"), idkey: "AIRRECEIV-RepairedBy", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [isRepairedByFilledValidator] },
    repairDate: { label: t("OptimizationDate"), idkey: "AIRRECEIV-RepairDate", value: null, isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [isRepairedDateFilledAndPast], type: 0, step: 0 },
    pressureActual: { label: t("Pressure") + Fmt.getUnitInBracket("pressure", t), idkey: "MCACEDIT-Pressure", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDecimal] },
    optimizationFlowPerCycleActual: { label: t("FlowPercycle") + Fmt.getUnitInBracket("nanoLiterPerMinute", t), idkey: "MCACEDIT-FlowPercycle", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [checkIsDecimal] },
    optimizationCycleTimeActual: { label: t("CycleTime") + Fmt.addBrackets("SecondsSymbol", t), idkey: "MCACEDIT-CycleTime", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger] },
    optimizationNrCyclesMinActual: { label: t("CyclesPerMinute"), idkey: "MCACEDIT-CyclesPerMinute", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [checkIsDecimal] },
    optimizationNrComponentsActual: { label: t("QuontityOfComponents"), idkey: "MCACEDIT-QuontityOfComponents", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger] },
    optimizationFlowActual: { label: "", idkey: "MCACEDIT-optimizationFlowActual", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [] },
    optimizationFlowFutureTOTAL: { label: "", idkey: "MCACEDIT-optimizationFlowFutureTOTAL", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [] },
    optimizationFlowSavingsTOTAL: { label: "", idkey: "MCACEDIT-optimizationFlowSavingsTOTAL", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [] },
    optimizationOperatingHoursActual: { label: t("OperatingHoursDay"), idkey: "MCACEDIT-OperatingHoursDay", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger] },
    optimizationOperatingDaysActual: { label: t("OperatingDaysWeek"), idkey: "MCACEDIT-OperatingDaysWeek", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger] },
    optimizationCostsActual: { label: t("OptimizationCostsActual"), idkey: "MCACEDIT-OptimizationCostsActual", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [] },
    optimizationVolumeYearActual: { label: t("OptimizationVolumeYearActual"), idkey: "MCACEDIT-OptimizationVolumeYearActual", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [] },
    pressureFuture: { label: t("Pressure") + Fmt.getUnitInBracket("pressure", t), idkey: "MCACEDIT-Pressure", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDecimal] },
    optimizationFlowPerCycleFuture: { label: t("FlowPercycle") + Fmt.getUnitInBracket("nanoLiterPerMinute", t), idkey: "MCACEDIT-FlowPercycle", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [checkIsDecimal] },
    optimizationCycleTimeFuture: { label: t("CycleTime") + Fmt.addBrackets("SecondsSymbol", t), idkey: "MCACEDIT-CycleTime", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger] },
    optimizationCycleMinFuture: { label: t("CyclesPerMinute"), idkey: "MCACEDIT-CyclesPerMinute", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [checkIsDecimal] },
    optimizationNrComponentsFuture: { label: t("QuontityOfComponents"), idkey: "MCACEDIT-QuontityOfComponents", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger] },
    optimizationFlowFuture: { label: "", idkey: "MCACEDIT-optimizationFlowFuture", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [] },
    optimizationOperatingHoursFuture: { label: t("OperatingHoursDay"), idkey: "MCACEDIT-OperatingHoursDay", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger] },
    optimizationOperatingDaysFuture: { label: t("OperatingDaysWeek"), idkey: "MCACEDIT-OperatingDaysWeek", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger] },
    optimizationVolumeYearFuture: { label: t("OptimizationVolumeYearFuture"), idkey: "MCACEDIT-OptimizationVolumeYearFuture", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [] },
    optimizationCostsFuture: { label: t("OptimizationCostsFuture"), idkey: "MCACEDIT-OptimizationCostsFuture", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [] },
    optimizationFlowSavings: { label: t("OptimizationFlowSavings"), idkey: "MCACEDIT-OptimizationFlowSavings", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [], headerName: t('SavingsVolumePerMinute') + Fmt.getUnitInBracket("nanoLiterPerMinute", t) },
    optimizationVolumeYearSavings: { label: t("OptimizationVolumeYearSavings"), idkey: "MCACEDIT-OptimizationVolumeYearSavings", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [], headerName: t('SavingsVolumePerYear') + Fmt.getUnitInBracket("cubicMeter", t) },
    optimizationLabourCosts: { label: t("LabourCosts") + Fmt.getCurrency(), idkey: "MCACEDIT-LabourCosts", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger] },
    optimizationSparePartsCosts: { label: t("SparePartsCosts") + Fmt.getCurrency(), idkey: "MCACEDIT-SparePartsCosts", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger] },
    optimizationLumpSumCosts: { label: t("LumpSumNoSuffix") + Fmt.getCurrency(), idkey: "MCACEDIT-LumpSumNoSuffix", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger] },
    optimizationtravelTimeCosts: { label: t("TravelTimeCosts") + Fmt.getCurrency(), idkey: "MCACEDIT-TravelTimeCosts", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger] },
    optimizationtravelCosts: { label: t("TravelCosts") + Fmt.getCurrency(), idkey: "MCACEDIT-TravelCosts", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger] },
    optimizationInvestment: { label: t("OptimizationInvestment") + Fmt.getCurrency(), idkey: "MCACEDIT-OptimizationInvestment", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [], headerName: t('TotalInvestment') + Fmt.getCurrency() },
    optimizationSavingsBeforeTaxes: { label: t("OptimizationSavingsBeforeTaxes") + Fmt.getCurrency(), idkey: "MCACEDIT-OptimizationSavingsBeforeTaxes", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [], headerName: t('SavingsCostsPerYear') + Fmt.getCurrency() },
    optimizationFiscalDepreciation: { label: t("OptimizationFiscalDepreciation") + Fmt.getCurrency(), idkey: "MCACEDIT-OptimizationFiscalDepreciation", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [] },
    settingsServiceLife: { label: t("SettingsServiceLife"), idkey: "MCACEDIT-SettingsServiceLife", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [] },
    optimizationTaxableSavings: { label: t("OptimizationTaxableSavings") + Fmt.getCurrency(), idkey: "MCACEDIT-OptimizationTaxableSavings", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [] },
    optimizationTaxProfit: { label: t("OptimizationTaxProfit") + Fmt.getCurrency(), idkey: "MCACEDIT-OptimizationTaxProfit", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [] },
    optimizationSavingsAfterTaxes: { label: t("OptimizationSavingsAfterTaxes") + Fmt.getCurrency(), idkey: "MCACEDIT-OptimizationSavingsAfterTaxes", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [] },
    optimizationPaybackTime: { label: t("OptimizationPaybackTime"), idkey: "MCACEDIT-OptimizationPaybackTime", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [], headerName: t('PaybackTimeInMonths') },
    applicationComment: { label: t("ApplicationComment"), idkey: "MCACEDIT-ApplicationComment", value: "", isValid: true, validationError: "", validators: [] },
    building: { label: t("Building"), idkey: "MCACEDIT-Building", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace] },
    department: { label: t("Department"), idkey: "MCACEDIT-Department", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace] },
    machine: { label: t("Machine"), idkey: "MCACEDIT-Machine", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace] },
    repairComment: { label: t("RepairComment"), idkey: "MCACEDIT-RepairComment", value: "", isValid: true, validationError: "", validators: [] },
})