// @ts-expect-error not converted yet
import { checkIsAnythingSelected, checkIsInteger } from "../../../../../helper/Validator";
import { ModelParam, Translate } from "../../../../Types/Types";

export interface IMoveLicenseFactory {
    executorReceiverId: ModelParam<number>,
    quantity:  ModelParam<string>,
}

export const MoveLicenseFactory = (t: Translate) : IMoveLicenseFactory => ({
    executorReceiverId: { value: 0, label: t("LicenseReceiverExecutor"), idkey: "MoveLicense-executorReceiverId", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsAnythingSelected] },
    quantity: { value: "", label: t("Quantity"), idkey: "MoveLicense-quantity", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsInteger] }
})
