import React from "react"
import { Checkbox, CheckboxProps, styled } from '@mui/material';

const StyledCheckbox = styled(Checkbox)((props) => ({
    borderRadius: 2,
    opacity: 1,
    marginBottom: 0,
    "&.Mui-checked": {
        color: !props.disabled && '#0091DC'
    },
})) as typeof Checkbox;

const SimpleCheckbox = (props: CheckboxProps) => (<StyledCheckbox {...props} />)
export default SimpleCheckbox;