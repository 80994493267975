import React from 'react';
import { Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { usePdfStyles, StyleTable, FlexTable } from './PdfStyles';
import { useTranslation } from 'react-i18next';
import * as DateHelper from '../../../helper/DateHelper';
import * as Fmt from '../../../helper/Formatters';
import Watermark from './Watermark';
import { getFontName } from '../../../helper/Pdf';
import { SplitText } from '../../../helper/PdfTested';

const styles = StyleSheet.create({
    noBorderCol: {
        borderBottom: "3px solid black",
        flexGrow: 1,
        flexBasis: 0
    },
    flexRow: {
        display: "flex",
    },
    flexItem: {
        flexGrow: 1,
        borderBottom: "3px solid black",
        flexBasis: 0,
    },
    tableCell: {
        marginTop: 5,
        marginBottom: 2,
        marginLeft: 5,
        marginRight: 5,
        fontSize: 10,
        minHeight: 15,
        width: "70px",
    },
    tableCellTotal: {
        marginTop: 5,
        marginBottom: 2,
        marginLeft: 5,
        marginRight: 5,
        fontSize: 10,
        minHeight: 15,
        width: "70px",
        fontFamily: getFontName(true),
    },
    tableCell2: {
        fontSize: 10,
        minHeight: 15,
        width: "70px",
        prosition: "absolute",
        left: "62px"
    },
    tableCol: {
        borderBottom: "3px solid black",
        borderRight: "1px solid black",
        flexGrow: 1,
        flexBasis: 0,
    },
    chartViewStyle: {
        marginRight: 10,
        width: 240,
        height: 192
    },
    chartStyle: {
        width: "240px",
        height: "192px"
    },
});

export const LeakageDetectionAndRepairPdf = (props) => {
    const { data, calcs, country, projectData } = props;

    const { t } = useTranslation();
    var PdfStyles = usePdfStyles();

    return (
        <Page size={props.pageSize} style={PdfStyles.page}>
            <Watermark projectData={projectData} />
            <Text style={PdfStyles.pageNumbers} render={({ pageNumber, totalPages }) => (pageNumber + ' ' + t('of') + ' ' + totalPages)} fixed />
            <View wrap={false}>
                <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["LeakageDetection"] = pageNumber }} />
            </View>
            <Text style={PdfStyles.boldHeading}>{props.tocValues.tocNumbering.ldar}. {t('LeakageDetectionAndDocumentation')}</Text>
            <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.ldar}.1 {t('Results')}</Text>
            <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["LeakageDetectionResults"] = pageNumber }} />
            <Text style={PdfStyles.spacer}></Text>
            <View style={StyleTable.table} wrap={false}>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}></Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('DetectedLeakages')}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('RepairedLeakages')}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('OpenLeakages')}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{t('AirFlow')} {Fmt.getUnitInBracket("volumeFlowRate", t)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(calcs.leakageFlowTotal, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(calcs.leakageFlowRepairedTOTAL, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(calcs.leakageFlowOpenTOTAL, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{t('AirVolume')} {Fmt.getUnitInBracket("cubicMeter", t)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(calcs.leakageAirVolumeTOTAL, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(calcs.leakageAirVolumeRepairedTOTAL, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(calcs.leakageAirVolumeOpenTOTAL, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{t('ExpectedLeakages')} {Fmt.getPercentage()}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(calcs.leakageDetectedPercentageTOTAL, country, 1)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(calcs.leakageRepairedPercentageTOTAL, country, 1)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(calcs.leakageOpenPercentageTOTAL, country, 1)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{t('ElectricityConsumption')} {Fmt.getUnitInBracket("powerPerHour", t)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(calcs.leakageElectricityConsumptionTOTAL, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(calcs.leakageElectricityConsumptionRepairedTOTAL, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(calcs.leakageElectricityConsumptionOpenTOTAL, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{t('CO2PerYear')} {Fmt.getUnitInBracket("co2Wheight", t)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(calcs.leakageCO2TOTAL, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(calcs.leakageCO2RepairedTOTAL, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(calcs.leakageCO2OpenTOTAL, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('CostPerYear')} {Fmt.getCurrency()}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(calcs.leakageDetectedCostsTOTAL, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(calcs.leakageRepairedCostsTOTAL, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(calcs.leakageOpenCostsTOTAL, country, 0)}</Text>
                    </View>
                </View>
            </View>
            {/**-------------------------------------------------------------------------- */}
            <Text style={PdfStyles.spacer}></Text>
            <View style={StyleTable.table} wrap={false}>
                <View style={[StyleTable.tableRow, { borderBottom: "3px solid black" }]}>
                    <View style={{ flexGrow: 1.48, flexBasis: 0, borderRight: "1px solid black" }}>
                    </View>
                    <View style={{ flexGrow: 5, flexBasis: 0, display: "flex", flexDirection: "column" }}>
                        <View style={{ flexDirection: "row", alignItems: "stretch" }}>
                            <View style={[StyleTable.tableColRowZero, { flexGrow: 2, flexBasis: 0 }]}>
                                <Text style={[StyleTable.tableCell, { textAlign: "center" }]}>{t('Tags')}</Text>
                            </View>
                            <View style={[StyleTable.tableColRowZero, { flexGrow: 3, flexBasis: 0 }]}>
                                <Text style={[StyleTable.tableCell, { textAlign: "center" }]}>{t('AirFlow')}</Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: "row", alignItems: "stretch" }}>
                            <View style={[StyleTable.tableRow, { flexGrow: 1, flexBasis: 0 }]}>
                                <View style={StyleTable.colNoBottomBorder}>
                                    <Text style={StyleTable.tableCell}>{t('Quantity')}</Text>
                                </View>
                                <View style={StyleTable.colNoBottomBorder}>
                                    <Text style={StyleTable.tableCell}>%</Text>
                                </View>
                                <View style={StyleTable.colNoBottomBorder}>
                                    <Text style={StyleTable.tableCell}>{SplitText(t('Detected') + Fmt.getUnitInBracket("nanoLiterPerMinute", t), 12)}</Text>
                                </View>
                                <View style={StyleTable.colNoBottomBorder}>
                                    <Text style={StyleTable.tableCell}>{t('CostPerYear')} {Fmt.getCurrency()}</Text>
                                </View>
                                <View style={StyleTable.colNoBottomBorder}>
                                    <Text style={StyleTable.tableCell}>%</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={[StyleTable.tableCol, { flexGrow: 1.5 }]}>
                        <Text style={styles.tableCell}>{t('HighLeakages')}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageQuantityHigh, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakagePercentageHigh, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageAirFlowHigh, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageCostsHigh, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageAirFlowPercentageHigh, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={[StyleTable.tableCol, { flexGrow: 1.5 }]}>
                        <Text style={styles.tableCell}>{t('MediumLeakages')}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageQuantityMedium, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakagePercentageMedium, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageAirFlowMedium, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageCostsMedium, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageAirFlowPercentageMedium, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={[StyleTable.tableCol, { flexGrow: 1.5 }]}>
                        <Text style={styles.tableCell}>{t('LowLeakages')}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageQuantityLow, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakagePercentageLow, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageAirFlowLow, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageCostsLow, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageAirFlowPercentageLow, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={[StyleTable.tableCol, { flexGrow: 1.5 }]}>
                        <Text style={styles.tableCell}>{t('Optimizations')}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageQuantityOptimizations, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakagePercentageOptimizations, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageAirFOptimizationsOptimizations, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageLeakageCostsOptimizations, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageAirFOptimizationsPercentageOptimizations, country, 0)}</Text>
                    </View>
                </View>

                <View style={StyleTable.tableRow}>
                    <View style={[StyleTable.tableColRowZero, { flexGrow: 1.5 }]}>
                        <Text style={styles.tableCellTotal}>{t('TotalTags')}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={styles.tableCellTotal}>{Fmt.round(calcs.leakageQuantityTOTAL, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={styles.tableCellTotal}>100%</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={styles.tableCellTotal}>{Fmt.round(calcs.leakageAirFlowTOTAL, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={styles.tableCellTotal}>{Fmt.round(calcs.leakageDetectedCostsTOTAL, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={styles.tableCellTotal}>100%</Text>
                    </View>
                </View>
            </View>
            {/**-------------------------------------------------------------------------- */}
            <Text style={PdfStyles.spacer}></Text>
            <View style={StyleTable.table} wrap={false}>
                <View style={{ display: "flex", flexDirection: "row", borderBottom: "3px solid black" }}>
                    <View style={{ flexGrow: 1.48, flexBasis: 0, borderRight: "1px solid black" }}>
                    </View>
                    <View style={[FlexTable.column, { flexGrow: 4, flexBasis: 0, borderRight: "1px solid black" }]}>
                        <View style={[FlexTable.row, { borderBottom: "3px solid black", }]}>
                            <View style={FlexTable.rowItem}>
                                <Text style={[FlexTable.rowItemText, { textAlign: "center" }]}>{t('RepairedLeakages')}</Text>
                            </View>
                            <View style={FlexTable.rowItem}>
                                <Text style={[FlexTable.rowItemText, { textAlign: "center" }]}>{t('RepairTime')}</Text>
                            </View>
                        </View>
                        <View style={FlexTable.row}>
                            <View style={FlexTable.rowItem}>
                                <Text style={[FlexTable.rowItemText, { textAlign: "center" }]}>{t('Done')}</Text>
                            </View>
                            <View style={FlexTable.rowItem}>
                                <Text style={[FlexTable.rowItemText, { textAlign: "center" }]}>{t('Open')}</Text>
                            </View>
                            <View style={FlexTable.rowItem}>
                                <Text style={[FlexTable.rowItemText, { textAlign: "center" }]}>{t('Estimated')}</Text>
                            </View>
                            <View style={FlexTable.rowItem}>
                                <Text style={[FlexTable.rowItemText, { textAlign: "center" }]}>{SplitText(t('Repaired'), 15)}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={[StyleTable.tableCol, { flexGrow: 1.5 }]}>
                        <Text style={styles.tableCell}>{t('HighLeakages')}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageQuantityRepairedHigh, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageQuantityOpenHigh, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageQuantityRepairTimeEstimatedHigh, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageQuantityRepairTimeRepairedHigh, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={[StyleTable.tableCol, { flexGrow: 1.5 }]}>
                        <Text style={styles.tableCell}>{t('MediumLeakages')}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageQuantityRepairedMedium, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageQuantityOpenMedium, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageQuantityRepairTimeEstimatedMedium, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageQuantityRepairTimeRepairedMedium, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={[StyleTable.tableCol, { flexGrow: 1.5 }]}>
                        <Text style={styles.tableCell}>{t('LowLeakages')}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageQuantityRepairedLow, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageQuantityOpenLow, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageQuantityRepairTimeEstimatedLow, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageQuantityRepairTimeRepairedLow, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={[StyleTable.tableCol, { flexGrow: 1.5 }]}>
                        <Text style={styles.tableCell}>{t('Optimizations')}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageQuantityRepairedOptimizations, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageQuantityOpenOptimizations, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageQuantityRepairTimeEstimatedOptimizations, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={styles.tableCell}>{Fmt.round(calcs.leakageQuantityRepairTimeRepairedOptimizations, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={[StyleTable.tableColRowZero, { flexGrow: 1.5 }]}>
                        <Text style={styles.tableCellTotal}>{t('TotalTags')}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={styles.tableCellTotal}>{Fmt.round(calcs.leakageQuantityRepairedTOTAL, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={styles.tableCellTotal}>{Fmt.round(calcs.leakageQuantityOpenTOTAL, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={styles.tableCellTotal}>{Fmt.round(calcs.leakageQuantityRepairTimeEstimatedTOTAL, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={styles.tableCellTotal}>{Fmt.round(calcs.leakageQuantityRepairTimeRepairedTOTAL, country, 0)}</Text>
                    </View>
                </View>
            </View>
            {/**-------------------------------------------------------------------------- */}
            <Text style={PdfStyles.spacer} break></Text>
            <View wrap={false}>
                <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["LeakageDetectionList"] = pageNumber }} />
                <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.ldar}.2. {t('ListOfLeakages')}</Text>
            </View>
            <Text style={PdfStyles.spacer}></Text>
            <View style={StyleTable.table}>
                <View style={StyleTable.tableRow} fixed>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('LeakageNr')}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('UniqueId')}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{SplitText(t('LeakageClasification'), 10)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{SplitText(t('Repaired?'), 10)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('Building')}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('Department')}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('Machine')}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('EntryDate')}</Text>
                    </View>
                </View>
                {data.leakages.map((item, index) =>
                    <View style={StyleTable.tableRow} key={'LDAR' + index} wrap={false}>
                        <View style={index == data.leakages.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{item.leackageNumber}</Text>
                        </View>
                        <View style={index == data.leakages.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{item.measurementId}</Text>
                        </View>
                        <View style={index == data.leakages.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{t(calcs?.indLeakageCalcResults[index]?.leakageClasification)}</Text>
                        </View>
                        <View style={index == data.leakages.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{item.leakageRepaired ? t('Yes') : t('No')}</Text>
                        </View>
                        <View style={index == data.leakages.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{SplitText(item.building, 11)}</Text>
                        </View>
                        <View style={index == data.leakages.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{SplitText(item.department, 11)}</Text>
                        </View>
                        <View style={index == data.leakages.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{SplitText(item.machine, 11)}</Text>
                        </View>
                        <View style={index == data.leakages.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{DateHelper.FormatDate(item.detectedDate)}</Text>
                        </View>
                    </View>
                )}
            </View>
            <Text style={PdfStyles.spacer}></Text>
            <View>
                <View wrap={false}>
                    <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["LeakageDetectionCharts"] = pageNumber }} />
                    <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.ldar}.3. {t('LeakageDetectionCharts')}</Text>
                    <Text style={PdfStyles.spacer}></Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}  >
                    <View wrap={false} style={styles.chartViewStyle} >
                        <Text style={PdfStyles.text}>{t('QuantityOfDetectedLeakages')}</Text>
                        <Text style={PdfStyles.spacer}></Text>
                        <Image src={props.chart.ldarDetectedLeakages} style={styles.chartStyle} />
                        <Text style={PdfStyles.spacer}></Text>
                    </View>
                    <View wrap={false} style={styles.chartViewStyle} >
                        <Text style={PdfStyles.text}>{t('RepairProcess')}</Text>
                        <Text style={PdfStyles.spacer}></Text>
                        <Image src={props.chart.ldarRepairProcess} style={styles.chartStyle} />
                        <Text style={PdfStyles.spacer}></Text>
                    </View>
                    <View wrap={false} style={styles.chartViewStyle}>
                        <Text style={PdfStyles.text}>{t('CostsAndSavingofTheLeakages')}</Text>
                        <Text style={PdfStyles.spacer}></Text>
                        <Image src={props.chart.ldarCostSavings} style={styles.chartStyle} />
                        <Text style={PdfStyles.spacer}></Text>
                    </View>
                    <View wrap={false} style={styles.chartViewStyle}>
                        <Text style={PdfStyles.text}>{t('CO2EmmissionsAndSavings')}</Text>
                        <Text style={PdfStyles.spacer}></Text>
                        <Image src={props.chart.ldarCO2} style={styles.chartStyle} />
                        <Text style={PdfStyles.spacer}></Text>
                    </View>
                </View>
            </View>
        </Page>
    )
}