import React, { useState, useEffect } from 'react'
import { Grid, IconButton, InputAdornment, InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { handleFilterFunc, handleFilterDelete } from "./FilterHelper/FilterHelper";

export default function FilterComponent(props) {
    const { onFilterFunction, textFilterFields, clearTags, id, chipsArray, handleChipArray, tableId } = props;
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        if (clearTags) {
            handleChipArray([])
        }
    }, [clearTags])

    useEffect(() => {
        if (chipsArray.length > 0) {
            filterRequestData(true)
        }
    }, [props.filterData])

    const filterRequestData = (checkRerender) => {
        let tempChips;
        if (checkRerender)
            tempChips = chipsArray;
        else {
            if (inputValue == "") return;
            tempChips = [{ name: inputValue, key: textFilterFields }, ...chipsArray];
        }
        let tempdata = props.defaultData;
        for (const chip of tempChips) {
            tempdata = handleFilterFunc(tempdata, chip.name, chip.key, t);
        }
        handleChipArray(tempChips)
        onFilterFunction(tempdata);
        setInputValue("");
    }

    const handleDeleteChip = (data, index, key) => {
        var tempdata = handleFilterDelete(chipsArray, data, props.filterData, t);
        handleChipArray((chips) => chips.filter((chip, inde) => inde !== index))
        onFilterFunction(tempdata)
        if (document.getElementById(`ref_${tableId}_${key}_${data}`) != null)
            document.getElementById(`ref_${tableId}_${key}_${data}`).checked = false;
    }

    const _handleKeyDownRequest = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            filterRequestData();
        }
    }

    return (
        <div>
            <Grid container alignItems="center">
                <InputBase
                    id={id ? "inpt_Filter-" + id : "inpt_filterDefault"}
                    placeholder={t('Filter')}
                    onKeyDown={e => _handleKeyDownRequest(e)}
                    inputProps={{ 'aria-label': 'Filter' }}
                    style={{
                        boxShadow: " 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
                    }}
                    startAdornment={<InputAdornment position="start"><IconButton
                        aria-label="menu"
                        id={id ? "btn_Filter-" + id : "btn_filterDefaultBtn"}
                        onClick={() => filterRequestData()}
                        size="large"
                    >
                        <SearchIcon />
                    </IconButton>
                    </InputAdornment>}
                    onChange={e => setInputValue(e.target.value)}
                    value={inputValue}
                />
                {chipsArray.map((data, index) => {
                    return (
                        <React.Fragment key={index}>
                            <Chip
                                className="filter_chips"
                                label={data.name}
                                onDelete={() => handleDeleteChip(data.name, index, data.key)}
                            />
                        </React.Fragment>
                    )
                })}
            </Grid>
        </div>
    );
}
