import React, { useEffect, useState } from 'react';
import { Snackbar, IconButton, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { setOpenGlobalSnackbar } from '../../../helper/GlobalVariables';

const StyledSnackbar = styled(Snackbar)((props) => ({
    '& >div:first-of-type': {
        background: colors[props.snackType],
        paddingLeft: '20px'
    }
}));

const colors = { danger: 'linear-gradient(to right, #D50000 10px, #333333 10px)', success: 'linear-gradient(to right, #0091DC 10px, #333333 10px)' };

export default function SimpleSnackbar(props) {
    const [open, setOpen] = useState(false);
    const [snackBarModel, setSnackBarModel] = useState({
        open: false,
        snackType: "success",
        message: "",
        vertical: "bottom",
        horizontal: "center"
    });

    const model = props.model ? props.model : snackBarModel;

    useEffect(() => {
        if (model.open)
            setOpen(true)
    }, [model])

    useEffect(() => {
        if (props.isGlobal === true) initiateGlobalVariables();
    }, [props.isGlobal]);

    const initiateGlobalVariables = async () => {
        await setOpenGlobalSnackbar(openSnack);
    }

    const openSnack = (type, message) => {
        snackBarModel.snackType = type;
        snackBarModel.message = message;
        snackBarModel.open = true;
        setSnackBarModel({ ...snackBarModel })
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <StyledSnackbar
            {...props}
            id={"snack_" + model.snackType}
            anchorOrigin={{
                vertical: model.vertical,
                horizontal: model.horizontal
            }}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            message={model.message}
            action={
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} id={"btn_snack-" + model.snackType}>
                        <CloseIcon id={"snack_close"} fontSize="small" />
                    </IconButton>
                </React.Fragment>
            }
        />
    );
}