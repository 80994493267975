import { TextField, Tooltip, createTheme, styled } from "@mui/material";

export const theme = createTheme({
    typography: {
        fontFamily: 'Meta Pro',
        h6: {
            font: "Normal 1.5rem/1.5rem Meta Pro !important",
        },
        h3: {
            font: "Normal 1.5rem/1.5rem Meta Pro !important",
        },
        body1: {
            font: " Normal 1rem/1.5rem Meta Pro !important",
        },
        body2: {
            font: " Normal 1rem/1.5rem Meta Pro !important",
        },
    },
    palette: {
        error: { 
            main: "#D50000" 
        }
    },
    components: {
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    "&.Mui-focused": {
                        color: "#0091dc",
                    },
                    font: " Normal 1rem/1.5rem Meta Pro !important",
                },
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    font: " Normal 1rem/1.5rem Meta Pro !important",
                },
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    font: " Normal 1rem/1.5rem Meta Pro !important",
                },
            }
        },
        MuiInput: {
            styleOverrides: {
                underline: {
                    "&:after": {
                        borderBottom: "2px solid #0091dc",
                    },
                },
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    "&:focus": {
                        outline: "none !important"
                    }
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    font: " Normal 1rem/1.5rem Meta Pro !important",
                },
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: '#0091dc'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: 'black'
                }
            }
        }
    },
});


export const StyledTooltip = styled(Tooltip)({
    '& .MuiTooltip-arrow': {
        color: "#f44336"
    },
    '& .MuiTooltip-tooltip': {
        backgroundColor: "#f44336",
        fontSize: 12
    },
}) as typeof Tooltip;

export const StyledTextField = styled(TextField)({
    font: 'Normal 1rem/1.125rem Meta Pro',
    '& > p': {
        position: "absolute",
        top: 50
    },
    '& > div': {
        '& > input': {
            paddingTop: 9,
            paddingBottom: 1
        }
    },
    '& > label': {
        whiteSpace: 'nowrap',
        margin: 0
    },
    '& .Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.87)',
        '&::before': {
            //borderBottomStyle: 'none', //since want to underline when denyEdit is in place
        }
    }
}) as typeof TextField;