import React from "react"
import { styled } from '@mui/material';

interface ILabelComponent {
    value: string,
}

const StyledLabel = styled('label')({
    font: "Normal 0.75rem/1.125rem Meta Pro",
    letterSpacing: 0,
    color: '#333333',
    opacity: 1,
    margin: 0
});

const LabelComponent = (props: ILabelComponent) => {
    return <StyledLabel>{props.value}</StyledLabel>
}

export default LabelComponent;