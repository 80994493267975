import React from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { usePdfStyles, StyleTable } from './PdfStyles';
import {
    savingsEliminatingTheLeakagesTable,
    savingsShuttingOffMainAirSupplyAtNonProductionTimeTable,
    savingsOptimizingTheAirConsumptionAtSingleApplicationsTable,
    cumulativeHighestSavingsPerYearTable,
    staticConsumptionNlMin,
    electricityConsumptionkWh,
    airConsumptionNm3,
    co2,
    energyCosts,
    totalCosts,
    level,
    timeAirSupplyPerYearHours,
    flowRateNlmin
} from '../../CostSavings/Constants/Constants';
import SavingsReducingThePressureDropTable from './PdfTables/SavingsReducingThePressureDropTable';
import SavingsReducingThePressureLevelTable from './PdfTables/SavingsReducingThePressureLevelTable';
import * as Fmt from '../../../helper/Formatters';
import Watermark from './Watermark';
import { SplitText } from '../../../helper/PdfTested';

export const CostSavingsPdf = (props) => {
    const { data, country, projectData } = props;

    const { t } = useTranslation();
    var PdfStyles = usePdfStyles();

    const future = (costSavingTypes) => {
        return data.find(x => x.costSavingMeasure == 'Future' && x.costSavingType == costSavingTypes)
    }

    const actual = (costSavingTypes) => {
        return data.find(x => x.costSavingMeasure == 'Actual' && x.costSavingType == costSavingTypes)
    }

    const savings = (costSavingTypes) => {
        return data.find(x => x.costSavingMeasure == 'Savings' && x.costSavingType == costSavingTypes)
    }

    return (
        <Page size={props.pageSize} style={PdfStyles.page}>
            <Watermark projectData={projectData} />
            <Text style={PdfStyles.pageNumbers} render={({ pageNumber, totalPages }) => (pageNumber + ' ' + t('of') + ' ' + totalPages)} fixed />
            <View wrap={false}>
                <Text style={PdfStyles.boldHeading}>{props.tocValues.tocNumbering.costSavings}. {t("CostSavings")} </Text>
                <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.costSavings}.1. {t("SavingsEliminatingTheLeakagesTable")} </Text>
                <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["SavingsLeakages"] = pageNumber }} />
            </View>
            <Text style={PdfStyles.spacer}></Text>
            <View style={StyleTable.table} wrap={false}>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}></Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{SplitText(t(staticConsumptionNlMin), 10) + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{SplitText(t(electricityConsumptionkWh), 10) + Fmt.getUnitInBracket("powerPerHour", t)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t(airConsumptionNm3) + Fmt.getUnitInBracket("cubicMeterOfGas", t)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t(co2) + Fmt.getUnitInBracket("co2Wheight", t)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{SplitText(t(energyCosts), 10) + Fmt.getCurrency()}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCellTotal}>{t(totalCosts) + Fmt.getCurrency()}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t(level) + Fmt.getPercentage()}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{SplitText(t('Actual'), 10)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(actual(savingsEliminatingTheLeakagesTable).staticConsmuption, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(actual(savingsEliminatingTheLeakagesTable).electricityConsumption, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(actual(savingsEliminatingTheLeakagesTable).airConsumption, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(actual(savingsEliminatingTheLeakagesTable).cO2, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(actual(savingsEliminatingTheLeakagesTable).energyCosts, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCellTotal}>{Fmt.round(actual(savingsEliminatingTheLeakagesTable).totalCosts, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(actual(savingsEliminatingTheLeakagesTable).level, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{SplitText(t('Future'), 10)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(future(savingsEliminatingTheLeakagesTable).staticConsmuption, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(future(savingsEliminatingTheLeakagesTable).electricityConsumption, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(future(savingsEliminatingTheLeakagesTable).airConsumption, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(future(savingsEliminatingTheLeakagesTable).cO2, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(future(savingsEliminatingTheLeakagesTable).energyCosts, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCellTotal}>{Fmt.round(future(savingsEliminatingTheLeakagesTable).totalCosts, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(future(savingsEliminatingTheLeakagesTable).level, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{SplitText(t('SavingsCompressedAirGeneration'), 10)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(savings(savingsEliminatingTheLeakagesTable).staticConsmuption, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(savings(savingsEliminatingTheLeakagesTable).electricityConsumption, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(savings(savingsEliminatingTheLeakagesTable).airConsumption, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(savings(savingsEliminatingTheLeakagesTable).cO2, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(savings(savingsEliminatingTheLeakagesTable).energyCosts, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCellTotal}>{Fmt.round(savings(savingsEliminatingTheLeakagesTable).totalCosts, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(savings(savingsEliminatingTheLeakagesTable).level, country, 0)}</Text>
                    </View>
                </View>
            </View>
            {/**--------------------------------------------------------------------------------- */}
            <Text style={PdfStyles.spacer}></Text>
            <View wrap={false}>
                <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.costSavings}.2. {t("SavingsShuttingOffMainAirSupplyAtNonProductionTimeTable")} </Text>
                <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["SavingsShuttingOffMainAirSupply"] = pageNumber }} />
            </View>

            <Text style={PdfStyles.spacer}></Text>
            <View style={StyleTable.table} wrap={false}>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}></Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t(timeAirSupplyPerYearHours) + Fmt.getUnitInBracket("hours", t)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{SplitText(t(electricityConsumptionkWh), 10) + Fmt.getUnitInBracket("powerPerHour", t)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t(airConsumptionNm3) + Fmt.getUnitInBracket("cubicMeterOfGas", t)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t(co2) + Fmt.getUnitInBracket("co2Wheight", t)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{SplitText(t(energyCosts), 10) + Fmt.getCurrency()}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCellTotal}>{t(totalCosts) + Fmt.getCurrency()}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t(level) + Fmt.getPercentage()}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{SplitText(t('Actual'), 10)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(actual(savingsShuttingOffMainAirSupplyAtNonProductionTimeTable).staticConsmuption, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(actual(savingsShuttingOffMainAirSupplyAtNonProductionTimeTable).electricityConsumption, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(actual(savingsShuttingOffMainAirSupplyAtNonProductionTimeTable).airConsumption, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(actual(savingsShuttingOffMainAirSupplyAtNonProductionTimeTable).cO2, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(actual(savingsShuttingOffMainAirSupplyAtNonProductionTimeTable).energyCosts, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCellTotal}>{Fmt.round(actual(savingsShuttingOffMainAirSupplyAtNonProductionTimeTable).totalCosts, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(actual(savingsShuttingOffMainAirSupplyAtNonProductionTimeTable).level, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{SplitText(t('Future'), 10)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(future(savingsShuttingOffMainAirSupplyAtNonProductionTimeTable).staticConsmuption, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(future(savingsShuttingOffMainAirSupplyAtNonProductionTimeTable).electricityConsumption, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(future(savingsShuttingOffMainAirSupplyAtNonProductionTimeTable).airConsumption, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(future(savingsShuttingOffMainAirSupplyAtNonProductionTimeTable).cO2, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(future(savingsShuttingOffMainAirSupplyAtNonProductionTimeTable).energyCosts, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCellTotal}>{Fmt.round(future(savingsShuttingOffMainAirSupplyAtNonProductionTimeTable).totalCosts, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(future(savingsShuttingOffMainAirSupplyAtNonProductionTimeTable).level, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{SplitText(t('SavingsCompressedAirGeneration'), 10)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(savings(savingsShuttingOffMainAirSupplyAtNonProductionTimeTable).staticConsmuption, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(savings(savingsShuttingOffMainAirSupplyAtNonProductionTimeTable).electricityConsumption, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(savings(savingsShuttingOffMainAirSupplyAtNonProductionTimeTable).airConsumption, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(savings(savingsShuttingOffMainAirSupplyAtNonProductionTimeTable).cO2, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(savings(savingsShuttingOffMainAirSupplyAtNonProductionTimeTable).energyCosts, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCellTotal}>{Fmt.round(savings(savingsShuttingOffMainAirSupplyAtNonProductionTimeTable).totalCosts, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(savings(savingsShuttingOffMainAirSupplyAtNonProductionTimeTable).level, country, 0)}</Text>
                    </View>
                </View>
            </View>
            {/**--------------------------------------------------------------------------------- */}
            <Text style={PdfStyles.spacer}></Text>
            <View wrap={false}>
                <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.costSavings}.3. {t("SavingsReducingThePressureDropTable")} </Text>
                <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["SavingsReducingThePressureDrop"] = pageNumber }} />
            </View>
            <Text style={PdfStyles.spacer}></Text>
            <SavingsReducingThePressureDropTable
                data={data}
                country={country}
            />
            {/**--------------------------------------------------------------------------------- */}
            <Text style={PdfStyles.spacer}></Text>
            <View wrap={false}>
                <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.costSavings}.4. {t("SavingsReducingThePressureLevelOfTheSystemTable")} </Text>
                <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["SavingsReducingThePressureLevelOfTheSystem"] = pageNumber }} />
                <Text style={PdfStyles.spacer}></Text>
                <SavingsReducingThePressureLevelTable
                    data={data}
                    country={country}
                />
            </View>
            {/**--------------------------------------------------------------------------------- */}
            <View wrap={false}>
                <Text style={PdfStyles.spacer}></Text>
                <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.costSavings}.5. {t("SavingsOptimizingTheAirConsumptionAtSingleApplicationsTable")} </Text>
                <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["SavingsOptimizingTheAirConsumptionAtSingleApplications"] = pageNumber }} />
                <Text style={PdfStyles.spacer}></Text>
                <View style={StyleTable.table} wrap={false}>
                    <View style={StyleTable.tableRow}>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}></Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{t(flowRateNlmin) + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t)}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{SplitText(t(electricityConsumptionkWh), 10) + Fmt.getUnitInBracket("powerPerHour", t)}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{t(airConsumptionNm3) + Fmt.getUnitInBracket("cubicMeterOfGas", t)}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{t(co2) + Fmt.getUnitInBracket("co2Wheight", t)}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{SplitText(t(energyCosts), 10) + Fmt.getCurrency()}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCellTotal}>{t(totalCosts) + Fmt.getCurrency()}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{t(level) + Fmt.getPercentage()}</Text>
                        </View>
                    </View>
                    <View style={StyleTable.tableRow}>
                        <View style={StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{SplitText(t('Actual'), 10)}</Text>
                        </View>
                        <View style={StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(actual(savingsOptimizingTheAirConsumptionAtSingleApplicationsTable).staticConsmuption, country, 0)}</Text>
                        </View>
                        <View style={StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(actual(savingsOptimizingTheAirConsumptionAtSingleApplicationsTable).electricityConsumption, country, 0)}</Text>
                        </View>
                        <View style={StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(actual(savingsOptimizingTheAirConsumptionAtSingleApplicationsTable).airConsumption, country, 0)}</Text>
                        </View>
                        <View style={StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(actual(savingsOptimizingTheAirConsumptionAtSingleApplicationsTable).cO2, country, 0)}</Text>
                        </View>
                        <View style={StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(actual(savingsOptimizingTheAirConsumptionAtSingleApplicationsTable).energyCosts, country, 0)}</Text>
                        </View>
                        <View style={StyleTable.tableCol}>
                            <Text style={StyleTable.tableCellTotal}>{Fmt.round(actual(savingsOptimizingTheAirConsumptionAtSingleApplicationsTable).totalCosts, country, 0)}</Text>
                        </View>
                        <View style={StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(actual(savingsOptimizingTheAirConsumptionAtSingleApplicationsTable).level, country, 0)}</Text>
                        </View>
                    </View>
                    <View style={StyleTable.tableRow}>
                        <View style={StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{SplitText(t('Future'), 10)}</Text>
                        </View>
                        <View style={StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(future(savingsOptimizingTheAirConsumptionAtSingleApplicationsTable).staticConsmuption, country, 0)}</Text>
                        </View>
                        <View style={StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(future(savingsOptimizingTheAirConsumptionAtSingleApplicationsTable).electricityConsumption, country, 0)}</Text>
                        </View>
                        <View style={StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(future(savingsOptimizingTheAirConsumptionAtSingleApplicationsTable).airConsumption, country, 0)}</Text>
                        </View>
                        <View style={StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(future(savingsOptimizingTheAirConsumptionAtSingleApplicationsTable).cO2, country, 0)}</Text>
                        </View>
                        <View style={StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(future(savingsOptimizingTheAirConsumptionAtSingleApplicationsTable).energyCosts, country, 0)}</Text>
                        </View>
                        <View style={StyleTable.tableCol}>
                            <Text style={StyleTable.tableCellTotal}>{Fmt.round(future(savingsOptimizingTheAirConsumptionAtSingleApplicationsTable).totalCosts, country, 0)}</Text>
                        </View>
                        <View style={StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(future(savingsOptimizingTheAirConsumptionAtSingleApplicationsTable).level, country, 0)}</Text>
                        </View>
                    </View>
                    <View style={StyleTable.tableRow}>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{SplitText(t('SavingsCompressedAirGeneration'), 10)}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(savings(savingsOptimizingTheAirConsumptionAtSingleApplicationsTable).staticConsmuption, country, 0)}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(savings(savingsOptimizingTheAirConsumptionAtSingleApplicationsTable).electricityConsumption, country, 0)}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(savings(savingsOptimizingTheAirConsumptionAtSingleApplicationsTable).airConsumption, country, 0)}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(savings(savingsOptimizingTheAirConsumptionAtSingleApplicationsTable).cO2, country, 0)}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(savings(savingsOptimizingTheAirConsumptionAtSingleApplicationsTable).energyCosts, country, 0)}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCellTotal}>{Fmt.round(savings(savingsOptimizingTheAirConsumptionAtSingleApplicationsTable).totalCosts, country, 0)}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(savings(savingsOptimizingTheAirConsumptionAtSingleApplicationsTable).level, country, 0)}</Text>
                        </View>
                    </View>
                </View>
            </View>
            {/**--------------------------------------------------------------------------------- */}
            <Text style={PdfStyles.spacer}></Text>
            <View wrap={false}>
                <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.costSavings}.6. {t("CumulativeHighestSavingsPerYearTable")} </Text>
                <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["CumulativeHighestSavingsPerYear"] = pageNumber }} />
            </View>
            <Text style={PdfStyles.spacer}></Text>
            <View style={StyleTable.table} wrap={false}>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}></Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{SplitText(t(electricityConsumptionkWh), 10) + Fmt.getUnitInBracket("powerPerHour", t)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t(airConsumptionNm3) + Fmt.getUnitInBracket("cubicMeterOfGas", t)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t(co2) + Fmt.getUnitInBracket("co2Wheight", t)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{SplitText(t(energyCosts), 10) + Fmt.getCurrency()}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCellTotal}>{t(totalCosts) + Fmt.getCurrency()}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t(level) + Fmt.getPercentage()}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{SplitText(t('Actual'), 10)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(actual(cumulativeHighestSavingsPerYearTable).electricityConsumption, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(actual(cumulativeHighestSavingsPerYearTable).airConsumption, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(actual(cumulativeHighestSavingsPerYearTable).cO2, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(actual(cumulativeHighestSavingsPerYearTable).energyCosts, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCellTotal}>{Fmt.round(actual(cumulativeHighestSavingsPerYearTable).totalCosts, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(actual(cumulativeHighestSavingsPerYearTable).level, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{SplitText(t('Future'), 10)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(future(cumulativeHighestSavingsPerYearTable).electricityConsumption, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(future(cumulativeHighestSavingsPerYearTable).airConsumption, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(future(cumulativeHighestSavingsPerYearTable).cO2, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(future(cumulativeHighestSavingsPerYearTable).energyCosts, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCellTotal}>{Fmt.round(future(cumulativeHighestSavingsPerYearTable).totalCosts, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(future(cumulativeHighestSavingsPerYearTable).level, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{SplitText(t('SavingsCompressedAirGeneration'), 10)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(savings(cumulativeHighestSavingsPerYearTable).electricityConsumption, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(savings(cumulativeHighestSavingsPerYearTable).airConsumption, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(savings(cumulativeHighestSavingsPerYearTable).cO2, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(savings(cumulativeHighestSavingsPerYearTable).energyCosts, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCellTotal}>{Fmt.round(savings(cumulativeHighestSavingsPerYearTable).totalCosts, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(savings(cumulativeHighestSavingsPerYearTable).level, country, 0)}</Text>
                    </View>
                </View>
            </View>
            <Text style={PdfStyles.text}>* {t("InclusinveElectricityMaintenanceInvestmentCosts")}</Text>
            <Text style={PdfStyles.spacer}></Text>
            <View wrap={false}>
                <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["CostSavingsChart"] = pageNumber }} />
                <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.costSavings}.7 {t('CostSavingsChart')}</Text>
                <Text style={PdfStyles.spacer}></Text>
                <Image src={props.chart} />
            </View>
            <View wrap={false}>
                <Text style={PdfStyles.text}>{t("CalibrationCyclePdfText")}</Text>
            </View>
        </Page>
    );
}