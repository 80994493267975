import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'state-pool';
import { Container, Grid, Box, Divider } from '@mui/material';
import { TextButtonComponent } from '../Reusable';
import { CountryListResponse, CountryListStore } from '../../store/CountryListStore';
import { UsedCountries } from '../../helper/CountryLanguages/CountryLanguages';
import { Languages } from '../../helper/GlobalVariables';
import { CountryParam, LanguageParam } from '../Types/Types';

export default function CountryLanguageSelection() {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { country, lng } = useParams<CountryParam & LanguageParam>();

    const [countryList, setCountryList] = useState<CountryListResponse[]>([]);
    const [isLanguageView, setIsLanguageView] = useState<boolean | null>(null);
    const [userCountry, setUserCountry] = useState<string>(country?.toUpperCase() ?? "");
    const [userLanguage, setUserLanguage] = useState(lng ?? "");
    const [_initLanguage, setInitLanguage] = useGlobalState("InitLanguage", { default: false, persist: false }); // eslint-disable-line

    useEffect(() => {
        CountryListStore.initCountryList(userLanguage == "" ? "en" : userLanguage);
        const countryListSub = CountryListStore.countryList.subscribe(list => setCountryList(list));
        return () => countryListSub.unsubscribe();
    }, []);

    const changeView = (langView: boolean) => {
        if (langView === true && userCountry === "") return;
        setIsLanguageView(langView);
    }

    const selectCountry = (country: string) => {
        setUserCountry(country);
        setIsLanguageView(true);
    }

    const selectLanguage = (languageCode: string) => {
        i18n.changeLanguage(languageCode);
        setUserLanguage(languageCode);
        setInitLanguage(false);

        let leadingUrl = '';
        if (location != undefined && location.pathname != undefined && location.pathname.split("/") != undefined) {
            const splitPath = location.pathname.split("/")
            if (splitPath.length > 2 && splitPath[1].length == 2 && splitPath[2].length == 2)
                leadingUrl = location.pathname.slice(location.pathname.split("/", 3).join("/").length);
            else
                leadingUrl = location.pathname;
        }
        history.push(`/${userCountry.toLowerCase()}/${languageCode}${leadingUrl}`)
    }

    const gridContainerStyle = {
        display: "grid",
        gridTemplateRows: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
        gridAutoFlow: "column"
    };

    return (
        <Container>
            <Grid container style={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
                <Divider orientation="vertical" flexItem />
                <Grid item xs={2}>
                    <Grid item>
                        <TextButtonComponent
                            selected={isLanguageView != true}
                            onClick={() => changeView(false)}
                        >
                            {t("Country")}
                        </TextButtonComponent>
                    </Grid>
                    <Grid item>
                        <TextButtonComponent
                            selected={isLanguageView == true}
                            onClick={() => changeView(true)}
                        >
                            {t("Language")}
                        </TextButtonComponent>
                    </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs={9}>
                    {isLanguageView !== true ?
                        <Box sx={gridContainerStyle}>
                            {countryList.filter(country => UsedCountries.some(x => x.countryCode == country.countryCode)).map(country =>
                                <Box key={country.countryId}>
                                    <TextButtonComponent
                                        selected={userCountry === country.countryCode}
                                        onClick={() => selectCountry(country.countryCode)}
                                    >
                                        {country.countryCode == "GB" ? t("CountrySelectionUnitedKingdom") : country.countryName}
                                    </TextButtonComponent>
                                </Box>
                            )}
                        </Box>
                        :
                        <Box sx={gridContainerStyle}>
                            {UsedCountries.find(country => country.countryCode == userCountry)?.languageList.map(language => {
                                const lang = Languages.find(x => x.countryCode == language);
                                return lang == null ? null : <Box key={lang.value}>
                                    <TextButtonComponent
                                        selected={userLanguage === language}
                                        onClick={() => selectLanguage(language)}
                                    >
                                        {t(lang.value)}
                                    </TextButtonComponent>
                                </Box>
                            })}
                        </Box>
                    }
                </Grid>
            </Grid>
        </Container>
    );
}
