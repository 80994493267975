import { MeasurementUnit } from "../../helper/GlobalVariables";
import { PageSize } from '@react-pdf/types';

export type ModelFactory<T> = {
    [K in keyof T]: ModelParam<any>;
};

export interface ModelParam<T, TT = void> {
    value: T,
    default?: T | null | string
    idkey: string,
    label: string,
    headerName?: string,
    isValid: boolean,
    validationError: string,
    validators: ((p1: T, p2?: TT) => boolean)[],
    notRequired?: boolean,
    tkey?: string,
    disabled?: boolean,
    dependsOn?: string,
    type?: number,
    step?: number,
    sortable?: boolean,
    name?: string
}

export interface IDropdownItem {
    id: number;
    value: string;
}

export interface IDropdownValueId {
    id: number;
    value: string;
    idValue: string;
}

export interface KeyValue {
    id: number;
    group: string;
    value: string;
}

export interface IReplacementPart {
    id: number;
    guid?: string;
    partNumber?: string;
    typeCode?: string;
    component?: IDropdownItem;
    manufacturer?: string;
    replaceWithDifferent: boolean;
    newPartNumber?: string;
    newTypeCode?: string;
    newManufacturer?: string;
    quantity: number;
    orderSparePart: boolean;
}
export interface ILeakageReplacementPart extends IReplacementPart {
    leakageId: number,
    leackageNumber?: number
}

export interface ILeakage {
    id: number;
    detectedDate?: string;
    detectedBy?: string;
    leackageNumber?: number;
    measurementId?: number;
    leakageRepaired: boolean;
    repairedBy?: string;
    repairComment?: string;
    repairDate?: Date;
    leakageRepairTimeRepairedInput?: number;
    building?: string;
    department?: string;
    machine?: string;
    leakageInputDevice?: IDropdownItem;
    leakageInputValue?: number;
    leakageFlowConverted?: number;
    leakageAction?: IDropdownItem;
    leakageClasification?: string;
    leakageRepairTimeEstimated?: ILeakageRepairTimeEstimated;
    leakageRepearableProdTime: boolean;
    leakageOptimization: boolean;
    leakageComment?: string;
    guid?: string;
    replacementPartList: IReplacementPart[];
    no: number;
}
export interface IndLeakageCalcResult {
    leakageFlowConverted: number;
    leakageClasification: string;
    leakageOpenCosts: number;
    volumePerYear: number;
    costPerYear: number;
}
export interface ILDARCaclResult {
    indLeakageCalcResults: IndLeakageCalcResult[];
    leakageFlowTOTAL?: number;
    leakageFlowRepairedTOTAL?: number;
    leakageFlowOpenTOTAL?: number;
    leakageAirVolumeTOTAL?: number;
    leakageAirVolumeRepairedTOTAL?: number;
    leakageAirVolumeOpenTOTAL?: number;
    leakageDetectedPercentageTOTAL?: number;
    leakageRepairedPercentageTOTAL?: number;
    leakageOpenPercentageTOTAL?: number;
    leakageElectricityConsumptionTOTAL?: number;
    leakageElectricityConsumptionRepairedTOTAL?: number;
    leakageElectricityConsumptionOpenTOTAL?: number;
    leakageCO2TOTAL?: number;
    leakageCO2RepairedTOTAL?: number;
    leakageCO2OpenTOTAL?: number;
    leakageDetectedCostsTOTAL?: number;
    leakageRepairedCostsTOTAL?: number;
    leakageOpenCostsTOTAL?: number;
    leakageQuantityHigh?: number;
    leakagePercentageHigh?: number;
    leakageAirFlowHigh?: object;
    leakageLeakageCostsHigh?: number;
    leakageCostsHigh?: number;
    leakageAirFlowPercentageHigh?: number;
    leakageQuantityRepairedHigh?: number;
    leakageQuantityOpenHigh?: number;
    leakageQuantityRepairTimeEstimatedHigh?: number;
    leakageQuantityRepairTimeRepairedHigh?: number;
    leakageQuantityMedium?: number;
    leakagePercentageMedium?: number;
    leakageAirFlowMedium?: number;
    leakageCostsMedium?: number;
    leakageAirFlowPercentageMedium?: number;
    leakageQuantityRepairedMedium?: number;
    leakageQuantityOpenMedium?: number;
    leakageQuantityRepairTimeEstimatedMedium?: number;
    leakageQuantityRepairTimeRepairedMedium?: number;
    leakageQuantityLow?: number;
    leakagePercentageLow?: number;
    leakageAirFlowLow?: number;
    leakageCostsLow?: number;
    leakageAirFlowPercentageLow?: number;
    leakageQuantityRepairedLow?: number;
    leakageQuantityOpenLow?: number;
    leakageQuantityRepairTimeEstimatedLow?: number;
    leakageQuantityRepairTimeRepairedLow?: number;
    leakageQuantityOptimizations?: number;
    leakagePercentageOptimizations?: number;
    leakageAirFlowOptimizations?: number;
    leakageCostsOptimization?: number;
    leakageAirFlowPercentageOptimizations?: number;
    leakageQuantityRepairedOptimizations?: number;
    leakageQuantityOpenOptimizations?: number;
    leakageQuantityRepairTimeEstimatedOptimizations?: number;
    leakageQuantityRepairTimeRepairedOptimizations?: number;
    leakageQuantityTOTAL?: number;
    leakageAirFlowTOTAL?: number;
    leakageLeakageCostsTOTAL?: number;
    leakageQuantityRepairedTOTAL?: number;
    leakageQuantityOpenTOTAL?: number;
    leakageQuantityRepairTimeEstimatedTOTAL?: number;
    leakageQuantityRepairTimeRepairedTOTAL?: number;
}
export interface IProjectLDAR {
    id?: number;
    projectId?: number;
    comment?: string;
    guid?: string;
    leakages: ILeakage[];
}
export interface IProjectLDARResults {
    ldar: IProjectLDAR,
    ldarCalcResults: ILDARCaclResult,
    leakageExcelData: ILeakageExcelResult
}
export interface ILeakageExcelResult {
    rows: ILeakageExcelRow[];
}
export interface ILeakageExcelRow {
    leackageNumber: string;
    measurementId: string;
    entryDate: string;
    detectedBy: string;
    building: string;
    department: string;
    machine: string;
    leakageInputDevice: string;
    leakageInputValue: string;
    airFlow: string;
    leakageAction: string;
    repairTimeEstimated: string;
    leakageOptimization: string;
    leakageRepearableProdTime: string;
    articleNrDefected: string;
    typeCodeDefected: string;
    componentDefected: string;
    manufacturerDefected: string;
    articleNrReplacement: string;
    typeCodeReplacement: string;
    newComponent: string;
    manufacturerReplacement: string;
    quantity: number;
    leakageRepaired: string;
    repairedBy: string;
    repairDate: string;
    leakageRepairTimeRepairedInput: string;
    volumePerYear: string;
    energyPerYear: string;
    emissionsPerYear: string;
    costPerYear: string;
    leakageComment: string;
}
export interface ILeakageRepairTimeEstimated {
    id: number;
    value: string;
    calculationValue: number;
}

export type Translate = (s: string) => string


export interface IAlert {
    id?: number;
    message?: string;
    alertType?: KeyValue;
    alertParameters?: IAlertParameter[];
}

export interface IAlertParameter {
    id: number;
    index: string;
    value: string;
}


export interface IBase {
    createdDate?: Date;
    changedDate?: Date;
    deletedDate?: Date;
}

//Param Types:
export type CountryParam = {
    country: string
}
export type LanguageParam = {
    lng: string
}
export type IdParam = {
    id: string
}
export type CidParam = {
    cid: string
}
export type EmailParam = {
    email: string
}
export type TabParam = {
    tab: string
}
export type ListNameParam = {
    listName: string
}

export interface ModalType {
    open: boolean,
    okText?: string,
    message?: string,
    headline?: string,
    cancelText?: string,
    clickOk?: () => Promise<void> | void,
    clickClose?: () => Promise<void> | void,
    clickCancel?: () => Promise<void> | void,
}

export const ModalTypeInit = {
    open: false,
    message: "",
}

export interface IProjectConfiguration {
    unitData?: IUnitData;
    projectInfo?: IProjectInfo;
    projectRights?: IProjectRight;
}

export interface IProjectInfo {
    createdDate?: Date;
    companyName: string;
    customer: string;
    address: string;
    postcode: string;
    contactPerson: string;
    city: string;
    phoneNumber: string;
    id: number;
    status: number;
    projectFinishUsername: string;
    projectFinishDate?: Date;
    isMachineAnalysisForEnergyEfficiencySelected: boolean;
    isLeakageDetectionAndDocumentationSelected: boolean;
    isLeakageDetectionAndDocumentationAndRepairSelected: boolean;
    isCompressedAirQualityAnalysisSelected: boolean;
    isCompressedAirEnergyEfficiencyPreAuditSelected: boolean;
    isCompressedAirConsumtionAnalysisSelected: boolean;
    isAnalysisOfTheCompressedAirGenerationSelected: boolean;
    isAirNetworkAnalysisSelected: boolean;
    executorId: number;
    country: string;
    email: string;
    licenceTypeId: number;
    licenceExpirationTime?: Date;
    isProLicence: boolean;
    isProjectExpired: boolean;
    hideButtonReplacementPartsList: boolean;
    hideButtonBuyReplacementParts: boolean;
}

export interface IProjectRight {
    isSuperAdmin: boolean;
    hasRestrictedSettingsEdit: boolean;
    isReader: boolean;
    isTechnician: boolean;
    isExecutorAdmin: boolean;
    hasEditRights: boolean;
}

export interface IUnitData {
    measurementSystem: string,
    currency: string
    units?: MeasurementUnit[]
}



////PDF Types////
export interface IExecutiveSummarySections {
    distributionLine: number;
    application?: number;
    airNetwork?: number;
    airQuality?: number;
    airReceiver?: number;
    compressedAirGeneration?: number;
    costSavings?: number;
    dryer?: number;
    generalInformation?: number;
    ldar?: number;
    leakageAnalysis?: number;
    macac?: number;
    measurement?: number;
    pressureDrop?: number;
}

export interface ICategories {
    isExecutiveSummarySelected: boolean;
    isGeneralInformationSelected: boolean;
    isAnalysisOfTheCompressedAirGenerationSelected: boolean;
    isCompressedAirQualityAnalysisSelected: boolean;
    isAirNetworkAnalysisSelected: boolean;
    isMachineAnalysisForEnergyEfficiencySelected: boolean;
    isLeakageAnalysisSelected: boolean;
    isLeakageDetectionAndDocumentationSelected: boolean;
    isCostSavingsSelected: boolean;
    isReplacementPartsSelected: boolean;
    isPdfAttachmentsSelected: boolean;
}

export interface ITocNumbering {
    executiveSummary: number;
    generalInformation: number;
    cag: number;
    airQuality: number;
    AirQualityMeasurement: number;
    airNetwork: number;
    StorageAirCapacity: number;
    macac: number;
    leakageAnalysis: number;
    ldar: number;
    costSavings: number;
    replacementParts: number;
    attachments: number;
    cagChildren: {
        [key: string]: number;
    };
}

export interface TocValuesCategories {
    AirSupplyTime: number;
    Applications: number;
    AirReceiversSummary: number;
    DistributionLine: number;
    AirConsumptionProfile: number;
    AirFlowAndReserve: number;
    AirNetwork: number;
    AirNetworkSummary: number;
    AirQuality: number;
    AirQualityMeasurement: number;
    AirQualityMeasurementSummary: number;
    AirQualitySummary: number;
    AirReceiverSummary: number;
    AmbientClimaticConditionsHeader: number;
    AnnualSavingsAfterTaxes: number;
    applications: number;
    attachments: number;
    AverageAirFlowPerCompressor: number;
    AverageAirFlowPerCompressorRoom: number;
    CACPageTitle: number;
    CO2GRA: number;
    CompressedAirGeneration: number;
    CompressorCapacity: number;
    CompressorExpectedOutput: number;
    CompressorRoomAverageWorkloadPerDay: number;
    CompressorUnloadTime: number;
    CostPerUnit: number;
    CostPerYear: number;
    CostSavingsChart: number;
    CostSavingsExec: number;
    CumulativeHighestSavingsPerYear: number;
    CustomerInformation: number;
    Dryers: number;
    EnergyCosts: number;
    ExecutiveSummary: number;
    GeneralInformation: number;
    IndividualCompressorDetails: number;
    Leakage: number;
    LeakageAnalysis: number;
    LeakageAnalysisPaybackTime: number;
    LeakageDetection: number;
    LeakageDetectionCharts: number;
    LeakageDetectionList: number;
    LeakageDetectionResults: number;
    LeakageDetectionAndDocumentation: number;
    LeakageLevelCosts: number;
    LeakageTotalCosts: number;
    LDAR: number;
    MACAC: number;
    MACACPaybackTime: number;
    MachineAnalysis: number;
    Maintenance: number;
    MeasurementCompressedAirGeneration: number;
    PercentegeofCompressedAir: number;
    PressureDrop: number;
    ProcessHeat: number;
    replacementParts: number;
    SavingsLeakages: number;
    SavingsOptimizingTheAirConsumptionAtSingleApplications: number;
    SavingsReducingThePressureDrop: number;
    SavingsReducingThePressureLevelOfTheSystem: number;
    SavingsShuttingOffMainAirSupply: number;
    SpecificPowerOfTheSystem: number;
    StaticConsumptionHeader: number;
    StorageAirCapacity: number;
    TOTAL: number;
    WorkingTimeInHours: number;
    YearlyConsumptionCalculated: number;
    YearlyConsumptionMeasured: number;
    YearlyConsumptionTotal: number;
}

export interface TocValues extends TocValuesCategories {
    categories: ICategories;
    tocNumbering: ITocNumbering;
    executiveSummarySections: IExecutiveSummarySections;
}

export interface Calcs {
    workingTimeWeek: number;
    workingTimeYear: number;
    humidityAverage: number;
    temperatureAverage: number;
    atmosphericPressure: number;
}
export interface IPDFProjectData {
    projectData: any
}
export interface IPDFPageProps {
    tocValues: TocValues,
    pageSize: PageSize,
    country: string,
    calcs: Calcs,
    data: Project,
    newImageArray: Image[]
}

export interface Project extends SyncMetaData {
    id: number
    projectName: string
    companyName: string
    address: string
    postcode: string
    city: string
    country: string
    contactPerson: string
    phoneNumber: string
    email: string
    isCompressedAirEnergyEfficiencyPreAuditSelected: boolean
    isAnalysisOfTheCompressedAirGenerationSelected: boolean
    isCompressedAirQualityAnalysisSelected: boolean
    isAirNetworkAnalysisSelected: boolean
    isMachineAnalysisForEnergyEfficiencySelected: boolean
    isCompressedAirConsumtionAnalysisSelected: boolean
    isLeakageDetectionAndDocumentationSelected: boolean
    isLeakageDetectionAndDocumentationAndRepairSelected: boolean
    temperatureSpring: number
    temperatureSummer: number
    temperatureAutumn: number
    temperatureWinter: number
    humiditySpring: number
    humiditySummer: number
    humidityAutumn: number
    humidityWinter: number
    altitudeAboveSeaLevel: number
    isTheAirSupllyShuttedOff: boolean
    costsPerkWh: number
    mondayToFridayWorkingTime: number
    saturdayWorkingTime: number
    sundayWorkingTime: number
    status: IDropdownItem
    executedByType: any
    leakageLevel: number
    temperatureAverage: number
    humidityAverage: number
    leakageFlow: any
    leakageDetectionDays: any
    freeTextField: string
    generalInformationComment: any
    airQualityComment: any
    macacComment: any
    costSavingsComment: any
    generalInformationChangedDate: string
    airQualityMeasurementList: AirQualityMeasurementList[]
    individualDryersList: any[]
    customer: string
    sapOrderNo: string
    projectSettings: ProjectSettings
    travelTime: number
    travelDistance: number
    travelCostsKnown: boolean
    travelLumpSum: number
    travelTimeCosts: number
    travelCosts: number
    airNetworkAnalysis: AirNetworkAnalysis
    compressedAirGeneration: CompressedAirGeneration
    leakageAnalysis: LeakageAnalysis
    projectApplicationList: ProjectApplicationList[]
    projectLDAR: ProjectLdar
    numberOfEmployeesInThePlant: number
    pneumaticTools: number
    pneumaticMachinery: number
    process: number
    isMaintanancePlanAvailable: boolean
    lastMaintenance: string
    lastMaintenanceFrequency: IDropdownItem
    lastLeackageDetection: string
    heatRecoverySystem: boolean
    heating: boolean
    hotWaterGeneration: boolean
    projectFinishCount: number
    changedUser: string
    assignedRoles: any[]
    executorId: number
    executor: Executor
    usedLicense: any
    licenseType: any
    initialLicenseType: any
    imageList: Image[]
  }
  
  export interface AirQualityMeasurementList extends SyncMetaData {
    id: number
    projectId: number
    detectedDate: string
    detectedBy: string
    repairIsDone: boolean
    repairedBy: string
    repairDate: string
    repairComment: string
    measurementId: number
    building: string
    department: string
    machine: string
    particleMeasuredPG: string
    particleRequiredClassId: number
    particleRequiredPG: string
    particleExpectedClassId: number
    particleExpectedPG: string
    particleMeasuredClass: string
    particleResultClass: string
    gasRequiredPG: string
    gasExpectedPG: string
    gasMeasuredPG: string
    gasRequiredClassId: number
    gasExpectedClassId: number
    gasMeasuredClass: string
    gasResultClass: string
    oilRequiredPG: string
    oilExpectedPG: string
    oilMeasuredPG: string
    oilRequiredClassId: number
    oilExpectedClassId: number
    oilMeasuredClass: string
    oilResultClass: string
    airQualityMeasurementComment: string
    guid: string
    replacementPartList: any[]
  }
  
  export interface ProjectSettings extends SyncMetaData {
    id: number
    measurementSystem: IDropdownItem
    currency: string
    cO2GridAverage: number
    subsidiaryFestoName: string
    subsidiaryAddress: string
    subsidiaryCountry: string
    subsidiaryCity: string
    subsidiaryTelephone: string
    subsidiaryContactPerson: string
    subsidiaryEmail: string
    subsidiaryExecutorId: number
    leakageDetectionCosts: number
    repairLabourCosts: number
    travelTimeCostsPerHour: number
    sparePartsCosts: number
    costsPerDistanceUnit: number
    lumpSum: number
    equipmentServiceLife: number
    taxOnProfit: number
    settingsLeakageDetectedDayFlow: number
    settingsLeakageDetectedDayPerTechnician: number
    settingsRepairTimePerLeakage: number
    settingsWorkingHoursPerDayPerDetection: number
    settingsSavings1barReduction: number
  }
  
  export interface AirNetworkAnalysis extends SyncMetaData {
    id: number
    projectId: number
    comment: any
    distributionLineList: any[]
    airReceiverList: any[]
    pressureDropAnalysisList: any[]
  }
  
  export interface CompressedAirGeneration extends SyncMetaData {
    id: number
    projectId: number
    no: number
    isElectricityAndAirConsumptionMeasured: boolean
    electricityConsumptionTimePeriod: any
    electricityConsumptionValue: any
    airConsumptionTimePeriod: any
    airConsumptionValue: any
    expectedLeakages: number
    unloadCyclesPerHour: number
    unloadTime: number
    comment: any
    compressorList: CompressorList[]
    guid: any
    staticConsumption: number
  }
  
  export interface CompressorList extends SyncMetaData {
    id: number
    no: number
    dateOfEntry: string
    detectedBy: string
    isRepairDone: boolean
    repairedBy: any
    repairDate: string
    repairComment: any
    compressorRoomNo: number
    manufacturer: string
    modelSerialNumber: string
    year: number
    compressorType: IDropdownItem
    controlType: IDropdownItem
    nominalPower: number
    freeAirDelivery: number
    nominalPressure: number
    setpointPressure: number
    lastMaintenance: string
    mondayToFridayWorkload: number
    saturdayWorkload: number
    sundayWorkload: number
    comment: any
    heatRecovery: boolean
    measurementId: number
    replacementPartList: any[]
    guid: string
  }
  
  export interface LeakageAnalysis extends SyncMetaData {
    id: number
    comment: any
  }
  
  export interface ProjectApplicationList extends SyncMetaData {
    id: number
    measurementId: number
    projectId: number
    detectedDate: string
    detectedBy: string
    isRepairDone: boolean
    repairedBy: any
    repairDate: string
    repairComment: any
    pressureActual: number
    optimizationFlowPerCycleActual: number
    optimizationCycleTimeActual: number
    optimizationNrCyclesMinActual: number
    optimizationNrComponentsActual: number
    optimizationOperatingHoursActual: number
    optimizationOperatingDaysActual: number
    pressureFuture: number
    optimizationFlowPerCycleFuture: number
    optimizationCycleTimeFuture: number
    optimizationCycleMinFuture: number
    optimizationNrComponentsFuture: number
    optimizationOperatingHoursFuture: number
    optimizationOperatingDaysFuture: number
    optimizationLabourCosts: number
    optimizationSparePartsCosts: number
    optimizationLumpSumCosts: number
    optimizationtravelTimeCosts: number
    optimizationtravelCosts: number
    applicationComment: any
    guid: string
    replacementPartList: any[]
    building: string
    department: string
    machine: string
  }
  
  export interface ProjectLdar extends SyncMetaData {
    id: number
    projectId: number
    comment: any
    guid: any
    leakages: Leakage[]
  }
  
  export interface Leakage extends SyncMetaData {
    id: number
    detectedDate: string
    detectedBy: string
    leackageNumber: number
    measurementId: number
    leakageRepaired: boolean
    repairedBy?: string
    repairDate: string
    repairComment?: string
    leakageRepairTimeRepairedInput?: number
    building: string
    department: string
    machine: string
    leakageInputDevice: LeakageInputDevice
    leakageInputValue: number
    leakageAction: any
    leakageRepairTimeEstimated: LeakageRepairTimeEstimated
    leakageRepearableProdTime: boolean
    leakageOptimization: boolean
    leakageComment?: string
    guid?: string
    replacementPartList: any[]
  }
  
  export interface LeakageInputDevice extends SyncMetaData {
    id: number
    value: string
  }
  
  export interface LeakageRepairTimeEstimated {
    id: number
    value: string
    calculationValue: number
  }
  
  export interface Executor extends SyncMetaData {
    id: number
    countryID: string
    currency: string
    printSize: any
    cO2GridAverage: number
    subsidiaryFestoName: string
    subsidiaryAddress: string
    subsidiaryCity: string
    subsidiaryTelephone: string
    subsidiaryContactPerson: string
    subsidiaryEmail: string
    leakageDetectionCosts: number
    repairLabourCosts: number
    travelTimeCostsPerHour: number
    sparePartsCosts: number
    costsPerDistanceUnit: number
    lumpSum: number
    equipmentServiceLife: number
    taxOnProfit: number
    paginationMaxRowCount: any
    settingsLeakageDetectedDayFlow: number
    settingsLeakageDetectedDayPerTechnician: number
    settingsRepairTimePerLeakage: number
    settingsWorkingHoursPerDayPerDetection: number
    settingsSavings1barReduction: number
    assignedRoles: any[]
    festoExecutor: boolean
    hideButtonReplacementPartsList: boolean
    hideButtonBuyReplacementParts: boolean
    tenantId: string
    standardLeakageDetectionsQnt: number
    standardFullAuditQnt: number
    professionalFullAudit: boolean
    proAuditExpirationTime: string
    professionalLeakageDetection: boolean
    proLeakageExpirationTime: string
    isWibuInitiated: boolean
    projects: any
    assignedLicenses: any
    licenseType: any
  }
  
 export interface SyncMetaData {
    createdDate: string
    changedDate: string
    deletedDate: any
    deletedBy: any
 }
  
  export interface Image {
    name: string
    dateModified: string
    url: string
    height: number
    width: number
    groupId: string
    isThumbnail: boolean
    tabIndex: number
  }
  