// @ts-expect-error not converted yet
import { checkIsAnythingSelectedObject, checkIsDecimal } from '../../../helper/Validator';
import * as Fmt from '../../../helper/Formatters';
import { IDropdownItem, ModelParam, Translate } from '../../Types/Types';

export interface ICompressorModel {
    id: ModelParam<number>,
    no: ModelParam<number>,
    nominalPower: ModelParam<number | null>,
    freeAirDelivery: ModelParam<number | null>,
    mondayToFridayWorkload: ModelParam<number | null>,
    sathurdayWorkload: ModelParam<number | null>,
    sundayWorkload: ModelParam<number | null>,
    controlType: ModelParam<IDropdownItem | null>,
    nominalPressure: ModelParam<number | null>,
    setpointPressure: ModelParam<number | null>,
    isChecked: ModelParam<boolean>,
    isEditOpen: ModelParam<boolean>,
}

export const CompressorModel = (t: Translate) : ICompressorModel => ({
    id: { idkey: '', isValid: true, label: '', validationError: "", validators: [], value: 0 },
    no: { idkey: '', isValid: true, label: t("No"), validationError: "", validators: [], value: 0 },
    nominalPower: { idkey: '', isValid: true, label: t("NominalPowerKW") + Fmt.getUnitInBracket("power", t), validationError: t("ValidNumberError"), validators: [checkIsDecimal], value: null },
    freeAirDelivery: { idkey: '', isValid: true, label: t("FreeAirDeliveryFAD") + Fmt.getUnitInBracket("volumePerTime", t), validationError: t("ValidNumberError"), validators: [checkIsDecimal], value: null },
    mondayToFridayWorkload: { idkey: '', isValid: true, label: t("MondayToFridayWorkload") + Fmt.getPercentage(), validationError: t("ValidNumberError"), validators: [checkIsDecimal], value: null },
    sathurdayWorkload: { idkey: '', isValid: true, label: t("SaturdayWorkload") + Fmt.getPercentage(), validationError: t("ValidNumberError"), validators: [checkIsDecimal], value: null },
    sundayWorkload: { idkey: '', isValid: true, label: t("SundayWorkload") + Fmt.getPercentage(), validationError: t("ValidNumberError"), validators: [checkIsDecimal], value: null },
    controlType: { idkey: '', isValid: true, label: t("ControlType"), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsAnythingSelectedObject], value: null },
    nominalPressure: { idkey: '', isValid: true, label: t("NominalPressureCompression") + Fmt.getUnitInBracket("pressure", t), validationError: t("ValidNumberError"), validators: [checkIsDecimal], value: null },
    setpointPressure: { idkey: '', isValid: true, label: t("SetpointPressure") + Fmt.getUnitInBracket("pressure", t), validationError: t("ValidNumberError"), validators: [checkIsDecimal], value: null },
    isChecked: { idkey: '', isValid: true, label: "", validationError: "", validators: [], value: false },
    isEditOpen: { idkey: '', isValid: true, label: '', validationError: "", validators: [], value: false }
})