import { ModelParam, Translate } from "../Types/Types";

export interface ILeakageModelFactory {
    id: ModelParam<number>;
    no:  ModelParam<number>;
    total: ModelParam<number>;
    totalAirReceiversVolume: ModelParam<number>;
    totalDistributionLinePipeLength: ModelParam<number>;
    totalDistributionLinePipeVolume: ModelParam<number>;
    totalVolumeAirReceivers: ModelParam<number>;
    totalVolumeNetwork: ModelParam<number>;
    comment:  ModelParam<string>;
}

export const AirNetworkModel = (t: Translate): ILeakageModelFactory => ({
    id: { label: t("Id"), idkey: "AN-Id", value: 0, isValid: true, validationError: "", validators: [], disabled: true },
    no: { label: t('Nr'), idkey: "AN-No", value: 0, isValid: true, validationError: "", validators: [], disabled: true },
    total: { label: t("Total"), idkey: "AN-Total", value: 0, isValid: true, validationError: "", validators: [] },
    totalAirReceiversVolume: { label: "", idkey: "AN-totalAirReceiversVolume", value: 0, isValid: true, validationError: "", validators: [] },
    totalDistributionLinePipeLength: { label: "", idkey: "AN-totalDistributionLinePipeLength", value: 0, isValid: true, validationError: "", validators: [] },
    totalDistributionLinePipeVolume: { label: "", idkey: "AN-totalDistributionLinePipeVolume", value: 0, isValid: true, validationError: "", validators: [] },
    totalVolumeAirReceivers: { label: t("TotalVolumeAirReceivers"), idkey: "AN-TotalVolumeAirReceivers", value: 0, isValid: true, validationError: "", validators: [] },
    totalVolumeNetwork: { label: t("TotalVolumeNetwork"), idkey: "AN-TotalVolumeNetwork", value: 0, isValid: true, validationError: "", validators: [] },
    comment: { label: "", idkey: "AN-comment", value: "", isValid: true, validationError: "", validators: [] }
})
