import { checkIsNumber } from '../../../../helper/Validator';
import * as DateHelper from '../../../../helper/DateHelper';

export const checkModelStructure = (item, listItem, t) => {
    if (item[listItem].value != undefined) {
        if (item[listItem].value.value == undefined) {
            return checkIsNumber(item[listItem].value) ? Math.round(item[listItem].value, 1) : t(item[listItem].value)
        }
        else {
            return checkIsNumber(item[listItem].value.value) ? Math.round(item[listItem].value.value, 1) : t(item[listItem].value.value)
        }
    }
    else {
        switch (listItem) {
            case "ChangedDate":
                return DateHelper.toLocalDateString(item.ChangedDate);
            case "CreatedDate":
                return DateHelper.toLocalDateString(item.CreatedDate);
            case "DetectedDate":
                return DateHelper.FormatDate(item.DetectedDate);
            case "leakageRepearableProdTime":
                return item.leakageRepearableProdTime ? t('Yes') : t('No');
            case "TypeOfDryer":
                return t(item.TypeOfDryer.Value);
            case "leakageRepaired":
                return item.leakageRepaired ? t('Yes') : t('No');
            case "isEmailNotification":
                return item.isEmailNotification ? t('Yes') : t('No');
            case "festoExecutor":
                return item.festoExecutor ? t('Yes') : t('No');
            case "orderSparePart":
                return item.orderSparePart ? t('Yes') : t('No');
            case "IsAirNetworkAnalysisSelected":
                return item.IsAirNetworkAnalysisSelected == 1 ? t("IsAirNetworkAnalysisSelected") : "";
            case "IsAnalysisOfTheCompressedAirGenerationSelected":
                return item.IsAnalysisOfTheCompressedAirGenerationSelected == 1 ? t("IsAnalysisOfTheCompressedAirGenerationSelected") : "";
            case "IsCompressedAirConsumtionAnalysisSelected":
                return item.IsCompressedAirConsumtionAnalysisSelected == 1 ? t("IsCompressedAirConsumtionAnalysisSelected") : "";
            case "IsCompressedAirEnergyEfficiencyPreAuditSelected":
                return item.IsCompressedAirEnergyEfficiencyPreAuditSelected == 1 ? t("IsCompressedAirEnergyEfficiencyPreAuditSelected") : "";
            case "IsCompressedAirQualityAnalysisSelected":
                return item.IsCompressedAirQualityAnalysisSelected == 1 ? t("IsCompressedAirQualityAnalysisSelected") : "";
            case "IsLeakageDetectionAndDocumentationSelected":
                return item.IsLeakageDetectionAndDocumentationSelected == 1 ? t("IsLeakageDetectionAndDocumentationSelected") : "";
            case "IsMachineAnalysisForEnergyEfficiencySelected":
                return item.IsMachineAnalysisForEnergyEfficiencySelected == 1 ? t("IsMachineAnalysisForEnergyEfficiencySelected") : "";
            case "IsLeakageDetectionAndDocumentationAndRepairSelected":
                return item.IsLeakageDetectionAndDocumentationAndRepairSelected == 1 ? t("IsLeakageDetectionAndDocumentationAndRepairSelected") : "";
            default:
                return checkIsNumber(item[listItem]) ? Math.round(item[listItem], 1) : t != undefined ? t(item[listItem]) : item[listItem]

        }
    }
}
export const handleFilterFunc = (tempdata, chip, filterList, t) => {
    var data = tempdata.filter(item => {
        var isFound;
        for (const listItem of filterList) {
            isFound = item[listItem] != null ? checkModelStructure(item, listItem, t).toString().toLowerCase().indexOf(checkIsNumber(chip) ? parseInt(chip) : chip.toLowerCase().trim()) > -1 : ""
            if (isFound == true) {
                return isFound;
            }
        }
    }
    )
    return data;
}
export const handleFilterDelete = (chipsArray, dataItem, filterData, t) => {
    var tempChips = chipsArray.filter(i => i.name !== dataItem);
    var tempdata = filterData;
    if (chipsArray.length > 1) {
        for (const chip of tempChips) {
            tempdata = handleFilterFunc(tempdata, chip.name, chip.key, t);
        }
    }
    else {
        tempdata = filterData;
    }
    return tempdata;
}