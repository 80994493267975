import React from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { usePdfStyles } from './PdfStyles';

export const ReplacementPartsPdf = (props) => {
    const { t } = useTranslation();
    var PdfStyles = usePdfStyles();

    return (
        <Page size={props.pageSize} style={PdfStyles.page}>
            <View style={PdfStyles.header}>
                <View style={PdfStyles.logoWrapper}>
                    {props.logo && <Image style={PdfStyles.logo} src={props.logo} />}
                </View>
            </View>
            <View style={PdfStyles.pageBody}>
                <View>
                    <Text style={PdfStyles.heading}>{t('Cart')}</Text>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.flexItem, { flexGrow: 2 }]}>
                    </View>
                    <View style={[PdfStyles.flexItem, { flexGrow: 4 }]}>
                        <Text style={[PdfStyles.label, { textAlign: 'center' }]}>{t('DefectedDevice')}</Text>
                    </View>
                    <View style={[PdfStyles.flexItem, { flexGrow: 3 }]}>
                        <Text style={[PdfStyles.label, { textAlign: 'center' }]}>{t('ReplacementDevice')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.table}>
                    <View style={PdfStyles.tableRow}>
                        <View style={PdfStyles.tableCol0}>
                            <Text style={PdfStyles.tableCell0}>{t('Nr')}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('Service')}</Text>
                        </View>
                        <View style={[PdfStyles.tableCol, { borderRight: '1px solid rgba(224, 224, 224, 1)' }]}>
                            <Text style={PdfStyles.tableCell}>{t('MeasurementId')}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('PartNumber')}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('TypeCode')}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('Component')}</Text>
                        </View>
                        <View style={[PdfStyles.tableCol, { borderRight: '1px solid rgba(224, 224, 224, 1)' }]}>
                            <Text style={PdfStyles.tableCell}>{t('Manufacturer')}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('PartNumber')}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('TypeCode')}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('Manufacturer')}</Text>
                        </View>
                    </View>
                    {Array.apply(null, { length: props.count }).map((e, i) => (
                        <View key={'replacementPart' + i} style={PdfStyles.tableRow}>
                            <View style={PdfStyles.tableCol0}>
                                <Text style={PdfStyles.tableCell0}>{i + 1 + props.offset}</Text>
                            </View>
                            <View style={PdfStyles.tableCol}>
                                <Text style={PdfStyles.tableCell}></Text>
                            </View>
                            <View style={[PdfStyles.tableCol, { borderRight: '1px solid rgba(224, 224, 224, 1)' }]}>
                                <Text style={PdfStyles.tableCell}></Text>
                            </View>
                            <View style={PdfStyles.tableCol}>
                                <Text style={PdfStyles.tableCell}></Text>
                            </View>
                            <View style={PdfStyles.tableCol}>
                                <Text style={PdfStyles.tableCell}></Text>
                            </View>
                            <View style={PdfStyles.tableCol}>
                                <Text style={PdfStyles.tableCell}></Text>
                            </View>
                            <View style={[PdfStyles.tableCol, { borderRight: '1px solid rgba(224, 224, 224, 1)' }]}>
                                <Text style={PdfStyles.tableCell}></Text>
                            </View>
                            <View style={PdfStyles.tableCol}>
                                <Text style={PdfStyles.tableCell}></Text>
                            </View>
                            <View style={PdfStyles.tableCol}>
                                <Text style={PdfStyles.tableCell}></Text>
                            </View>
                            <View style={PdfStyles.tableCol}>
                                <Text style={PdfStyles.tableCell}></Text>
                            </View>
                        </View>
                    ))}
                </View>
            </View>
        </Page>
    );
}