// @ts-expect-error not converted yet
import { checkIsNotNullOrWhitespace, checkIsNumber } from "../../../../helper/Validator";
import * as Fmt from '../../../../helper/Formatters';
import { ModelParam, Translate } from "../../../Types/Types";

export interface IRestrictedSettingsModelFactory {
    settingsLeakageDetectedDayFlow: ModelParam<string>,
    settingsLeakageDetectedDayPerTechnician: ModelParam<string>,
    settingsRepairTimePerLeakage: ModelParam<string>,
    settingsWorkingHoursPerDayPerDetection: ModelParam<string>,
    settingsSavings1barReduction: ModelParam<string>,

}

export const RestrictedSettingsModelFactory = (t: Translate) : IRestrictedSettingsModelFactory => ({
    settingsLeakageDetectedDayFlow: { label: t("SettingsLeakageDetectedDayFlow") + Fmt.addBrackets("l/min/day", t), idkey: "RS-SettingsLeakageDetectedDayFlow", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace, checkIsNumber] },
    settingsLeakageDetectedDayPerTechnician: { label: t("SettingsLeakageDetectedDayPerTechnician") + Fmt.addBrackets("leakages/day", t), idkey: "RS-SettingsLeakageDetectedDayPerTechnician", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace, checkIsNumber] },
    settingsRepairTimePerLeakage: { label: t("SettingsRepairTimePerLeakage") + Fmt.addBrackets("min", t), idkey: "RS-SettingsRepairTimePerLeakage", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace, checkIsNumber] },
    settingsWorkingHoursPerDayPerDetection: { label: t("SettingsWorkingHoursPerDayPerDetection") + Fmt.getUnitInBracket("hours", t), idkey: "RS-SettingsWorkingHoursPerDayPerDetection", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace, checkIsNumber] },
    settingsSavings1barReduction: { label: t("SettingsSavings1barReduction") + Fmt.getPercentage(), idkey: "RS-SettingsSavings1barReduction", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace, checkIsNumber] }
});