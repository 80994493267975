import { checkIsNumber } from '../../helper/Validator';
import * as Fmt from '../../helper/Formatters';

export const PdfReportFormModel = t => {
    return {
        travelTime: { value: "", idkey: "INQ-travelTime", label: t("TravelTime") + Fmt.getUnitInBracket("hours", t), isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNumber] },
        travelDistance: { value: "", idkey: "INQ-travelDistance", label: t("TravelDistance") + Fmt.getUnitInBracket("unitKmMile", t), isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNumber] },
        travelCostsKnown: { value: false, idkey: "INQ-travelCostsKnown", label: t("TotalTravelCostsAvailable"), isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [] },
        travelLumpSum: { value: "", idkey: "INQ-travelLumpSum", label: t("LumpSum") + Fmt.getCurrency(), isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNumber] },
        travelTimeCosts: { value: "", idkey: "INQ-travelTimeCosts", label: t("TravelTimeCosts") + Fmt.getCurrency(), isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNumber] },
        travelCosts: { value: "", idkey: "INQ-travelCosts", label: t("TravelCosts") + Fmt.getCurrency(), isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNumber] },
    }
}

const travelValidators = [checkIsNumber];

export const setTravelValidators = model => {
    let value = model.travelCostsKnown.value;
    model.travelTime.validators = value ? [] : travelValidators;
    model.travelDistance.validators = value ? [] : travelValidators;
    model.travelLumpSum.validators = value ? travelValidators : [];
    model.travelTimeCosts.validators = value ? travelValidators : [];
    model.travelCosts.validators = value ? travelValidators : [];
    return model;
}