import { checkIsInteger, checkIsNotNullOrWhitespace, checkIsAnythingSelected, checkIsDecimal, isRepairedByFilledValidator, checkIsDateInPastUTC, isRepairedDateFilledAndPast, checkIsDateOrNull, checkYear } from "../../../helper/Validator";
import * as Fmt from '../../../helper/Formatters';
import { UserStore } from '../../../store';
import { nowUTC } from '../../../helper/DateHelper'

let user = {};
UserStore.user.subscribe(usr => {
    user = usr;
})

export const CompressorModelFactory = (t) =>
({
    isSelected: { value: false, isValid: true, validationError: "", validators: [] },
    id: { value: 0, isValid: true, validationError: "", validators: [] },
    no: { idkey: "CAGEDIT-No", value: 0, isValid: true, validationError: "", validators: [], headerName: t('Nr') },
    dateOfEntry: { label: t("EntryDate"), idkey: "CAGEDIT-EntryDate", value: nowUTC(), isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDateInPastUTC], type: 0, step: 0 },
    detectedBy: { label: t("DetectedBy"), idkey: "CAGEDIT-DetectedBy", value: user.firstName + " " + user.lastName, isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace] },
    isRepairDone: { label: t("OptimizationIsDone"), idkey: "CAGEDIT-RepairIsDone", value: false, isValid: true, validationError: "", validators: [] },
    repairedBy: { label: t("OptimizedBy"), idkey: "CAGEDIT-RepairedBy", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [isRepairedByFilledValidator] },
    repairDate: { label: t("OptimizationDate"), idkey: "CAGEDIT-RepairDate", value: null, isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [isRepairedDateFilledAndPast], type: 0, step: 0 },
    compressorRoomNo: { label: t("CompressorRoomNo"), idkey: "CAGEDIT-CompressorRoomNo", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger] },
    manufacturer: { label: t("Manufacturer"), idkey: "CAGEDIT-Manufacturer", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace] },
    modelSerialNumber: { label: t("ModelSerialNumber"), idkey: "CAGEDIT-ModelSerialNumber", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace] },
    year: { label: t("Year"), idkey: "CAGEDIT-Year", value: null, default: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsInteger, checkYear] },
    compressorType: { label: t("CompressorType"), idkey: "CAGEDIT-CompressorType", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsAnythingSelected] },
    controlType: { label: t("ControlType"), idkey: "CAGEDIT-ControlType", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsAnythingSelected] },
    nominalPower: { label: t("NominalPowerKW") + Fmt.getUnitInBracket("power", t), idkey: "CAGEDIT-NominalPowerKW", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDecimal] },
    freeAirDelivery: { label: t("FreeAirDelivery") + Fmt.getUnitInBracket("volumePerTime", t), idkey: "CAGEDIT-FreeAirDelivery", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDecimal] },
    nominalPressure: { label: t("NominalPressureCompression") + Fmt.getUnitInBracket("pressure", t), idkey: "CAGEDIT-NominalPressureCompression", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDecimal], headerName: t('NominalPressure') + Fmt.getUnitInBracket("pressure", t) },
    setpointPressure: { label: t("SetpointPressure") + Fmt.getUnitInBracket("pressure", t), idkey: "CAGEDIT-SetpointPressure", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDecimal] },
    lastMaintenance: { label: t("LastMaintenanceLabel"), idkey: "CAGEDIT-LastMaintenanceLabel", value: null, isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDateOrNull], notRequired: true },
    mondayToFridayWorkload: { label: t("MondayToFriday") + Fmt.getPercentage(), idkey: "CAGEDIT-MondayToFriday", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDecimal] },
    saturdayWorkload: { label: t("Saturday") + Fmt.getPercentage(), idkey: "CAGEDIT-Saturday", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDecimal] },
    sundayWorkload: { label: t("Sunday") + Fmt.getPercentage(), idkey: "CAGEDIT-Sunday", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDecimal] },
    comment: { label: t("Comment"), idkey: "CAGEDIT-Comment", value: "", isValid: true, validationError: "", validators: [] },
    repairComment: { label: t("RepairComment"), idkey: "CAGEDIT-RepairComment", value: "", isValid: true, validationError: "", validators: [] },
    heatRecovery: { label: t("HeatRecovery"), idkey: "CAGEDIT-HeatRecovery", value: false, isValid: true, validationError: "", validators: [], sortable: false },
    effectivePower: { value: "", isValid: true, validationError: "", validators: [] },
    measurementId: { label: t("MeasurementId"), idkey: "CAGEDIT-MeasurementId", value: "", isValid: true, validationError: "", validators: [checkIsInteger] },
    guid: { value: "", isValid: true, validationError: "", validators: [] }
});

export const CompressorCalcsModelFactory = (t) =>
({
    no: { idkey: "CAGEDIT-No", headerName: t('Nr') },
    rEffectivePower: { idkey: "CAGEDIT-rEffectivePower", headerName: t('EffectivePower') + Fmt.getUnitInBracket("power", t) },
    rfadOperatingConditions: { idkey: "CAGEDIT-rfadOperatingConditions", headerName: t('ExpectedOutputFreeAirDelivery') + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t) },
    rFlowMondayFriday: { idkey: "CAGEDIT-MondayToFriday", headerName: t("MondayToFriday") + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t) },
    rFlowSaturday: { idkey: "CAGEDIT-Saturday", headerName: t("Saturday") + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t) },
    rFlowSunday: { idkey: "CAGEDIT-Sunday", headerName: t("Sunday") + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t) }
});

export const CompRoomCalcsModelFactory = (t) =>
({
    no: { idkey: "CAGEDIT-No", headerName: t('CompressorRoomNo') },
    cEffectivePowerROOM: { idkey: "CAGEDIT-rEffectivePower", headerName: t('EffectivePower') + Fmt.getUnitInBracket("power", t) },
    cfadOperatingConditionsROOM: { idkey: "CAGEDIT-rfadOperatingConditions", headerName: t('FADCorrected') + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t) },
    rFlowMondayFridayWorkloadROOM: { idkey: "CAGEDIT-MondayToFriday", headerName: t("MondayToFriday") + Fmt.getPercentage() },
    rFlowSaturdayWorkloadROOM: { idkey: "CAGEDIT-Saturday", headerName: t("Saturday") + Fmt.getPercentage() },
    rFlowSundayWorkloadROOM: { idkey: "CAGEDIT-Sunday", headerName: t("Sunday") + Fmt.getPercentage() },
    rFlowMondayFridayROOM: { idkey: "CAGEDIT-MondayToFriday", headerName: t('MondayFriday') + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t) },
    rFlowSaturdayROOM: { idkey: "CAGEDIT-Saturday", headerName: t('Saturday') + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t) },
    rFlowSundayROOM: { idkey: "CAGEDIT-Sunday", headerName: t('Sunday') + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t) },
    mYearlyConsumptionElectricityROOM: { idkey: "CAGEDIT-mYearlyConsumptionElectricityROOM", headerName: t('ElectricityConsumption') + Fmt.getUnitInBracket("powerPerHour", t) },
    mYearlyConsumptionAirConsumptionROOM: { idkey: "CAGEDIT-mYearlyConsumptionAirConsumptionROOM", headerName: t('AirConsumption') + Fmt.getUnitInBracket("cubicMeterOfGas", t) },
    mYearlyConsumptionAirFlowROOM: { idkey: "CAGEDIT-mYearlyConsumptionAirFlowROOM", headerName: t('AverageAirFlow') + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t) },
    cYearlyConsumptionElectricityROOM: { idkey: "CAGEDIT-cYearlyConsumptionElectricityROOM", headerName: t('ElectricityConsumption') + Fmt.getUnitInBracket("powerPerHour", t) },
    cYearlyConsumptionAirConsumptionROOM: { idkey: "CAGEDIT-cYearlyConsumptionAirConsumptionROOM", headerName: t('AirConsumption') + Fmt.getUnitInBracket("cubicMeterOfGas", t) },
    cYearlyConsumptionAirFlowROOM: { idkey: "CAGEDIT-cYearlyConsumptionAirFlowROOM", headerName: t('AverageAirFlow') + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t) }
});