import { BehaviorSubject } from "rxjs";
import { API } from '../helper/ApiHelper';
import * as Consts from '../helper/ConstantRepository';
import { IProjectConfiguration } from "../components/Types/Types";

const DenyEdit = new BehaviorSubject<boolean>(true);
const ProjectRunning = new BehaviorSubject<boolean>(false);
const ProjectInfo = new BehaviorSubject<IProjectConfiguration>({});
const settingsUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "project-management";

const checkProjectAccess = (projInfo: IProjectConfiguration) => {
  ProjectRunning.next(projInfo?.projectInfo?.status == Consts.RUNNING);
  DenyEdit.next(projInfo?.projectInfo?.status != Consts.RUNNING || !projInfo?.projectRights?.hasEditRights);
}

const ProjectStore = {
  DenyEdit,
  ProjectRunning,
  ProjectInfo,
  initProjectInfo: (projectId: number, forceReload = false) => {
    if (projectId != ProjectInfo.value?.projectInfo?.id || forceReload === true)
      API.get<IProjectConfiguration>(`${settingsUrl}/${projectId}/settings`).then(resp => {
        ProjectInfo.next(resp);
        checkProjectAccess(resp);
      });
  }
}

export {
  ProjectStore
}