import React from "react";
import { useTranslation } from 'react-i18next';
import * as borders from '../../../../../helper/ExcelHelpers/Constants';
// @ts-expect-error not converted yet
import { GenerateRows, GenerateTopHeader } from '../../../../../helper/ExcelHelpers/ExcelHelper';
import { ExcelFile, ExcelSheet } from 'react-xlsx-wrapper';
import { format, parseISO } from "date-fns";
import { IReplacementPartsReport } from "../ReportGeneration";

const styleRulesReplacementParts = [
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topRightThick, middle: borders.rightThick, last: borders.bottomRightThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, }
];

interface IProps {
    data: IReplacementPartsReport[]
}

const ReplacementPartsReportToExcel = (props: IProps) => {
    const { data } = props;
    const { t } = useTranslation();

    const button: React.RefObject<HTMLButtonElement> = React.createRef();

    React.useEffect(() => {
        button.current?.click();
    }, []);

    const labelArray = [
        { title: t("CreationDate"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("ProjectNo"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("ExecutorName"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("CustomerNo"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("CompanyName"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("Address"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("Postcode"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("City"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("CustomerCountry"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("ServiceType"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("Status"), width: { wch: borders.titleWidth }, style: { border: borders.rightThick, }, },
        { title: t("SparePartsCreationDate"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("MeasurementId"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("Quantity"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("PartNumber"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("TypeCode"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("Manufacturer"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
    ];

    const generateFullReport = () => [
        GenerateTopHeader("ReplacementPartReport", t),
        {
            ySteps: 2,
            columns: labelArray,
            data: GenerateRows(
                data.flatMap(({ createdDate, nr, executor, customer, companyName, address, postcode, city, country, serviceType, status, replacementPartDTOs }) =>
                    replacementPartDTOs.map(y => (
                        {
                            createdDate: createdDate ? format(parseISO(createdDate), "dd.MM.yyyy") : "",
                            nr: nr,
                            executor: executor,
                            customer: customer,
                            companyName: companyName,
                            address: address,
                            postcode: postcode,
                            city: city,
                            country: country,
                            serviceType: serviceType.map(serviceType => t(serviceType)).join("; "),
                            status: status,
                            sparePartsCreationDate: y.createdDate ? format(parseISO(typeof y.createdDate === 'string' ? y.createdDate : y.createdDate.toDateString()), "dd.MM.yyyy") : "",
                            idcode: y.parentMeasurementCode,
                            quantity: y.quantity,
                            partNumber: y.replaceWithDifferent ? y.newPartNumber : y.partNumber,
                            typeCode: y.replaceWithDifferent ? y.newTypeCode : y.typeCode,
                            manufacturer: y.replaceWithDifferent ? y.newManufacturer : y.manufacturer
                        }
                    ))),
                styleRulesReplacementParts)
        },
    ];

    return (
        <ExcelFile
            filename="FESS-Portal Replacement Parts Report"
            element={<button className='hidden' ref={button} />}
        >
            <ExcelSheet dataSet={generateFullReport()} name="Report" />
        </ExcelFile>
    );
}

export default ReplacementPartsReportToExcel;