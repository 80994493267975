
export const getLink = (projectId: number, editId : number, type: number, country: string, language: string) => {
    switch (type) {
        case 0:
            return ("/" + country + "/" + language + '/project/' + projectId + '/CAG/AddEditCompressor/' + editId + '/#validate');
        case 1:
            return ("/" + country + "/" + language + '/project/' + projectId + '/AQ/IndividualDryer/' + editId);
        case 2:
            return ("/" + country + "/" + language + '/project/' + projectId + '/AQ/AirQualityMeasurement/' + editId);
        case 3:
            return ("/" + country + "/" + language + '/project/' + projectId + '/AN/DistributionLine/' + editId);
        case 4:
            return ("/" + country + "/" + language + '/project/' + projectId + '/AN/AirReceiver/' + editId);
        case 5:
            return ("/" + country + "/" + language + '/project/' + projectId + '/AN/PressureDropAnalysis/' + editId);
        case 6:
            return ("/" + country + "/" + language + '/project/' + projectId + '/MACAC/Application/' + editId);
        case 7:
            return ("/" + country + "/" + language + '/project/' + projectId + '/LDAR/Leakage/' + editId);
        case 21:
            return ("/" + country + "/" + language + '/project/' + projectId + '/PS/#validate');
        case 22:
            return ("/" + country + "/" + language + '/project/' + projectId + '/GI/#validate');
        case 23:
            return ("/" + country + "/" + language + '/project/' + projectId + '/CAG/#validate');
        case 24:
            return ("/" + country + "/" + language + '/project/' + projectId + '/AN/#validate');
        default:
            return "";
    }
}