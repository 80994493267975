import * as locales from '@mui/material/locale';
import { Localization } from '@mui/material/locale';

export const parseRouteLngToLocale : (lng: string) => Localization = (lng) => {
    switch (lng) {
        case "en":
            return locales.enUS
        case "fr":
            return locales.frFR
        case "es":
            return locales.esES
        case "ca":
            return locales.caES
        case "de":
            return locales.deDE
        case "zh":
            return locales.zhCN
        case "cz":
            return locales.csCZ
        case "hu":
            return locales.huHU
        case "jp":
            return locales.jaJP
        case "ko":
            return locales.koKR
        case "lt":
            return ltLT
        case "nl":
            return locales.nlNL
        case "pt":
            return locales.ptPT
        case "ru":
            return locales.ruRU
        case "sv":
            return locales.svSE
        case "sk":
            return locales.skSK
        case "in":
            return locales.hiIN
        case "id":
            return locales.idID
        case "it":
            return locales.itIT
        case "ro":
            return locales.roRO
        case "th":
            return locales.thTH
        case "my":
            return locales.myMY
        case "vi":
            return locales.viVN
        default:
            return locales.enUS
    }
}

const ltLT : Localization= {
    components: {
        MuiBreadcrumbs: {
            defaultProps: {
                expandText: 'Rodyti kelią',
            }
        },
        MuiTablePagination: {
            defaultProps: {
                getItemAriaLabel: (type) => {
                    if (type === 'first') {
                        return 'Eiti į pirmą';
                    }
                    if (type === 'last') {
                        return 'Eiti į paskutinį';
                    }
                    if (type === 'next') {
                        return 'Eiti į kitą';
                    }
                    // if (type === 'previous') {
                    return 'Eiti į praeitą';
                },
                labelRowsPerPage: 'Eilučių per psl.:',
                labelDisplayedRows: ({ from, to, count }) =>
                    `${from}-${to} iš ${count !== -1 ? count : `daugiau nei ${to}`}`,
            }
        },
        MuiRating: {
            defaultProps: {
                getLabelText: value => `${value} žvaigdžių}`,
                emptyLabelText: 'Tuščia',
            }
        },
        MuiAutocomplete: {
            defaultProps: {
                clearText: 'Išvalyti',
                closeText: 'Uždaryti',
                loadingText: 'Kraunama…',
                noOptionsText: 'Nėra pasirinkimų',
                openText: 'Atverti',
            }
        },
        MuiAlert: {
            defaultProps: {
                closeText: 'Uždaryti',
            }
        },
        MuiPagination: {
            defaultProps: {
                'aria-label': 'Puslapių navigacija',
                getItemAriaLabel: (type, page, selected) => {
                    if (type === 'page') {
                        return `${selected ? '' : 'Eiti į '}puslapį ${page}`;
                    }
                    if (type === 'first') {
                        return 'Eiti į pirmą';
                    }
                    if (type === 'last') {
                        return 'Eiti į paskutinį';
                    }
                    if (type === 'next') {
                        return 'Eiti į kitą';
                    }
                    // if (type === 'previous') {
                    return 'Eiti į praeitą';
                },
            }
        },
    },
};