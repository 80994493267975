import { ModelParam, Translate } from "../../Types/Types";


export interface IPrintLeakagesModelFactory {
    repairedLeakages: ModelParam<boolean>
    openLeakages: ModelParam<boolean>
}


export const PrintLeakagesModel = (t: Translate) : IPrintLeakagesModelFactory => (
    {
        repairedLeakages: { label: t("RepairedLeakages"), idkey: "LDAREDIT-leakageRepaired", value: true, default: true, isValid: true, validationError: "", validators: []},
        openLeakages: { label: t("OpenLeakages"), idkey: "LDAREDIT-leakageRepaired", value: true, default: true, isValid: true, validationError: "", validators: []},
    }
)