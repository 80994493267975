import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { API } from '../../../../helper/ApiHelper';
import { ButtonComponent, HeadingComponent, TextFieldComponent, SingleSelectComponent } from '../../../Reusable';
import { useTranslation } from 'react-i18next';
import { AlertManagementModel, AlertModelToAlert, AlertParameterModel, AlertToAlertModel, IAlertManagementModel, IAlertParameterModel } from './AlertManagementModel';
import { AlertStore } from '../../../../store/AlertStore';
import { AlertTypes } from '../../../../helper/GlobalVariables';
import { OpenGlobalSnackbar } from '../../../../helper/GlobalVariables';
// @ts-expect-error not converted yet
import * as Mapper from '../../../../helper/Mappers';
import { IAlert, KeyValue } from '../../../Types/Types';

export default function AlertManagement() {
	const { t } = useTranslation();
	const [model, setModel] = useState<IAlertManagementModel>(AlertManagementModel(t));
	const updateAlertUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "alert";
	const getAlertUrl = process.env.REACT_APP_DEVFESSINQUIRYSERVICES_BASE + "inquiry-management/alert";

	useEffect(() => {
		API.get(getAlertUrl)
			.then((alert : IAlert) => {
				const mappedModel = AlertToAlertModel(alert, t);
				setModel(mappedModel);
			});
	}, []);

	const updateMessage = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const translation = t(e.target.value);
		let i = 0;
		while (translation.includes("{" + i + "}")) {
			if (model.alertParameters.value.length < i + 1) {
				model.alertParameters.value.push(AlertParameterModel(i, i.toString(), model?.alertParameters?.value[i]?.value?.value ?? ""));
			}
			i++;
		}
		const totalParam = model.alertParameters.value.length;
		if (totalParam > i) {
			const paramsToRemove = totalParam - i;
			model.alertParameters.value.splice(-paramsToRemove, paramsToRemove);
		}
		model.message.value = e.target.value;
		setModel({ ...model });
		AlertStore.setAlertModel(AlertModelToAlert(model));
	}

	const updateTranslationParam = (item: IAlertParameterModel, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		item.value.value = e.target.value;
		setModel({ ...model });
		AlertStore.setAlertModel(AlertModelToAlert(model));
		return item;
	}

	const updateAlertType = (value: number, values : KeyValue[]) => {
		model.alertType.value = values.find(x => x.id == value) ??  { id: 0, group: "", value: "" };
		setModel({ ...model });
		AlertStore.setAlertModel(AlertModelToAlert(model));
	}

	const saveAlert = () => {
		const putModel : IAlert = Mapper.extractValuesFromModelRecursive(model);
		putModel.alertType = model.alertType?.value;
		putModel.alertParameters = model.alertParameters.value.map(v => ({id: v.id, index: v.index, value: v.value.value})) ?? [];
		API.put(updateAlertUrl, putModel).then(() => OpenGlobalSnackbar("success", t("SaveSuccessful")));
	}

	return (
		<Grid container spacing={3} direction="row">
			<Grid item xs={12}>
				<HeadingComponent value={t("AlertManagement")} size="h6" />
			</Grid>
			<Grid item xs={12}>
				<TextFieldComponent t={t} model={model.message} onChange={e => updateMessage(e)} />
			</Grid>
			{model.alertParameters.value && model.alertParameters.value.map((item, index) => (
				<Grid item xs={12} key={index}>
					<TextFieldComponent t={t} label={'{' + index + '}'} id={'input_TranslationPlaceholder_' + index} model={item.value} onChange={e => updateTranslationParam(item, e)} />
				</Grid>
			))}
			<Grid item xs={12}>
				<SingleSelectComponent id={'dd_alertType'} model={model.alertType} listFromDb={AlertTypes} t={t} onChange={(e: { target: { value: number; }; }) => updateAlertType(e.target.value, AlertTypes)} />
			</Grid>
			<Grid item xs={12}>
				<Grid container direction="row" justifyContent="flex-end">
					<Grid item>
						<ButtonComponent value={t("Save")} color="primary" onChange={saveAlert} id="alertManagement_save" />
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
