import React from 'react';
import { Page, Text, View } from '@react-pdf/renderer';
import { usePdfStyles, StyleTable, StyleTableNoBorder } from './PdfStyles';
import { useTranslation } from 'react-i18next';
import * as Fmt from '../../../helper/Formatters';
import Watermark from './Watermark';

export const MachineAnalysisPdf = (props) => {
    const { projectData, calcs, country } = props;

    const { t } = useTranslation();
    var PdfStyles = usePdfStyles();

    return (
        <Page size={props.pageSize} style={PdfStyles.page}>
            <Watermark projectData={projectData} />
            <Text style={PdfStyles.pageNumbers} render={({ pageNumber, totalPages }) => (pageNumber + ' ' + t('of') + ' ' + totalPages)} fixed />
            <View wrap={false}>
                <Text render={({ pageNumber }) => { props.tocValues["MachineAnalysis"] = pageNumber }}></Text>
                <Text style={PdfStyles.boldHeading}>{props.tocValues.tocNumbering.macac}. {t('MachineAnalysisForEnergyEfficiency')}</Text>
            </View>
            <View wrap={false}>
                <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["TOTAL"] = pageNumber }}></Text>
                <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.macac}.1 {t('Results')}</Text>
            </View>
            <Text style={PdfStyles.spacer}></Text>
            <View style={StyleTable.table}>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}></Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('Actual')}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('Future')}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('OpenSavings')}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('AchievedSavings')}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{t('StaticConsumption')} {Fmt.getUnitInBracket("cubicMeterOfGasperTime", t)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.optimizationFlowActualTOTAL, country, 2)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.optimizationFlowFutureTOTAL, country, 2)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.optimizationFlowSavingsTOTAL, country, 2)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.optimizationFlowAchievedSavingsTOTAL, country, 2)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{t('VolumePerYear')} {Fmt.getUnitInBracket("cubicMeterOfGas", t)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.optimizationVolumeYearActualTOTAL, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.optimizationVolumeYearFutureTOTAL, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.optimizationVolumeYearSavingsTOTAL, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.optimizationVolumeYearAchievedSavingsTOTAL, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{t('ElectricityConsumption')} {Fmt.getUnitInBracket("powerPerHour", t)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.optimizationActualElectricityConsumption, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.optimizationFutureElectricityConsumption, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.optimizationElectricityConsumptionSavingsOpen, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.optimizationElectricityConsumptionSavingsAchieved, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{t('CO2')} {Fmt.getUnitInBracket("co2Wheight", t)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.optimizationCO2Actual, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.optimizationCO2Future, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.optimizationCO2SavingsOpen, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.optimizationCO2SavingsAchieved, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('CostPerYear')} {Fmt.getCurrency()}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.optimizationCostsActualTOTAL, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.optimizationCostsFutureTOTAL, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.optimizationSavingsBeforeTaxesTOTAL, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.optimizationAchievedSavingsBeforeTaxesTOTAL, country, 0)}</Text>
                    </View>
                </View>
            </View>
            {/**------------------------------------------------------------------- */}
            <Text style={PdfStyles.spacer}></Text>
            <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.macac}.2 {t('MA/CACTabName')} </Text>
            <Text render={({ pageNumber }) => { props.tocValues["CACPageTitle"] = pageNumber }}></Text>
            <Text style={PdfStyles.spacer}></Text>
            <View style={StyleTable.table}>
                <View style={StyleTable.tableRow} fixed>
                    <View style={[StyleTable.tableColRowZero, { flexGrow: 0.4 }]}>
                        <Text style={StyleTable.tableCell}>{t('Nr')}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('Building')} / {t('Department')} / {t('Machine')}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('SavingsVolumePerMinute')} {Fmt.getUnitInBracket("cubicMeterOfGasperTime", t)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('SavingsVolumePerYear')} {Fmt.getUnitInBracket("cubicMeter", t)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('SavingsCostsPerYear')} {Fmt.getCurrency()}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCellTotal}>{t('TotalInvestment')} {Fmt.getCurrency()}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('PaybackTimeInMonths')}</Text>
                    </View>
                </View>
                {calcs?.singleAppCalcResults?.map((item, index) =>
                    <View style={StyleTable.tableRow} key={'MCAC' + index} wrap={false}>
                        <View style={index == calcs.singleAppCalcResults.length - 1 ? [StyleTable.tableColRowZero, { flexGrow: 0.4 }] : [StyleTable.tableCol, { flexGrow: 0.4 }]}>
                            <Text style={StyleTable.tableCell}>{index + 1}</Text>
                        </View>
                        <View style={index == calcs.singleAppCalcResults.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{(projectData.projectApplicationList[index]?.building || "") + ' / ' + (projectData.projectApplicationList[index]?.department || "") + ' / ' + (projectData.projectApplicationList[index]?.machine || "")}</Text>
                        </View>
                        <View style={index == calcs.singleAppCalcResults.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(item.optimizationFlowSavings, country, 0)}</Text>
                        </View>
                        <View style={index == calcs.singleAppCalcResults.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(item.optimizationVolumeYearSavings, country, 0)}</Text>
                        </View>
                        <View style={index == calcs.singleAppCalcResults.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(item.optimizationSavingsBeforeTaxes, country, 0)}</Text>
                        </View>
                        <View style={index == calcs.singleAppCalcResults.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableColTotal}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(item.optimizationInvestment, country, 0)}</Text>
                        </View>
                        <View style={index == calcs.singleAppCalcResults.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(item.optimizationPaybackTime, country, 1)}</Text>
                        </View>
                    </View>
                )}

            </View>
            {/**------------------------------------------------------------------- */}
            <Text style={PdfStyles.spacer}></Text>
            <View style={PdfStyles.content} >
                <View wrap={false}>
                    <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["MACACPaybackTime"] = pageNumber }}></Text>
                    <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.macac}.3 {t('PaybackTime')} </Text>
                </View>
                <Text style={PdfStyles.spacer}></Text>
                <View style={StyleTableNoBorder.table}>
                    <View style={StyleTableNoBorder.tableRow}>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{t('AnnualSavingsAfterTaxes')} {Fmt.getCurrency()}</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableColColon}>
                            <Text style={StyleTableNoBorder.tableCell}>:</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{Fmt.round(props.calcs?.optimizationSavingsAfterTaxesTOTAL, country, 0)}</Text>
                        </View>
                    </View>
                    <View style={StyleTableNoBorder.tableRow}>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCellTotal}>{t('TotalInvestment')} {Fmt.getCurrency()}</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableColColon}>
                            <Text style={StyleTableNoBorder.tableCellTotal}>:</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCellTotal}>{Fmt.round(props.calcs?.optimizationInvestmentTOTAL, country, 0)}</Text>
                        </View>
                    </View>
                    <View style={StyleTableNoBorder.tableRow}>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{t('PaybackTimeInMonths')}</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableColColon}>
                            <Text style={StyleTableNoBorder.tableCell}>:</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{Fmt.round(props.calcs?.optimizationPaybackTimeTOTAL, country, 1)}</Text>
                        </View>
                    </View>
                </View>
            </View>
        </Page>
    )
}