import React from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { usePdfStyles } from './PdfStyles';

export const AirNetworkDistributionLinePdf = (props) => {
    const { t } = useTranslation();
    var PdfStyles = usePdfStyles();

    return (
        <Page size={props.pageSize} style={PdfStyles.page}>
            <View style={PdfStyles.header}>
                <View style={PdfStyles.logoWrapper}>
                    {props.logo && <Image style={PdfStyles.logo} src={props.logo} />}
                </View>
            </View>
            <View style={PdfStyles.pageBody}>
                <View>
                    <Text style={PdfStyles.heading}>{t('DistributionLine')}</Text>
                </View>
                <View style={PdfStyles.table}>
                    <View style={PdfStyles.tableRow}>
                        <View style={PdfStyles.tableCol0}>
                            <Text style={PdfStyles.tableCell0}>{t('Nr')}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('MeasurementId')}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('Name')}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('PipeDiameter')}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('DistributionLineLayout')}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('PipeMaterial')}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('PipeDiameter')}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('TotalPipeLength')}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('PipeVolume')}</Text>
                        </View>
                    </View>
                    {Array.apply(null, { length: props.count }).map((e, i) => (
                        <View key={'distributionline' + i} style={PdfStyles.tableRow}>
                            <View style={PdfStyles.tableCol0}>
                                <Text style={PdfStyles.tableCell0}>{i + 1 + props.offset}</Text>
                            </View>
                            <View style={PdfStyles.tableCol}>
                                <Text style={PdfStyles.tableCell}></Text>
                            </View>
                            <View style={PdfStyles.tableCol}>
                                <Text style={PdfStyles.tableCell}></Text>
                            </View>
                            <View style={PdfStyles.tableCol}>
                                <Text style={PdfStyles.tableCell}></Text>
                            </View>
                            <View style={PdfStyles.tableCol}>
                                <Text style={PdfStyles.tableCell}></Text>
                            </View>
                            <View style={PdfStyles.tableCol}>
                                <Text style={PdfStyles.tableCell}></Text>
                            </View>
                            <View style={PdfStyles.tableCol}>
                                <Text style={PdfStyles.tableCell}></Text>
                            </View>
                            <View style={PdfStyles.tableCol}>
                                <Text style={PdfStyles.tableCell}></Text>
                            </View>
                            <View style={PdfStyles.tableCol}>
                                <Text style={PdfStyles.tableCell}></Text>
                            </View>
                        </View>
                    ))}
                </View>
                <Text style={PdfStyles.heading}>{t('Comment')}</Text>
                <View style={{ height: 100, border: "1px solid rgba(0, 0, 0, 1)" }} ></View>
            </View>
        </Page>
    );
}