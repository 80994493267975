import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { ButtonComponent, HeadingComponent, CheckboxComponent } from '../Reusable/';
import { Close } from '@mui/icons-material';
import * as Mapper from '../../helper/Mappers';
import { ReportCategoryModel } from './ReportCategoryModel';
import * as ImageBlobConstants from '../ImageViewer/Constants';

export default function ReportCategoryDialog(props) {
    const { open, setOpen, data, generate, isProjectPneumaticsOnly, pdfFiles } = props;
    const { t } = useTranslation();

    const [mainModel, setMainModel] = useState({});

    useEffect(() => {
        if (open)
            setCategories(data.project);
    }, [open])

    const setCategories = (project) => {
        let newModel = ReportCategoryModel(t);
        if (project.isCompressedAirQualityAnalysisSelected !== true)
            delete newModel.isCompressedAirQualityAnalysisSelected
        if (project.isAirNetworkAnalysisSelected !== true)
            delete newModel.isAirNetworkAnalysisSelected
        if (project.isMachineAnalysisForEnergyEfficiencySelected !== true)
            delete newModel.isMachineAnalysisForEnergyEfficiencySelected
        if (project.isLeakageDetectionAndDocumentationSelected !== true)
            delete newModel.isLeakageAnalysisSelected
        if (project.isLeakageDetectionAndDocumentationSelected !== true || project.projectLDAR == undefined)
            delete newModel.isLeakageDetectionAndDocumentationSelected
        if (
            data.costSavingsResult == undefined ||
            project.isAnalysisOfTheCompressedAirGenerationSelected !== true ||
            project.isCompressedAirQualityAnalysisSelected !== true ||
            project.isAirNetworkAnalysisSelected !== true ||
            project.isMachineAnalysisForEnergyEfficiencySelected !== true ||
            project.isLeakageDetectionAndDocumentationSelected !== true
        )
            delete newModel.isCostSavingsSelected
        if (!Array.isArray(data.replacementParts) || data.replacementParts.length < 1)
            delete newModel.isReplacementPartsSelected
        setMainModel({ ...newModel });
    }

    const getTocNumbering = (categories) => {
        let n = 1;
        let m = 0;
        let tocNumbers = {};
        if (categories.isExecutiveSummarySelected) {
            n++;
            tocNumbers["executiveSummary"] = n;
        }
        if (categories.isGeneralInformationSelected) {
            n++;
            tocNumbers["generalInformation"] = n;
        }
        if (categories.isAnalysisOfTheCompressedAirGenerationSelected) {
            n++;
            tocNumbers["cag"] = n;
        }
        if (categories.isCompressedAirQualityAnalysisSelected) {
            n++;
            tocNumbers["airQuality"] = n;
            m = 0;
            if (data.project.individualDryersList.length > 0) {
                m++;
                tocNumbers["InstalledDryers"] = m;
            }
            if (data.project.airQualityMeasurementList.length > 0) {
                m++;
                tocNumbers["AirQualityMeasurement"] = m;
            }
        }
        if (categories.isAirNetworkAnalysisSelected) {
            n++;
            tocNumbers["airNetwork"] = n;
            m = 0;
            if (data.project.airNetworkAnalysis.distributionLineList.length > 0) {
                m++;
                tocNumbers["DistributionLine"] = m;
            }
            if (data.project.airNetworkAnalysis.airReceiverList.length > 0) {
                m++;
                tocNumbers["AirReceivers"] = m;
            }
            m++
            tocNumbers["StorageAirCapacity"] = m;
            if (data.project.airNetworkAnalysis.pressureDropAnalysisList.length > 0) {
                m++;
                tocNumbers["PressureDropAnalysis"] = m;
            }
        }
        if (categories.isMachineAnalysisForEnergyEfficiencySelected) {
            n++;
            tocNumbers["macac"] = n;
        }
        if (categories.isLeakageAnalysisSelected) {
            n++;
            tocNumbers["leakageAnalysis"] = n;
        }
        if (categories.isLeakageDetectionAndDocumentationSelected) {
            n++;
            tocNumbers["ldar"] = n;
        }
        if (categories.isCostSavingsSelected) {
            n++;
            tocNumbers["costSavings"] = n;
        }
        if (categories.isReplacementPartsSelected) {
            n++;
            tocNumbers["replacementParts"] = n;
        }
        if (categories.isPdfAttachmentsSelected && pdfFiles && pdfFiles.length > 0) {
            n++;
            tocNumbers["attachments"] = n;
        }
        tocNumbers["cagChildren"] = getCagChildren();
        return tocNumbers;
    }
    const getCagChildren = () => {
        let response = {
            1: 1,
            2: 2,
            3: 3,
            4: 4,
            5: 5,
            6: 6,
            7: 7,
            8: 8,
            9: 9,
            10: 10,
            11: 11,
            12: 12,
            13: 13,
            14: 14,
            15: 15,
            16: 16
        };

        if (isProjectPneumaticsOnly === true) {
            response = {
                1: 1,
                2: 2,
                3: undefined,
                4: 3,
                5: undefined,
                6: undefined,
                7: 4,
                8: 5,
                9: undefined,
                10: undefined,
                11: undefined,
                12: undefined,
                13: undefined,
                14: 6,
                15: undefined,
                16: 7
            };
        }

        return response;
    }

    const anyArrayChildren = (imagelist, list, blobConst) => list != undefined && list.length > 0 && (list.some(x => x.comment != undefined && x.comment.trim() != "") || imagelist.some(image => image.groupId.includes(blobConst)));

    const getExecutiveSummarySectionNumbering = (categories, tocValues) => {
        let n = 0;
        let aq = 0;
        let an = 0;
        let numbering = {};

        //general information
        if (tocValues.tocNumbering.generalInformation != undefined &&
            ((data.project.generalInformationComment != undefined && data.project.generalInformationComment.trim() != "") ||
                data.project.imageList.some(image => image.groupId === ImageBlobConstants.GeneralInfo))
        ) {
            n++;
            numbering["generalInformation"] = n;
        }

        //compressed air generation
        if (
            tocValues.tocNumbering.cag != undefined &&
            ((data.project.compressedAirGeneration?.comment != undefined
                && data.project.compressedAirGeneration.comment.trim() != "")
                || (data.project.compressedAirGeneration?.compressorList != undefined && data.project.compressedAirGeneration.compressorList.length > 0 && data.project.compressedAirGeneration.compressorList.some(x => x.comment != undefined && x.comment.trim() != ""))
                || data.project.imageList.some(image => image.groupId === ImageBlobConstants.CompressedAirGen || image.groupId.includes(ImageBlobConstants.IndividualCompressorPrefix)))
        ) {
            n++;
            numbering["compressedAirGeneration"] = n;
        }

        //air quality
        let anyDryer = data.project.individualDryersList != undefined
            && data.project.individualDryersList.length > 0
            && (data.project.individualDryersList.some(x => x.individualDryerComment != undefined && x.individualDryerComment.trim() != "") || data.project.imageList.some(image => image.groupId.includes(ImageBlobConstants.IndividualDryerPrefix)));
        let anyMeasurement = data.project.airQualityMeasurementList != undefined
            && data.project.airQualityMeasurementList.length > 0
            && (data.project.airQualityMeasurementList.some(x => x.airQualityMeasurementComment != undefined && x.airQualityMeasurementComment.trim() != "") || data.project.imageList.some(image => image.groupId.includes(ImageBlobConstants.AirQualityMeasurementPrefix)));
        if (tocValues.tocNumbering.airQuality != undefined
            && ((data.project.airQualityComment != undefined && data.project.airQualityComment.trim() != "") || data.project.imageList.some(image => image.groupId === ImageBlobConstants.AirQuality) || anyDryer || anyMeasurement)) {
            n++;
            numbering["airQuality"] = n;
        }
        if (tocValues.tocNumbering.airQuality != undefined && anyDryer) {
            aq++;
            numbering["dryer"] = aq;
        }
        if (tocValues.tocNumbering.airQuality != undefined && anyMeasurement) {
            aq++;
            numbering["measurement"] = aq;
        }

        //air network
        let anyDistributionLine = anyArrayChildren(data.project.imageList, data.project.airNetworkAnalysis?.distributionLineList, ImageBlobConstants.DirstibutionLinePrefix);
        let anyAirReceiver = anyArrayChildren(data.project.imageList, data.project.airNetworkAnalysis?.airReceiverList, ImageBlobConstants.AirReceiverPrefix);
        let anyPressureDrop = anyArrayChildren(data.project.imageList, data.project.airNetworkAnalysis?.pressureDropAnalysisList, ImageBlobConstants.PreassureDropAnalysisPrefix);
        if (tocValues.tocNumbering.airNetwork != undefined
            && ((data.project.airNetworkAnalysis?.comment != undefined && data.project.airNetworkAnalysis.comment.trim() != "") || data.project.imageList.some(image => image.groupId === ImageBlobConstants.AirNetworkAnalysis) || anyDistributionLine || anyAirReceiver || anyPressureDrop)) {
            n++;
            numbering["airNetwork"] = n;
        }
        if (tocValues.tocNumbering.airNetwork != undefined && anyDistributionLine) {
            an++;
            numbering["distributionLine"] = an;
        }
        if (tocValues.tocNumbering.airNetwork != undefined && anyAirReceiver) {
            an++;
            numbering["airReceiver"] = an;
        }
        if (tocValues.tocNumbering.airNetwork != undefined && anyPressureDrop) {
            an++;
            numbering["pressureDrop"] = an;
        }

        //machine analysis
        let anyApplication = data.project.projectApplicationList != undefined
            && data.project.projectApplicationList.length > 0
            && (data.project.projectApplicationList.some(x => x.applicationComment != undefined && x.applicationComment.trim() != "") || data.project.imageList.some(image => image.groupId.includes(ImageBlobConstants.ApplicationPrefix)));
        if (tocValues.tocNumbering.macac != undefined
            && ((data.project.macacComment != undefined && data.project.macacComment.trim() != "") || data.project.imageList.some(image => image.groupId === ImageBlobConstants.MACAC) || anyApplication)) {
            n++;
            numbering["macac"] = n;
        }
        if (tocValues.tocNumbering.macac != undefined && anyApplication) {
            numbering["application"] = 1;
        }

        //leakage analysis
        if (tocValues.tocNumbering.leakageAnalysis != undefined
            && ((data.project.leakageAnalysis?.comment != undefined && data.project.leakageAnalysis.comment.trim() != "") || data.project.imageList.some(image => image.groupId === ImageBlobConstants.LeakageAnalysis))) {
            n++;
            numbering["leakageAnalysis"] = n;
        }

        //ldar
        if (tocValues.tocNumbering.ldar != undefined
            && ((data.project.projectLDAR?.comment != undefined && data.project.projectLDAR.comment.trim() != "") || data.project.imageList.some(image => image.groupId === ImageBlobConstants.LDAR))) {
            n++;
            numbering["ldar"] = n;
        }

        //cost savings
        if (tocValues.tocNumbering.costSavings != undefined
            && ((data.project.costSavingsComment != undefined && data.project.costSavingsComment.trim() != "") || data.project.imageList.some(image => image.groupId === ImageBlobConstants.CostSavings))) {
            n++;
            numbering["costSavings"] = n;
        }

        return numbering;
    }

    const save = () => {
        let categories = Mapper.extractValuesFromModel(mainModel);
        let tocValues = {};
        tocValues["categories"] = categories;
        tocValues["tocNumbering"] = getTocNumbering(categories);
        tocValues["executiveSummarySections"] = getExecutiveSummarySectionNumbering(categories, tocValues);
        generate(tocValues);
        setOpen(false);
    }

    return (
        <Dialog open={open}>
            <DialogTitle>
                <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
                    <Grid item xs={12} md={8}>
                        <HeadingComponent value={t('SelectChapters')} size="h6" />
                    </Grid>
                    <Grid item>
                        <IconButton color="inherit" size="small" onClick={() => setOpen(false)}>
                            <Close fontSize="small" />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container direction="column">
                    {Object.entries(mainModel).map(x =>
                        <Grid item key={x[0]}>
                            <CheckboxComponent model={x[1]} onChange={e => Mapper.updateModel(x[0], e.target.checked, mainModel, setMainModel)} />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item><ButtonComponent id="Cancel" value={t('Cancel')} color="secondary" onChange={() => setOpen(false)}></ButtonComponent></Grid>
                    <Grid item><ButtonComponent id="Generate" value={t('Generate')} color="primary" onChange={save}></ButtonComponent></Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}