import { BehaviorSubject } from "rxjs";
import { API } from '../helper/ApiHelper';
import { IAlert } from "../components/Types/Types";

const getAlertUrl = process.env.REACT_APP_DEVFESSINQUIRYSERVICES_BASE + "inquiry-management/alert";
const AlertModel = new BehaviorSubject<IAlert>({});

export const AlertStore = {
    AlertModel,
    setAlertModel: (alert: IAlert) => {
        AlertModel.next(alert);
    },
    initAlertModel: () => {
        API.get(getAlertUrl)
            .then(alert => {
                AlertModel.next(alert);
            });
    }
}