// @ts-expect-error not converted yet
import { checkIsInteger, checkIsNotNullOrWhitespace, checkYear, checkIsDecimal, checkIsDateInPast, checkIsDateInPastUTC } from "../../../helper/Validator";
import * as Fmt from '../../../helper/Formatters';
import { UserStore } from '../../../store'
import { IUser } from "../../../store/UserStore";
import { IDropdownItem, ModelParam, Translate } from "../../Types/Types";
import { nowUTC } from "../../../helper/DateHelper";

let user: IUser = {};
UserStore.user.subscribe(usr => {
    user = usr;
})


export interface IIndividualDryerModelFactory {
    id: ModelParam<number>
    guid: ModelParam<string | null>;
    measurementId: ModelParam<string>;

    entryDate: ModelParam<Date>
    detectedBy: ModelParam<string>

    repairIsDone: ModelParam<boolean>;
    repairedBy: ModelParam<string>;
    repairDate: ModelParam<Date | null>;

    individualDryerComment: ModelParam<string>;
    repairComment: ModelParam<string>;

    manufacturer: ModelParam<string>;

    year: ModelParam<string>;
    compressorRoomNo: ModelParam<string>;
    modelSerialNumber: ModelParam<string>;
    flowRangeOfDryer: ModelParam<number | null>;
    temperatureOftheDryer: ModelParam<number | null>;
    typeOfDryer: ModelParam<IDropdownItem>;
}

export const IndividualDryerModelFactory = (t: Translate): IIndividualDryerModelFactory => (
    {
        id: { headerName: t('Nr'), name: "no", label: t("Id"), idkey: "DRYEREDIT-Id", value: 0, default: 0, isValid: true, validationError: "", validators: [] },
        guid: { label: t("Guid"), idkey: "DRYEREDIT-guid", value: null, default: "", isValid: true, validationError: "", validators: [] },
        measurementId: { label: t("MeasurementId"), idkey: "DRYEREDIT-measurementId", value: "", default: "", isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger], },

        entryDate: { label: t("EntryDate"), idkey: "DRYEREDIT-entryDate", value: nowUTC(), default: null, isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDateInPastUTC], headerName: "IndividualDryerInformation" },
        detectedBy: { label: t("DetectedBy"), idkey: "DRYEREDIT-detectedBy", value: user.firstName + " " + user.lastName, default: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace] },

        repairIsDone: { label: t("OptimizationIsDone"), idkey: "DRYEREDIT-repairIsDone", value: false, default: false, isValid: true, validationError: "", validators: [], dependsOn: "" },
        repairedBy: { label: t("OptimizedBy"), idkey: "DRYEREDIT-repairedBy", value: "", default: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace], dependsOn: "RepairIsDone" },
        repairDate: { label: t("OptimizationDate"), idkey: "DRYEREDIT-repairDate", value: null, default: null, isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDateInPast], dependsOn: "RepairIsDone" },

        individualDryerComment: { label: t("IndividualDryerComment"), idkey: "DRYEREDIT-individualDryerComment", value: "", default: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [] },
        repairComment: { label: t("RepairComment"), idkey: "DRYEREDIT-RepairComment", value: "", isValid: true, validationError: "", validators: [] },

        manufacturer: { label: t("Manufacturer"), idkey: "DRYEREDIT-manufacturer", value: "", default: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace], headerName: "Details" },

        compressorRoomNo: { label: t("CompressorRoomNo"), idkey: "DRYEREDIT-compressorRoomNo", value: "", default: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsInteger], headerName: "CompressorRoomNo" },
        typeOfDryer: { label: t("TypeOfDryer"), idkey: "DRYEREDIT-typeOfDryer", value: { id: 0, value: "string" }, default: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace] },
        modelSerialNumber: { label: t("ModelSerialNumber"), default: "", idkey: "DRYEREDIT-modelSerialNumber", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace] },
        year: { label: t("Year"), idkey: "DRYEREDIT-year", value: "", default: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsInteger, checkYear] },
        flowRangeOfDryer: { label: t("FlowRangeOfDryer") + Fmt.getUnitInBracket("volumePerTime", t), idkey: "DRYEREDIT-FlowRangeOfDryer", value: null, default: "", isValid: true, validationError: t("ValidNumberError"), validators: [checkIsDecimal], headerName: t('FlowRange') + Fmt.getUnitInBracket("volumePerTime", t) },
        temperatureOftheDryer: { label: t("TemperatureOftheDryer") + Fmt.getUnitInBracket("temperature", t), idkey: "DRYEREDIT-temperatureOftheDryer", value: null, default: "", isValid: true, validationError: t("ValidNumberError"), validators: [checkIsDecimal], headerName: t('TemperatureOfTheAir') + Fmt.getUnitInBracket("temperature", t) },
    }
)

export interface IAirQualityCalcModelFactory {
    assignedCompressorsNumbers: ModelParam<string>
    dryerCapacityCorrectedOperatingConditions: ModelParam<number>
    dryerFlowAssignedCompressor: ModelParam<number>
    dryerReserve: ModelParam<number>
}

export const AirQualityCalcModelFactory = (t: Translate): IAirQualityCalcModelFactory => (
    {
        assignedCompressorsNumbers: { value: "", label: "", isValid: true, idkey: "DRYEREDIT-assignedCompressorsNumbers", headerName: t('AssignedCompressors'), sortable: false, validationError: "", validators: [] },
        dryerCapacityCorrectedOperatingConditions: { value: 0, label: "", isValid: true, idkey: "DRYEREDIT-dryerCapacityCorrectedOperatingConditions", headerName: t('FlowRangeOfDryersAtOperatingnm3') + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t), sortable: false, validationError: "", validators: [] },
        dryerFlowAssignedCompressor: { value: 0, label: "", isValid: true, idkey: "DRYEREDIT-dryerFlowAssignedCompressor", headerName: t('FlowRangeOfAssignednm3') + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t), sortable: false, validationError: "", validators: [] },
        dryerReserve: { value: 0, label: "", isValid: true, idkey: "DRYEREDIT-dryerReserve", headerName: t('Reserve') + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t), sortable: false, validationError: "", validators: [] }
    }
)