//Table name consts
const savingsEliminatingTheLeakagesTable = "SavingsEliminatingTheLeakagesTable";
const savingsShuttingOffMainAirSupplyAtNonProductionTimeTable = "SavingsShuttingOffMainAirSupplyAtNonProductionTimeTable";
const savingsReducingThePressureDropTable = "SavingsReducingThePressureDropTable";
const savingsReducingThePressureLevelOfTheSystemTable = "SavingsReducingThePressureLevelOfTheSystemTable"
const savingsOptimizingTheAirConsumptionAtSingleApplicationsTable = "SavingsOptimizingTheAirConsumptionAtSingleApplicationsTable";
const cumulativeHighestSavingsPerYearTable = "CumulativeHighestSavingsPerYearTable";

//Column name consts
const staticConsumptionNlMin = "StaticConsumption"
const electricityConsumptionkWh = "ElectricityConsumption"
const airConsumptionNm3 = "AirConsumption"
const co2 = "CO2"
const energyCosts = "EnergyCost"
const totalCosts = "TotalCosts"
const level = "Level"
const timeAirSupplyPerYearHours = "TimeAirSupplyPerYearHours"
const pressureDropBar = "PressureDrop"
const flowRateNlmin = "AirFlow"
const pressureLevel = "PressureLevel"

export {
    savingsEliminatingTheLeakagesTable,
    savingsShuttingOffMainAirSupplyAtNonProductionTimeTable,
    savingsReducingThePressureDropTable,
    savingsReducingThePressureLevelOfTheSystemTable,
    savingsOptimizingTheAirConsumptionAtSingleApplicationsTable,
    cumulativeHighestSavingsPerYearTable,
    staticConsumptionNlMin,
    electricityConsumptionkWh,
    airConsumptionNm3,
    co2,
    energyCosts,
    totalCosts,
    level,
    timeAirSupplyPerYearHours,
    pressureDropBar,
    flowRateNlmin,
    pressureLevel
}