import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Dialog, DialogTitle, DialogContent, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@mui/material';
import { HeadingComponent, TableHeadCells, TableHeadingContent, FilterModel } from '../../Reusable/';
import { Close, Forward } from '@mui/icons-material';
import { API } from '../../../helper/ApiHelper';
import { IndividualDryerModelFactory } from './IndividualDryerModel';

export default function DryerSearchDialog(props) {
    const { open, setOpen, importDryerModel } = props;

    const getDryerModelsUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "air-quality/dryer-models";
    const { t } = useTranslation();
    const dryerModel = IndividualDryerModelFactory(t);

    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(5);
    const [dryerModels, setDryerModels] = useState([]);
    const [filterData, setFilterData] = useState([]);

    useEffect(() => {
        if (open === true && dryerModels.length === 0)
            fetchDryerModels();
        else
            setDryerModels([...filterData]);
    }, [open]);

    const fetchDryerModels = async () => {
        let response = await API.get(getDryerModelsUrl);
        setDryerModels(response);
        setFilterData(response);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const filterFunction = (tempdata) => {
        setDryerModels(tempdata);
        setPage(0);
    }

    const changeRows = (e) => {
        setRows(e.target.value);
        setPage(0);
    }

    return (
        <Dialog open={open}>
            <DialogTitle>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <HeadingComponent value={t("DryerModelSearch")} size="h6" />
                    </Grid>
                    <Grid item>
                        <IconButton color="inherit" onClick={() => setOpen(false)} size="large">
                            <Close fontSize="small" />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={3} direction="column" className="ReplacementPartMaterialSearch">
                    <Grid item>
                        <TableHeadingContent
                            defaultData={dryerModels}
                            filterData={filterData}
                            onFilterFunction={filterFunction}
                            filterModel={FilterModel.InstalledDryerTechnicalData}
                            NotShowAddDelete={true}
                            tableId={"DryerSearch"}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper} >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableHeadCells
                                            model={dryerModel}
                                            propertyList={["manufacturer", "typeOfDryer", "modelSerialNumber", "flowRangeOfDryer"]}
                                            tableName="DryerModels"
                                            data={dryerModels}
                                            setData={setDryerModels}
                                        />
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        dryerModels.slice(page * rows, rows + (page * rows)).map((dryer, rowIndex) =>
                                            <TableRow key={rowIndex}>
                                                <TableCell>{dryer.manufacturer}</TableCell>
                                                <TableCell>{t(dryer.typeOfDryer?.value)}</TableCell>
                                                <TableCell>{dryer.modelSerialNumber}</TableCell>
                                                <TableCell>{dryer.flowRangeOfDryer}</TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        onClick={() => importDryerModel(dryer)}
                                                        id="btn_MaterialDialog-Pick"
                                                        color="inherit"
                                                        size="large"
                                                    >
                                                        <Forward fontSize="large" />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                            <TablePagination
                                component="div"
                                rowsPerPageOptions={[5, 10, 15]}
                                count={dryerModels && dryerModels.length > 0 ? dryerModels.length : 0}
                                rowsPerPage={rows}
                                onRowsPerPageChange={changeRows}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        </TableContainer>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}