import React, { useEffect, useState } from 'react'
import { Grid } from "@mui/material";
import './TopContentContainer.scss'
import BreadcrumbsComponent from '../Breadcrumbs/Breadcrumbs';
import { ExecutorStore } from '../../store';
import { useTranslation } from 'react-i18next';

export default function TopContentContainer(props) {
    const { ContentComponent, showExecutor } = props
    const [executorSettings, setExecutorSettings] = useState({});
    const { t } = useTranslation();

    useEffect(() => {
        let execSub = ExecutorStore.executorSettings.subscribe(exec => {
            setExecutorSettings(exec);
        })
        return () => execSub.unsubscribe();
    }, []);
    return (
        <div className="project-view">
            <Grid container justifyContent="space-between" alignItems="center">
                <BreadcrumbsComponent></BreadcrumbsComponent>
                {
                    executorSettings.subsidiaryFestoName != undefined &&
                    showExecutor &&
                    <div className="subsidiaryFestoName">
                        <span>{t("Executor")} - </span> {executorSettings.subsidiaryFestoName}
                    </div>
                }
            </Grid>
            <Grid container spacing={3} className="projectMenu-body-inner">
                <Grid item lg={6} md={3} sm={0}>
                    <img
                        src={"/pictures/16977c_1.png"}
                        alt=""
                        className="projectMenu-fessicon"
                    />
                </Grid>
                <Grid item lg={6} md={9} sm={12}>
                    {ContentComponent}
                </Grid>
            </Grid>
        </div>
    )
}
