import React from "react"
import { Typography, TypographyProps, styled } from '@mui/material';
import { Variant } from "@mui/material/styles/createTypography";

interface IHeadingComponent extends TypographyProps {
    value: string,
    size: Variant,
}

const StyledTypography = styled(Typography)({
    font: "Normal 1.5rem/1.5rem Meta Pro",
    marginTop: "1rem",
    overflow: "hidden",
    textOverflow: "ellipsis"
}) as typeof Typography;

const HeadingComponent = (props: IHeadingComponent) => {
    const { value, size, style, ...rest } = props;
    return (<StyledTypography variant={size} style={style} gutterBottom {...rest}>{value}</StyledTypography>);
}

export default HeadingComponent;