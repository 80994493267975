import React from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import * as Fmt from '../../helper/Formatters'

export default function ConsumptionTable(props) {
    const { t } = useTranslation();
    const { country } = useParams();

    const { Consumptions } = props

    const staticConsumption = Consumptions.find(x => x.consumptionType == "Static");
    const yearlyConsumption = Consumptions.find(x => x.consumptionType == "Yearly");
    const futureConsumption = Consumptions.find(x => x.consumptionType == "Future");
    const leakageConsumption = Consumptions.find(x => x.consumptionType == "Leakage");
    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                {Consumptions.length > 0
                    ?
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>{t('Level') + Fmt.getPercentage()}</TableCell>
                                <TableCell>{t('AverageAirFlow') + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t)}</TableCell>
                                <TableCell>{t('ElectricityConsumption') + Fmt.getUnitInBracket("powerPerHour", t)}</TableCell>
                                <TableCell>{t('AirConsumption') + Fmt.getUnitInBracket("cubicMeterOfGas", t)}</TableCell>
                                <TableCell>{t('CO2') + Fmt.getUnitInBracket("co2Wheight", t)}</TableCell>
                                <TableCell>{t('EnergyCost') + Fmt.getCurrency()}</TableCell>
                                <TableCell>{t('AcquisitionAndMaintenanceCosts') + Fmt.getCurrency()}</TableCell>
                                <TableCell className="font-bold">{t('TotalCosts') + Fmt.getCurrency()}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">{t('YearlyConsumption')}</TableCell>
                                <TableCell id="td_yearlyCon-airLevel">{Fmt.round(yearlyConsumption.airLevel, country, 0)}</TableCell>
                                <TableCell id="td_yearlyCon-averageAirFlow">{Fmt.round(yearlyConsumption.averageAirFlow, country, 1)}</TableCell>
                                <TableCell id="td_yearlyCon-electricity">{Fmt.round(yearlyConsumption.electricity, country, 0)}</TableCell>
                                <TableCell id="td_yearlyCon-air">{Fmt.round(yearlyConsumption.air, country, 0)}</TableCell>
                                <TableCell id="td_yearlyCon-co2">{Fmt.round(yearlyConsumption.co2, country, 0)}</TableCell>
                                <TableCell id="td_yearlyCon-energyCosts">{Fmt.round(yearlyConsumption.energyCosts, country, 0)}</TableCell>
                                <TableCell id="td_yearlyCon-acquisitionAndMaintenanceCosts">{Fmt.round(yearlyConsumption.acquisitionAndMaintenanceCosts, country, 0)}</TableCell>
                                <TableCell className="font-bold" id="td_yearlyCon-totalCosts">{Fmt.round(yearlyConsumption.totalCosts, country, 0)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">{t('StaticConsumption')}</TableCell>
                                <TableCell id="td_staticCon-airLevel">{Fmt.round(staticConsumption.airLevel, country, 0)}</TableCell>
                                <TableCell id="td_staticCon-averageAirFlow">{Fmt.round(staticConsumption.averageAirFlow, country, 1)}</TableCell>
                                <TableCell id="td_staticCon-electricity">{Fmt.round(staticConsumption.electricity, country, 0)}</TableCell>
                                <TableCell id="td_staticCon-air">{Fmt.round(staticConsumption.air, country, 0)}</TableCell>
                                <TableCell id="td_staticCon-co2">{Fmt.round(staticConsumption.co2, country, 0)}</TableCell>
                                <TableCell id="td_staticCon-energyCosts">{Fmt.round(staticConsumption.energyCosts, country, 0)}</TableCell>
                                <TableCell id="td_staticCon-acquisitionAndMaintenanceCosts">{Fmt.round(staticConsumption.acquisitionAndMaintenanceCosts, country, 0)}</TableCell>
                                <TableCell className="font-bold" id="td_staticCon-totalCosts">{Fmt.round(staticConsumption.totalCosts, country, 0)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">{t('ExpectedLeakages')}</TableCell>
                                <TableCell id="td_leakageCon-airLevel">{Fmt.round(leakageConsumption.airLevel, country, 0)}</TableCell>
                                <TableCell id="td_leakageCon-averageAirFlow">{Fmt.round(leakageConsumption.averageAirFlow, country, 1)}</TableCell>
                                <TableCell id="td_leakageCon-electricity">{Fmt.round(leakageConsumption.electricity, country, 0)}</TableCell>
                                <TableCell id="td_leakageCon-air">{Fmt.round(leakageConsumption.air, country, 0)}</TableCell>
                                <TableCell id="td_leakageCon-co2">{Fmt.round(leakageConsumption.co2, country, 0)}</TableCell>
                                <TableCell id="td_leakageCon-energyCosts">{Fmt.round(leakageConsumption.energyCosts, country, 0)}</TableCell>
                                <TableCell id="td_leakageCon-acquisitionAndMaintenanceCosts">{Fmt.round(leakageConsumption.acquisitionAndMaintenanceCosts, country, 0)}</TableCell>
                                <TableCell className="font-bold" id="td_leakageCon-totalCosts">{Fmt.round(leakageConsumption.totalCosts, country, 0)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">{t('FutureStaticConsumption')}</TableCell>
                                <TableCell id="td_futureCon-airLevel">{Fmt.round(futureConsumption.airLevel, country, 0)}</TableCell>
                                <TableCell id="td_futureCon-averageAirFlow">{Fmt.round(futureConsumption.averageAirFlow, country, 1)}</TableCell>
                                <TableCell id="td_futureCon-electricity">{Fmt.round(futureConsumption.electricity, country, 0)}</TableCell>
                                <TableCell id="td_futureCon-air">{Fmt.round(futureConsumption.air, country, 0)}</TableCell>
                                <TableCell id="td_futureCon-co2">{Fmt.round(futureConsumption.co2, country, 0)}</TableCell>
                                <TableCell id="td_futureCon-energyCosts">{Fmt.round(futureConsumption.energyCosts, country, 0)}</TableCell>
                                <TableCell id="td_futureCon-acquisitionAndMaintenanceCosts">{Fmt.round(futureConsumption.acquisitionAndMaintenanceCosts, country, 0)}</TableCell>
                                <TableCell className="font-bold" id="td_futureCon-totalCosts">{Fmt.round(futureConsumption.totalCosts, country, 0)}</TableCell>
                            </TableRow>
                        </TableBody>

                    </Table>
                    : null}
            </TableContainer>
            <Typography>
                * {t("InclusinveElectricityMaintenanceInvestmentCosts")}
            </Typography>
            <Typography>
                ** 25% {t('OfTheTotalCosts')}
            </Typography>
        </React.Fragment>
    );
}