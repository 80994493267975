import * as React from "react";
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { LinkComponent } from '../Reusable';

const LoginMessage = () => {
    const { t } = useTranslation();

    return (
        <Grid container>
            <Grid item md={12}>
                <Grid container className="content-max-height" justifyContent="center" alignItems="center" direction="column">
                    <CircularProgress style={{ color: "#0091dc" }} />
                    <Typography variant="h4">{t("SessionExpired")}</Typography>
                    <Typography variant="h4">{t("PleaseAllowPopups")}</Typography>
                    <LinkComponent
                        newTab
                        link="https://support.google.com/chrome/answer/95472"
                        innerComponent={t("PopupInstructionLink")}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}
export default LoginMessage;