// @ts-expect-error not converted yet
import { checkEmail, checkIsNotNullOrWhitespace, checkNumberMoreZero, checkPhoneNumber, checkIsAnythingSelectedObject, checkIsInteger, checkIsDecimal, checkPercentage, checkHours, checkIsNotNull, checkPostcode } from '../../helper/Validator';
import { Translate, ModelParam, IDropdownItem } from '../Types/Types';
import * as Fmt from '../../helper/Formatters';

export interface IInquiry {
    id?: number;
    projectId?: string;
    executorId?: number;
    licenseTypeId?: number;
    customerNumber?: string;
    measurementSystem?: IDropdownItem;
    temperatureSpring?: string;
    temperatureSummer?: string;
    temperatureAutumn?: string;
    temperatureWinter?: string;
    humiditySpring?: string;
    humiditySummer?: string;
    humidityAutumn?: string;
    humidityWinter?: string;
    altitudeAboveSeaLevel?: string;
    isTheAirSupllyShuttedOff?: boolean;
    costsPerkWh?: string;
    mondayToFridayWorkingTime?: string;
    saturdayWorkingTime?: string;
    sundayWorkingTime?: string;
    isTermsAndConditionsApproved?: boolean;
    staticConsumption?: string;
    leakageLevel?: string;
    leakageDetectionDays?: string;
    comment?: string;

    companyName?: string;
    address?: string;
    postcode?: string;
    postcodeRegex?: string;
    city?: string;
    country?: string;

    salutation?: number;
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: number | null;

    isCompressedAirEnergyEfficiencyPreAuditSelected?: boolean;
    isAnalysisOfTheCompressedAirGenerationSelected?: boolean;
    isCompressedAirQualityAnalysisSelected?: boolean;
    isAirNetworkAnalysisSelected?: boolean;
    isMachineAnalysisForEnergyEfficiencySelected?: boolean;
    isLeakageDetectionAndDocumentationSelected?: boolean;
    isLicenseLeakageDetectionSelected?: boolean;
    isSubscriptionLeakageDetectionSelected?: boolean;
    isFreeCalculationOfLeakagesAndSavingsPotentialSelected?: boolean;
    isFreeTestLicenseForFestoEnergySavingsServicesSelected?: boolean;
}

export interface IInquiryModel {
    id: ModelParam<number>;
    projectId: ModelParam<string>;
    executorId: ModelParam<number>;
    licenseTypeId: ModelParam<number>;
    customerNumber: ModelParam<string>;
    measurementSystem: ModelParam<IDropdownItem>;
    temperatureSpring: ModelParam<string>;
    temperatureSummer: ModelParam<string>;
    temperatureAutumn: ModelParam<string>;
    temperatureWinter: ModelParam<string>;
    humiditySpring: ModelParam<string>;
    humiditySummer: ModelParam<string>;
    humidityAutumn: ModelParam<string>;
    humidityWinter: ModelParam<string>;
    altitudeAboveSeaLevel: ModelParam<string>;
    isTheAirSupllyShuttedOff: ModelParam<boolean>;
    costsPerkWh: ModelParam<string>;
    mondayToFridayWorkingTime: ModelParam<string>;
    saturdayWorkingTime: ModelParam<string>;
    sundayWorkingTime: ModelParam<string>;
    staticConsumption: ModelParam<string>;
    leakageLevel: ModelParam<string>;
    leakageDetectionDays: ModelParam<string>;
    comment: ModelParam<string>;

    companyName: ModelParam<string>;
    address: ModelParam<string>;
    postcode: ModelParam<string>;
    postcodeRegex: ModelParam<string>;
    city: ModelParam<string>;
    country: ModelParam<string>;

    salutation: ModelParam<number>
    firstName: ModelParam<string>;
    lastName: ModelParam<string>;
    email: ModelParam<string>;
    phoneNumber: ModelParam<number | null>;

    isCompressedAirEnergyEfficiencyPreAuditSelected: ModelParam<boolean>;
    isAnalysisOfTheCompressedAirGenerationSelected: ModelParam<boolean>;
    isCompressedAirQualityAnalysisSelected: ModelParam<boolean>;
    isAirNetworkAnalysisSelected: ModelParam<boolean>;
    isMachineAnalysisForEnergyEfficiencySelected: ModelParam<boolean>;
    isLeakageDetectionAndDocumentationSelected: ModelParam<boolean>;
    isLicenseLeakageDetectionSelected: ModelParam<boolean>;
    isSubscriptionLeakageDetectionSelected: ModelParam<boolean>;
    isFreeCalculationOfLeakagesAndSavingsPotentialSelected: ModelParam<boolean>;
    isFreeTestLicenseForFestoEnergySavingsServicesSelected: ModelParam<boolean>;

    isTermsAndConditionsApproved: ModelParam<boolean>;
    isFollowUpApproved: ModelParam<boolean>;
}

export const InquiryModel = (t: Translate, isAuthorized?: boolean): IInquiryModel => ({
    id: { value: 0, idkey: "", label: "", isValid: true, validationError: "", validators: [] },
    projectId: { value: "", idkey: "INQ-ProjectId", label: t(""), isValid: true, validationError: "", validators: [] },
    executorId: { value: 0, idkey: "INQ-executorSelectId", label: t("Executor"), isValid: true, validationError: "", validators: isAuthorized ? [checkIsNotNull] : [] },
    licenseTypeId: { value: 0, idkey: "INQ-LicenseTypeSelectId", label: t("LicenseTypeId"), isValid: true, validationError: "", validators: isAuthorized ? [checkIsNotNull] : [] },
    customerNumber: { value: "", idkey: "INQ-CustomerNumber", label: t("CustomerName"), isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: isAuthorized ? [checkIsNotNullOrWhitespace] : [] },
    measurementSystem: { value: { id: 1, value: "Metric" }, idkey: "INQ-Measurement-System", label: t("MeasurementSystem"), isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsAnythingSelectedObject] },
    temperatureSpring: { value: "", idkey: "INQ-Temp-Spring", label: `${t('Temperature')} ${t("Spring")}${Fmt.getUnitInBracket("temperature", t)}`, isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger], notRequired: true },
    temperatureSummer: { value: "", idkey: "INQ-Temp-Summer", label: `${t('Temperature')} ${t("Summer")}${Fmt.getUnitInBracket("temperature", t)}`, isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger], notRequired: true },
    temperatureAutumn: { value: "", idkey: "INQ-Temp-Autumn", label: `${t('Temperature')} ${t("Autumn")}${Fmt.getUnitInBracket("temperature", t)}`, isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger], notRequired: true },
    temperatureWinter: { value: "", idkey: "INQ-Temp-Winter", label: `${t('Temperature')} ${t("Winter")}${Fmt.getUnitInBracket("temperature", t)}`, isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger], notRequired: true },
    humiditySpring: { value: "", idkey: "INQ-Humidity-Spring", label: `${t('Humidity')} ${t("Spring")}${Fmt.getPercentage()}`, isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger, checkPercentage], notRequired: true },
    humiditySummer: { value: "", idkey: "INQ-Humidity-Summer", label: `${t('Humidity')} ${t("Summer")}${Fmt.getPercentage()}`, isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger, checkPercentage], notRequired: true },
    humidityAutumn: { value: "", idkey: "INQ-Humidity-Autumn", label: `${t('Humidity')} ${t("Autumn")}${Fmt.getPercentage()}`, isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger, checkPercentage], notRequired: true },
    humidityWinter: { value: "", idkey: "INQ-Humidity-Winter", label: `${t('Humidity')} ${t("Winter")}${Fmt.getPercentage()}`, isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger, checkPercentage], notRequired: true },
    altitudeAboveSeaLevel: { value: "", idkey: "INQ-AltitudeAboveSeaLevel", label: t('AltitudeAboveSeaLevel') + Fmt.getUnitInBracket("unitMFoot", t), isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger], notRequired: true },
    isTheAirSupllyShuttedOff: { value: false, idkey: "INQ-IsTheAirSupllyShuttedOff", label: t("IsTheAirSupllyShuttedOff"), isValid: true, validationError: "", validators: [] },
    costsPerkWh: { value: "", idkey: "INQ-CostsPerkWh", label: t("CostsPerkWh") + Fmt.getUnitWithCurrency("powerPerHour", t), isValid: true, validationError: t("ValidNumberError"), validators: [checkIsDecimal], notRequired: true },
    mondayToFridayWorkingTime: { value: "", idkey: "INQ-MondayToFriday", label: t("MondayToFriday") + Fmt.getUnitInBracket("HoursPerDay", t), isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger, checkHours], notRequired: true },
    saturdayWorkingTime: { value: "", idkey: "INQ-Saturday", label: t("Saturday") + Fmt.getUnitInBracket("HoursPerDay", t), isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger, checkHours], notRequired: true },
    sundayWorkingTime: { value: "", idkey: "INQ-Sunday", label: t("Sunday") + Fmt.getUnitInBracket("HoursPerDay", t), isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger, checkHours], notRequired: true },
    staticConsumption: { value: "15", idkey: "INQ-StaticConsumption", label: t("StaticConsumptionDuringProductionTime") + Fmt.getPercentage(), isValid: true, validationError: "", validators: [checkIsDecimal] },
    leakageLevel: { value: "10", idkey: "INQ-LeakageLevel", label: t("LeakageLevelDuringProductionTime") + Fmt.getPercentage(), isValid: true, validationError: "", validators: [checkIsDecimal] },
    leakageDetectionDays: { value: "", idkey: "INQ-EstimatedLeakageDetectionDays", label: t("EstimatedLeakageDetectionDays"), isValid: true, validationError: "", validators: [] },
    comment: { value: "", idkey: "INQ-Comment", label: t("Comment"), isValid: true, validationError: "", validators: [] },
    
    companyName: { value: "", idkey: "INQ-CompanyName", label: t("CompanyName"), isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace] },
    address: { value: "", idkey: "INQ-Address", label: t("Address"), isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace] },
    postcode: { value: "", idkey: "INQ-Postcode", label: t("Postcode"), isValid: true, validationError: t("InvalidPostcode"), validators: [checkPostcode], notRequired: true },
    postcodeRegex: { label: "", idkey: "", value: "", isValid: true, validationError: "", validators: [] },
    city: { value: "", idkey: "INQ-City", label: t("City"), isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace] },
    country: { value: "", idkey: "INQ-Country", label: t("Country"), isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace] },

    salutation: { value: 0, idkey: "INQ-Salutation", label: t("Salutation"), isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkNumberMoreZero] },
    firstName: { value: "", idkey: "INQ-FirstName", label: t("FirstName"), isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace] },
    lastName: { value: "", idkey: "INQ-LastName", label: t("LastName"), isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace] },
    email: { value: "", idkey: "INQ-Email", label: t("Email"), isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkEmail] },
    phoneNumber: { value: null, idkey: "INQ-PhoneNumber", label: t("PhoneNumber"), isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkPhoneNumber] },

    isCompressedAirEnergyEfficiencyPreAuditSelected: { value: false, idkey: "INQ-CompressedAirEnergyEfficiencyPreAudit", label: t("CompressedAirEnergyEfficiencyPreAudit"), isValid: true, validationError: "", validators: [] },
    isAnalysisOfTheCompressedAirGenerationSelected: { value: false, idkey: "INQ-AnalysisOfTheCompressedAirGeneration", label: t("AnalysisOfTheCompressedAirGeneration"), isValid: true, validationError: "", validators: [] },
    isCompressedAirQualityAnalysisSelected: { value: false, idkey: "INQ-CompressedAirQualityAnalysis", label: t("CompressedAirQualityAnalysis"), isValid: true, validationError: "", validators: [] },
    isAirNetworkAnalysisSelected: { value: false, idkey: "INQ-AirNetworkAnalysis", label: t("AirNetworkAnalysis"), isValid: true, validationError: "", validators: [] },
    isMachineAnalysisForEnergyEfficiencySelected: { value: false, idkey: "INQ-MachineAnalysisForEnergyEfficiency", label: t("MachineAnalysisForEnergyEfficiency"), isValid: true, validationError: "", validators: [] },
    isLeakageDetectionAndDocumentationSelected: { value: false, idkey: "INQ-LeakageDetectionAndDocumentation", label: t("LeakageDetectionAndDocumentation"), isValid: true, validationError: "", validators: [] },
    isLicenseLeakageDetectionSelected: { value: false, idkey: "INQ-LicenseLeakageDetection", label: t("LicenseLeakageDetection"), isValid: true, validationError: "", validators: [] },
    isSubscriptionLeakageDetectionSelected: { value: false, idkey: "INQ-SubscriptionLeakageDetection", label: t("SubscriptionLeakageDetection"), isValid: true, validationError: "", validators: [] },
    isFreeCalculationOfLeakagesAndSavingsPotentialSelected: { value: false, idkey: "INQ-FreeCalculationOfLeakagesAndSavingsPotential", label: t("FreeCalculationOfLeakagesAndSavingsPotential"), isValid: true, validationError: "", validators: [] },
    isFreeTestLicenseForFestoEnergySavingsServicesSelected: { value: false, idkey: "INQ-FreeTestLicenseForFestoEnergySavingsServices", label: t("FreeTestLicenseForFestoEnergySavingsServices"), isValid: true, validationError: "", validators: [] },

    isTermsAndConditionsApproved: { value: false, idkey: "INQ-isTermsAndConditionsApproved", label: "", isValid: true, validationError: "", validators: [] },
    isFollowUpApproved: { value: false, idkey: "INQ-isFollowUpApproved", label: "", isValid: true, validationError: "", validators: [] },
})