import { IAlert, KeyValue, ModelParam, Translate } from "../../../Types/Types";

export interface IAlertManagementModel {
	id: ModelParam<number>,
	alertParameters: ModelParam<IAlertParameterModel[]>,
	message: ModelParam<string>,
	alertType: ModelParam<KeyValue>,
}

export interface IAlertParameterModel {
	id: number,
	index: string,
	value: ModelParam<string>,
}

export const AlertManagementModel = (t: Translate): IAlertManagementModel => ({
	id: { label: t("Id"), idkey: "id", value: 0, isValid: true, validators: [], validationError: "" },
	alertParameters: { label: t("AlertParameters"), idkey: "alertParameters", value: [], isValid: true, validators: [], validationError: "" },
	message: { label: t("Message"), idkey: "alertMessage", value: "", isValid: true, validators: [], validationError: "" },
	alertType: { label: t("AlertType"), idkey: "alertType", value: { id: 0, group: "", value: "" }, isValid: true, validators: [], validationError: "" }
});

export const AlertParameterModel = (id : number, index: string, value: string): IAlertParameterModel => ({
	id: id,
	index: index,
	value: { label: "", idkey: "value", value: value, isValid: true, validators: [], validationError: "" },
});

export const AlertModelToAlert = (model: IAlertManagementModel): IAlert => ({
	id: model.id.value,
	alertParameters: model?.alertParameters?.value?.map(v => ({id: v.id, index: v.index, value: v.value.value})) ?? [],
	message: model.message.value,
	alertType: model.alertType.value
})

export const AlertToAlertModel = (alert: IAlert, t: Translate): IAlertManagementModel => ({
	id: { label: t("Id"), idkey: "id", value: alert.id ?? 0, isValid: true, validators: [], validationError: "" },
	alertParameters: { label: t("AlertParameters"), idkey: "alertParameters", value: alert?.alertParameters?.map(v => AlertParameterModel(v.id, v.index, v.value)) ?? [], isValid: true, validators: [], validationError: "" },
	message: { label: t("Message"), idkey: "alertMessage", value: alert.message ?? "", isValid: true, validators: [], validationError: "" },
	alertType: { label: t("AlertType"), idkey: "alertType", value: { id: alert.alertType?.id ?? 0, group: alert.alertType?.group ?? "", value: alert.alertType?.value ?? "" }, isValid: true, validators: [], validationError: "" }
})