import React from 'react';
import { useTranslation } from 'react-i18next';
import jsQR from "jsqr";
import png from "@syfwl/png.js";
import jpeg from "jpeg-js"
import { OpenGlobalSnackbar } from '../../../helper/GlobalVariables';
import { ButtonComponent } from '../../Reusable'

export default function QrReaderButton(props) {
    const { setCode, setFilename, id } = props;

    const fileInput = React.useRef();

    const convertPNGtoByteArray = (pngData) => {
        const data = new Uint8ClampedArray(pngData.width * pngData.height * 4);
        for (let y = 0; y < pngData.height; y++) {
            for (let x = 0; x < pngData.width; x++) {
                const pixelData = pngData.getPixel(x, y);

                data[(y * pngData.width + x) * 4 + 0] = pixelData[0];
                data[(y * pngData.width + x) * 4 + 1] = pixelData[1];
                data[(y * pngData.width + x) * 4 + 2] = pixelData[2];
                data[(y * pngData.width + x) * 4 + 3] = pixelData[3];
            }
        }
        return data;
    }

    const { t } = useTranslation();

    const onChangeHandler = event => {
        if (event.target.files == undefined) return;
        const f = event.target.files[0];
        if (f.type === "image/png" || f.type === "image/jpeg") {
            setFilename && setFilename(f.name);

            var r = new FileReader();
            r.onload = function (e) {

                const pngReader = new png(e.target.result);
                try {
                    pngReader.parse(function (err, pngData) {
                        if (err) throw err;
                        const pixelArray = convertPNGtoByteArray(pngData);
                        const qrcode = jsQR(pixelArray, pngData.width, pngData.height);
                        if (qrcode) {
                            let id = parseInt(qrcode.data);
                            if (id > 0) {
                                setCode(id);
                            } else { OpenGlobalSnackbar("danger", t('CouldNotReadTheQRcode')); }
                        }
                        else {
                            OpenGlobalSnackbar("danger", t('CouldNotReadTheQRcode'));
                        }
                    });
                }
                catch (error) {
                    //try jpeg reader
                    try {
                        var rawImageData = jpeg.decode(e.target.result, { useTArray: true }); // return as Uint8Array
                        const qrcode = jsQR(rawImageData.data, rawImageData.width, rawImageData.height);
                        if (qrcode) {
                            let id = parseInt(qrcode.data);
                            if (id > 0) {
                                setCode(id);
                            }
                            else {
                                OpenGlobalSnackbar("danger", t('CouldNotReadTheQRcode'));
                            }
                        }
                        else {
                            OpenGlobalSnackbar("danger", t('CouldNotReadTheQRcode'));
                        }
                    }
                    catch (error) {
                        OpenGlobalSnackbar("danger", t('CouldNotReadTheQRcode'));
                    }
                }
            }
            r.readAsArrayBuffer(f);
        }
        else {
            setFilename && setFilename(null);
            OpenGlobalSnackbar("danger", t('OnlyPngAndJpgFilesAllowed'));
        }
    };

    return (
        <React.Fragment>
            <ButtonComponent
                value={t("Upload")}
                color="secondary"
                onChange={() => fileInput.current.click()}
                id="btn_upload-executor-logo"
                icon={<img style={{ height: 24 }} src={"/pictures/qrImage.png"} />}
            />
            <input
                id={id ?? "image-upload"}
                ref={fileInput}
                type="file"
                style={{ display: 'none' }}
                accept="image/png,image/jpeg"
                onChange={onChangeHandler}
            />
        </React.Fragment>
    );

}