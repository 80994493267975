import React from "react";
import { Page, Text, View, Image } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { usePdfStyles } from './PdfStyles';
import * as Fmt from '../../../helper/Formatters';

export const AirQualityMeasurmentPdf = (props) => {
    const { t } = useTranslation();
    var PdfStyles = usePdfStyles();

    return (
        <Page size={props.pageSize} style={PdfStyles.page}>
            <View style={PdfStyles.header}>
                <View style={PdfStyles.logoWrapper}>
                    {props.logo && <Image style={PdfStyles.logo} src={props.logo} />}
                </View>
            </View>
            <View style={{ marginRight: "3%", marginLeft: "3%", marginTop: 0, marginBottom: "1%" }}>
                <View style={PdfStyles.heading}>
                    <Text>{t('AirQualityMeasurement')}</Text>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('MeasurementId')}</Text>
                    </View>
                    <View style={PdfStyles.flexItem}>
                        <Text style={PdfStyles.inputLabel}></Text>
                    </View>
                </View>
                <View>
                    <Text>{t('Particle')}</Text>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={{ marginRight: 10, flexGrow: 1, flexBasis: 0 }}>
                        <Text style={PdfStyles.label}>{t('Class')}</Text>
                    </View>
                    <View style={{ marginRight: 10, flexGrow: 1, flexBasis: 0 }}>
                        <Text style={PdfStyles.label}>{t('ParticleGrad') + Fmt.getUnitInBracket("mu", t)}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Required')}</Text>
                    </View>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Required')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Expected')}</Text>
                    </View>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Expected')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Measured')}</Text>
                    </View>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Measured')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Result')}</Text>
                    </View>
                    <View style={PdfStyles.flexItem}>
                    </View>
                </View>
                <View>
                    <Text>{t('Water')}</Text>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={PdfStyles.flexItem}>
                        <Text style={PdfStyles.label}>{t('Class')}</Text>
                    </View>
                    <View style={PdfStyles.flexItem}>
                        <Text style={PdfStyles.label}>{t('DewPoint') + Fmt.getUnitInBracket("temperature", t)}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Required')}</Text>
                    </View>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Required')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Expected')}</Text>
                    </View>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Expected')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Measured')}</Text>
                    </View>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Measured')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Result')}</Text>
                    </View>
                    <View style={PdfStyles.flexItem}>
                    </View>
                </View>
                <View>
                    <Text>{t('Oil')}</Text>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={PdfStyles.flexItem}>
                        <Text style={PdfStyles.label}>{t('Class')}</Text>
                    </View>
                    <View style={PdfStyles.flexItem}>
                        <Text style={PdfStyles.label}>{t('OilContent') + Fmt.getUnitInBracket("densityInQubicMeter", t)}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Required')}</Text>
                    </View>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Required')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Expected')}</Text>
                    </View>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Expected')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Measured')}</Text>
                    </View>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Measured')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Result')}</Text>
                    </View>
                    <View style={PdfStyles.flexItem}>
                    </View>
                </View>
                <View style={{ height: 40, border: "1px solid rgba(0, 0, 0, 1)" }} >
                    <Text style={PdfStyles.heading}>{t('Comment')}</Text>
                </View>
            </View>
        </Page>

    )
}
