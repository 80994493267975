import React from 'react';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import * as DateHelper from '../../../helper/DateHelper';
import * as Fmt from '../../../helper/Formatters';
// @ts-expect-error not converted yet
import { usePdfStyles, StyleTable, StyleTableNoBorder, FlexTable } from './PdfStyles';
import Watermark from './Watermark';
import { IPDFPageProps } from '../../Types/Types';

const StyleLocal = StyleSheet.create({
    tableCol20: {
        width: "20%",
    },
    tableCol25: {
        width: "25%",
        borderBottom: "1px solid black",
        borderRight: "1px solid black"
    },
    tableCol25RowZero: {
        width: "25%",
        borderBottom: "3px solid black",
        borderRight: "1px solid black"
    },
    tableCol30RowZero: {
        width: "30%",
        borderBottom: "3px solid black",
        borderRight: "1px solid black"
    }
});

export const GeneralInformationPdf = (props: IPDFPageProps) => {
    const { country, data } = props;
    const { t } = useTranslation();
    const PdfStyles = usePdfStyles();

    return (
        <Page size={props.pageSize} style={PdfStyles.page}>
            <Watermark projectData={data} />
            <Text style={PdfStyles.pageNumbers} render={({ pageNumber, totalPages }) => (pageNumber + ' ' + t('of') + ' ' + totalPages)} fixed />
            <View style={PdfStyles.content} >
                <View wrap={false}>
                    <Text style={PdfStyles.boldHeading}>{props.tocValues.tocNumbering.generalInformation} {t('GeneralInformation')}</Text>
                </View>
                <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.generalInformation}.1 {t('CustomerInformation')}</Text>
                <Text style={PdfStyles.spacer}></Text>
                <View style={StyleTableNoBorder.table} wrap={false}>
                    <View style={StyleTableNoBorder.tableRow}>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{t('CompanyName')}</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableColColon}>
                            <Text style={StyleTableNoBorder.tableCell}>:</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{props.data.companyName}</Text>
                        </View>
                    </View>
                    <View style={StyleTableNoBorder.tableRow}>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{t('Address')}</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableColColon}>
                            <Text style={StyleTableNoBorder.tableCell}>:</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{props.data.address}</Text>
                        </View>
                    </View>
                    <View style={StyleTableNoBorder.tableRow}>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{t('City')}</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableColColon}>
                            <Text style={StyleTableNoBorder.tableCell}>:</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{props.data.city}</Text>
                        </View>
                    </View>
                    <View style={StyleTableNoBorder.tableRow}>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{t('Country')}</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableColColon}>
                            <Text style={StyleTableNoBorder.tableCell}>:</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{props.data.country}</Text>
                        </View>
                    </View>
                    <View style={StyleTableNoBorder.tableRow}>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{t('ContactPerson')}</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableColColon}>
                            <Text style={StyleTableNoBorder.tableCell}>:</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{props.data.contactPerson}</Text>
                        </View>
                    </View>
                    <View style={StyleTableNoBorder.tableRow}>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{t('PhoneNumber')}</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableColColon}>
                            <Text style={StyleTableNoBorder.tableCell}>:</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{props.data.phoneNumber}</Text>
                        </View>
                    </View>
                    <View style={StyleTableNoBorder.tableRow}>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{t('Email')}</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableColColon}>
                            <Text style={StyleTableNoBorder.tableCell}>:</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{props.data.email}</Text>
                        </View>
                    </View>
                    <View style={StyleTableNoBorder.tableRow}>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{t('NumberOfEmployeesInThePlant')}</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableColColon}>
                            <Text style={StyleTableNoBorder.tableCell}>:</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{props.data.numberOfEmployeesInThePlant}</Text>
                        </View>
                    </View>
                </View>
            </View>
            <Text style={PdfStyles.spacer}></Text>
            <View wrap={false}>
                <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.generalInformation}.2 {t('WorkingTimeInHours')}</Text>
            </View>
            <Text style={PdfStyles.spacer}></Text>
            <View style={[FlexTable.column, { borderLeft: "1px solid black" }]}>
                <View style={[FlexTable.row, { borderBottom: "3px solid black" }]}>
                    <View style={[FlexTable.rowItem, { flexGrow: 3 }]}>
                        <View style={[FlexTable.column]}>
                            <View style={[FlexTable.row, { borderBottom: "1px solid black" }]}>
                                <View style={[FlexTable.rowItem, { borderRight: "none" }]}>
                                    <Text style={[FlexTable.rowItemText, { textAlign: "center" }]}>{t('PdfHoursPerDay')}</Text>
                                </View>
                            </View>
                            <View style={[FlexTable.row]}>
                                <View style={FlexTable.rowItem}>
                                    <Text style={FlexTable.rowItemText}>{t('MondayToFriday')}</Text>
                                </View>
                                <View style={FlexTable.rowItem}>
                                    <Text style={FlexTable.rowItemText}>{t('Saturday')}</Text>
                                </View>
                                <View style={[FlexTable.rowItem, { borderRight: "none" }]}>
                                    <Text style={FlexTable.rowItemText}>{t('Sunday')}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={FlexTable.rowItem}>
                        <Text style={[FlexTable.rowItemText, { textAlign: "center" }]}>{t('PdfHoursPerWeek')}</Text>
                    </View>
                    <View style={FlexTable.rowItem}>
                        <Text style={[FlexTable.rowItemText, { textAlign: "center" }]}>{t('PdfHoursPerYear')}</Text>
                    </View>
                </View>
                <View style={[FlexTable.row, { borderBottom: "3px solid black", }]}>
                    <View style={FlexTable.rowItem}>
                        <Text style={FlexTable.rowItemText}>{props.data.mondayToFridayWorkingTime}</Text>
                    </View>
                    <View style={FlexTable.rowItem}>
                        <Text style={FlexTable.rowItemText}>{props.data.saturdayWorkingTime}</Text>
                    </View>
                    <View style={FlexTable.rowItem}>
                        <Text style={FlexTable.rowItemText}>{props.data.sundayWorkingTime}</Text>
                    </View>
                    <View style={FlexTable.rowItem}>
                        <Text style={FlexTable.rowItemText}>{props.calcs.workingTimeWeek}</Text>
                    </View>
                    <View style={FlexTable.rowItem}>
                        <Text style={FlexTable.rowItemText}>{props.calcs.workingTimeYear}</Text>
                    </View>
                </View>
            </View>
            <Text style={PdfStyles.spacer}></Text>
            <View wrap={false}>
                <View>
                    <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.generalInformation}.3 {t('AmbientClimaticConditionsHeader')}</Text>
                </View>
                <Text style={PdfStyles.spacer}></Text>
                <View style={StyleTable.table}>
                    <View style={StyleTable.tableRow}>
                        <View style={StyleLocal.tableCol25RowZero}>
                            <Text style={StyleTable.tableCell}></Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{t('Spring')}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{t('Summer')}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{t('Autumn')}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{t('Winter')}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{t('Average')}</Text>
                        </View>
                    </View>
                    <View style={StyleTable.tableRow}>
                        <View style={StyleLocal.tableCol25}>
                            <Text style={StyleTable.tableCell}>{t('Temperature')} {Fmt.getUnitInBracket('temperature', t)}</Text>
                        </View>
                        <View style={StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(props.data.temperatureSpring, country, 0)}</Text>
                        </View>
                        <View style={StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(props.data.temperatureSummer, country, 0)}</Text>
                        </View>
                        <View style={StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(props.data.temperatureAutumn, country, 0)}</Text>
                        </View>
                        <View style={StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(props.data.temperatureWinter, country, 0)}</Text>
                        </View>
                        <View style={StyleTable.tableCol}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs.temperatureAverage, country, 1)}</Text>
                        </View>
                    </View>
                    <View style={StyleTable.tableRow}>
                        <View style={StyleLocal.tableCol25RowZero}>
                            <Text style={StyleTable.tableCell}>{t('Humidity')} {Fmt.getPercentage()}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(props.data.humiditySpring, country, 0)}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(props.data.humiditySummer, country, 0)}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(props.data.humidityAutumn, country, 0)}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(props.data.humidityWinter, country, 0)}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs.humidityAverage, country, 1)}</Text>
                        </View>
                    </View>
                </View>
                <Text style={PdfStyles.spacer}></Text>
                <View style={PdfStyles.content} >
                    <View style={StyleTableNoBorder.table} wrap={false}>
                        <View style={StyleTableNoBorder.tableRow}>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{t('AltitudeAboveSeaLevel')}</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableColColon}>
                                <Text style={StyleTableNoBorder.tableCell}>:</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{props.data.altitudeAboveSeaLevel} {Fmt.getUnit('unitMFoot', t)}</Text>
                            </View>
                        </View>
                        <View style={StyleTableNoBorder.tableRow}>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{t('AtmosphericPressure')}</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableColColon}>
                                <Text style={StyleTableNoBorder.tableCell}>:</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{Fmt.round(props.calcs.atmosphericPressure, country, 3)} {Fmt.getUnit('pressure', t)}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
            <Text style={PdfStyles.spacer}></Text>
            <View style={PdfStyles.content} >
                <View wrap={false}>
                    <View>
                        <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.generalInformation}.4 {t('EnergyCost')}</Text>
                    </View>
                    <Text style={PdfStyles.spacer}></Text>
                    <View style={StyleTableNoBorder.table} wrap={false}>
                        <View style={StyleTableNoBorder.tableRow}>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{t('CostsPerkWh')}</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableColColon}>
                                <Text style={StyleTableNoBorder.tableCell}>:</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{Fmt.round(props.data.costsPerkWh, country, 2)} {Fmt.getCurrency()}/{Fmt.getUnit('powerPerHour', t)}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
            <Text style={PdfStyles.spacer}></Text>
            <View style={PdfStyles.content} >
                <View wrap={false}>
                    <View>
                        <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.generalInformation}.5 {t('CO2GRA')}</Text>
                    </View>
                    <Text style={PdfStyles.spacer}></Text>
                    <View style={StyleTableNoBorder.table} wrap={false}>
                        <View style={StyleTableNoBorder.tableRow}>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{t('GreenEnergy')}</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableColColon}>
                                <Text style={StyleTableNoBorder.tableCell}>:</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{Fmt.round(props.data.projectSettings.cO2GridAverage, country, 1) == 0 ? t('Yes') : t('No')}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={StyleTableNoBorder.table} wrap={false}>
                        <View style={StyleTableNoBorder.tableRow}>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{t('CO2GRA')}</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableColColon}>
                                <Text style={StyleTableNoBorder.tableCell}>:</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{Fmt.round(props.data.projectSettings.cO2GridAverage, country, 1)} {Fmt.getUnitInBracket("co2WheightPerKwh", t)}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <Text style={PdfStyles.spacer}></Text>
                <View wrap={false}>
                    <View>
                        <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.generalInformation}.6 {t('AirSupplyTime')}</Text>
                    </View>
                    <Text style={PdfStyles.spacer}></Text>
                    <View style={StyleTableNoBorder.table} wrap={false}>
                        <View style={StyleTableNoBorder.tableRow}>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{t('IsTheAirSupllyShuttedOff')}</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableColColon}>
                                <Text style={StyleTableNoBorder.tableCell}>:</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{props.data.isTheAirSupllyShuttedOff ? t('Yes') : t('No')}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <Text style={PdfStyles.spacer}></Text>
                <View wrap={false}>
                    <View>
                        <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.generalInformation}.7 {t('PercentegeofCompressedAir')}</Text>
                    </View>
                    <Text style={PdfStyles.spacer}></Text>
                    <View style={StyleTableNoBorder.table}>
                        <View style={StyleTableNoBorder.tableRow}>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{t('PneumaticTools')}</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableColColon}>
                                <Text style={StyleTableNoBorder.tableCell}>:</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{props.data.pneumaticTools} %</Text>
                            </View>
                        </View>
                        <View style={StyleTableNoBorder.tableRow}>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{t('PneumaticMachinery')}</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableColColon}>
                                <Text style={StyleTableNoBorder.tableCell}>:</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{props.data.pneumaticMachinery} %</Text>
                            </View>
                        </View>
                        <View style={StyleTableNoBorder.tableRow}>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{t('Process')}</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableColColon}>
                                <Text style={StyleTableNoBorder.tableCell}>:</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{props.data.process} %</Text>
                            </View>
                        </View>
                        <View style={StyleTableNoBorder.tableRow}>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{t('IsMaintanancePlanAvailable')}</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableColColon}>
                                <Text style={StyleTableNoBorder.tableCell}>:</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{props.data.isMaintanancePlanAvailable ? t('Yes') : t('No')}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <Text style={PdfStyles.spacer}></Text>
                <View wrap={false}>
                    <View>
                        <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.generalInformation}.8 {t('Maintenance')}</Text>
                    </View>
                    <Text style={PdfStyles.spacer}></Text>
                    <View style={StyleTableNoBorder.table} wrap={false}>
                        <View style={StyleTableNoBorder.tableRow}>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{t('LastMaintenance')}</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableColColon}>
                                <Text style={StyleTableNoBorder.tableCell}>:</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{DateHelper.FormatDate(props.data.lastMaintenance)}</Text>
                            </View>
                        </View>
                        <View style={StyleTableNoBorder.tableRow}>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{t('LastMaintenanceFrequency')}</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableColColon}>
                                <Text style={StyleTableNoBorder.tableCell}>:</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{t(props.data.lastMaintenanceFrequency?.value)}</Text>
                            </View>
                        </View>
                        <View style={StyleTableNoBorder.tableRow}>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{t('LastLeackageDetection')}</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableColColon}>
                                <Text style={StyleTableNoBorder.tableCell}>:</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{DateHelper.FormatDate(props.data.lastLeackageDetection)}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
            <Text style={PdfStyles.spacer}></Text>
            <View wrap={false}>
                <View>
                    <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.generalInformation}.9 {t('ProcessHeat')}</Text>
                </View>
                <Text style={PdfStyles.spacer}></Text>
                <View style={PdfStyles.content} >
                    <View style={StyleTableNoBorder.table} wrap={false}>
                        <View style={StyleTableNoBorder.tableRow}>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{t('HeatRecoverySystem')}</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableColColon}>
                                <Text style={StyleTableNoBorder.tableCell}>:</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{props.data.heatRecoverySystem ? t('Yes') : t('No')}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <Text style={PdfStyles.spacer}></Text>
                <View style={StyleTable.table}>
                    <View style={StyleTable.tableRow}>
                        <View style={StyleLocal.tableCol30RowZero}>
                            <Text style={StyleTable.tableCell}></Text>
                        </View>
                        <View style={[StyleTable.tableColRowZero, { textAlign: 'center' }]}>
                            <Text style={StyleTable.tableCell}>{t('Heating')}</Text>
                        </View>
                        <View style={[StyleTable.tableColRowZero, { textAlign: 'center' }]}>
                            <Text style={StyleTable.tableCell}>{t('HotWaterGeneration')}</Text>
                        </View>
                    </View>
                    <View style={StyleTable.tableRow}>
                        <View style={StyleLocal.tableCol30RowZero}>
                            <Text style={StyleTable.tableCell}>{t('HeatRecoverySystemQuestion')}</Text>
                        </View>
                        <View style={[StyleTable.tableColRowZero, { textAlign: 'center' }]}>
                            <Text style={StyleTable.tableCell}>{props.data.heating ? t('Yes') : t('No')}</Text>
                        </View>
                        <View style={[StyleTable.tableColRowZero, { textAlign: 'center' }]}>
                            <Text style={StyleTable.tableCell}>{props.data.hotWaterGeneration ? t('Yes') : t('No')}</Text>
                        </View>
                    </View>
                </View>
            </View>
        </Page>
    );
}