import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { NumberInputFieldComponent, ButtonComponent, HeadingComponent } from '../../../Reusable';
import { useTranslation } from 'react-i18next';
import { API } from '../../../../helper/ApiHelper';
import { OpenGlobalSnackbar } from '../../../../helper/GlobalVariables';
// @ts-expect-error not converted yet
import * as Mapper from '../../../../helper/Mappers';
// @ts-expect-error not converted yet
import * as Validator from '../../../../helper/Validator';
import { IRestrictedSettingsModelFactory, RestrictedSettingsModelFactory } from './RestrictedSettingsModel';
import * as InputRestrictions from '../../../../helper/InputRestrictions';

export default function RestrictedSettings() {
    const url = process.env.REACT_APP_DEVFESSSERVICE_BASE + "restricted-settings";

    const { t } = useTranslation();
    const [mainModel, setMainModel] = useState<IRestrictedSettingsModelFactory>(RestrictedSettingsModelFactory(t));

    useEffect(() => {
        loadExistingData();
    }, []);

    const loadExistingData = () => {
        API.get(url).then(resp => {
            const mappedModel = Mapper.mapDataToModelValues(resp, RestrictedSettingsModelFactory(t));
            setMainModel(mappedModel)
        });
    }

    const postForm = () => {
        const [isValid, validatedModel] = Validator.checkAndValidateModel(mainModel, true);
        setMainModel(validatedModel);
        if (isValid === true) {
            const objectPostModel = Mapper.extractValuesFromModel(validatedModel);
            API.post(url, objectPostModel).then(() => OpenGlobalSnackbar("success", t("SaveSuccessful")));
        }
        else {
            OpenGlobalSnackbar("danger", t('ModelInvalidError'));
        }
    }

    return (
        <Grid container spacing={3} direction="row">
            <Grid item xs={12}>
                <HeadingComponent value={t("RestrictedSettings")} size="h6" />
            </Grid>
            <Grid item xs={12} md={6}>
                <NumberInputFieldComponent
                    t={t}
                    model={mainModel.settingsLeakageDetectedDayFlow}
                    onChange={e => Mapper.updateModel("settingsLeakageDetectedDayFlow", InputRestrictions.Decimal(e.target.value), mainModel, setMainModel)}
                    id="restrictedsettings_leakagedetecteddayflow"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <NumberInputFieldComponent
                    t={t}
                    model={mainModel.settingsLeakageDetectedDayPerTechnician}
                    onChange={e => Mapper.updateModel("settingsLeakageDetectedDayPerTechnician", InputRestrictions.Decimal(e.target.value), mainModel, setMainModel)}
                    id="restrictedsettings_leakagedetecteddaypertechnician"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <NumberInputFieldComponent
                    t={t}
                    model={mainModel.settingsRepairTimePerLeakage}
                    onChange={e => Mapper.updateModel("settingsRepairTimePerLeakage", InputRestrictions.Decimal(e.target.value), mainModel, setMainModel)}
                    id="restrictedsettings_repairtimeperleakage"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <NumberInputFieldComponent
                    t={t}
                    model={mainModel.settingsWorkingHoursPerDayPerDetection}
                    onChange={e => Mapper.updateModel("settingsWorkingHoursPerDayPerDetection", InputRestrictions.Decimal(e.target.value), mainModel, setMainModel)}
                    id="restrictedsettings_workinghoursperdayperdetection"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <NumberInputFieldComponent
                    t={t}
                    model={mainModel.settingsSavings1barReduction}
                    onChange={e => Mapper.updateModel("settingsSavings1barReduction", InputRestrictions.Decimal(e.target.value), mainModel, setMainModel)}
                    id="restrictedsettings_savings1barreduction"
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item>
                        <ButtonComponent value={t("Save")} color="primary" onChange={postForm} id="restrictedsettings_save" />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
