// @ts-expect-error not converted yet
import { checkIsInteger, checkIsNotNullOrWhitespace, checkIsAnythingSelectedObject, checkIsDecimal, isRepairedByFilledValidator, checkIsDateInPastUTC, isRepairedDateFilledAndPast } from "../../../helper/Validator";
import * as Fmt from '../../../helper/Formatters';
import { UserStore } from '../../../store'
import { IDropdownItem, ModelParam, Translate } from "../../Types/Types";
import { IUser } from "../../../store/UserStore";
import { nowUTC } from "../../../helper/DateHelper";

let user: IUser = {};
UserStore.user.subscribe(usr => {
    user = usr;
})

export interface IDistributionLineModelFactory {
    id: ModelParam<number>
    no: ModelParam<number>
    guid: ModelParam<string | null>;
    measurementId: ModelParam<string>;

    dateOfDetection: ModelParam<Date>
    detectedBy: ModelParam<string>

    isRepairDone: ModelParam<boolean>;
    repairedBy: ModelParam<string>;
    repairDate: ModelParam<Date | null>;

    comment: ModelParam<string>;
    repairComment: ModelParam<string>;

    building: ModelParam<string>;

    name: ModelParam<string>;
    pipeVolume: ModelParam<string>;
    pipeMaterial: ModelParam<string>;
    pipeDiameter: ModelParam<string>;
    totalPipeLength: ModelParam<string>;
    distributionLineLayout: ModelParam<IDropdownItem>;
}

export const DistributionLineModelFactory = (t: Translate): IDistributionLineModelFactory => ({
    id: { label: t("Id"), idkey: "DLINE-Id", value: 0, isValid: true, validationError: "", validators: [], disabled: true },
    no: { label: t('Nr'), idkey: "DLINE-No", value: 0, isValid: true, validationError: "", validators: [], disabled: true },
    guid: { label: "", idkey: "", value: "", isValid: true, validationError: "", validators: [] },
    measurementId: { label: t("MeasurementId"), idkey: "DLINE-MeasurementId", value: "", isValid: true, validationError: "", validators: [checkIsInteger] },
    dateOfDetection: { label: t("EntryDate"), idkey: "DLINE-EntryDate", value: nowUTC(), isValid: true, validationError: "", validators: [checkIsDateInPastUTC] },
    detectedBy: { label: t("DetectedBy"), idkey: "DLINE-DetectedBy", value: user.firstName + " " + user.lastName, isValid: true, validationError: "", validators: [checkIsNotNullOrWhitespace] },
    isRepairDone: { label: t("OptimizationIsDone"), idkey: "DLINE-RepairIsDone", value: false, isValid: true, validationError: "", validators: [] },
    repairedBy: { label: t("OptimizedBy"), idkey: "DLINE-RepairedBy", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [isRepairedByFilledValidator] },
    repairDate: { label: t("OptimizationDate"), idkey: "DLINE-RepairDate", value: null, isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [isRepairedDateFilledAndPast], type: 0, step: 0 },
    name: { label: t("Name"), idkey: "DLINE-Name", value: "", isValid: true, validationError: "", validators: [checkIsNotNullOrWhitespace] },
    building: { label: t("Building"), idkey: "DLINE-Building", value: "", isValid: true, validationError: "", validators: [checkIsNotNullOrWhitespace] },
    pipeDiameter: { label: t("PipeDiameter") + Fmt.getUnitInBracket("unitMmIn", t), idkey: "DLINE-PipeDiameter", value: "", isValid: true, validationError: "", validators: [checkIsDecimal] },
    distributionLineLayout: { label: t("DistributionLineLayout"), idkey: "DLINE-DistributionLineLayout", value: { id: 0, value: "string" }, isValid: true, validationError: "", validators: [checkIsAnythingSelectedObject] },
    pipeMaterial: { label: t("PipeMaterial"), idkey: "DLINE-PipeMaterial", value: "", isValid: true, validationError: "", validators: [checkIsNotNullOrWhitespace] },
    totalPipeLength: { label: t("TotalPipeLength") + Fmt.getUnitInBracket("unitMFoot", t), idkey: "DLINE-TotalPipeLength", value: "", isValid: true, validationError: "", validators: [checkIsDecimal] },
    pipeVolume: { label: t('PipeVolume') + Fmt.getUnitInBracket("cubicMeter", t), idkey: "DLINE-PipeVolume", value: "", isValid: true, validationError: "", validators: [], disabled: true },
    comment: { label: t("Comment"), idkey: "DLINE-Comment", value: "", isValid: true, validationError: "", validators: [] },
    repairComment: { label: t("RepairComment"), idkey: "DLINE-RepairComment", value: "", isValid: true, validationError: "", validators: [] },
});

export const applyRounding = (model : IDistributionLineModelFactory | void, country: string) => {
    return Object.assign({}, model, { pipeVolume: { ...model?.pipeVolume, value: Fmt.round(model?.pipeVolume.value, country, 1) } })
}