import _ from 'lodash';
import { isBefore, add } from "date-fns";
import { newUTC, nowUTC } from '../helper/DateHelper'

export const checkIsNotNull = (expression) => expression != undefined;
export const checkLength = (expression, length) => checkIsNotNull(expression) ? expression.trim().length >= length : false;
export const checkIsfilled = expression => checkIsNotNull(expression) ? expression.length > 0 : false;
export const checkIsNotNullOrWhitespace = expression => checkIsNotNull(expression) ? expression.toString().trim().length > 0 : false;
export const checkIsStringNotEmpty = expression => checkIsNotNull(expression) ? (typeof expression == 'string' && expression.trim().length > 0) : false;
export const checkIsNotNullOrWhitespaceToString = value => checkIsNotNull(value) ? value.toString().trim().length > 0 : false;
export const checkIsTrue = expression => expression;
export const checkLengthIsNotZero = (value) => value.length > 0;
export const isTrue = value => value === true;
export const checkEmail = mail => {
    if (checkIsNotNull(mail)) {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(mail);
    } else return false;
}
export const isFestoEmail = value => value.toLowerCase().endsWith("@festo.com");
export const checkPhoneNumber = phone => {
    if (checkIsNotNull(phone) && phone.length >= 5) {
        const regex = /^[+]?[0-9 ]*$/;
        return regex.test(phone)
    }
    else return false;
}
export const checkPercentage = (value) => {
    if (checkIsNotNull(value)) {
        let parsedValue = parseFloat(value);
        return !isNaN(value) && !isNaN(parsedValue) && parsedValue >= 0 && parsedValue <= 100;
    } else return false;
};
export const checkPostcode = (value, model) => {
    if (!value || !model.country || !model.postcodeRegex.value) return true;
    var countryRegex = new RegExp(model.postcodeRegex.value);
    return value.match(countryRegex);
};
export const checkNumberMoreZero = (value) => {
    if (checkIsNumber(value)) {
        return value > 0;
    } else return false;
};
export const checkIsInteger = (value) => {
    if (checkIsNotNull(value)) {
        if (value.toString().indexOf(",") > 0 || value.toString().indexOf(".") > 0) return false;
        let parsedValue = parseInt(value);
        return !isNaN(value) && !isNaN(parsedValue) && Number.isInteger(parsedValue) && parsedValue == value;
    } else return false;
};
export const checkIsNumber = (value) => {
    if (checkIsNotNull(value)) {
        return !isNaN(value);
    }
    return false;
};
export const checkIsEmptyOrInteger = (value) => {
    return !checkIsNotNullOrWhitespaceToString(value) || checkIsInteger(value);
};
export const checkYear = (value) => {
    if (checkIsNotNull(value)) {
        let parsedValue = parseInt(value);
        return parsedValue > 1970 && parsedValue < 2050
    } else return false;
};
export const checkIsDecimal = (value) => !isNaN(value) && !isNaN(parseFloat(value));
export const checkIsDecimalOrNull = (value) => {
    if (checkIsNotNull(value)) {
        return !isNaN(value.toString().replace(",", ".")) && !isNaN(parseFloat(value.toString().replace(",", ".")))
    } else return false;
};
export const checkIsDate = (value) => {
    if (checkIsNotNull(value) && Date.parse(value)) {
        if (checkLengthIsNotZero(value.toString())) {
            return true;
        } else {
            return false;
        }
    } else return false;
};
export const checkIsDateOrNull = (value) => {
    if (checkIsNotNull(value)) {
        return checkIsDate(value);
    } else return true;
};
export const checkIsDateInPast = value => checkIsDate(value) && isBefore(new Date(value), add(new Date(), { hours: 20 }));
export const checkIsDateInPastOrNull = value => !checkIsNotNullOrWhitespace(value) || checkIsDateInPast(value);

export const checkIsDateInPastUTC = value => checkIsDate(value) && isBefore(newUTC(new Date(value)), add(nowUTC(), { hours: 20 }));
export const checkIsDateInPastOrNullUTC = value => !checkIsNotNullOrWhitespace(value) || checkIsDateInPastUTC(value);

export const checkCurrency = (value) => {
    const regex = /^[a-zA-Z]+$|^[$\xA2-\xA5\u058F\u060B\u09F2\u09F3\u09FB\u0AF1\u0BF9\u0E3F\u17DB\u20A0-\u20BD\uA838\uFDFC\uFE69\uFF04\uFFE0\uFFE1\uFFE5\uFFE6]{1}$/;
    if (value.length <= 3) {
        return regex.test(value);
    }
    else {
        return false;
    }
}
export const checkHours = (value) => {
    if (value <= 24 && value >= 0) {
        return true;
    }
    else {
        return false;
    }
};
export const checkIsAnythingSelected = (value) => {
    if (checkIsNotNull(value)) {
        if (value) {
            return value.id && value.id > 0;
        }
        else {
            return false;
        }
    } else return false;
};

export const checkIsAnythingSelectedObject = (value) => {
    if (value != undefined && value.value != undefined) {
        return value.value && value.value.length > 0;
    } else return false;
};

export const isRepairedByFilledValidator = (value, model) => {
    if (model.isRepairDone?.value) {
        return checkIsNotNullOrWhitespace(value);
    }
    return true;
}

export const isRepairedDateFilledValidator = (value, model) => {
    if (model.isRepairDone?.value) {
        return checkIsDate(value);
    }
    return true;
}

export const isRepairedDateFilledAndPast = (value, model) => {
    if (model.isRepairDone?.value) {
        return checkIsDateInPast(value);
    }
    return true;
}

/////// validation functions \\\\\\\

export const checkAndValidateModel = (sourceModel, deepClone = false) => {
    let model = deepClone ? _.cloneDeep(sourceModel) : sourceModel;
    let isValid = true;
    Object.keys(model).forEach(property => {
        model[property].isValid = true;
        let validators = model[property].validators;
        if (Array.isArray(validators) && validators.length > 0) {
            validators.forEach(v => {
                if (v.length == 1 && !v(model[property].value)) {
                    model[property].isValid = false;
                    isValid = false;
                }
                else if (v.length == 2 && !v(model[property].value, model)) {
                    model[property].isValid = false;
                    isValid = false;
                }
            });
        }
    });
    return [isValid, model];
}

export const checkAndValidateModelArray = (modelArray, deepClone = false) => {
    if (!modelArray) return true;
    let model = deepClone ? _.cloneDeep(modelArray) : modelArray;
    let isValid = true;
    for (var i = 0; i < model.length; i++) {
        Object.keys(model[i]).forEach(key => {
            if (!model[i][key].value && model[i][key].validators.length > 0) {
                model[i][key].validators.forEach(f => {
                    if (f(model[i][key].value) == false) {
                        isValid = false;
                        model[i][key].isValid = false;
                    }
                })
            }
        })
    }

    return [isValid, model];
}

export const isValidModel = (model) => {
    let isValid = true;
    Object.keys(model).forEach(key => {
        if ((model[key].isValid) == false) {
            isValid = false;
        }
    })
    return isValid;
}

export const validateModel = (model) => {
    let clonedModel = _.cloneDeep(model);
    Object.keys(clonedModel).forEach(key => {
        clonedModel[key].isValid = true;
        if (clonedModel[key].validators.length > 0) {
            clonedModel[key].validators.forEach(f => {
                if (f(clonedModel[key].value, model) == false) {
                    clonedModel[key].isValid = false;
                }
            })
        }
    })
    return clonedModel;
}

export const isValidModelArray = (modelArray) => {
    if (!modelArray) return true;
    let isValid = true;
    for (var i = 0; i < modelArray.length; i++) {
        Object.keys(modelArray[i]).forEach(key => {
            if (!modelArray[i][key].value && modelArray[i][key].validators.length > 0) {
                modelArray[i][key].validators.forEach(f => {
                    if (f(modelArray[i][key].value) == false) {
                        isValid = false;
                    }
                })
            }
        })
    }

    return isValid;
}

export const validateSingleProperty = (model, property) => {
    let isValid = true;
    model[property].isValid = true;
    model[property].validators.forEach(v => {
        if (v.length == 1 && !v(model[property].value)) {
            model[property].isValid = false;
            isValid = false;
        }
        else if (v.length == 2 && !v(model[property].value, model)) {
            model[property].isValid = false;
            isValid = false;
        }
    })
    return isValid;
}

export const validateForm = (data) => {
    let isValid = true;
    data.map(e => {
        e.isValid = true;
        if (!e.value && e.validators.length > 0 && e.dependsOn == "") {
            e.isValid = isValid = false;
        }
        else e.validators.map(v => {
            if (v(e.value) === false && !(!!e.dependsOn && data.find(x => x.name == e.dependsOn && !x.value))) {
                e.isValid = isValid = false
            }
        });

    });

    return isValid;
}

export const doesNameAlreadyExist = (name, images) => images.some(x => x.image.name.toLowerCase() == name.toLowerCase());

export const checkIsfilledMessage = 'notfilledErrorMessage';
