import React from "react"
import { StyledTooltip, StyledTextField } from '../MaterialUIStyles/Styles';
import { StandardTextFieldProps } from "@mui/material";
import { ModelParam } from "../../Types/Types";

interface ITextFieldComponent extends StandardTextFieldProps  {
    t: (s: string | undefined) => string,
    id?: string,
    label?: string
    upperCase?: boolean,
    model: ModelParam<string>
}

const TextFieldComponent = (props: ITextFieldComponent)  => {
    const { model, t, id, upperCase, label, ...rest } = props;
    return (
        <StyledTooltip
            arrow
            placement="bottom-end"
            title={model.isValid === false ? (t ? t(model.validationError) : model.validationError) : ""}
        >
            <StyledTextField
                variant="standard"
                {...rest}
                required={!model.notRequired && model.validators?.length > 0}
                error={model.isValid === false}
                id={id ? "input_" + id : "input_" + model.idkey}
                label={label ? label : model.label ? model.label : t ? t(model.tkey) : model.tkey}
                value={upperCase ? model.value?.toUpperCase() : model.value ?? ''}
                fullWidth
            />
        </StyledTooltip>
    );
}

export default TextFieldComponent;
