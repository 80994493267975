import React from "react";
import { Button, ButtonProps, styled } from '@mui/material';

const StyledSimpleButton = styled(Button)((props) => ({
    color: props.color == "primary" ? "#0091DC" : "black",
    backgroundColor: "transparent",
    '&:hover': {
        backgroundColor: "transparent",
    },
})) as typeof Button;

export default function SimpleButton(props: ButtonProps) {
    const { ...rest } = props;
    return (<StyledSimpleButton {...rest}>{props.children}</StyledSimpleButton>);
}