import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// @ts-expect-error not converted yet
import { checkIsNotNullOrWhitespace, doesNameAlreadyExist } from '../../helper/Validator';
import { useParams } from "react-router-dom";
import { Grid, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { API } from '../../helper/ApiHelper';
import { ButtonComponent, HeadingComponent, TextFieldComponent, SpinnerComponent } from '../Reusable';
import { Save, Close, Info } from '@mui/icons-material';
import { OpenGlobalSnackbar } from '../../helper/GlobalVariables';
import { ImageModel } from './ImageModel';
import { DropzoneArea } from 'mui2-file-dropzone';
import { ProjectImage } from './ImageViewer';
import { IdParam } from '../Types/Types';

interface IProps {
    groupId: string,
    showDialog: boolean,
    images: ProjectImage[],
    setShowDialog: React.Dispatch<React.SetStateAction<boolean>>,
    setImages: React.Dispatch<React.SetStateAction<ProjectImage[]>>
}

export default function ImageViewerDialog(props: IProps) {
    const { images, setImages, groupId, showDialog, setShowDialog } = props;
    const postUrl = process.env.REACT_APP_DEVFESSIMAGESERVICES_BASE + "blob";
    const { id } = useParams<IdParam>();
    const { t } = useTranslation();

    const [model, setModel] = useState(ImageModel(t));
    const [dragndropFiles, setDragndropFiles] = useState<File[]>([]);
    const [uploading, setUploading] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);

    useEffect(() => {
        resetModel();
    }, []);

    const resetModel = () => {
        setModel(ImageModel(t));
    }

    const getExtension = (filename : string) => "." + filename.toLowerCase().split('.').pop();

    const loadImageAsync = async (file: File): Promise<string> => new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
          const dataURL = reader.result as string;
          const base64Data = dataURL.split(',')[1];
          resolve(base64Data);
        };
        reader.readAsDataURL(file);
      });
      

    const save = async () => {
        try {
            setUploading(true);
            let resp;
            let imagesResult = images;
            if (images?.length > 4) {
                OpenGlobalSnackbar("danger", t('MaximumNumberOfFilesError'));
            }
            else if (dragndropFiles.length > 0) {
                for (const file of dragndropFiles) {
                    resp = await post(await loadImageAsync(file), getExtension(file.name));
                    imagesResult = [resp, ...imagesResult];
                }
            }
            if (resp != undefined) {
                resetModel();
                setImages(imagesResult);
                setRefreshKey(refreshKey + 1);
                OpenGlobalSnackbar("success", t('FileWasAddedMessage'));
            }
        } finally {
            setUploading(false);
        }
    };

    const post = async (fileContent: string, extension: string) => {
        const postObject = {
            name: dragndropFiles.length === 1 && model.imgName.value !== "" ? encodeURIComponent(model.imgName.value) : "",
            extension: extension,
            image: fileContent,
            groupId: groupId
        };
        const resp = await API.post(`${postUrl}/project/${id}/images/`, postObject);
        return resp;
    }

    const onChangeImageName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const obj = { ...model };
        obj.imgName.value = e.target.value;
        validateName(obj);
        setModel(obj);
    }

    const validateName = (model: any) => {
        if (!checkIsNotNullOrWhitespace(model.imgName.value)) {
            model.imgName.isValid = false;
            model.imgName.validationError = 'InquiryErrorInvalidFileName';
        } else if (doesNameAlreadyExist(model.imgName.value, images)) {
            model.imgName.isValid = false;
            model.imgName.validationError = 'ErrorNameAlreadyExists';
        } else {
            model.imgName.isValid = true;
        }
    }

    const cacheFiles = (loadedFiles: File[]) :  void => {
        if (images.length > 4 && loadedFiles.length > 0) {
            OpenGlobalSnackbar("danger", t('MaximumNumberOfFilesError'));
            setRefreshKey(refreshKey + 1);
        }
        else
            setDragndropFiles(loadedFiles);
    }

    return (
        <Dialog open={showDialog} aria-labelledby="contained-modal-title-vcenter" className="ImageViewerDialog">
            <SpinnerComponent open={uploading} />
            <DialogTitle>
                <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
                    <Grid item xs={12} md={8}>
                        <HeadingComponent value={t('AddFiles')} size="h6" />
                    </Grid>
                    <Grid item>
                        <IconButton color="inherit" size="small" onClick={() => setShowDialog(false)}>
                            <Close fontSize="small" />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container direction="column">
                    {dragndropFiles.length > 1 ?
                        <Grid item>
                            <Grid container spacing={1} direction="row" alignItems="center" style={{ color: "#0091dc" }}>
                                <Grid item><Info /></Grid>
                                <Grid item><Typography>{t('WhenMoreThan1FilesAreUploadedSystemWillGenerateFilesNamesAutomatically')}</Typography></Grid>
                            </Grid>
                        </Grid>
                        :
                        <Grid item> <TextFieldComponent id="imgName" t={t} model={model.imgName} onChange={e => onChangeImageName(e)} /></Grid>
                    }
                </Grid>
                <DropzoneArea
                    key={refreshKey}
                    onChange={cacheFiles}
                    filesLimit={5 - images.length}
                    maxFileSize={30000000}
                    showAlerts={['error']}
                    fileObjects={dragndropFiles}
                />
            </DialogContent>
            <DialogActions>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item><ButtonComponent id="Cancel" value={t('Cancel')} color="secondary" onChange={() => setShowDialog(false)}></ButtonComponent></Grid>
                    <Grid item><ButtonComponent id="Add" value={t('Add')} icon={<Save />} color="primary" onChange={save}></ButtonComponent></Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
