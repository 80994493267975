import React from 'react';
import { Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { usePdfStyles, StyleTable, StyleTableNoBorder } from './PdfStyles';
import * as DateHelper from '../../../helper/DateHelper';
import * as Mappers from '../../../helper/Mappers';
import * as Fmt from '../../../helper/Formatters';
import Watermark from './Watermark';
import { SplitText } from '../../../helper/PdfTested';

const StyleLocal = StyleSheet.create({
    tableCol35: {
        width: "28%",
        borderBottom: "1px solid black",
        borderRight: "1px solid black"
    },
    tableCol35RowZero: {
        width: "28%",
        borderBottom: "3px solid black",
        borderRight: "1px solid black"
    }
});

export const CompressedAirGenerationPdf = (props) => {
    const { country, projectData } = props;
    const { t } = useTranslation();
    var PdfStyles = usePdfStyles();

    return (
        <Page size={props.pageSize} style={PdfStyles.page}>
            <Watermark projectData={projectData} />
            <Text style={PdfStyles.pageNumbers} render={({ pageNumber, totalPages }) => (pageNumber + " " + t("of") + " " + totalPages)} fixed />
            <View wrap={false}>
                <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["IndividualCompressorDetails"] = pageNumber }} />
                <Text style={PdfStyles.boldHeading}>{props.tocValues.tocNumbering.cag} {t("CompressedAirGeneration")}</Text>
                <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.cag}.1 {t("IndividualCompressorDetails")}</Text>
                <Text style={PdfStyles.spacer}></Text>
            </View>
            <View wrap={false}>
                <View style={StyleTable.table}>
                    <View style={StyleTable.tableRow}>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{t("Nr")}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{SplitText(t("Location"), 11)}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{SplitText(t("Manufacturer"), 11)}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{t("ModelSerialNumber")}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{t("Year")}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{t("CompressorType")}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{SplitText(t("TagId"), 11)}</Text>
                        </View>
                    </View>
                    {props.compressors.map((el, i) =>
                        <View key={"CompressorDetails" + i} style={StyleTable.tableRow}>
                            <View style={i == props.compressors.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{el.no}</Text>
                            </View>
                            <View style={i == props.compressors.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{el.compressorRoomNo}</Text>
                            </View>
                            <View style={i == props.compressors.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{el.manufacturer}</Text>
                            </View>
                            <View style={i == props.compressors.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{el.modelSerialNumber}</Text>
                            </View>
                            <View style={i == props.compressors.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{el.year}</Text>
                            </View>
                            <View style={i == props.compressors.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{Mappers.translateDropdownValue(props.dropdownValues.compressorTypes, el.compressorType, t)}</Text>
                            </View>
                            <View style={i == props.compressors.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{el.measurementId}</Text>
                            </View>
                        </View>
                    )}
                </View>
                <Text style={PdfStyles.spacer}></Text>
            </View>
            <View wrap={false}>
                <View wrap={false}>
                    <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["CompressorCapacity"] = pageNumber }} />
                    <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.cag}.2 {t("CompressorCapacity")}</Text>
                    <Text style={PdfStyles.spacer}></Text>
                </View>
                <View style={StyleTable.table}>
                    <View style={StyleTable.tableRow}>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{t("Nr")}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{t("ModelSerialNumber")}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{SplitText(t("NominalPowerKW") + " " + Fmt.getUnitInBracket("power", t), 9)}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{SplitText(t("FreeAirDelivery") + " " + Fmt.getUnitInBracket("volumePerTime", t), 9)}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{SplitText(t("NominalPressure") + " " + Fmt.getUnitInBracket("pressure", t), 9)}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{SplitText(t("SetpointPressure") + " " + Fmt.getUnitInBracket("pressure", t), 9)}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{SplitText(t("LastMaintenanceLabel"), 9)}</Text>
                        </View>
                        <View style={StyleTable.tableColRowZero}>
                            <Text style={StyleTable.tableCell}>{SplitText(t("HeatRecovery"), 9)}</Text>
                        </View>
                    </View>
                    {props.compressors.map((el, i) =>
                        <View key={"CompressorCapacity" + i} style={StyleTable.tableRow}>
                            <View style={i == props.compressors.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{el.no}</Text>
                            </View>
                            <View style={i == props.compressors.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{el.modelSerialNumber}</Text>
                            </View>
                            <View style={i == props.compressors.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(el.nominalPower, country, 2)}</Text>
                            </View>
                            <View style={i == props.compressors.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(el.freeAirDelivery, country, 2)}</Text>
                            </View>
                            <View style={i == props.compressors.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(el.nominalPressure, country, 1)}</Text>
                            </View>
                            <View style={i == props.compressors.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(el.setpointPressure, country, 1)}</Text>
                            </View>
                            <View style={i == props.compressors.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{DateHelper.FormatDate(el.lastMaintenance)}</Text>
                            </View>
                            <View style={i == props.compressors.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{el.heatRecovery ? t("Yes") : t("No")}</Text>
                            </View>
                        </View>
                    )}
                </View>
                <Text style={PdfStyles.spacer}></Text>
            </View>
            {props.tocValues.tocNumbering.cagChildren[3] !== undefined &&
                <View wrap={false}>
                    <View wrap={false}>
                        <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["CompressorExpectedOutput"] = pageNumber }} />
                        <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.cag}.{props.tocValues.tocNumbering.cagChildren[3]} {t("CompressorExpectedOutput")}</Text>
                        <Text style={PdfStyles.spacer}></Text>
                    </View>
                    <View style={StyleTable.table}>
                        <View style={StyleTable.tableRow}>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("Nr")}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("EffectivePower")} {Fmt.getUnitInBracket("power", t)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("ExpectedOutputFreeAirDelivery")} {Fmt.getUnitInBracket("cubicMeterOfGasperTime", t)}</Text>
                            </View>
                        </View>
                        {props.calcs?.compressorCalcs.map((el, i) =>
                            <View key={"CompressorOutput" + i} style={StyleTable.tableRow}>
                                <View style={StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>{el.no}</Text>
                                </View>
                                <View style={StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>{Fmt.round(el.rEffectivePower, country, 0)}</Text>
                                </View>
                                <View style={StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>{Fmt.round(el.rfadOperatingConditions, country, 2)}</Text>
                                </View>
                            </View>
                        )}
                        <View style={StyleTable.tableRow}>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCellTotal}>{t("TOTAL")}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCellTotal}>{Fmt.round(props.calcs?.totalEffectivePower, country, 0)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCellTotal}>{Fmt.round(props.calcs?.cfadOperatingConditionsTOTAL, country, 2)}</Text>
                            </View>
                        </View>
                    </View>
                    <Text style={PdfStyles.spacer}></Text>
                </View>
            }
            {props.tocValues.tocNumbering.cagChildren[4] !== undefined &&
                <View wrap={false}>
                    <View wrap={false}>
                        <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["MeasurementCompressedAirGeneration"] = pageNumber }} />
                        <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.cag}.{props.tocValues.tocNumbering.cagChildren[4]} {t("MeasurementOfTheCompressedAirGeneration")}</Text>
                        <Text style={PdfStyles.spacer}></Text>
                    </View>
                    <View style={StyleTable.table}>
                        <View style={StyleTable.tableRow}>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}></Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("TimePeriod")} {Fmt.getUnitInBracket("hours", t)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("Results")} {Fmt.getUnitInBracket("hours", t)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("ResultsPerYear")} {Fmt.getUnitInBracket("hours", t)}</Text>
                            </View>
                        </View>
                        <View style={StyleTable.tableRow}>
                            <View style={StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{t("ElectricityConsumption")} {Fmt.getUnitInBracket("powerPerHour", t)}</Text>
                            </View>
                            <View style={StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.data.electricityConsumptionTimePeriod, country, 0)}</Text>
                            </View>
                            <View style={StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.data.electricityConsumptionValue, country, 0)}</Text>
                            </View>
                            <View style={StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.mYearlyConsumptionElectricityTOTAL, country, 0)}</Text>
                            </View>
                        </View>
                        <View style={StyleTable.tableRow}>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("AirConsumption")} {Fmt.getUnitInBracket("cubicMeter", t)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.data.airConsumptionTimePeriod, country, 0)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.data.airConsumptionValue, country, 0)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.mYearlyConsumptionAirConsumptionTOTAL, country, 0)}</Text>
                            </View>
                        </View>
                    </View>
                    <Text style={PdfStyles.spacer}></Text>
                </View>
            }
            {props.tocValues.tocNumbering.cagChildren[5] !== undefined &&
                <View wrap={false}>
                    <View wrap={false}>
                        <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["CAGWorkload"] = pageNumber }} />
                        <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.cag}.{props.tocValues.tocNumbering.cagChildren[5]} {t("Workload")}</Text>
                        <Text style={PdfStyles.spacer}></Text>
                    </View>
                    <View style={StyleTable.table}>
                        <View style={StyleTable.tableRow}>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("Nr")}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("MondayFriday")} [%]</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("Saturday")} [%]</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("Sunday")} [%]</Text>
                            </View>
                        </View>
                        {props.compressors.map((el, i) =>
                            <View key={"CompressorWorkload" + i} style={StyleTable.tableRow}>
                                <View style={i == props.compressors.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>{el.no}</Text>
                                </View>
                                <View style={i == props.compressors.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>{Fmt.round(el.mondayToFridayWorkload, country, 2)}</Text>
                                </View>
                                <View style={i == props.compressors.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>{Fmt.round(el.saturdayWorkload, country, 2)}</Text>
                                </View>
                                <View style={i == props.compressors.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>{Fmt.round(el.sundayWorkload, country, 2)}</Text>
                                </View>
                            </View>
                        )}
                    </View>
                    <Text style={PdfStyles.spacer}></Text>
                </View>
            }
            {props.tocValues.tocNumbering.cagChildren[6] !== undefined &&
                <View wrap={false}>
                    <View wrap={false}>
                        <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["CompressorRoomAverageWorkloadPerDay"] = pageNumber }} />
                        <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.cag}.{props.tocValues.tocNumbering.cagChildren[6]} {t("CompressorRoomAverageWorkloadPerDay")}</Text>
                        <Text style={PdfStyles.spacer}></Text>
                    </View>
                    <View style={StyleTable.table}>
                        <View style={StyleTable.tableRow}>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("Nr")}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("MondayFriday")} [%]</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("Saturday")} [%]</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("Sunday")} [%]</Text>
                            </View>
                        </View>
                        {props.calcs?.compRoomCalcs?.map((el, i) =>
                            <View key={"CompressorWorkloadPerRoom" + i} style={StyleTable.tableRow}>
                                <View style={i == props.calcs?.compRoomCalcs?.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>{el.no}</Text>
                                </View>
                                <View style={i == props.calcs?.compRoomCalcs?.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>{Fmt.round(el.rFlowMondayFridayWorkloadROOM, country, 0)}</Text>
                                </View>
                                <View style={i == props.calcs?.compRoomCalcs?.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>{Fmt.round(el.rFlowSaturdayWorkloadROOM, country, 0)}</Text>
                                </View>
                                <View style={i == props.calcs?.compRoomCalcs?.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>{Fmt.round(el.rFlowSundayWorkloadROOM, country, 0)}</Text>
                                </View>
                            </View>
                        )}
                    </View>
                    <Text style={PdfStyles.spacer}></Text>
                </View>
            }
            {props.tocValues.tocNumbering.cagChildren[7] !== undefined &&
                <View wrap={false}>
                    <View wrap={false}>
                        <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["StaticConsumptionHeader"] = pageNumber }} />
                        <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.cag}.{props.tocValues.tocNumbering.cagChildren[7]} {t("StaticConsumptionHeader")}</Text>
                        <Text style={PdfStyles.spacer}></Text>
                    </View>
                    <View style={StyleTable.table}>
                        <View style={StyleTable.tableRow}>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}></Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("LevelProductionTime")} {Fmt.getPercentage()}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("LevelTotal")} {Fmt.getPercentage()}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("FlowTotal")} {Fmt.getUnitInBracket("cubicMeterOfGas", t)}</Text>
                            </View>
                        </View>
                        <View style={StyleTable.tableRow}>
                            <View style={StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{t("StaticConsumption")}</Text>
                            </View>
                            <View style={StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.data.staticConsumption, country, 2)}</Text>
                            </View>
                            <View style={StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.rStaticConsumptionPercentageTOTAL, country, 1)}</Text>
                            </View>
                            <View style={StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.rStaticConsumptionAirFlowTOTAL, country, 1)}</Text>
                            </View>
                        </View>
                        <View style={StyleTable.tableRow}>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("ExpectedLeakages")}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{props.data.expectedLeakages}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.rLeakagePercentageTOTAL, country, 1)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.rLeakagesAirFlowTOTAL, country, 1)}</Text>
                            </View>
                        </View>
                    </View>
                    <Text style={PdfStyles.spacer}></Text>
                </View>
            }
            {props.tocValues.tocNumbering.cagChildren[8] !== undefined &&
                <View wrap={false} style={PdfStyles.content}>
                    <View wrap={false}>
                        <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["CompressorUnloadTime"] = pageNumber }} />
                        <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.cag}.{props.tocValues.tocNumbering.cagChildren[8]} {t("CompressorUnloadTime")}</Text>
                        <Text style={PdfStyles.spacer}></Text>
                    </View>
                    <View style={StyleTableNoBorder.table}>
                        <View style={StyleTableNoBorder.tableRow}>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{t("UnloadTimeInSeconds")}</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableColColon}>
                                <Text style={StyleTableNoBorder.tableCell}>:</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{Fmt.round(props.data.unloadTime, country, 0)}</Text>
                            </View>
                        </View>
                        <View style={StyleTableNoBorder.tableRow}>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{t("UnloadCyclesPerHour")}</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableColColon}>
                                <Text style={StyleTableNoBorder.tableCell}>:</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{Fmt.round(props.data.unloadCyclesPerHour, country, 0)}</Text>
                            </View>
                        </View>
                        <View style={StyleTableNoBorder.tableRow}>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{t("UnloadTimeRatio")}</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableColColon}>
                                <Text style={StyleTableNoBorder.tableCell}>:</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCell}>{Fmt.round(props.calcs?.unloadTimeCompressorsPercentage, country, 2)} %</Text>
                            </View>
                        </View>
                    </View>
                    <Text style={PdfStyles.spacer}></Text>
                </View>
            }
            {props.tocValues.tocNumbering.cagChildren[9] !== undefined &&
                <View wrap={false}>
                    <View wrap={false}>
                        <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["AirFlowAndReserve"] = pageNumber }} />
                        <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.cag}.{props.tocValues.tocNumbering.cagChildren[9]} {t("AverageAirFlowAndReserve")}</Text>
                        <Text style={PdfStyles.spacer}></Text>
                    </View>
                    <View style={StyleTable.table}>
                        <View style={StyleTable.tableRow}>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}></Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("MondayFriday")}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("Saturday")}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("Sunday")}</Text>
                            </View>
                        </View>
                        <View style={StyleTable.tableRow}>
                            <View style={StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{t("FADCorrected")} {Fmt.getUnitInBracket("cubicMeterOfGas", t)}</Text>
                            </View>
                            <View style={StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.cfadOperatingConditionsTOTAL, country, 1)}</Text>
                            </View>
                            <View style={StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.cfadOperatingConditionsTOTAL, country, 1)}</Text>
                            </View>
                            <View style={StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.cfadOperatingConditionsTOTAL, country, 1)}</Text>
                            </View>
                        </View>
                        <View style={StyleTable.tableRow}>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("AverageAirFlow")} {Fmt.getUnitInBracket("cubicMeterOfGas", t)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.rFlowMondayFridayTOTAL, country, 1)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.rFlowSaturdayTOTAL, country, 1)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.rFlowSundayTOTAL, country, 1)}</Text>
                            </View>
                        </View>
                        <View style={StyleTable.tableRow}>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("Reserve")} {Fmt.getUnitInBracket("cubicMeterOfGas", t)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.rFlowMondayFridayTOTALReserve, country, 1)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.rFlowSaturdayTOTALReserve, country, 1)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.rFlowSundayTOTALReserve, country, 1)}</Text>
                            </View>
                        </View>
                    </View>
                    <Text style={PdfStyles.spacer}></Text>
                </View>
            }
            {props.tocValues.tocNumbering.cagChildren[10] !== undefined &&
                <View wrap={false}>
                    <View wrap={false}>
                        <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["AverageAirFlowPerCompressor"] = pageNumber }} />
                        <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.cag}.{props.tocValues.tocNumbering.cagChildren[10]} {t("CompressorAverageAirFlowPerDay")}</Text>
                        <Text style={PdfStyles.spacer}></Text>
                    </View>
                    <View style={StyleTable.table}>
                        <View style={StyleTable.tableRow}>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("Nr")}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("MondayFriday")} {Fmt.getUnitInBracket("cubicMeterOfGasperTime", t)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("Saturday")} {Fmt.getUnitInBracket("cubicMeterOfGasperTime", t)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("Sunday")} {Fmt.getUnitInBracket("cubicMeterOfGasperTime", t)}</Text>
                            </View>
                        </View>
                        {props.calcs?.compressorCalcs?.map((el, i) =>
                            <View key={"CompressorAirflowPerCompressor" + i} style={StyleTable.tableRow}>
                                <View style={i == props.compressors.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>{el.no}</Text>
                                </View>
                                <View style={i == props.compressors.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>{Fmt.round(el.rFlowMondayFriday, country, 1)}</Text>
                                </View>
                                <View style={i == props.compressors.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>{Fmt.round(el.rFlowSaturday, country, 1)}</Text>
                                </View>
                                <View style={i == props.compressors.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>{Fmt.round(el.rFlowSunday, country, 1)}</Text>
                                </View>
                            </View>
                        )}
                    </View>
                    <Text style={PdfStyles.spacer}></Text>
                </View>
            }
            {props.tocValues.tocNumbering.cagChildren[11] !== undefined &&
                <View wrap={false}>
                    <View wrap={false}>
                        <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["AverageAirFlowPerCompressorRoom"] = pageNumber }} />
                        <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.cag}.{props.tocValues.tocNumbering.cagChildren[11]} {t("CompressorRoomAverageAirFlowPerDay")}</Text>
                        <Text style={PdfStyles.spacer}></Text>
                    </View>
                    <View style={StyleTable.table}>
                        <View style={StyleTable.tableRow}>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("Nr")}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("MondayFriday")} {Fmt.getUnitInBracket("cubicMeterOfGasperTime", t)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("Saturday")} {Fmt.getUnitInBracket("cubicMeterOfGasperTime", t)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("Sunday")} {Fmt.getUnitInBracket("cubicMeterOfGasperTime", t)}</Text>
                            </View>
                        </View>
                        {props.calcs?.compRoomCalcs?.map((el, i) =>
                            <View key={"CompressorAirflowPerRoom" + i} style={StyleTable.tableRow}>
                                <View style={i == props.calcs?.compRoomCalcs?.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>{el.no}</Text>
                                </View>
                                <View style={i == props.calcs?.compRoomCalcs?.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>{Fmt.round(el.rFlowMondayFridayROOM, country, 1)}</Text>
                                </View>
                                <View style={i == props.calcs?.compRoomCalcs?.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>{Fmt.round(el.rFlowSaturdayROOM, country, 1)}</Text>
                                </View>
                                <View style={i == props.calcs?.compRoomCalcs?.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>{Fmt.round(el.rFlowSundayROOM, country, 1)}</Text>
                                </View>
                            </View>
                        )}
                    </View>
                    <Text style={PdfStyles.spacer}></Text>
                </View>
            }
            {props.tocValues.tocNumbering.cagChildren[12] !== undefined &&
                <View wrap={false}>
                    <View wrap={false}>
                        <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["SpecificPowerOfTheSystem"] = pageNumber }} />
                        <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.cag}.{props.tocValues.tocNumbering.cagChildren[12]} {t("SpecificPowerOfTheSystem")}</Text>
                        <Text style={PdfStyles.spacer}></Text>
                    </View>
                    <View style={StyleTable.table}>
                        <View style={StyleTable.tableRow}>
                            <View style={StyleLocal.tableCol35RowZero}>
                                <Text style={StyleTable.tableCell}></Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("Calculated")}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("Measured")}</Text>
                            </View>
                        </View>
                        <View style={StyleTable.tableRow}>
                            <View style={StyleLocal.tableCol35}>
                                <Text style={StyleTable.tableCell}>{t("SpecificPowerAccordingToTechnicalData")}</Text>
                            </View>
                            <View style={StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.cSpecificPowerTechnDataH, country, 3)} {Fmt.getUnit("powerPerHourPerVolume", t)}</Text>
                            </View>
                            <View style={StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.cSpecificPowerTechnDataMin, country, 2)} {Fmt.getUnit("powerPerTimePerVolume", t)}</Text>
                            </View>
                            <View style={StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.mSpecificPowerTechnDataH, country, 3)} {Fmt.getUnit("powerPerHourPerVolume", t)}</Text>
                            </View>
                            <View style={StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.mSpecificPowerTechnDataMin, country, 2)} {Fmt.getUnit("powerPerTimePerVolume", t)}</Text>
                            </View>
                        </View>
                        <View style={StyleTable.tableRow}>
                            <View style={StyleLocal.tableCol35}>
                                <Text style={StyleTable.tableCell}>{t("LosesDueToAtmosphericConditions")}</Text>
                            </View>
                            <View style={StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.cSpecificPowerLosesAtmConditions, country, 1)} %</Text>
                            </View>
                            <View style={StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.mSpecificPowerLosesAtmConditions, country, 0)} %</Text>
                            </View>
                        </View>
                        <View style={StyleTable.tableRow}>
                            <View style={StyleLocal.tableCol35}>
                                <Text style={StyleTable.tableCell}>{t("SpecificPowerOfTheCompressors")}</Text>
                            </View>
                            <View style={StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.cSpecificPowerCorrectedH, country, 3)} {Fmt.getUnit("powerPerHourPerNm", t)}</Text>
                            </View>
                            <View style={StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.cSpecificPowerCorrectedMin, country, 2)} {Fmt.getUnit("powerPerMinPerNm", t)}</Text>
                            </View>
                            <View style={StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.mSpecificPowerCorrectedH, country, 3)} {Fmt.getUnit("powerPerHourPerNm", t)}</Text>
                            </View>
                            <View style={StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.mSpecificPowerCorrectedMin, country, 2)} {Fmt.getUnit("powerPerMinPerNm", t)}</Text>
                            </View>
                        </View>
                        <View style={StyleTable.tableRow}>
                            <View style={StyleLocal.tableCol35}>
                                <Text style={StyleTable.tableCell}>{t("LosesDueToOperations")}</Text>
                            </View>
                            <View style={StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.cSpecificPowerLosesOperation, country, 1)} %</Text>
                            </View>
                            <View style={StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.mSpecificPowerLosesOperation, country, 1)} %</Text>
                            </View>
                        </View>
                        <View style={StyleTable.tableRow}>
                            <View style={StyleLocal.tableCol35RowZero}>
                                <Text style={StyleTable.tableCell}>{t("SpecificPowerOfTheSystem")}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.cSpecificPowerSystemh, country, 3)} {Fmt.getUnit("powerPerHourPerNm", t)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.cSpecificPowerSystemMin, country, 2)} {Fmt.getUnit("powerPerMinPerNm", t)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.mSpecificPowerSystemH, country, 3)} {Fmt.getUnit("powerPerHourPerNm", t)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={{ fontSize: 10, marginTop: 1, marginBottom: 1, marginLeft: 1, marginRight: 1 }}>
                                    {Fmt.round(props.calcs?.mSpecificPowerSystemMin, country, 2)} {Fmt.getUnit("powerPerMinPerNm", t)}
                                </Text>
                            </View>
                        </View>
                    </View>
                    <Text style={PdfStyles.spacer}></Text>
                </View>
            }
            {props.tocValues.tocNumbering.cagChildren[13] !== undefined &&
                <View>
                    <View wrap={false}>
                        <View wrap={false}>
                            <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["YearlyConsumptionCalculated"] = pageNumber }} />
                            <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.cag}.{props.tocValues.tocNumbering.cagChildren[13]} {t("YearlyConsumption")}</Text>
                            <Text style={PdfStyles.spacer}></Text>
                            <Text style={PdfStyles.text}>{t("Calculated")}</Text>
                            <Text style={PdfStyles.spacer}></Text>
                        </View>
                        <View style={StyleTable.table}>
                            <View style={StyleTable.tableRow}>
                                <View style={StyleTable.tableColRowZero}>
                                    <Text style={StyleTable.tableCell}>{t("CompressorRoomNo")}</Text>
                                </View>
                                <View style={StyleTable.tableColRowZero}>
                                    <Text style={StyleTable.tableCell}>{t("ElectricityConsumption")} {Fmt.getUnitInBracket("powerPerHour", t)}</Text>
                                </View>
                                <View style={StyleTable.tableColRowZero}>
                                    <Text style={StyleTable.tableCell}>{t("AirConsumption")} {Fmt.getUnitInBracket("cubicMeterOfGas", t)}</Text>
                                </View>
                                <View style={StyleTable.tableColRowZero}>
                                    <Text style={StyleTable.tableCell}>{t("AverageAirFlow")} {Fmt.getUnitInBracket("cubicMeterOfGasperTime", t)}</Text>
                                </View>
                            </View>
                            {props.calcs?.compRoomCalcs?.map((el, i) =>
                                <View key={"CompressorYearlyConsumptionCalculated" + i} style={StyleTable.tableRow}>
                                    <View style={i == props.calcs?.compRoomCalcs?.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{el.no}</Text>
                                    </View>
                                    <View style={i == props.calcs?.compRoomCalcs?.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{Fmt.round(el.cYearlyConsumptionElectricityROOM, country, 0)}</Text>
                                    </View>
                                    <View style={i == props.calcs?.compRoomCalcs?.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{Fmt.round(el.cYearlyConsumptionAirConsumptionROOM, country, 0)}</Text>
                                    </View>
                                    <View style={i == props.calcs?.compRoomCalcs?.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{Fmt.round(el.cYearlyConsumptionAirFlowROOM, country, 1)}</Text>
                                    </View>
                                </View>
                            )}
                        </View>
                        <Text style={PdfStyles.spacer}></Text>
                    </View>
                    <View wrap={false}>
                        <View wrap={false}>
                            <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["YearlyConsumptionMeasured"] = pageNumber }} />
                            <Text style={PdfStyles.text}>{t("Measured")}</Text>
                            <Text style={PdfStyles.spacer}></Text>
                        </View>
                        <View style={StyleTable.table}>
                            <View style={StyleTable.tableRow}>
                                <View style={StyleTable.tableColRowZero}>
                                    <Text style={StyleTable.tableCell}>{t("CompressorRoomNo")}</Text>
                                </View>
                                <View style={StyleTable.tableColRowZero}>
                                    <Text style={StyleTable.tableCell}>{t("ElectricityConsumption")} {Fmt.getUnitInBracket("powerPerHour", t)}</Text>
                                </View>
                                <View style={StyleTable.tableColRowZero}>
                                    <Text style={StyleTable.tableCell}>{t("AirConsumption")} {Fmt.getUnitInBracket("cubicMeterOfGas", t)}</Text>
                                </View>
                                <View style={StyleTable.tableColRowZero}>
                                    <Text style={StyleTable.tableCell}>{t("AverageAirFlow")} {Fmt.getUnitInBracket("cubicMeterOfGasperTime", t)}</Text>
                                </View>
                            </View>
                            {props.calcs?.compRoomCalcs?.map((el, i) =>
                                <View key={"CompressorYearlyConsumptionMeasured" + i} style={StyleTable.tableRow}>
                                    <View style={i == props.calcs?.compRoomCalcs?.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{el.no}</Text>
                                    </View>
                                    <View style={i == props.calcs?.compRoomCalcs?.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{Fmt.round(el.mYearlyConsumptionElectricityROOM, country, 0)}</Text>
                                    </View>
                                    <View style={i == props.calcs?.compRoomCalcs?.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{Fmt.round(el.mYearlyConsumptionAirConsumptionROOM, country, 0)}</Text>
                                    </View>
                                    <View style={i == props.calcs?.compRoomCalcs?.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{Fmt.round(el.mYearlyConsumptionAirFlowROOM, country, 1)}</Text>
                                    </View>
                                </View>
                            )}
                        </View>
                        <Text style={PdfStyles.spacer}></Text>
                    </View>
                    <View wrap={false}>
                        <View wrap={false}>
                            <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["YearlyConsumptionTotal"] = pageNumber }} />
                            <Text style={PdfStyles.text}>{t("Total")}</Text>
                            <Text style={PdfStyles.spacer}></Text>
                        </View>
                        <View style={StyleTable.table}>
                            <View style={StyleTable.tableRow}>
                                <View style={StyleTable.tableColRowZero}>
                                    <Text style={StyleTable.tableCell}></Text>
                                </View>
                                <View style={StyleTable.tableColRowZero}>
                                    <Text style={StyleTable.tableCell}>{t("Calculated")}</Text>
                                </View>
                                <View style={StyleTable.tableColRowZero}>
                                    <Text style={StyleTable.tableCell}>{t("Measured")}</Text>
                                </View>
                            </View>
                            <View style={StyleTable.tableRow}>
                                <View style={StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>{t("ElectricityConsumtion") + Fmt.getUnitInBracket("powerPerHour", t)}</Text>
                                </View>
                                <View style={StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.cYearlyConsumptionElectricityTOTAL, country, 0)}</Text>
                                </View>
                                <View style={StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.mYearlyConsumptionElectricityTOTAL, country, 0)}</Text>
                                </View>
                            </View>
                            <View style={StyleTable.tableRow}>
                                <View style={StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>{t("AirConsumtion") + Fmt.getUnitInBracket("cubicMeterOfGas", t)}</Text>
                                </View>
                                <View style={StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.cYearlyConsumptionAirConsumptionTOTAL, country, 0)}</Text>
                                </View>
                                <View style={StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.mYearlyConsumptionAirConsumptionTOTAL, country, 0)}</Text>
                                </View>
                            </View>
                            <View style={StyleTable.tableRow}>
                                <View style={StyleTable.tableColRowZero}>
                                    <Text style={StyleTable.tableCell}>{t("AverageAirFlow") + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t)}</Text>
                                </View>
                                <View style={StyleTable.tableColRowZero}>
                                    <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.cYearlyConsumptionAirFlowTOTAL, country, 0)}</Text>
                                </View>
                                <View style={StyleTable.tableColRowZero}>
                                    <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.mYearlyConsumptionAirFlowTOTAL, country, 0)}</Text>
                                </View>
                            </View>
                        </View>
                        <Text style={PdfStyles.spacer}></Text>
                    </View>
                </View>
            }
            {props.tocValues.tocNumbering.cagChildren[14] !== undefined &&
                <View wrap={false}>
                    <View wrap={false}>
                        <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["CostPerUnit"] = pageNumber }} />
                        <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.cag}.{props.tocValues.tocNumbering.cagChildren[14]} {t("CostsPerUnit")}</Text>
                        <Text style={PdfStyles.spacer}></Text>
                    </View>
                    <View style={StyleTable.table}>
                        <View style={StyleTable.tableRow}>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}></Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("EnergyCost")} {Fmt.getUnitWithCurrency("powerPerHour", t)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("CompressedAirCosts")} {Fmt.getUnitWithCurrency("cubicMeterOfGas", t)}</Text>
                            </View>
                        </View>
                        <View style={StyleTable.tableRow}>
                            <View style={StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{t("Electricity")}</Text>
                            </View>
                            <View style={StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.costPerKwh, country, 2)}</Text>
                            </View>
                            <View style={StyleTable.tableCol}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.rCostElectricityPerm3, country, 3)}</Text>
                            </View>
                        </View>
                        <View style={StyleTable.tableRow}>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCellTotal}>{t("TotalCosts")}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCellTotal}>{Fmt.round(props.calcs?.rCostPerm3, country, 3)}</Text>
                            </View>
                        </View>
                    </View>
                    <Text style={PdfStyles.text}>*{t("InclusinveElectricityMaintenanceInvestmentCosts")}</Text>
                    <Text style={PdfStyles.spacer}></Text>
                </View>
            }
            {props.tocValues.tocNumbering.cagChildren[15] !== undefined &&
                <View wrap={false}>
                    <View wrap={false}>
                        <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["CostPerYear"] = pageNumber }} />
                        <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.cag}.{props.tocValues.tocNumbering.cagChildren[15]} {t("CostPerYear")}</Text>
                        <Text style={PdfStyles.spacer}></Text>
                    </View>
                    <View style={StyleTable.table}>
                        <View style={StyleTable.tableRow}>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("CO2")} {Fmt.getUnitInBracket("co2Wheight", t)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("EnergyCost")} {Fmt.getCurrency()}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("AcquisitionAndMaintenanceCosts")} {Fmt.getCurrency()}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCellTotal}>{t("TotalCosts")} {Fmt.getCurrency()}</Text>
                            </View>
                        </View>
                        <View style={StyleTable.tableRow}>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.rYearlyConsumptionCO2TOTAL, country, 0)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.rYearlyConsumptionElectricityCosts, country, 0)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.rYearlyConsumption25Percentage, country, 0)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCellTotal}>{Fmt.round(props.calcs?.rYearlyConsumptionAirConsumptionCosts, country, 0)}</Text>
                            </View>
                        </View>
                    </View>
                    <Text style={PdfStyles.text}>* {t("InclusinveElectricityMaintenanceInvestmentCosts")}</Text>
                    <Text style={PdfStyles.text}>** 25% {t("OfTheTotalCosts")}</Text>
                    <Text style={PdfStyles.spacer}></Text>
                </View>
            }
            {props.tocValues.tocNumbering.cagChildren[16] !== undefined &&
                <View wrap={false}>
                    <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["AirConsumptionProfile"] = pageNumber }} />
                    <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.cag}.{props.tocValues.tocNumbering.cagChildren[16]} {t("AirConsumptionProfile")}</Text>
                    <Text style={PdfStyles.spacer}></Text>
                    <Image src={props.chart} />
                </View>
            }
        </Page>
    );
}