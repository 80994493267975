import React, { useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Container, Grid } from '@mui/material';
import { ButtonComponent, HeadingComponent } from '../Reusable';
import { API } from '../../helper/ApiHelper';
import { EmailParam } from '../Types/Types';

export default function Unsubscribe() {
    const { t } = useTranslation();
    const url = process.env.REACT_APP_DEVFESSINQUIRYSERVICES_BASE + "unsubscribe/";
    const { email } = useParams<EmailParam>();
    const history = useHistory();

    const [unsubscribed, setUnsubscribed] = useState(false);

    const unsubscribe = async () => {
        await API.get(url + email);
        setUnsubscribed(true);
    }

    const redirect = () => {
        history.push("/");
    }

    return (
        <Container>
            <Grid container direction="column" spacing={3}>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                    <HeadingComponent value={t(unsubscribed ? "SuccessfullyUnsubscribed" : "AreYouSureYouWantToUnsubscribe")} size="h6" />
                </Grid>
                {unsubscribed === false &&
                    <Grid item xs={12}>
                        <Grid container direction="row" justifyContent="center">
                            <Grid item>
                                <ButtonComponent value={t("Yes")} color="primary" onChange={unsubscribe} id="unsubscribe-yes" />
                            </Grid>
                            <Grid item>
                                <ButtonComponent value={t("No")} color="secondary" onChange={redirect} id="unsubscribe-no" />
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Container>
    );
}
