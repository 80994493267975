import { nowUTC } from "../../../helper/DateHelper";
// @ts-expect-error not converted yet
import { checkIsInteger, checkIsNotNullOrWhitespace, checkIsDateInPast, checkIsDateInPastUTC } from "../../../helper/Validator";
import { IUser } from "../../../store/UserStore";
import { UserStore } from '../../../store/index'
import {Translate, ModelParam } from '../../Types/Types';

let user : IUser = {};

UserStore.user.subscribe((usr) => {
    user = usr;
})

export interface AirQualityMeasurementModelFactory {
    id: ModelParam<number>;
    no: ModelParam<number>;
    detectedDate: ModelParam<Date>;
    detectedBy: ModelParam<string>;
    repairIsDone: ModelParam<boolean>;
    repairedBy: ModelParam<string>;
    repairDate: ModelParam<Date | null>;
    measurementId: ModelParam<string>;
    building: ModelParam<string>;
    department: ModelParam<string>;
    machine: ModelParam<string>;
    particleRequiredPG: ModelParam<string>;
    particleExpectedPG: ModelParam<string>;
    particleMeasuredPG: ModelParam<string>;
    particleRequiredClassId: ModelParam<number>;
    particleExpectedClassId: ModelParam<number>;
    particleMeasuredClass: ModelParam<string>;
    particleResultClass: ModelParam<string>;
    gasRequiredPG: ModelParam<string>;
    gasExpectedPG: ModelParam<string>;
    gasMeasuredPG: ModelParam<string>;
    gasRequiredClassId: ModelParam<number>;
    gasExpectedClassId: ModelParam<number>;
    gasMeasuredClass: ModelParam<string>;
    gasResultClass: ModelParam<string>;
    oilRequiredPG: ModelParam<string>;
    oilExpectedPG: ModelParam<string>;
    oilMeasuredPG: ModelParam<string>;
    oilRequiredClassId: ModelParam<number>;
    oilExpectedClassId: ModelParam<number>;
    oilMeasuredClass: ModelParam<string>;
    oilResultClass: ModelParam<string>;
    airQualityMeasurementComment: ModelParam<string>;
    guid: ModelParam<string | null>;
    repairComment: ModelParam<string>;
}

export const AirQualityMeasurementModelFactory = (t: Translate): AirQualityMeasurementModelFactory => {
    return {
        id: { value: 0, idkey: "", label: "", isValid: true, validationError: "", validators: [] },
        no: { label: t('Nr'), idkey: "MEASURMENTEDIT-No", value: 0, isValid: true, validationError: "", validators: [], disabled: true },
        detectedDate: { label: t("EntryDate"), idkey: "MEASURMENTEDIT-DetectedDate", value: nowUTC(), isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDateInPastUTC] },
        detectedBy: { label: t("DetectedBy"), idkey: "MEASURMENTEDIT-detectedBy", value: user?.firstName + " " + user?.lastName, isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace] },
        repairIsDone: { label: t("OptimizationIsDone"), idkey: "MEASURMENTEDIT-repairIsDone", value: false, isValid: true, validationError: "", validators: [] },
        repairedBy: { label: t("OptimizedBy"), idkey: "MEASURMENTEDIT-repairedBy", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace], dependsOn: "RepairIsDone" },
        repairDate: { label: t("OptimizationDate"), idkey: "MEASURMENTEDIT-repairDate", value: null, isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDateInPast], dependsOn: "RepairIsDone" },
        measurementId: { label: t("MeasurementId"), idkey: "MEASURMENTEDIT-measurementId", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger] },
        building: { label: t("Building"), idkey: "MEASURMENTEDIT-building", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace] },
        department: { label: t("Department"), idkey: "MEASURMENTEDIT-department", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace] },
        machine: { label: t("Machine"), idkey: "MEASURMENTEDIT-machine", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [] },
        particleRequiredPG: { label: t("Required"), idkey: "MEASURMENTEDIT-particleRequiredPG", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [] },
        particleExpectedPG: { label: t("Expected"), idkey: "MEASURMENTEDIT-particleExpectedPG", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [] },
        particleMeasuredPG: { label: t("Measured"), idkey: "MEASURMENTEDIT-particleMeasuredPG", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [] },
        particleRequiredClassId: { label: t("Required"), idkey: "MEASURMENTEDIT-particleRequiredClass", value: -1, isValid: true, validationError: t("ValidNumberError"), validators: [checkIsNotNullOrWhitespace] },
        particleExpectedClassId: { label: t("Expected"), idkey: "MEASURMENTEDIT-particleExpectedClass", value: -1, isValid: true, validationError: t("ValidNumberError"), validators: [checkIsNotNullOrWhitespace] },
        particleMeasuredClass: { label: t("Measured"), idkey: "MEASURMENTEDIT-particleMeasuredClass", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [] },
        particleResultClass: { label: t("Result"), idkey: "MEASURMENTEDIT-particleResultClass", value: "", isValid: true, validationError: "", validators: [] },
        gasRequiredPG: { label: t("Required"), idkey: "MEASURMENTEDIT-gasRequiredPG", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [] },
        gasExpectedPG: { label: t("Expected"), idkey: "MEASURMENTEDIT-gasExpectedPG", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [] },
        gasMeasuredPG: { label: t("Measured"), idkey: "MEASURMENTEDIT-gasMeasuredPG", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [] },
        gasRequiredClassId: { label: t("Required"), idkey: "MEASURMENTEDIT-gasRequiredClass", value: -1, isValid: true, validationError: t("ValidNumberError"), validators: [checkIsNotNullOrWhitespace] },
        gasExpectedClassId: { label: t("Expected"), idkey: "MEASURMENTEDIT-gasExpectedClass", value: -1, isValid: true, validationError: t("ValidNumberError"), validators: [checkIsNotNullOrWhitespace] },
        gasMeasuredClass: { label: t("Measured"), idkey: "MEASURMENTEDIT-gasMeasuredClass", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [] },
        gasResultClass: { label: t("Result"), idkey: "MEASURMENTEDIT-gasResultClass", value: "", isValid: true, validationError: "", validators: [] },
        oilRequiredPG: { label: t("Required"), idkey: "MEASURMENTEDIT-oilRequiredPG", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [] },
        oilExpectedPG: { label: t("Expected"), idkey: "MEASURMENTEDIT-oilExpectedPG", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [] },
        oilMeasuredPG: { label: t("Measured"), idkey: "MEASURMENTEDIT-oilMeasuredPG", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [] },
        oilRequiredClassId: { label: t("Required"), idkey: "MEASURMENTEDIT-oilRequiredClass", value: -1, isValid: true, validationError: t("ValidNumberError"), validators: [checkIsNotNullOrWhitespace] },
        oilExpectedClassId: { label: t("Expected"), idkey: "MEASURMENTEDIT-oilExpectedClass", value: -1, isValid: true, validationError: t("ValidNumberError"), validators: [checkIsNotNullOrWhitespace] },
        oilMeasuredClass: { label: t("Measured"), idkey: "MEASURMENTEDIT-oilMeasuredClass", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [] },
        oilResultClass: { label: t("Result"), idkey: "MEASURMENTEDIT-oilResultClass", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [] },
        airQualityMeasurementComment: { label: "", idkey: "MEASURMENTEDIT-airQualityMeasurementComment", value: "", isValid: true, validationError: "", validators: [] },
        guid: { label: "", idkey: "MEASURMENTEDIT-guid", value: null, isValid: true, validationError: "", validators: [] },
        repairComment: { label: t("RepairComment"), idkey: "MEASURMENTEDIT-RepairComment", value: "", isValid: true, validationError: "", validators: [] },
    }
}