import React from 'react';
import { styled } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ReferenceLine, ResponsiveContainer } from 'recharts';
import * as Fmt from '../../helper/Formatters';

const StyledResponsiveContainer = styled(ResponsiveContainer)({
    '& .recharts-surface': {
        height: '110%',
        overflow: 'visible',
    }
});

export default function PaybackTimeChart(props) {
    const { country } = useParams();
    const { t } = useTranslation();
    const { ChartModel } = props
    const generateChartData = () => {
        var chartData = [];
        for (var i = 0; i < ChartModel.length; i++) {
            chartData.push({
                uv: ChartModel[i].projectCost,
            })
        }
        return chartData;
    }
    return (
        <StyledResponsiveContainer id="leakage-analysis-payback-chart" height={400} className={"chart-Container"}>
            <LineChart data={generateChartData()}>
                <Line type="monotone" dataKey="uv" stroke="#8884d8" name={t('Costs')} unit={" " + Fmt.getCurrency()} formatter={(value) => Fmt.round(value, country, 2)} />
                <CartesianGrid stroke="#ccc" />
                <XAxis axisLine={false} label={{ value: t('Months'), position: 'bottom' }} />
                <YAxis unit={" " + Fmt.getCurrency()} label={{ value: t('Costs'), angle: -90, offset: 20, position: 'left' }} />
                <Tooltip />
                <ReferenceLine purpose='0-24 months' y={0} label="" stroke="red" />
            </LineChart>
        </StyledResponsiveContainer>
    );
}
