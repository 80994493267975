import { checkIsInteger, checkIsNotNullOrWhitespace, checkIsDateInPast, isTrue } from "../../../helper/Validator";
import { UserStore } from '../../../store';

let user = {};
UserStore.user.subscribe(usr => {
    user = usr;
})

export const MultiRepairedModel = (t) => (
    {
        leakageRepaired: { label: t("LeakageRepaired"), idkey: "LDAREDIT-leakageRepaired", value: false, default: false, isValid: true, validationError: "", validators: [isTrue], headerName: t('Repaired?') },
        repairedBy: { label: t("RepairedBy"), idkey: "LDAREDIT-repairedBy", value: user.firstName + " " + user.lastName, default: "", isValid: true, validationError: t("ValidNumberError"), validators: [checkIsNotNullOrWhitespace] },
        repairDate: { label: t("RepairDate"), idkey: "LDAREDIT-repairDate", value: null, default: null, isValid: true, validationError: t("ValidNumberError"), validators: [checkIsDateInPast] },
        leakageRepairTimeRepairedInput: { label: t("LeakageRepairTimeRepairedInput"), idkey: "LDAREDIT-leakageRepairTimeRepairedInput", value: "", default: "", isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger] }
    }
)
