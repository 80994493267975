import { BehaviorSubject } from "rxjs";

const reloadPin = new BehaviorSubject(0);

export const ProjectSettingsTrigger = {
    reloadPin,
    reload: function (pinValue: number | undefined) {
        if (pinValue != undefined)
            reloadPin.next(pinValue);
        else
            reloadPin.next(reloadPin.value + 1);
    }
}