import { GridColDef, GridEditInputCell, GridPreProcessEditCellProps, GridRenderEditCellParams, GridSingleSelectColDef, ValueOptions } from '@mui/x-data-grid';
import { IDropdownItem, Translate, ModelParam, ModelFactory } from '../Types/Types';
// @ts-expect-error not converted yet
import * as Mapper from '../../helper/Mappers';
import * as DateHelper from '../../helper/DateHelper';
import { Tooltip } from '@mui/material';
import React from 'react';

type CustomGridColDef = <T>(model: ModelFactory<T>, t: Translate, field: keyof T, custom?: Partial<GridColDef>) => GridColDef;
type YesNoGridSingleSelectColDef = <T>(model: ModelFactory<T>, t: Translate, field: keyof T, custom?: Partial<GridSingleSelectColDef>) => GridSingleSelectColDef;
type ItemsGridSingleSelectColDef = <T>(model: ModelFactory<T>, t: Translate, field: keyof T, dropdownItems: IDropdownItem[], custom?: Partial<GridSingleSelectColDef>) => GridSingleSelectColDef;

export const valueColDef: CustomGridColDef = (model, t, field, custom) => {
    const modelParam = model[field];
    return {
        minWidth: 100,
        maxWidth: 300,
        field: field as string,
        headerName: modelParam?.headerName,
        headerClassName: 'datagrid-bold',
        ...custom
    }
};

export const inputColDef: CustomGridColDef = (model, t, field, custom) => {
    const modelParam = model[field];
    return {
        minWidth: 100,
        maxWidth: 300,
        field: field as string,
        headerName: modelParam?.headerName,
        headerClassName: 'datagrid-bold',
        editable: true,
        renderEditCell: (params: GridRenderEditCellParams) => {
            return modelParam.isValid ?
                (<GridEditInputCell {...params} />) :
                (<Tooltip title={modelParam.validationError}><GridEditInputCell {...params} /></Tooltip>)
        },
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
            modelParam.value = params.props.value;
            const mappedModel = Mapper.mapDataToModelValues(params.row, model);
            const isValid = validateSingleParam(modelParam, mappedModel);
            modelParam.isValid = isValid;
            return { ...params.props, error: !isValid };
        },
        ...custom
    }
};

export const datesColDef: CustomGridColDef = (model, t, field, custom) => {
    const modelParam = model[field];

    return {
        minWidth: 110,
        maxWidth: 300,
        field: field as string,
        headerName: modelParam?.headerName,
        valueGetter: (value: any) => value ? new Date(value) : value,
        valueFormatter: (value: any) => DateHelper.FormatDate(value),
        headerClassName: 'datagrid-bold',
        editable: true,
        type: 'date',
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
            modelParam.value = params.props.value;
            const mappedModel = Mapper.mapDataToModelValues(params.row, model);
            const isValid = validateSingleParam(modelParam, mappedModel);
            modelParam.isValid = isValid;
            return { ...params.props, error: !isValid };
        },
        ...custom
    }
};

export const yesNoColDef: YesNoGridSingleSelectColDef = (model, t, field, custom) => {
    const modelParam = model[field];
    return {
        minWidth: 100,
        maxWidth: 300,
        field: field as string,
        headerName: modelParam?.headerName,
        valueOptions: [{ value: true, label: t('Yes') }, { value: false, label: t('No') }],
        getOptionValue: (value: any) => value?.value,
        getOptionLabel: (value: any) => value?.label,
        headerClassName: 'datagrid-bold',
        type: 'singleSelect',
        editable: true,
        ...custom
    }
};

export const itemsColDef: ItemsGridSingleSelectColDef = (model, t, field, dropdownItems, custom) => {
    const modelParam = model[field];
    return {
        minWidth: 135,
        maxWidth: 300,
        field: field as string,
        headerName: modelParam?.headerName,
        valueOptions: transformDropdownItems(dropdownItems),
        valueGetter: (value: IDropdownItem) => value?.id,
        valueSetter: (value: number, row: object) => ({ ...row, [field]: transformValueOption(dropdownItems, value) }),
        getOptionValue: (value: any) => value?.value,
        getOptionLabel: (value: any) => t(value?.label),
        headerClassName: 'datagrid-bold',
        type: 'singleSelect',
        editable: true,
        ...custom
    }
};

const validateSingleParam = <T, TT>(modelParam: ModelParam<T, TT>, model: ModelFactory<T>) => {
    let isValid = true;
    modelParam.isValid = true;
    modelParam.validators.forEach((v: any) => {
        if (v.length == 1 && !v(modelParam.value)) {
            modelParam.isValid = false;
            isValid = false;
        }
        else if (v.length == 2 && !v(modelParam.value, model)) {
            modelParam.isValid = false;
            isValid = false;
        }
    })
    return isValid;
}

function transformValueOption(dropdownItems: IDropdownItem[], value: number): IDropdownItem {
    return {
        id: dropdownItems[value - 1].id,
        value: dropdownItems[value - 1].value
    };
}

function transformDropdownItems(dropdownItems: IDropdownItem[]): ValueOptions[] {
    return dropdownItems.map((item) => ({
        value: item.id,
        label: item.value,
    }));
}