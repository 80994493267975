import React, { useEffect, useState } from "react"
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import "./Autocomplete.scss";
import { useTranslation } from 'react-i18next';

export function AutocompleteDropdown(props) {
    const { model, options, onChange, disabled, optionKey, optionName, portaldisable, TranslateFirstTwo, tabIndex } = props;
    let id = props.id ? props.id : model.idkey;
    const [value, setValue] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (options && model.value && model.value.value) {
            setValue(options.find(x => x[optionKey] == model.value.id));
        }
        else if (options && (model.value || (model.value == 0 && options.find((x) => x.id == 0)))) {
            setValue(options.find(x => x[optionKey] == model.value));
        }
        else
            setValue("");
    }, [model.value, options]);
    return (
        <Autocomplete
            id={"input_" + id + 'AutocompleteDropdown'}
            options={options}
            autoHighlight
            disablePortal={portaldisable ? true : false}
            getOptionLabel={(option) => TranslateFirstTwo && option[optionKey] < 0 ? t(option[optionName]) : option[optionName] ?? option}
            value={value}
            disabled={disabled}
            className={"auto-complete"}
            onChange={(e, newVal) => !disabled && onChange(newVal)}
            renderInput={(params) => (
                <TextField
                    variant="standard"
                    {...params}
                    required={model.validators?.length > 0 && !model.notRequired}
                    error={!model.isValid}
                    label={model.label ?? model.tkey}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // Disable autofill
                        tabIndex: tabIndex ?? 0
                    }}
                />
            )}
        />
    );
}