import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Dialog, DialogTitle, DialogContent, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@mui/material';
import { HeadingComponent, TableHeadCells } from '../../Reusable/';
import { Close, Forward } from '@mui/icons-material';
import { API } from '../../../helper/ApiHelper';
import TableHeadingContent from '../../Reusable/TableHeadingContent/TableHeadingContent';
import { FilterModel } from "../../Reusable/TableHeadingContent/GlobalFilterModel";
import { CompressorModelFactory } from './CompressorModel';

export default function CompressorSearchDialog(props) {
    const { open, setOpen, importCompressorModel } = props;

    const getCompressorModelsUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "compressed-air-generation/compressor-models";
    const { t } = useTranslation();
    const compressorModel = CompressorModelFactory(t);

    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(5);
    const [compressorModels, setCompressorModels] = useState([]);
    const [filterData, setFilterData] = useState([]);

    useEffect(() => {
        if (open === true && compressorModels.length === 0)
            fetchCompressorModels();
        else
            setCompressorModels([...filterData]);
    }, [open]);

    const fetchCompressorModels = async () => {
        let response = await API.get(getCompressorModelsUrl);
        setCompressorModels(response);
        setFilterData(response);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const filterFunction = (tempdata) => {
        setCompressorModels(tempdata);
        setPage(0);
    }

    const changeRows = (e) => {
        setRows(e.target.value);
        setPage(0);
    }

    return (
        <Dialog open={open}>
            <DialogTitle>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <HeadingComponent value={t("CompressorModelSearch")} size="h6" />
                    </Grid>
                    <Grid item>
                        <IconButton color="inherit" onClick={() => setOpen(false)} size="large">
                            <Close fontSize="small" />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={3} direction="column" className="ReplacementPartMaterialSearch">
                    <Grid item>
                        <TableHeadingContent
                            defaultData={compressorModels}
                            filterData={filterData}
                            onFilterFunction={filterFunction}
                            filterModel={FilterModel.CompressorTechnicalData}
                            NotShowAddDelete={true}
                            tableId={"MaterialSearch"}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper} >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableHeadCells
                                            model={compressorModel}
                                            propertyList={["manufacturer", "modelSerialNumber", "compressorType", "controlType", "nominalPower", "freeAirDelivery", "nominalPressure"]}
                                            tableName="CompressorModels"
                                            data={compressorModels}
                                            setData={setCompressorModels}
                                        />
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        compressorModels.slice(page * rows, rows + (page * rows)).map((compressor, rowIndex) =>
                                            <TableRow key={rowIndex}>
                                                <TableCell>{compressor.manufacturer}</TableCell>
                                                <TableCell>{compressor.modelSerialNumber}</TableCell>
                                                <TableCell>{t(compressor.compressorType?.value)}</TableCell>
                                                <TableCell>{t(compressor.controlType?.value)}</TableCell>
                                                <TableCell>{compressor.nominalPower}</TableCell>
                                                <TableCell>{compressor.freeAirDelivery}</TableCell>
                                                <TableCell>{compressor.nominalPressure}</TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        onClick={() => importCompressorModel(compressor)}
                                                        id="btn_MaterialDialog-Pick"
                                                        color="inherit"
                                                        size="large"
                                                    >
                                                        <Forward fontSize="large" />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                            <TablePagination
                                component="div"
                                rowsPerPageOptions={[5, 10, 15]}
                                count={compressorModels && compressorModels.length > 0 ? compressorModels.length : 0}
                                rowsPerPage={rows}
                                onRowsPerPageChange={changeRows}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        </TableContainer>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}