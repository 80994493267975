import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@mui/material';
import { TableHeadingContent, TableHeadCells, HeadingComponent } from '../../components/Reusable/';
import { Forward } from '@mui/icons-material';
import { FilterModel } from "../Reusable/TableHeadingContent/GlobalFilterModel";
import { API } from '../../helper/ApiHelper';
import { ProjectModelFactory, ProjectListFilterModelFactory } from '../ProjectSettings/ProjectModel';
import { ExecutorStore } from '../../store/';
import { toLocalDateString } from '../../helper/DateHelper'

export default function ImportProjectView(props) {
    var projectUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "project-management";
    const { open, pickProject } = props;
    const { t } = useTranslation();
    const projectFilterModel = { ...ProjectModelFactory(t), ...ProjectListFilterModelFactory(t) };

    const [list, setList] = useState([]);
    const [filterList, setFilterList] = useState([]);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(5);

    useEffect(() => {
        if (open === true) {
            let executorId = ExecutorStore.executorSelection.value;
            API.get(`${projectUrl}/projects/${executorId}`).then(resp => {
                var res = JSON.parse(resp);
                setList(res);
                setFilterList(res);
            })
        }
    }, [open]);

    const filterFunction = (tempdata) => {
        setList(tempdata);
        setPage(0);
    }

    const changeRows = (e) => {
        setRows(e.target.value);
        setPage(0);
    }

    return (
        <Grid container spacing={3} direction="column">
            <Grid item>
                <HeadingComponent value={t('Projects')} size="h6" />
            </Grid>
            <Grid item>
                <TableHeadingContent
                    id="import-full-project"
                    defaultData={list}
                    filterData={filterList}
                    onFilterFunction={filterFunction}
                    filterModel={FilterModel.ImportProject}
                    NotShowAddDelete={true}
                    tableId="ImportProject"
                />
            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper} >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeadCells
                                    model={projectFilterModel}
                                    propertyList={["projectId", "customer", "companyName", "createdDate"]}
                                    tableName="ImportProject"
                                    data={list}
                                    setData={setList}
                                />
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                list.slice(page * rows, rows + (page * rows)).map((item, rowIndex) =>
                                    <TableRow key={rowIndex}>
                                        <TableCell>{item.ProjectId}</TableCell>
                                        <TableCell>{item.Customer}</TableCell>
                                        <TableCell>{item.CompanyName}</TableCell>
                                        <TableCell>{toLocalDateString(item.CreatedDate)}</TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={() => pickProject(item.Id)}
                                                id="btn_Project-Pick"
                                                color="inherit"
                                                size="large"
                                            >
                                                <Forward fontSize="large" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                    <TablePagination
                        component="div"
                        rowsPerPageOptions={[5, 10, 15]}
                        count={list && list.length > 0 ? list.length : 0}
                        rowsPerPage={rows}
                        onRowsPerPageChange={changeRows}
                        page={page}
                        onPageChange={(e, newPage) => setPage(newPage)}
                    />
                </TableContainer>
            </Grid>
        </Grid>
    );
}