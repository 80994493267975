import React from 'react';
import { Page, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { usePdfStyles } from './PdfStyles';
import Watermark from './Watermark';

export const AttachmentsPdf = (props) => {
    const { projectData } = props;
    const { t } = useTranslation();
    var PdfStyles = usePdfStyles();

    return (
        <Page size={props.pageSize} style={PdfStyles.page}>
            <Watermark projectData={projectData} />
            <Text style={PdfStyles.pageNumbers} render={({ pageNumber, totalPages }) => (pageNumber + ' ' + t('of') + ' ' + totalPages)} fixed />
            <View wrap={false}>
                <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["attachments"] = pageNumber }} />
                <Text style={PdfStyles.boldHeading}>{props.tocValues.tocNumbering.attachments}. {t('Attachments')}</Text>
                <Text style={PdfStyles.spacer}></Text>
            </View>
        </Page>
    );
}