export const FilterModel = {
    ProjectOverview: {
        textFilter: ["ProjectId", "Customer", "ChangedDate", "ExecutorName", "CompanyName", "City", "Country", "Email", "Status"],
        iconFilter: [
            { name: "CustomerCity", key: ["City"] },
            { name: "CustomerCountry", key: ["Country"] },
            { name: "ServiceType", key: ["IsLeakageDetectionAndDocumentationAndRepairSelected", "IsLeakageDetectionAndDocumentationSelected", "IsMachineAnalysisForEnergyEfficiencySelected", "IsCompressedAirQualityAnalysisSelected", "IsCompressedAirEnergyEfficiencyPreAuditSelected", "IsAirNetworkAnalysisSelected", "IsAnalysisOfTheCompressedAirGenerationSelected", "IsCompressedAirConsumtionAnalysisSelected"] },
            { name: "Status", key: ["Status"] },
        ]
    },
    RequestOverview: {
        textFilter: ["ProjectId", "ExecutorName", "CreatedDate", "CompanyName", "City", "Country", "Email"],
        iconFilter: [
            { name: "CustomerCity", key: ["City"] },
            { name: "CustomerCountry", key: ["Country"] },
            { name: "ServiceType", key: ["IsLeakageDetectionAndDocumentationAndRepairSelected", "IsLeakageDetectionAndDocumentationSelected", "IsMachineAnalysisForEnergyEfficiencySelected", "IsCompressedAirQualityAnalysisSelected", "IsCompressedAirEnergyEfficiencyPreAuditSelected", "IsAirNetworkAnalysisSelected", "IsAnalysisOfTheCompressedAirGenerationSelected", "IsCompressedAirConsumtionAnalysisSelected"] },
        ]
    },
    CompressedAirGeneration: {
        textFilter: ["no", "compressorRoomNo", "manufacturer", "modelSerialNumber", "year", "compressorType", "controlType", "measurementId"],
        iconFilter: [
            { name: "CompressorRoomNo", key: ["compressorRoomNo"] },
            { name: "Manufacturer", key: ["manufacturer"] },
            { name: "ModelSerialNumber", key: ["modelSerialNumber"] },
            { name: "Year", key: ["year"] },
            { name: "CompressorType", key: ["compressorType"] },
            { name: "ControlType", key: ["controlType"] },
        ]
    },
    CompressorTechnicalData: {
        textFilter: ["manufacturer", "modelSerialNumber", "compressorType", "controlType"],
        iconFilter: [
            { name: "Manufacturer", key: ["manufacturer"] },
            { name: "ModelSerialNumber", key: ["modelSerialNumber"] },
            { name: "CompressorType", key: ["compressorType"] },
            { name: "ControlType", key: ["controlType"] },
        ]
    },
    InstalledDryers: {
        textFilter: ["measurementId", "year", "typeOfDryer", "building", "department", "machine", "manufacturer", "modelSerialNumber", "flowRangeOfDryer", "temperatureOftheDryer"],
        iconFilter: [
            { name: "TypeOfDryer", key: ["typeOfDryer"] },
            { name: "Manufacturer", key: ["manufacturer"] },
            { name: "ModelSerialNumber", key: ["modelSerialNumber"] },
            { name: "Year", key: ["year"] },
            { name: "TemperatureOfTheAir", key: ["temperatureOftheDryer"] },
        ]
    },
    InstalledDryerTechnicalData: {
        textFilter: ["typeOfDryer", "manufacturer", "modelSerialNumber", "flowRangeOfDryer"],
        iconFilter: [
            { name: "TypeOfDryer", key: ["typeOfDryer"] },
            { name: "Manufacturer", key: ["manufacturer"] },
            { name: "ModelSerialNumber", key: ["modelSerialNumber"] },
        ]
    },
    AQMeasurment: {
        textFilter: ['building', 'department', 'measurementId', 'machine'],
        iconFilter: [
            { name: "Building", key: ["building"] },
            { name: "Department", key: ["department"] },
            { name: "Machine", key: ["machine"] },
        ]
    },
    DistributionLine: {
        textFilter: ["comment", "distributionLineLayout", "id", "measurementId", "name", "no", "pipeDiameter", "pipeMaterial", "totalPipeLength", "pipeVolume", "building"],
        iconFilter: [
            { name: "Name", key: ["name"] },
            { name: "Building", key: ["building"] },
            { name: "DistributionLineLayout", key: ["distributionLineLayout"] },
            { name: "PipeMaterial", key: ["pipeMaterial"] },
        ]
    },
    AirReceivers: {
        textFilter: ["comment", "building", "id", "measurementId", "department", "no", "lsInspection", "machine", "volume", "workingPressure", "year"],
        iconFilter: [
            { name: "Building", key: ["building"] },
            { name: "Department", key: ["department"] },
            { name: "Machine", key: ["machine"] },
            { name: "Year", key: ["year"] },
        ]
    },
    PressureDropAnalysis: {
        textFilter: ["no", "measurementId", "nameOfTheAirNetwork", "setpointPressureRel", "pressureDropMax",],
        iconFilter: [
            { name: "NameOfTheAirNetwork", key: ["nameOfTheAirNetwork"] },
        ]
    },
    LDAR: {
        textFilter: ["leackageNumber", "measurementId", "building", "leakageClasification", "department", "machine", "detectedDate", "leakageRepearableProdTime", "leakageRepaired", "leakageAction", "costPerYear"],
        iconFilter: [
            { name: "LeakageClasification", key: ["leakageClasification"] },
            { name: "LeakageRepearableProdTime", key: ["leakageRepearableProdTime"] },
            { name: "LeakageAction", key: ["leakageAction"] },
            { name: "Repaired?", key: ["leakageRepaired"] },
            { name: "Building", key: ["building"] },
            { name: "Department", key: ["department"] },
            { name: "Machine", key: ["machine"] },
        ]
    },
    Application: {
        textFilter: ["no", "optimizationFlowSavings", "optimizationVolumeYearSavings", "optimizationSavingsBeforeTaxes", "optimizationInvestment", "optimizationPaybackTime", "machine", "building", "department"],
        iconFilter: [
            { name: "Building", key: ["building"] },
            { name: "Department", key: ["department"] },
            { name: "Machine", key: ["machine"] },
        ]
    },
    ReplacmentParts: {
        textFilter: ["leackageNumber", "partNumber", "typeCode", "component", "manufacturer", "newPartNumber", "newTypeCode", "newManufacturer"],
        iconFilter: [
            { name: "Component", key: ["component"] },
            { name: "Manufacturer", key: ["manufacturer"] },
            { name: "Replacement", key: ["newPartNumber"] },
            { name: "OrderSparePart", key: ["orderSparePart"] },
        ]
    },
    ReplacmentPartsCart: {
        textFilter: ["component", "manufacturer", "newManufacturer", "newPartNumber", "newTypeCode", "typeCode", "no", "partNumber", "service", "measurementId", "building", "department", "machine"],
        iconFilter: [
            { name: "Service", key: ["service"] },
            { name: "Component", key: ["component"] },
            { name: "Manufacturer", key: ["manufacturer"] },
            { name: "New Manufacturer", key: ["newManufacturer"] },
            { name: "Building", key: ["building"] },
            { name: "Department", key: ["department"] },
            { name: "Machine", key: ["machine"] },
        ]
    },
    ExecutorManagement: {
        textFilter: ["subsidiaryFestoName", "subsidiaryAddress", "subsidiaryCity", "subsidiaryTelephone", "subsidiaryContactPerson", "subsidiaryEmail", "countryID"],
        iconFilter: [
            { name: "Country", key: ["countryID"] },
            { name: "City", key: ["subsidiaryCity"] },
            { name: "ContactPerson", key: ["subsidiaryContactPerson"] },
            { name: "IsFestoExecutor", key: ["festoExecutor"] },
        ]
    },
    AddEditExecutorUsers: {
        textFilter: ["firstName", "lastName", "email",],
        iconFilter: [
            { name: "FirstName", key: ["firstName"] },
            { name: "Role", key: ["role"] },
        ]
    },
    UserManagement: {
        textFilter: ["name", "lastName", "email"],
        iconFilter: [
            { name: "IsEmailNotification", key: ["isEmailNotification"] },
        ]
    },
    QrCodeAudit: {
        textFilter: ["service", "no", "measurementId", "reason"],
        iconFilter: [
            { name: "Service", key: ["service"] },
            { name: "Nr", key: ["no"] },
        ]
    },
    QrCodeProjectPrint: {
        textFilter: ["measurementId", "service", "dateOfDetection", "detectedBy"],
        iconFilter: [
            { name: "Service", key: ["service"] },
            { name: "DetectedBy", key: ["detectedBy"] },
        ]
    },
    MaterialSearch: {
        textFilter: ["description", "materialNo", "typecode"],
        iconFilter: []
    },
    ImportProject: {
        textFilter: ["ProjectId", "Customer", "CompanyName"],
        iconFilter: []
    }
}
