import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { TextFieldComponent, ButtonComponent } from '../Reusable';
import { API } from '../../helper/ApiHelper';
import { ModelParam } from '../Types/Types';

interface IMeasurementId {
    onChange: (e: string) => void;
    element: ModelParam<string>;
    disabled: boolean;
}

interface IReserveQrCodeSeed {
    start: number;
    end: number;
}

export default function MeasurementId(props: IMeasurementId) {
    const { disabled, element, onChange } = props;
    const { t } = useTranslation();
    const qcUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "qr-code/reserve/";

    const generateIdCode = async () => {
        const resp = await API.post<IReserveQrCodeSeed>(qcUrl + 1) 
        onChange(resp.start.toString())
    };

    return (
        <Grid container spacing={2} direction="row" alignItems="center">
            <Grid item xs={4}>
                <TextFieldComponent
                    disabled={disabled}
                    t={t}
                    model={element}
                    onChange={e => onChange(e.target.value)}
                />
            </Grid>
            {!disabled &&
                <Grid item xs={8}>
                    <ButtonComponent
                        disabled={element.disabled}
                        onChange={generateIdCode}
                        value={t('generateIdCode')}
                    />
                </Grid>
            }
        </Grid>
    );
}