import { checkIsInteger } from "../../helper/Validator";

export const PdfChecklistModel = t => ({
    compressedAirGeneration: { label: t("CompressedAirGeneration"), value: 20, isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger] },
    installedDryers: { label: t("InstalledDryers"), value: 20, isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger] },
    airQualityMeasurement: { label: t("AirQualityMeasurement"), value: 10, isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger] },
    distributionLine: { label: t("DistributionLine"), value: 5, isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger] },
    airReceivers: { label: t("AirReceivers"), value: 10, isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger] },
    pressureDropAnalysis: { label: t("PressureDropAnalysis"), value: 3, isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger] },
    macac: { label: t("MA/CACTabName"), value: 5, isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger] },
    leakageDetection: { label: t("ListOfLeakages"), value: 30, isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger] },
    replacementParts: { label: t("Cart"), value: 20, isValid: true, validationError: t("ValidNumberError"), validators: [checkIsInteger] },
})
