import React from "react";
import { Page, Text, View, Image } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { usePdfStyles } from './PdfStyles';

export const AirQualityDryersPdf = (props) => {
    const { t } = useTranslation();
    var PdfStyles = usePdfStyles();

    return (
        <Page size={props.pageSize} style={PdfStyles.page}>
            <View style={PdfStyles.header}>
                <View style={PdfStyles.logoWrapper}>
                    {props.logo && <Image style={PdfStyles.logo} src={props.logo} />}
                </View>
            </View>
            <View style={PdfStyles.pageBody}>

                <View style={PdfStyles.heading}>
                    <Text>{t('InstalledDryers')}</Text>
                </View>

                <View style={PdfStyles.table}>
                    <View style={PdfStyles.tableRow}>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('Nr')}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('MeasurementId')}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('CompressorRoomNo')}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('TypeOfDryer')}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('Manufacturer')}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('ModelSerialNumber')}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('Year')}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('FlowRangem3')}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('TemperatureOfTheAir')}</Text>
                        </View>
                        <View style={PdfStyles.tableCol}>
                            <Text style={PdfStyles.tableCell}>{t('AssignedCompressors')}</Text>
                        </View>
                    </View>
                    {Array.apply(null, { length: props.count }).map((e, i) => (
                        <View key={'airreceiver' + i} style={PdfStyles.tableRow}>
                            <View style={PdfStyles.tableCol}>
                                <Text style={PdfStyles.tableCell}>{i + 1 + props.offset}</Text>
                            </View>
                            <View style={PdfStyles.tableCol}>
                                <Text style={PdfStyles.tableCell}></Text>
                            </View>
                            <View style={PdfStyles.tableCol}>
                                <Text style={PdfStyles.tableCell}></Text>
                            </View>
                            <View style={PdfStyles.tableCol}>
                                <Text style={PdfStyles.tableCell}></Text>
                            </View>
                            <View style={PdfStyles.tableCol}>
                                <Text style={PdfStyles.tableCell}></Text>
                            </View>
                            <View style={PdfStyles.tableCol}>
                                <Text style={PdfStyles.tableCell}></Text>
                            </View>
                            <View style={PdfStyles.tableCol}>
                                <Text style={PdfStyles.tableCell}></Text>
                            </View>
                            <View style={PdfStyles.tableCol}>
                                <Text style={PdfStyles.tableCell}></Text>
                            </View>
                            <View style={PdfStyles.tableCol}>
                                <Text style={PdfStyles.tableCell}></Text>
                            </View>
                            <View style={PdfStyles.tableCol}>
                                <Text style={PdfStyles.tableCell}></Text>
                            </View>
                        </View>
                    ))}
                </View>
                <Text style={PdfStyles.heading}>{t('Comment')}</Text>
                <View style={{ height: 90, border: "1px solid rgba(0, 0, 0, 1)" }} ></View>
            </View>
        </Page>
    )

};
