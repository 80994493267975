import React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';
import { usePdfStyles } from './PdfStyles';

export default function ImagesPdf(props) {
    const { image } = props;
    var PdfStyles = usePdfStyles();

    return (
        <View style={PdfStyles.imageContainer}>
            <View style={[PdfStyles.tableRowHeader, { flexDirection: "row", flexWrap: "wrap" }]}>
                {image[0] != undefined ?
                    image.map((item, index) => {
                        return (
                            <View style={PdfStyles.ldarImageView} key={index}>
                                <Image src={item.url} ></Image>
                            </View>
                        )
                    })
                    :
                    <View style={PdfStyles.errorPhoto}>
                        <Text>No photos found</Text>
                    </View>
                }
            </View>
        </View>
    )
}
