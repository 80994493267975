import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Close } from '@mui/icons-material';
import { HeadingComponent, NumberInputFieldComponent, ButtonComponent, CheckboxComponent } from '../Reusable/';
import * as Mapper from '../../helper/Mappers';
import * as InputRestrictions from '../../helper/InputRestrictions';

export default function PdfReportForm(props) {
    const { model, setModel, open, setOpen, generatePdfReport } = props;

    const { t } = useTranslation();

    return (
        <Dialog open={open}>
            <DialogTitle>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <HeadingComponent value={t("Values")} size="h6" />
                    </Grid>
                    <Grid item>
                        <IconButton color="inherit" onClick={() => setOpen(false)} size="large">
                            <Close fontSize="small" />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={3} direction="column">
                    <Grid item xs={12}>
                        <CheckboxComponent
                            model={model.travelCostsKnown}
                            onChange={e => Mapper.updateModel("travelCostsKnown", e.target.checked, model, setModel)}
                        />
                    </Grid>
                    {model.travelCostsKnown.value === true ?
                        <React.Fragment>
                            <Grid item>
                                <NumberInputFieldComponent
                                    t={t}
                                    model={model.travelLumpSum}
                                    onChange={e => Mapper.updateModel("travelLumpSum", InputRestrictions.Decimal(e.target.value, 1), model, setModel)}
                                />
                            </Grid>
                            <Grid item>
                                <NumberInputFieldComponent
                                    t={t}
                                    model={model.travelTimeCosts}
                                    onChange={e => Mapper.updateModel("travelTimeCosts", InputRestrictions.Decimal(e.target.value, 1), model, setModel)}
                                />
                            </Grid>
                            <Grid item>
                                <NumberInputFieldComponent
                                    t={t}
                                    model={model.travelCosts}
                                    onChange={e => Mapper.updateModel("travelCosts", InputRestrictions.Decimal(e.target.value, 1), model, setModel)}
                                />
                            </Grid>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Grid item>
                                <NumberInputFieldComponent
                                    t={t}
                                    model={model.travelTime}
                                    onChange={e => Mapper.updateModel("travelTime", InputRestrictions.Decimal(e.target.value, 1), model, setModel)}
                                />
                            </Grid>
                            <Grid item>
                                <NumberInputFieldComponent
                                    t={t}
                                    model={model.travelDistance}
                                    onChange={e => Mapper.updateModel("travelDistance", InputRestrictions.Decimal(e.target.value, 1), model, setModel)}
                                />
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item><ButtonComponent value={t('Generate')} color="primary" onChange={generatePdfReport}></ButtonComponent></Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}