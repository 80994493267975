import * as cnsts from './Constants'

export const GenerateTopHeader = (title, t) => {
    return {
        columns: [
            {
                title: t("FestoEnergySavingServices"),
                style: { font: { sz: "16", bold: true }, width: { wch: 40 } },
            },
        ],
        data: [
            [
                {
                    value: t(title),
                    style: { font: { bold: true } },
                },
            ],
            [{ value: t("Date") }, { value: new Date().toLocaleDateString() }],
        ],
    }

}

export const GetCellStyleClassName = (i, length) => {
    if (i == 0)
        return cnsts.first;
    else if (i != 0 && i != length)
        return cnsts.middle;
    else
        return cnsts.last;
}

export const GenerateBorderStyles = (columnIndex, rowPosition, totalRows, rules) => {
    let className = GetCellStyleClassName(rowPosition, totalRows - 1);
    let columnRule = rules[columnIndex];

    if (!columnRule) {
        return cnsts.basicBorder;
    }

    return columnRule[className];
};

export const ExtractValue = (val, numFmt) => {
	let value = val;
	if (IsValueAnObject(val))
		value = val.value;
    if (value == null || value == "")
        return "";
    if (numFmt)
        return parseFloat(value);
    return value.toString();
}

export const IsValueAnObject = (val) => {
	return (!!val && typeof val === 'object');
}

export const GenerateRows = (data, styleRules) => {
    var rows = [];
    for (let i = 0; i < data.length; i++) {
        var row = [];
        Object.keys(data[i]).forEach((key, columnIndex) => {
            var item = data[i];
            var numFmt = styleRules[columnIndex]?.numFmt;
            row.push({
                value: ExtractValue(item[key], numFmt),
                style: {
                    border: GenerateBorderStyles(columnIndex, i, data.length, styleRules),
                    numFmt: numFmt
                },
            });
        });
        rows.push(row);
    }

    return rows;
};