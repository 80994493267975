import React from "react";
import { Button, ButtonProps, styled } from '@mui/material';

interface ITextButtonComponent extends ButtonProps {
    selected: boolean
}

const StyledButton = styled(Button)((props: { selected?: boolean }) => ({
    borderRadius: 0,
    borderBottom: props.selected ? "1px solid #0091DC" : "1px solid transparent",
    color: props.selected ? "#0091DC" : "black",
    textTransform: "capitalize",
    justifyContent: "start",
    backgroundColor: "transparent",
    '&:hover': {
        borderBottom: "1px solid #0091DC",
        backgroundColor: "transparent",
    }
})) as typeof Button;

export default function TextButtonComponent(props: ITextButtonComponent) {
    const { ...rest } = props;
    return (<StyledButton  {...rest} />);
}