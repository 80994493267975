import { IUser, IUserRights } from "../../store/UserStore";

export const HasUserRole = (roles: string[] | undefined, user: IUser | undefined) => {
    if (roles == undefined || Object.keys(roles).length == 0)
        return true;
    else if (user == undefined || Object.keys(user).length == 0)
        return false;

    const containsRole = roles.some(r =>
        user.userRights != null && user.userRights[r as keyof(IUserRights)]);

    return containsRole;
}