import React from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Grid,
    Paper,
    styled
} from '@mui/material';
import * as Fmt from '../../helper/Formatters'

const StyledTableContainer = styled(TableContainer)({
    maxWidth: '700px',
    '& .gap-row': {
        height: '5rem',
        verticalAlign: 'bottom'
    }
});

export default function AnnualSavingTable(props) {
    const { t } = useTranslation();
    const { country } = useParams();
    const { AnnualSavings } = props

    return (<>
        {Object.keys(AnnualSavings).length > 0 ?
            <Grid container spacing={5} direction="column">
                <Grid item>
                    <StyledTableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead />
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">{t('ServiceLifeOfEquipment')}</TableCell>
                                    <TableCell id="td_AnnualSavings-serviceLife">{Fmt.round(AnnualSavings.serviceLife, country, 0)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">{t('AnnualFiscalDeprecation') + Fmt.getCurrency()}</TableCell>
                                    <TableCell id="td_AnnualSavings-fiscalDeprecation">{Fmt.round(AnnualSavings.fiscalDeprecation, country, 0)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">{t('TaxableSavings') + Fmt.getCurrency()}</TableCell>
                                    <TableCell id="td_AnnualSavings-taxSaving">{Fmt.round(AnnualSavings.taxSaving, country, 0)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row"><b>{t('TaxOnProfit')} {AnnualSavings.settingsTaxOnProfit}%{Fmt.getCurrency()}</b></TableCell>
                                    <TableCell id="td_AnnualSavings-taxOnProfit"><b>{Fmt.round(AnnualSavings.taxOnProfit, country, 0)}</b></TableCell>
                                </TableRow>
                                <TableRow className='gap-row'>
                                    <TableCell component="th" scope="row">{t('LeakageCostsPerYear') + Fmt.getCurrency()}</TableCell>
                                    <TableCell id="td_AnnualSavings-costPerYear">{Fmt.round(AnnualSavings.costPerYear, country, 0)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">{t('TaxOnProfit')} {AnnualSavings.settingsTaxOnProfit}%{Fmt.getCurrency()}</TableCell>
                                    <TableCell id="td_AnnualSavings-taxOnProfit">{Fmt.round(AnnualSavings.taxOnProfit, country, 0)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row"><b>{t('AnnualSavingsAfterTaxes') + Fmt.getCurrency()}</b></TableCell>
                                    <TableCell id="td_AnnualSavings-annualSavingsAfterTaxes"><b>{Fmt.round(AnnualSavings.annualSavingsAfterTaxes, country, 0)}</b></TableCell>
                                </TableRow>
                                <TableRow className='gap-row'>
                                    <TableCell component="th" scope="row" ><b>{t('PaybackTime')}</b></TableCell>
                                    <TableCell id="td_AnnualSavings-paybackTime" rowSpan={2} ><b>{Fmt.round(AnnualSavings.paybackTime, country, 1)} {t('Months')}</b></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </StyledTableContainer>
                </Grid>
            </Grid>
            : null}
    </>);
}