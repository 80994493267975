import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import * as Validators from '../../helper/Validator';
import { OpenGlobalSnackbar } from '../../helper/GlobalVariables';
import { API } from '../../helper/ApiHelper';
import { getExecutorLogo, getChartDomAsync } from '../../helper/Pdf';
import { ExecutorStore } from '../../store/AccountStore';
import domtoimage from 'dom-to-image';
import { Document, pdf } from '@react-pdf/renderer';
import * as PDF from '../PdfReport/PdfContent';
import { saveAs } from 'file-saver';
import ChartDialog from './ChartDialog';
import { ButtonComponent, SpinnerComponent } from '../../components/Reusable/';
import PdfReportForm from './PdfReportForm';
import { PdfReportFormModel, setTravelValidators } from './PdfReportFormModel';
import * as Mapper from '../../helper/Mappers';

export default function PdfReportLeakageEstimation(props) {
    const { rawInquiryModel, inquiryModel, setInquiryModel, compressorModel, finishClickEvent, isAnyServiceSelected } = props;
    const reqUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "request-management";

    const { t } = useTranslation();
    const { country, id } = useParams();

    const [isGeneratePressed, setIsGeneratePressed] = useState(false);
    const [pdfData, setPdfData] = useState({});
    const [charts, setCharts] = useState({});
    const [chartDialogOpen, setChartDialogOpen] = useState(false);
    const [formOpen, setFormOpen] = useState(false);
    const [formModel, setFormModel] = useState(PdfReportFormModel(t));

    useEffect(() => {
        let mappedModel = Mapper.mapDataToModelValues(rawInquiryModel, PdfReportFormModel(t));
        setFormModel(mappedModel);
    }, [rawInquiryModel])

    const leakageEstimation = async () => {
        let [isValid, validatedModel] = Validators.checkAndValidateModel(inquiryModel, true);
        setInquiryModel(validatedModel);
        let [isValidCompressors, validatedCompressors] = Validators.checkAndValidateModelArray(compressorModel);
        let isServiceSelectionValid = isAnyServiceSelected(validatedModel);
        if (validatedCompressors.length === 0)
            OpenGlobalSnackbar("danger", t('NoCompressorsError'));
        else if (isValid && isServiceSelectionValid && isValidCompressors) {
            await finishClickEvent(false, true);
            setFormOpen(true);
        } else
            OpenGlobalSnackbar("danger", t('NotAllTheFieldsAreFullfilled'));
    }

    const generatePdfReport = async () => {
        try {
            let model = setTravelValidators(formModel);
            let [isValid, validatedModel] = Validators.checkAndValidateModel(model, true);
            setFormModel(validatedModel);
            if (!isValid) {
                OpenGlobalSnackbar("danger", t('ModelInvalidError'));
                return;
            }
            setFormOpen(false);
            setIsGeneratePressed(true);
            let modelValues = Mapper.extractValuesFromModel(model);
            let dataPromise = API.post(`${reqUrl}/request/${id}/request-pdf-report`, modelValues, { isBackground: true });
            let executorLogoPromise = getExecutorLogo();

            let data = await dataPromise;
            data.executorLogo = await executorLogoPromise;
            let executorSettings = ExecutorStore.executorSettings.value;
            data.pageSize = executorSettings?.printSize?.value ?? "A4";
            setPdfData(data);

            setChartDialogOpen(true);
            await new Promise((resolve) => { setTimeout(() => resolve(), 5000) }) // wait for chart dialog to draw charts
            let leakagePaybackDom = await getChartDomAsync("leakage-analysis-payback-chart", 100, 100);
            charts["leakagePayback"] = await domtoimage.toPng(leakagePaybackDom);
            setCharts({ ...charts });

            let tocValues = {
                "leakageAnalysis": 2,
                "categories": { "isLeakageAnalysisSelected": true },
                "tocNumbering": { "leakageAnalysis": 2 }
            };
            let blobPdf = await pdf(pdfDocument(tocValues, data));
            blobPdf.updateContainer(pdfDocument(tocValues, data));
            await blobPdf.toBlob();
            blobPdf = await pdf(pdfDocument(tocValues, data));
            blobPdf.updateContainer(pdfDocument(tocValues, data));
            let pdfBlobResponse = await blobPdf.toBlob();
            saveAs(pdfBlobResponse, "FESS_leakage_estimation.pdf");

            OpenGlobalSnackbar("success", t('PdfGeneratedSuccessfully'));
        } catch {
            OpenGlobalSnackbar("danger", t('ErrorGeneratingPdf'));
        } finally {
            setIsGeneratePressed(false);
            setChartDialogOpen(false);
        }
    }

    const pdfDocument = (tocValues, data) =>
    (
        <Document>
            <PDF.CoverPdf
                inquiry={true}
                logo={data.executorLogo}
                data={data.project}
                pageSize={data.pageSize}
            />
            <PDF.TableOfContentPdf
                inquiry={true}
                tocValues={tocValues}
                data={data.project}
                pageSize={data.pageSize}
            />
            {tocValues.categories.isLeakageAnalysisSelected &&
                <PDF.LeakageAnalysisPdf
                    inquiry={true}
                    tocValues={tocValues}
                    chart={charts.leakagePayback}
                    data={data.project}
                    calcs={data.leakageAnalysisCalcResult}
                    pageSize={data.pageSize}
                    country={country}
                />
            }
        </Document>
    );

    return (
        <React.Fragment>
            <SpinnerComponent open={isGeneratePressed} />
            <ChartDialog open={chartDialogOpen} data={pdfData} />
            <PdfReportForm
                open={formOpen}
                setOpen={setFormOpen}
                model={formModel}
                setModel={setFormModel}
                generatePdfReport={generatePdfReport}
            />
            <ButtonComponent
                id='INQUiRY-leakage-estimation'
                value={t('LeakageAnalysis')}
                color="primary"
                onChange={leakageEstimation}
            />
        </React.Fragment>
    )
}