import React from 'react';
import { useParams, useHistory } from "react-router-dom";
import { CountryParam, LanguageParam } from '../Types/Types';
import { ButtonComponent } from '../Reusable';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

export function ThankYou() {
    const history = useHistory();
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(true);
    const { country, lng } = useParams<CountryParam & LanguageParam>();
    const close = () => { setOpen(false); history.push(`/${country}/${lng}/`); }
    const ok = () => { setOpen(false); window.location.href = "https://www.festo.com/de/en/e/support/energy-saving-services-id_21636" }

    return (
        <div>
            <Dialog open={open} onClose={() => setOpen(false)} PaperProps={{ style: { padding: 20 } }} closeAfterTransition aria-labelledby="thankyou-modal-title">
                <DialogTitle>
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item id="thankyou-modal-headline">
                            <h2>{t('ThankYouPageHeadline')}</h2>
                        </Grid>
                        <Grid item>
                            <IconButton color="inherit" size="small" onClick={close} id="btn_Modal-Close">
                                <Close fontSize="small" />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container direction="column">
                        <Grid item pb={4}>
                            <p id="thankyou-modal-description">{t('ThankYouPageText')}</p>
                        </Grid>
                        <Grid item>
                            <p id="thankyou-modal-description2">{t('ThankYouPageText2')}</p>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container direction="row" justifyContent="flex-start">
                        <Grid item style={{ minWidth: 200 }}>
                            <ButtonComponent fullWidth value={t('HomePageFestoButton')} color="primary" id="btn_Yes" onChange={ok}></ButtonComponent>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
}
