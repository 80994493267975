// @ts-expect-error not converted yet
import { checkIsDate } from "../../../../helper/Validator";
import { ModelParam, Translate } from "../../../Types/Types";

const today = new Date();
const defautFrom = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());

export interface IReportFactory {
    toCO2Date: ModelParam<Date>,
    fromCO2Date: ModelParam<Date>,
    toReplacementDate: ModelParam<Date>,
    fromReplacementDate: ModelParam<Date>,
}

export const ReportFactory = (t: Translate): IReportFactory => ({
    toCO2Date: { label: t("ToDate"), idkey: "To-Date", value: today, isValid: true, validationError: t("Error"), validators: [checkIsDate] },
    fromCO2Date: { label: t("FromDate"), idkey: "From-Date", value: defautFrom, isValid: true, validationError: t("Error"), validators: [checkIsDate] },
    toReplacementDate: { label: t("ToDate"), idkey: "To-Date", value: today, isValid: true, validationError: t("Error"), validators: [checkIsDate] },
    fromReplacementDate: { label: t("FromDate"), idkey: "From-Date", value: defautFrom, isValid: true, validationError: t("Error"), validators: [checkIsDate] }
})