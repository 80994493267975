import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import ImageViewer from '../ImageViewer/ImageViewer';
import { Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, TablePagination, IconButton, Link, TextareaAutosize } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { HeadingComponent, ButtonComponent, ModalComponent, SimpleCheckbox, TableHeadingContent, TableHeadCells, LinkComponent } from '../Reusable';
import { API } from '../../helper/ApiHelper';
import * as DateHelper from '../../helper/DateHelper';
// @ts-expect-error not converted yet
import { ReplacementPartModel } from '../ReplacementParts/ReplacementPartModel';
import * as Fmt from '../../helper/Formatters'
import './LDAR.scss';
import * as ImageBlobConstants from '../ImageViewer/Constants';
// @ts-expect-error not converted yet
import { LeakageToExcel } from './Excel/LeakageToExcel';
// @ts-expect-error not converted yet
import PrintLeakagesButton from "./LeakagesPdf/PrintLeakagesButton";
// @ts-expect-error not converted yet
import { DetectedLeakagesChart, RepairProcessChart, CostsAndSavingsChart, CO2Chart } from './LDARCharts/LDARCharts';
import { OpenGlobalSnackbar, ScrollerToTag } from '../../helper/GlobalVariables';
import { FilterModel } from "../Reusable/TableHeadingContent/GlobalFilterModel"
import { useGlobalState } from 'state-pool';
import { LeakageModelFactory, LeakageCalculationsModelFactory } from './Leakage/LeakageModel'
// @ts-expect-error not converted yet
import MultiRepairedDialog from './MultiRepairedDialog/MultiRepairedDialog';
import { DirtyStore } from '../../store';
import { CountryParam, ILDARCaclResult, ILeakage, ILeakageExcelRow, ILeakageReplacementPart, IProjectLDAR, IProjectLDARResults, IReplacementPart, IdParam, IndLeakageCalcResult, LanguageParam, ModalType, ModalTypeInit } from '../Types/Types';

export default function LDAR(props: { denyEdit: boolean }) {
    const { denyEdit } = props;
    const ldUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "leakage-detection";
    const { country, lng, id } = useParams<CountryParam & LanguageParam & IdParam>();
    const { t } = useTranslation();
    const history = useHistory();

    const [data, setData] = useState<(ILeakage & IndLeakageCalcResult)[]>([]);
    const [replacementParts, setReplacementParts] = useState<ILeakageReplacementPart[]>([]);
    const [filterData, setFilterData] = useState<(ILeakage & IndLeakageCalcResult)[]>([]);
    const [LDAR, setLDAR] = useState<IProjectLDAR>({ leakages: [] });
    const [page, setPage] = useGlobalState("LDARPage" + id, { default: 0, persist: false });
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [checkedArray, setCheckedArray] = useState<number[]>([]);
    const [checkedArrayKey, setcheckedArrayKey] = useState<number[]>([]);
    const [ldarCalcResults, setldarCalcResults] = useState<ILDARCaclResult>({ indLeakageCalcResults: [] });
    const [excelData, setExcelData] = useState<ILeakageExcelRow[]>([]);
    const [multiRepairedDialogOpen, setMultiRepairedDialogOpen] = useState(false);
    const [modalModelDelete, setModalModelDelete] = useState<ModalType>(ModalTypeInit);
    const leakageModel = { ...LeakageModelFactory(t), ...LeakageCalculationsModelFactory(t) };
    const replacementPartModel = ReplacementPartModel(t);

    useEffect(() => {
        if (parseInt(id) > 0) {
            fetchData(true);
        }
    }, []);

    const fetchData = (initialValue?: boolean) => {
        try {
            API.get<IProjectLDARResults>(ldUrl + '/' + id).then(resp => {
                setExcelData(resp.leakageExcelData.rows)
                if (resp.ldar !== null) {
                    setLDAR(resp.ldar);
                }
                if (resp.ldar.leakages !== null) {
                    const leakagesWithCalcs = resp.ldar.leakages.map((leakage: ILeakage, index: number) => { return Object.assign({}, leakage, resp.ldarCalcResults.indLeakageCalcResults[index]) });
                    setData(leakagesWithCalcs);
                    setFilterData(leakagesWithCalcs);
                    const parts: (ILeakageReplacementPart)[] = resp.ldar.leakages.map((leakage: ILeakage) => leakage.replacementPartList.map((x: IReplacementPart) => { return { leakageId: leakage.id, leackageNumber: leakage.leackageNumber, ...x } })).flat();
                    setReplacementParts(parts);
                }
                setldarCalcResults(resp.ldarCalcResults);
                if (initialValue) ScrollerToTag();
            })
        } catch (error) {
            OpenGlobalSnackbar("danger", t('error'));
        }
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event?.target?.value, 10));
        setPage(0);
    };

    const postForm = () => {
        const dataUpload = {
            ProjectId: id,
            ProjectLDAR: LDAR
        };
        API.put(ldUrl + '/' + id, dataUpload).then(() => {
            DirtyStore.setIsDirty(false);
            OpenGlobalSnackbar("success", t('SaveSuccesfull'))
        });
        return true;
    };

    const clickCancel = () => {
        history.push("/" + country + "/" + lng + '/projectsandrequests');
    }

    const clickDelete = () => {
        if (checkedArray == undefined || checkedArray.length == 0)
            OpenGlobalSnackbar("danger", t('NoEntriesCheckedError'));
        else
            clickDeleteOpenModal();
    }

    const clickMultiRepaired = () => {
        if (checkedArray == undefined || checkedArray.length == 0)
            OpenGlobalSnackbar("danger", t('NoEntriesCheckedError'));
        else
            setMultiRepairedDialogOpen(true);
    }

    const clickDeleteOpenModal = () => {
        setModalModelDelete({
            open: true,
            okText: t('Yes'),
            cancelText: t('No'),
            message: t('DeleteSelectedRowsQuestion'),
            clickOk: deleteRequest
        });
    }

    const handleCheck = (checkId: number, checkIndex: number) => {
        const isAlready = checkedArray.indexOf(checkId);
        const isAlreadyKEY = checkedArray.indexOf(checkIndex);
        if (isAlready < 0) {
            checkedArray.push(checkId);
            checkedArrayKey.push(checkIndex);
        }
        else {
            checkedArray.splice(isAlready, 1);
            checkedArrayKey.splice(isAlreadyKEY, 1);
        }
        checkedArray.sort(function (a, b) { return b - a });
        checkedArrayKey.sort(function (a, b) { return b - a });
        setCheckedArray(checkedArray);
        setcheckedArrayKey(checkedArrayKey);
    }

    const deleteRequest = async () => {
        const deleteData = async () => {
            const dataUpload = {
                projectId: id,
                Ids: checkedArray
            };
            API.post(`${ldUrl}/${id}/Leakage`, dataUpload).then(() => {
                checkedArrayKey.forEach(ite => data.splice(ite, 1));
                OpenGlobalSnackbar("success", t("DeleteSuccesfull"));
                fetchData();
            })
        };
        deleteData();
    };

    const filterFunction = (tempdata: Array<ILeakage & IndLeakageCalcResult>) => {
        setData(tempdata);
        setPage(0);
    }

    const getAddLdarLink = (leakageId: number) => {
        return "/" + country + "/" + lng + '/project/' + id + '/LDAR/Leakage/' + leakageId + '#Tabs';
    }

    const pushAddLdarLinkToHistory = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, leakageId: number) => {
        e.preventDefault();
        const link = getAddLdarLink(leakageId);
        history.push(link);
    }

    const clearTableSelection = () => {
        setCheckedArray([]);
        setcheckedArrayKey([]);
    }
    const clickMassEdit = () => {
        window.location.href = `/${country}/${lng}/project/${id}/MassEditLDAR`;
    }

    return (
        <div className="ldar table-layout-fix">
            <ModalComponent model={modalModelDelete} />
            <MultiRepairedDialog open={multiRepairedDialogOpen} setOpen={setMultiRepairedDialogOpen} leakageIds={checkedArray} fetchData={fetchData} clearTableSelection={clearTableSelection} />
            <Grid container spacing={5} direction="column">
                <Grid item xs={12} id="#CostsAndSavingofTheLeakages">
                    <HeadingComponent value={t('CostsAndSavingofTheLeakages')} size="h6" />
                </Grid>
                <Grid item xs={12}>
                    {Object.keys(ldarCalcResults).length > 0 &&
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell component="th" ><b>{t('DetectedLeakages')}</b></TableCell>
                                        <TableCell component="th" ><b>{t('RepairedLeakages')}</b></TableCell>
                                        <TableCell component="th" ><b>{t('OpenLeakages')}</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>{t('Leakages') + Fmt.getUnitInBracket("volumeFlowRate", t)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageFlowTotal">{Fmt.round(ldarCalcResults.leakageAirFlowTOTAL, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageFlowRepairedTOTAL">{Fmt.round(ldarCalcResults.leakageFlowRepairedTOTAL, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageFlowOpenTOTAL">{Fmt.round(ldarCalcResults.leakageFlowOpenTOTAL, country, 0)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{t('AirVolume') + Fmt.getUnitInBracket("cubicMeter", t)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageAirVolumeTOTAL">{Fmt.round(ldarCalcResults.leakageAirVolumeTOTAL, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageAirVolumeRepairedTOTAL">{Fmt.round(ldarCalcResults.leakageAirVolumeRepairedTOTAL, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageAirVolumeOpenTOTAL">{Fmt.round(ldarCalcResults.leakageAirVolumeOpenTOTAL, country, 0)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{t('LeakageLevel') + Fmt.getPercentage()}</TableCell>
                                        <TableCell id="td_LDAR-LeakageDetectedPercentageTOTAL">{Fmt.round(ldarCalcResults.leakageDetectedPercentageTOTAL, country, 1)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageRepairedPercentageTOTAL">{Fmt.round(ldarCalcResults.leakageRepairedPercentageTOTAL, country, 1)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageOpenPercentageTOTAL">{Fmt.round(ldarCalcResults.leakageOpenPercentageTOTAL, country, 1)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{t('ElectricityConsumption') + Fmt.getUnitInBracket("powerPerHour", t)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageElectricityConsumptionTOTAL">{Fmt.round(ldarCalcResults.leakageElectricityConsumptionTOTAL, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageElectricityConsumptionRepairedTOTAL">{Fmt.round(ldarCalcResults.leakageElectricityConsumptionRepairedTOTAL, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageElectricityConsumptionOpenTOTAL">{Fmt.round(ldarCalcResults.leakageElectricityConsumptionOpenTOTAL, country, 0)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{t('CO2PerYear') + Fmt.getUnitInBracket("co2Wheight", t)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageCO2TOTAL">{Fmt.round(ldarCalcResults.leakageCO2TOTAL, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageCO2RepairedTOTAL">{Fmt.round(ldarCalcResults.leakageCO2RepairedTOTAL, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageCO2OpenTOTAL">{Fmt.round(ldarCalcResults.leakageCO2OpenTOTAL, country, 0)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{t('CostPerYear') + Fmt.getCurrency()}</TableCell>
                                        <TableCell id="td_LDAR-LeakageDetectedCostsTOTAL">{Fmt.round(ldarCalcResults.leakageDetectedCostsTOTAL, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageRepairedCostsTOTAL">{Fmt.round(ldarCalcResults.leakageRepairedCostsTOTAL, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageOpenCostsTOTAL">{Fmt.round(ldarCalcResults.leakageOpenCostsTOTAL, country, 0)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>}
                </Grid>
                <Grid item xs={12} id="#QuantityOfDetectedLeakages">
                    <HeadingComponent value={t('QuantityOfDetectedLeakages')} size="h6" />
                </Grid>
                <Grid item xs={12} >
                    {Object.keys(ldarCalcResults).length > 0 &&
                        <TableContainer component={Paper}>
                            <Table className="detected-leakage-table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell align="center" className="side-borders" colSpan={2}><b>{t('Tags')}</b></TableCell>
                                        <TableCell align="center" className="side-borders" colSpan={3}><b>{t('AirFlow')}</b></TableCell>
                                        <TableCell align="center" className="side-borders" colSpan={2}><b>{t('RepairedLeakages')}</b></TableCell>
                                        <TableCell align="center" className="side-borders" colSpan={2}><b>{t('RepairTime')}</b></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell align="center" className="left-border"><b>{t('Quantity')}</b></TableCell>
                                        <TableCell align="center" className="right-border"><b>{t('%')}</b></TableCell>
                                        <TableCell align="center"><b>{t('Detected') + Fmt.getUnitInBracket("volumeFlowRate", t)}</b></TableCell>
                                        <TableCell align="center"><b>{t('CostPerYear') + Fmt.getCurrency()}</b></TableCell>
                                        <TableCell align="center" className="right-border"><b>{t('%')}</b></TableCell>
                                        <TableCell align="center"><b>{t('Done')}</b></TableCell>
                                        <TableCell align="center" className="right-border"><b>{t('Open')}</b></TableCell>
                                        <TableCell align="center"><b>{t('Repaired')}</b></TableCell>
                                        <TableCell align="center"><b>{t('Estimated')}</b></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{t('HighLeakages')}</TableCell>
                                        <TableCell id="td_LDAR-LeakageQuantityHigh" align="center" className="left-border">{Fmt.round(ldarCalcResults.leakageQuantityHigh, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakagePercentageHigh" align="center" className="right-border">{Fmt.round(ldarCalcResults.leakagePercentageHigh, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageAirFlowHigh" align="center">{Fmt.round(ldarCalcResults.leakageAirFlowHigh, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageCostsHigh" align="center">{Fmt.round(ldarCalcResults.leakageCostsHigh, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageAirFlowPercentageHigh" align="center" className="right-border">{Fmt.round(ldarCalcResults.leakageAirFlowPercentageHigh, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageQuantityRepairedHigh" align="center">{Fmt.round(ldarCalcResults.leakageQuantityRepairedHigh, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageQuantityOpenHigh" align="center" className="right-border">{Fmt.round(ldarCalcResults.leakageQuantityOpenHigh, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageQuantityRepairTimeRepairedHigh" align="center">{Fmt.round(ldarCalcResults.leakageQuantityRepairTimeRepairedHigh, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageQuantityRepairTimeEstimatedHigh" align="center">{Fmt.round(ldarCalcResults.leakageQuantityRepairTimeEstimatedHigh, country, 0)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{t('MediumLeakages')}</TableCell>
                                        <TableCell id="td_LDAR-LeakageQuantityMedium" align="center" className="left-border">{Fmt.round(ldarCalcResults.leakageQuantityMedium, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakagePercentageMedium" align="center" className="right-border">{Fmt.round(ldarCalcResults.leakagePercentageMedium, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageAirFlowMedium" align="center">{Fmt.round(ldarCalcResults.leakageAirFlowMedium, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageLeakageCostsMedium" align="center">{Fmt.round(ldarCalcResults.leakageCostsMedium, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageAirFlowPercentageMedium" align="center" className="right-border">{Fmt.round(ldarCalcResults.leakageAirFlowPercentageMedium, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageQuantityRepairedMedium" align="center">{Fmt.round(ldarCalcResults.leakageQuantityRepairedMedium, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageQuantityOpenMedium" align="center" className="right-border">{Fmt.round(ldarCalcResults.leakageQuantityOpenMedium, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageQuantityRepairTimeRepairedMedium" align="center">{Fmt.round(ldarCalcResults.leakageQuantityRepairTimeRepairedMedium, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageQuantityRepairTimeEstimatedMedium" align="center">{Fmt.round(ldarCalcResults.leakageQuantityRepairTimeEstimatedMedium, country, 0)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{t('LowLeakages')}</TableCell>
                                        <TableCell id="td_LDAR-LeakageQuantityLow" align="center" className="left-border">{Fmt.round(ldarCalcResults.leakageQuantityLow, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakagePercentageLow" align="center" className="right-border">{Fmt.round(ldarCalcResults.leakagePercentageLow, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageAirFlowLow" align="center">{Fmt.round(ldarCalcResults.leakageAirFlowLow, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageLeakageCostsLow" align="center">{Fmt.round(ldarCalcResults.leakageCostsLow, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageAirFlowPercentageLow" align="center" className="right-border">{Fmt.round(ldarCalcResults.leakageAirFlowPercentageLow, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageQuantityRepairedLow" align="center">{Fmt.round(ldarCalcResults.leakageQuantityRepairedLow, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageQuantityOpenLow" align="center" className="right-border">{Fmt.round(ldarCalcResults.leakageQuantityOpenLow, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageQuantityRepairTimeRepairedLow" align="center">{Fmt.round(ldarCalcResults.leakageQuantityRepairTimeRepairedLow, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageQuantityRepairTimeEstimatedLow" align="center">{Fmt.round(ldarCalcResults.leakageQuantityRepairTimeEstimatedLow, country, 0)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{t('Optimizations')}</TableCell>
                                        <TableCell id="td_LDAR-LeakageQuantityOptimizations" align="center" className="left-border">{Fmt.round(ldarCalcResults.leakageQuantityOptimizations, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakagePercentageOptimizations" align="center" className="right-border">{Fmt.round(ldarCalcResults.leakagePercentageOptimizations, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageAirFOptimizationsOptimizations" align="center">{Fmt.round(ldarCalcResults.leakageAirFlowOptimizations, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageLeakageCostsOptimizations" align="center">{Fmt.round(ldarCalcResults.leakageCostsOptimization, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageAirFOptimizationsPercentageOptimizations" align="center" className="right-border">{Fmt.round(ldarCalcResults.leakageAirFlowPercentageOptimizations, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageQuantityRepairedOptimizations" align="center">{Fmt.round(ldarCalcResults.leakageQuantityRepairedOptimizations, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageQuantityOpenOptimizations" align="center" className="right-border">{Fmt.round(ldarCalcResults.leakageQuantityOpenOptimizations, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageQuantityRepairTimeRepairedOptimizations" align="center">{Fmt.round(ldarCalcResults.leakageQuantityRepairTimeRepairedOptimizations, country, 0)}</TableCell>
                                        <TableCell id="td_LDAR-LeakageQuantityRepairTimeEstimatedOptimizations" align="center">{Fmt.round(ldarCalcResults.leakageQuantityRepairTimeEstimatedOptimizations, country, 0)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><b>{t('TotalTags')}</b></TableCell>
                                        <TableCell id="td_LDAR-LeakageQuantityTOTAL" align="center" className="left-border"><b>{Fmt.round(ldarCalcResults.leakageQuantityTOTAL, country, 0)}</b></TableCell>
                                        <TableCell id="td_LDAR-100" align="center" className="right-border"><b>100%</b></TableCell>
                                        <TableCell id="td_LDAR-LeakageAirFlowTOTAL" align="center"><b>{Fmt.round(ldarCalcResults.leakageAirFlowTOTAL, country, 0)}</b></TableCell>
                                        <TableCell id="td_LDAR-LeakageLeakageCostsTOTAL" align="center"><b>{Fmt.round(ldarCalcResults.leakageDetectedCostsTOTAL, country, 0)}</b></TableCell>
                                        <TableCell id="td_LDAR-100" align="center" className="right-border"><b>100%</b></TableCell>
                                        <TableCell id="td_LDAR-LeakageQuantityRepairedTOTAL" align="center"><b>{Fmt.round(ldarCalcResults.leakageQuantityRepairedTOTAL, country, 0)}</b></TableCell>
                                        <TableCell id="td_LDAR-LeakageQuantityOpenTOTAL" align="center" className="right-border"><b>{Fmt.round(ldarCalcResults.leakageQuantityOpenTOTAL, country, 0)}</b></TableCell>
                                        <TableCell id="td_LDAR-LeakageQuantityRepairTimeRepairedTOTAL" align="center"><b>{Fmt.round(ldarCalcResults.leakageQuantityRepairTimeRepairedTOTAL, country, 0)}</b></TableCell>
                                        <TableCell id="td_LDAR-LeakageQuantityRepairTimeEstimatedTOTAL" align="center"><b>{Fmt.round(ldarCalcResults.leakageQuantityRepairTimeEstimatedTOTAL, country, 0)}</b></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>}
                </Grid>
                <Grid item xs={12} id="#LeakageDetection">
                    <HeadingComponent value={t('ListOfLeakages')} size="h6" />
                </Grid>
                <Grid item xs={12}>
                    <TableHeadingContent
                        clickMassEdit={clickMassEdit}
                        clickMultiRepaired={clickMultiRepaired}
                        clickDelete={clickDelete}
                        clickAdd={() => history.push(getAddLdarLink(0))}
                        defaultData={data}
                        filterData={filterData}
                        onFilterFunction={filterFunction}
                        filterModel={FilterModel.LDAR}
                        denyEdit={denyEdit}
                        tableId={"LDAR"}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                {!denyEdit && <TableCell></TableCell>}
                                <TableHeadCells
                                    model={leakageModel}
                                    propertyList={["leackageNumber", "measurementId", "leakageClasification", "leakageRepearableProdTime", "leakageAction", "leakageRepaired", "costPerYear", "building", "department", "machine", "detectedDate"]}
                                    styleList={[{ className: "font-bold" }, { className: "font-bold" }, { className: "font-bold" }, { className: "font-bold" }, { className: "font-bold" }, { className: "font-bold" }, { className: "font-bold" }, { className: "font-bold" }, { className: "font-bold" }, { className: "font-bold" }, { className: "font-bold" }]}
                                    tableName="ListOfLeakages"
                                    data={data}
                                    setData={setData}
                                />
                                <TableCell></TableCell>
                            </TableHead>
                            {data && data.length > 0 ?
                                <TableBody>
                                    {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item: ILeakage & IndLeakageCalcResult, index: number) =>
                                        <TableRow key={'leakages' + item.leackageNumber + index}>
                                            {
                                                !denyEdit &&
                                                <TableCell>
                                                    <SimpleCheckbox
                                                        id={'leakage-checkbox' + page * rowsPerPage + index}
                                                        key={Math.random()}
                                                        color="default"
                                                        defaultChecked={checkedArray.includes(item.id)}
                                                        onChange={() => handleCheck(item.id, index)}
                                                    />
                                                </TableCell>
                                            }
                                            <TableCell id={"td_LDAR-LeackageNumber" + index}>{item.leackageNumber}</TableCell>
                                            <TableCell id={"td_LDAR-MeasurementId" + index}>
                                                <LinkComponent
                                                    link={getAddLdarLink(item.id)}
                                                    innerComponent={item.measurementId}
                                                />
                                            </TableCell>
                                            <TableCell id={"td_LDAR-LeakageOptimization" + index} className={item.leakageClasification == "LeakageLow" ? "LDAR-LeakageLow" : item.leakageClasification == "LeakageMedium" ? "LDAR-LeakageMedium" : item.leakageClasification == "LeakageHigh" ? "LDAR-LeakageHigh" : item.leakageClasification == "LeakageOptimization" ? "LDAR-LeakageOptimization" : ""}>{t(item.leakageClasification)}</TableCell>
                                            <TableCell id={"td_LDAR-LeakageRepearableProdTime" + index}>{item.leakageRepearableProdTime ? t('Yes') : t('No')}</TableCell>
                                            <TableCell id={"td_LDAR-LeakageAction" + index}>{item.leakageAction && t(item.leakageAction.value)}</TableCell>
                                            <TableCell id={"td_LDAR-LeakageRepaired" + index}>{item.leakageRepaired ? t('Yes') : t('No')}</TableCell>
                                            <TableCell id={"td_LDAR-LeakageOpenCosts" + index}>{Fmt.round(item.costPerYear, country, 0)}</TableCell>
                                            <TableCell id={"td_LDAR-Building" + index}>{item.building}</TableCell>
                                            <TableCell id={"td_LDAR-Department" + index}>{item.department}</TableCell>
                                            <TableCell id={"td_LDAR-Machine" + index}>{item.machine}</TableCell>
                                            <TableCell id={"td_LDAR-DetectedDate" + index}>{DateHelper.FormatDate(item.detectedDate)}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    id={"leakage-edit" + page * rowsPerPage + index}
                                                    href={getAddLdarLink(item.id)}
                                                    onClick={(e) => pushAddLdarLinkToHistory(e, item.id)}
                                                    size="large"
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                                :
                                <TableBody><TableRow><TableCell>No data</TableCell></TableRow></TableBody>}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 20, 25]}
                        component="div"
                        count={data && data.length > 0 ? data.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="space-between" className="header-margin">
                        <Grid item id="#ReplacementParts">
                            <HeadingComponent value={t('ReplacementParts')} size="h6" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell colSpan={4} align="center">
                                        <b>{t('DefectedDevice')}</b>
                                    </TableCell>
                                    <TableCell colSpan={3} align="center">
                                        <b>{t('ReplacementDevice')}</b>
                                    </TableCell>
                                </TableRow>
                                <TableHeadCells
                                    model={replacementPartModel}
                                    propertyList={["leackageNumber", "partNumber", "typeCode", "component", "manufacturer", "partNumber", "newTypeCode", "newManufacturer"]}
                                    styleList={[{ className: "font-bold table-rightborder" }, { className: "font-bold" }, { className: "font-bold" }, { className: "font-bold" }, { className: "font-bold table-rightborder" }, { className: "font-bold" }, { className: "font-bold" }, { className: "font-bold" }]}
                                    tableName="ReplacementParts"
                                    data={replacementParts}
                                    setData={setReplacementParts}
                                />
                            </TableHead>
                            {data && data.length > 0 ?
                                <TableBody>
                                    {replacementParts.filter(x => data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item: ILeakage & IndLeakageCalcResult) => item.id).includes(x.leakageId)).map((part: ILeakageReplacementPart, partIndex: number) =>
                                        <TableRow key={'leakageparts' + part.partNumber + partIndex}>
                                            <TableCell id={"td_LDAR-LeackageNumber" + partIndex} className="table-rightborder">
                                                <Link
                                                    href={getAddLdarLink(part.leakageId)}
                                                    onClick={(e) => { pushAddLdarLinkToHistory(e, part.leakageId) }}
                                                    underline="hover"
                                                >
                                                    {part.leackageNumber}
                                                </Link>
                                            </TableCell>
                                            <TableCell id={"td_LDAR-partNumber" + partIndex}>{part.partNumber}</TableCell>
                                            <TableCell id={"td_LDAR-typeCode" + partIndex}>{part.typeCode}</TableCell>
                                            <TableCell id={"td_LDAR-component" + partIndex}>{part.component ? t(part.component.value) : null}</TableCell>
                                            <TableCell id={"td_LDAR-manufacturer" + partIndex} className="table-rightborder">{part.manufacturer}</TableCell>
                                            <TableCell id={"td_LDAR-newPartNumber" + partIndex}>{part.replaceWithDifferent ? part.newPartNumber : part.partNumber}</TableCell>
                                            <TableCell id={"td_LDAR-newTypeCode" + partIndex}>{part.replaceWithDifferent ? part.newTypeCode : part.typeCode}</TableCell>
                                            <TableCell id={"td_LDAR-newManufacturer" + partIndex}>{part.replaceWithDifferent ? part.newManufacturer : part.manufacturer}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                                : <TableBody><TableRow><TableCell>No data</TableCell></TableRow></TableBody>}
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} id="#Charts">
                    <Grid container direction="row" spacing={5}>
                        <Grid item xs={12} md={6} >
                            <Grid item md={6}>
                                <HeadingComponent value={t('QuantityOfDetectedLeakages')} size="h6" />
                            </Grid>
                            <Grid item md={9}>
                                <DetectedLeakagesChart data={ldarCalcResults} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <Grid item md={6}>
                                <HeadingComponent value={t('RepairProcess')} size="h6" />
                            </Grid>
                            <Grid item md={9}>
                                <RepairProcessChart data={ldarCalcResults} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <Grid item md={6}>
                                <HeadingComponent value={t('CostsAndSavingofTheLeakages')} size="h6" />
                            </Grid>
                            <Grid item md={9}>
                                <CostsAndSavingsChart data={ldarCalcResults} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <Grid item md={6}>
                                <HeadingComponent value={t('CO2EmmissionsAndSavings')} size="h6" />
                            </Grid>
                            <Grid item md={9}>
                                <CO2Chart data={ldarCalcResults} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <HeadingComponent value={t('Comment')} size="h6" />
                    <TextareaAutosize
                        id="ldar-comment"
                        //type="text"
                        value={LDAR.comment}
                        minRows="5"
                        onChange={e => {
                            const evalue = e.target.value;
                            LDAR.comment = evalue;
                            setLDAR({ ...LDAR });
                            DirtyStore.setIsDirty(postForm());
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ImageViewer groupId={ImageBlobConstants.LDAR} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="flex-end">
                        <Grid item><ButtonComponent id="LDAR-cancel" value={t('Cancel')} color="secondary" onChange={() => clickCancel()}></ButtonComponent></Grid>
                        <Grid item>
                            <PrintLeakagesButton leakageData={data} ldarCalcResults={ldarCalcResults} />
                        </Grid>
                        <Grid item>
                            <LeakageToExcel data={excelData}></LeakageToExcel>
                        </Grid>
                        {!denyEdit && <Grid item><ButtonComponent id="LDAR-save" value={t('Save')} color="primary" onChange={postForm}></ButtonComponent></Grid>}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

