import _ from 'lodash';
import { validateSingleProperty } from './Validator';
import { v4 as uuidv4 } from 'uuid';
import * as Fmt from './Formatters';

export const updateModelUnitData = (property, value, model, setModel, defaultModel, ...args) => {
	Fmt.setUnitData({
		measurementSystem: property == "measurementSystem" ? value?.value : model?.measurementSystem?.value?.value ?? "Metric",
		currency: property == "currency" ? value : model?.currency?.value ?? "\u20AC"
	});
	let updatedModel = updateExistingModelFormattingLabels(model, defaultModel(...args));
	updatedModel = updateModel(property, value, model);
	setModel(updatedModel);
}

export const mapArrayDataToArrayModel = (rawData, model) => {
	let mappedArrayModel = [];
	rawData.forEach(row => {
		let mappedModel = mapDataToModelValues(row, model)
		mappedArrayModel.push(mappedModel);
	})
	return mappedArrayModel;
}

export const mapDataToModelValues = (source, targetModel) => {
	var model = _.cloneDeep(targetModel);
	Object.keys(source).forEach(key => {
		if (Object.prototype.hasOwnProperty.call(model, key)) {
			var target = model[key];
			target.value = source[key];
		}
	});
	return model;
}

export const mapDataToModelValuesRecursive = (source, targetModel) => {
	var model = _.cloneDeep(targetModel);
	Object.keys(source).forEach(key => {
		if (Object.prototype.hasOwnProperty.call(model, key)) {
			var target = model[key];
			if (Array.isArray(target.value)) {
				// Object array
				if (target.objectType) {
					source[key].forEach(item => target.value.push(mapDataToModelValuesRecursive(item, _.cloneDeep(target.objectType))));
				} else {
					target.value = source[key];
				}
			} else if (typeof target.value === 'object' && target.value !== null) {
				// Object
				target.value = mapDataToModelValuesRecursive(source[key], target.value);
			} else {
				// Property
				target.value = source[key];
			}
		}
	});
	return model;
}

export const extractValuesFromModelRecursive = (model) => {
	let tmpModel = {};
	Object.keys(model).forEach(key => {
		if (Array.isArray(model[key].value) && model[key].objectType) {
			// Object array
			tmpModel[key] = [];
			model[key].value.forEach(item => tmpModel[key].push(extractValuesFromModelRecursive(item)));
		} else if (typeof model[key].value === 'object' && model[key].value !== null) {
			// Object
			tmpModel[key] = extractValuesFromModelRecursive(model[key].value);
		} else {
			// Property
			tmpModel[key] = model[key].value;
		}
	});
	return tmpModel;
}

export const updateModel = (property, value, targetModel, setModel) => {
	var model = _.cloneDeep(targetModel);
	model[property].value = value;
	if (model[property].validators.length > 0) {
		model[property].isValid = true;
		let isValid = validateSingleProperty(model, property);
		model[property].isValid = isValid;
	}
	if (setModel != undefined)
		setModel(model);
	else
		return model;
}

export const updateSelectModel = (property, value, values, targetModel, setModel) => {
	var model = _.cloneDeep(targetModel);
	model[property].value = values.find(x => x.id == value);
	validateSingleProperty(model, property);
	setModel(model);
}

export const extractValuesFromModel = (model) => {
	let tmpModel = {};
	Object.keys(model).forEach(key => {
		tmpModel[key] = model[key].value;
	});
	return tmpModel;
}

export const extractValuesFromModelOfArray = (model) => {
	let tmpModel = []
	if (!model) return tmpModel;
	model.forEach(row => {
		let tmpModelRow = extractValuesFromModel(row)
		tmpModel.push(tmpModelRow)
	})

	return tmpModel;
}

export const objectArrayKeysToUpper = (array) => {
	let newArray = [];
	array.forEach(item => {
		let newItem = {};
		Object.keys(item).forEach(key => {
			newItem[key.charAt(0).toUpperCase() + key.slice(1)] = item[key] && typeof (item[key]) === "object" ? objectKeysToUpper(item[key]) : item[key];
		});
		newArray.push(newItem);
	});
	return newArray;
}

export const objectKeysToUpper = (item) => {
	let newItem = {};
	Object.keys(item).forEach(key => {
		newItem[key.charAt(0).toUpperCase() + key.slice(1)] = item[key];
	});
	return newItem;
}

export const mergeArrayModels = (arrayModel1, arrayModel2) => {
	let result = [];
	if (arrayModel1.length == arrayModel2.length) {
		arrayModel1.forEach((model1, index) => {
			let mergedModel = Object.assign(model1, arrayModel2[index])
			result.push(mergedModel);
		})
	}

	return result;
}

export const mapMathcingArrayDataToArrayModel = (rawData, model) => {
	let mappedArrayModel = [];
	rawData.forEach(row => {
		let mappedModel = mapMachingDataToModelValues(row, model)
		mappedArrayModel.push(mappedModel);
	})
	return mappedArrayModel;
}

export const mapMachingDataToModelValues = (source, targetModel) => {
	var model = _.cloneDeep(targetModel);
	Object.keys(model).forEach(key => {
		if (Object.prototype.hasOwnProperty.call(source, key)) {
			var target = model[key];
			if (Array.isArray(target.value) && target.objectType) {
				// Object array
				source[key].forEach(item => target.value.push(mapDataToModelValues(item, _.cloneDeep(target.objectType))));
			} else if (typeof target.value === 'object' && target.value !== null) {
				// Object
				target.value = mapDataToModelValues(source[key], target.value);
			} else {
				// Property
				target.value = source[key];
			}
		}
		else {
			delete model[key]
		}
	});
	return model;
}

export const translateDropdownValue = (values, value, t) => {
	if (values != undefined && value != undefined) {
		let foundValue = values.find(x => x.id == value.id).value;
		return t ? t(foundValue) : foundValue
	}
	return ''
}

export const updateExistingModelFormattingLabels = (existingModel, defaultModel, setFunction) => {
	if (existingModel != undefined && Object.keys(existingModel) != undefined && Object.keys(existingModel).length > 0) {
		Object.keys(defaultModel).forEach(key => {
			if (defaultModel[key].label != undefined)
				existingModel[key].label = defaultModel[key].label;
		});
		if (setFunction != undefined)
			setFunction({ ...existingModel });
		else
			return existingModel;
	}
}

export const updateArrayFormattingLabels = (existingArray, defaultModel, setFunction) => {
	if (existingArray != undefined && existingArray.length > 0) {
		existingArray.forEach(el => updateExistingModelFormattingLabels(el, defaultModel));
		if (setFunction != undefined) setFunction([...existingArray]);
	}
}

export const copyForm = async (model, setFunction) => {
	model.guid.value = uuidv4();
	model.id.value = 0;
	model.measurementId.value = "";
	setFunction(model);
}

export const getNewGuid = () => uuidv4();