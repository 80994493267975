import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Close } from '@mui/icons-material';
import { HeadingComponent, ButtonComponent, CheckboxComponent, AutocompleteComponent, DatePickerComponent, TextFieldComponent } from '../../Reusable/';
import * as Mapper from '../../../helper/Mappers';
import * as Validator from '../../../helper/Validator';
import { MultiRepairedModel } from './MultiRepairedModel';
import { ExecutorStore } from '../../../store';
import { API } from '../../../helper/ApiHelper';
import { OpenGlobalSnackbar } from '../../../helper/GlobalVariables';

export default function MultiRepairedDialog(props) {
    const url = process.env.REACT_APP_DEVFESSSERVICE_BASE + "leakage-detection/";
    const { open, setOpen, leakageIds, fetchData, clearTableSelection } = props;

    const { t } = useTranslation();
    let { id } = useParams();

    const [model, setModel] = useState(MultiRepairedModel(t));
    const [executorUsersOptions, setexecutorUsersOptions] = useState([]);

    useEffect(() => {
        let execSub = ExecutorStore.executorSettings.subscribe(executorSettings => {
            if (executorSettings && executorSettings.executorUsers) setexecutorUsersOptions(executorSettings.executorUsers);
        })
        return () => {
            execSub.unsubscribe();
        }
    }, []);

    const save = () => {
        let [isValid, validatedModel] = Validator.checkAndValidateModel(model, true);
        setModel(validatedModel);
        if (isValid)
            postForm();
        else
            OpenGlobalSnackbar("danger", t('PleaseVerifyYourEntries'));
    }

    const postForm = async () => {
        let postModel = Mapper.extractValuesFromModel(model);
        postModel["leakageIds"] = leakageIds;
        await API.post(`${url}${id}/mark-leakages-repaired`, postModel);
        OpenGlobalSnackbar("success", t("SaveSuccessful"));
        clearTableSelection();
        setOpen(false);
        fetchData();
    }

    return (
        <Dialog open={open}>
            <DialogTitle>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <HeadingComponent value={t("Repaired")} size="h6" />
                    </Grid>
                    <Grid item>
                        <IconButton color="inherit" onClick={() => setOpen(false)} size="large">
                            <Close fontSize="small" />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={3} direction="column">
                    <Grid item xs={12}>
                        <CheckboxComponent
                            model={model.leakageRepaired}
                            onChange={e => Mapper.updateModel("leakageRepaired", e.target.checked, model, setModel)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AutocompleteComponent
                            options={executorUsersOptions}
                            t={t}
                            model={model.repairedBy}
                            onInputChange={(e, value) => Mapper.updateModel("repairedBy", value, model, setModel)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DatePickerComponent
                            t={t}
                            model={model.repairDate}
                            onChange={e => Mapper.updateModel("repairDate", e, model, setModel)}
                            maxDate={new Date()}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldComponent
                            t={t}
                            model={model.leakageRepairTimeRepairedInput}
                            onChange={e => Mapper.updateModel("leakageRepairTimeRepairedInput", e.target.value, model, setModel)} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item><ButtonComponent value={t('Save')} color="primary" onChange={save}></ButtonComponent></Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}