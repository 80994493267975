import React from 'react';
import { Table, Grid, Box, Collapse, IconButton, ButtonGroup, Button, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, styled } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { CompressorModel } from './CompressorModel';
import { NumberInputFieldComponent, SingleSelectComponent, CheckboxComponent } from '../../../components/Reusable/';
import _ from 'lodash'
import { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import * as map from '../../../helper/Mappers';
import * as Fmt from '../../../helper/Formatters';
import * as InputRestrictions from '../../../helper/InputRestrictions';
import './CompressorTable.scss';
import { OpenGlobalSnackbar } from '../../../helper/GlobalVariables';
import { useTheme } from '@mui/material/styles';

const StyledForm = styled('form')(() => ({
    '& .MuiTextField-root': {
        margin: useTheme().spacing(3),
        width: '32ch',
    },
}));

function Row(props) {
    const { t } = useTranslation();
    let { country } = useParams();
    const { rows, id, setRows, controlTypes, compact } = props;
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        setOpen(rows[id].isEditOpen.value);
    }, [rows.length])

    const updateModel = (property, value) => {
        rows[id][property].value = value;
        if (rows[id][property].validators.length > 0) {
            rows[id][property].isValid = true;
            rows[id][property].validators.forEach(f => {
                if (f(rows[id][property].value) == false) {
                    rows[id][property].isValid = false;
                }
            })
        }
        setRows([...rows]);
    }

    const updateSelectModel = (property, value, values) => {
        rows[id][property].value = values.find(x => x.id == value);
        rows[id][property].isValid = true;
        if (rows[id][property].validators.length > 0) {
            rows[id][property].isValid = true;
            rows[id][property].validators.forEach(f => {
                if (f(rows[id][property].value) == false) {
                    rows[id][property].isValid = false;
                }
            })
        }
        setRows([...rows]);
    }

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>
                    <CheckboxComponent
                        id={'compressor-delete-checkbox' + id}
                        model={rows[id]["isChecked"]}
                        onChange={e => updateModel("isChecked", e.target.checked)}
                    />
                </TableCell>
                <TableCell component="th" scope="rows[id]">
                    <ButtonGroup variant="text button group" aria-label="split button">
                        <Button>{id + 1}</Button>
                        <IconButton id={'compressor-edit' + id} defaultValue="sd" aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </ButtonGroup>
                </TableCell>
                <TableCell align="center">{Fmt.round(rows[id]["nominalPower"].value, country, 2)}</TableCell>
                <TableCell align="center">{Fmt.round(rows[id]["freeAirDelivery"].value, country, 2)}</TableCell>
                <TableCell align="center">{Fmt.round(rows[id]["mondayToFridayWorkload"].value, country, 2)}</TableCell>
                <TableCell align="center">{Fmt.round(rows[id]["sathurdayWorkload"].value, country, 2)}</TableCell>
                <TableCell align="center">{Fmt.round(rows[id]["sundayWorkload"].value, country, 2)}</TableCell>
                <TableCell>{t(controlTypes.find(x => x.id == rows[id]["controlType"]?.value?.id)?.value)}</TableCell>
                <TableCell align="center">{Fmt.round(rows[id]["nominalPressure"].value, country, 2)}</TableCell>
                <TableCell align="center">{Fmt.round(rows[id]["setpointPressure"].value, country, 2)}</TableCell>
                {compact && <>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                </>}
            </TableRow>
            <TableRow style={{ background: 'whitesmoke' }}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={compact ? 13 : 11}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <StyledForm className={'compressor-form'} noValidate autoComplete="off">
                                <div>
                                    <NumberInputFieldComponent t={t} model={rows[id]["nominalPower"]} id={id + 'nominalPower'} name="nominalPower" onChange={e => {
                                        updateModel("nominalPower", InputRestrictions.Decimal(e.target.value))
                                    }} />
                                    <NumberInputFieldComponent t={t} model={rows[id]["freeAirDelivery"]} id={id + 'freeAirDelivery'} name="freeAirDelivery" onChange={e => {
                                        updateModel("freeAirDelivery", InputRestrictions.Decimal(e.target.value, 2))
                                    }} />
                                    <NumberInputFieldComponent t={t} model={rows[id]["mondayToFridayWorkload"]} id={id + 'mondayToFridayWorkload'} name="mondayToFridayWorkload" onChange={e => {
                                        updateModel("mondayToFridayWorkload", InputRestrictions.Decimal(e.target.value))
                                    }} />
                                    <NumberInputFieldComponent t={t} model={rows[id]["sathurdayWorkload"]} id={id + 'sathurdayWorkload'} name="sathurdayWorkload" onChange={e => {
                                        updateModel("sathurdayWorkload", InputRestrictions.Decimal(e.target.value))
                                    }} />
                                </div>
                                <div>
                                    <NumberInputFieldComponent t={t} model={rows[id]["sundayWorkload"]} id={id + 'sundayWorkload'} name="sundayWorkload" onChange={e => {
                                        updateModel("sundayWorkload", InputRestrictions.Decimal(e.target.value))
                                    }} />
                                    <NumberInputFieldComponent t={t} model={rows[id]["nominalPressure"]} id={id + 'nominalPressure'} name="nominalPressure" onChange={e => {
                                        updateModel("nominalPressure", InputRestrictions.Decimal(e.target.value, 2))
                                    }} />
                                    <NumberInputFieldComponent t={t} model={rows[id]["setpointPressure"]} id={id + 'setpointPressure'} name="setpointPressure" onChange={e => {
                                        updateModel("setpointPressure", InputRestrictions.Decimal(e.target.value, 2))
                                    }} />
                                    <SingleSelectComponent
                                        listFromDb={controlTypes}
                                        model={rows[id]["controlType"]}
                                        id={'controlType' + id}
                                        t={t}
                                        onChange={e => { updateSelectModel("controlType", e.target.value, controlTypes) }}
                                    />
                                </div>
                            </StyledForm>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function CompressorTable(props) {
    const { sendCompressorsToInquiryPage, compressorList, controlTypes, compact } = props;

    const { t } = useTranslation();
    const [rows, setRows] = React.useState([]);

    const deleteRow = () => {
        if (rows == undefined || rows.find(r => r.isChecked.value == true) == undefined) {
            OpenGlobalSnackbar("danger", t('NoEntriesCheckedError'));
            return;
        }
        const filteredRows = rows.filter(r => r.isChecked.value == false)
        setRows([...filteredRows])
        sendCompressorsToInquiryPage([...filteredRows]);
    }

    const validateCompressors = (compressors) => {
        let isValid = true;
        compressors.forEach(row => {
            Object.keys(row).forEach(prop => {
                if (row[prop].validators.length > 0) {
                    row[prop].isValid = true;
                    row[prop].validators.forEach(f => {
                        if (f(row[prop].value) == false) {
                            row[prop].isValid = isValid = false;
                        }
                    })
                }
            })
        })
        return { isValid, compressors };
    }

    function createCompressorRow() {
        let newModel = _.cloneDeep(CompressorModel(t));
        newModel.isEditOpen.value = true;
        newModel.no.value = rows.length + 1;
        return newModel;
    }

    const AddRow = () => {
        const { isValid, compressors } = validateCompressors(rows);
        if (isValid) {
            compressors.forEach(c => c.isEditOpen.value = false);
            sendCompressorsToInquiryPage([...compressors, createCompressorRow()])
        }
        else {
            sendCompressorsToInquiryPage(compressors)
        }
    }

    const updateRow = (rows) => {
        setRows(rows);
        sendCompressorsToInquiryPage(rows);
    }

    useEffect(() => {
        sendCompressorsToInquiryPage(rows);
    }, [rows.length])

    useEffect(() => {
        if (compressorList.length > 0) {
            setRows(compressorList);
        }
    }, [compressorList.length])

    useEffect(() => {
        map.updateArrayFormattingLabels(rows, CompressorModel(t), setRows);
    }, [])

    return (
        <React.Fragment>
            {!compact && <Grid container direction="row" justifyContent="flex-end">
                <IconButton id='compressor-delete' onClick={deleteRow} size="large">
                    <DeleteIcon fontSize="medium" />
                </IconButton>
                <IconButton id='compressor-add' onClick={AddRow} size="large">
                    <AddCircleIcon fontSize="medium" />
                </IconButton>
            </Grid>
            }
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="center">{t('Nr')}</TableCell>
                            <TableCell>{t("NominalPowerKW") + Fmt.getUnitInBracket("power", t)}</TableCell>
                            <TableCell>{t("FreeAirDeliveryFAD") + Fmt.getUnitInBracket("volumePerTime", t)}</TableCell>
                            <TableCell>{t("MondayToFridayWorkload") + Fmt.getPercentage()}</TableCell>
                            <TableCell>{t("SaturdayWorkload") + Fmt.getPercentage()}</TableCell>
                            <TableCell>{t("SundayWorkload") + Fmt.getPercentage()}</TableCell>
                            <TableCell>{t("ControlType")}</TableCell>
                            <TableCell>{t("NominalPressureCompression") + Fmt.getUnitInBracket("pressure", t)}</TableCell>
                            <TableCell>{t("SetpointPressure") + Fmt.getUnitInBracket("pressure", t)}</TableCell>
                            {compact && <>
                                <TableCell>
                                    <IconButton id='compressor-delete' onClick={deleteRow} size="large">
                                        <DeleteIcon fontSize="medium" />
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    <IconButton id='compressor-add' onClick={AddRow} size="large">
                                        <AddCircleIcon fontSize="medium" />
                                    </IconButton>
                                </TableCell>
                            </>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, id) => (
                            <Row
                                controlTypes={controlTypes}
                                key={row["No"]}
                                rows={rows}
                                id={id}
                                setRows={updateRow}
                                deleteRow={deleteRow}
                                compact={compact}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
}