import { MeasurementUnit, MeasurementUnits } from './GlobalVariables';
import { ProjectStore } from '../store';
import { IUnitData, Translate } from '../components/Types/Types';
import { CountryListResponse } from '../store/CountryListStore';

export let unitData: IUnitData = { measurementSystem: "Metric", currency: "\u20AC" }; //Default

ProjectStore.ProjectInfo.subscribe(projInfo => {
    if (Object.keys(projInfo).length > 0)
        unitData = projInfo.unitData ?? unitData;
})

export const setUnitData = (untData: IUnitData) => {
    unitData = untData;
}

export const setCurrency = (currency: string) => {
    unitData.currency = currency ?? "\u20AC";
}

export const setMeasurementSystem = (measurementSystem: string) => {
    unitData.measurementSystem = measurementSystem ?? "Metric";
}

export const round = (number: any, country = "de", fractionDigits = 0) => {
    if (isNaN(parseInt(number)) || isNaN(parseFloat(number))) return 0;
    const result = parseFloat(number).toLocaleString(country, { maximumFractionDigits: fractionDigits });
    return result.replace(/\s/g, ' ');
}

export const roundNumber = (number: any, fractionDigits = 0) => {
    if (isNaN(parseFloat(number)))
        return 0;
    else
        return Math.round(number * Math.pow(10, fractionDigits)) / Math.pow(10, fractionDigits);
}

export const getUnitInBracket = (unitName: string, t: Translate) => {
    const unit = getUnit(unitName, t);
    return addBrackets(unit, t);
}

export const getUnitWithCurrency = (unitName: string, t: Translate) => {
    const unit = getUnit(unitName, t);
    return " [" + unitData.currency + "/" + unit + "]";
}

export const getUnit = (unitName: string, t: Translate) => {
    if (!!unitData.measurementSystem && !!unitName && MeasurementUnits.some(x => x.key == unitName)) {
        const unitValue = MeasurementUnits.find(x => x.key == unitName);
        if (unitValue != undefined) {
            const val = unitValue[unitData.measurementSystem.toLowerCase() as keyof (MeasurementUnit)];
            return t(typeof val === 'number' ? val.toString() : val);
        }
    }
    return t(unitName);
}

export const addBrackets = (value: string, t: Translate) => {
    return " [" + t(value) + "]";
}

export const getCurrency = () => {
    return " [" + unitData.currency + "]";
}

export const getPercentage = () => {
    return " [%]";
}

export const replaceUnitString = (dropdownTargetString: string, t: Translate) => {
    if (dropdownTargetString != undefined) {
        const startPosion = dropdownTargetString.indexOf('{');
        const endPosion = dropdownTargetString.indexOf('}');
        if (startPosion > 0 && endPosion > 0 && endPosion - startPosion > 0) {
            const unitString = dropdownTargetString.slice(startPosion + 1, endPosion);
            const replaceString = dropdownTargetString.slice(startPosion, endPosion + 1);
            dropdownTargetString = dropdownTargetString.replace(replaceString, getUnit(unitString, t));
        }
    }
    return dropdownTargetString;
}

export const decodeImageName = (name: string) => {
    try {
        return decodeURIComponent(name);
    } catch {
        return name;
    }
}

export const CountryToPostalMask = (country: CountryListResponse | undefined) => {
    const maskArray = country?.countryPostalFormat?.split('|');
    if (maskArray !== undefined && maskArray?.length > 1) {
        return maskArray.map((e) => {
            if (e.includes('#') || e.includes('@')) {
                return {
                    mask: e,
                    lazy: true,
                    definitions: {
                        '#': /[0-9]/,
                        '@': /[a-zA-Z]/
                    }
                };
            }
            else {
                return {
                    mask: e.replace('0', '\\0'),
                    lazy: true,
                };
            }
        });
    }
    return (country?.countryPostalFormat ?? '************')
}