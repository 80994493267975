import React, { useEffect, useState } from 'react'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function ScrollComponent() {
    const [scrollPosition, setScrollPosition] = useState<boolean>(false);
    const myStyleBox: React.CSSProperties = {
        position: "fixed",
        bottom: "45px",
        right: "5px",
        width: "30px",
        height: "30px",
        zIndex: 9,
        backgroundColor: "#0091dc",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    }

    const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position > 1250 ? true : false);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            {scrollPosition ? <div style={myStyleBox} onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}><KeyboardArrowUpIcon /></div> : null}
        </div>
    )
}
