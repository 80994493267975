import React, { CSSProperties } from "react"
import { CheckboxProps, FormControlLabel, FormHelperText, styled } from '@mui/material';
import SimpleCheckbox from './SimpleCheckbox';
import { ModelParam } from "../../Types/Types";

const StyledSpan = styled('span')((props: CSSProperties) => ({
    fontSize: props.fontSize != undefined?  props.fontSize : '',
    font: "Normal 1rem/1.5rem Meta Pro",
    color: '#333333',
    opacity: 1,
    textAlign: 'left',
    marginBottom: 0,
}));

const StyledFormControlLabel = styled(FormControlLabel)({
    font: "Normal 1rem/1.5rem Meta Pro",
    color: '#333333',
    opacity: 1,
    textAlign: 'left',
    marginBottom: 0,
    marginRight: 4
}) as typeof FormControlLabel;

const ErrorFormHelperText = styled(FormHelperText)({
    position: 'absolute',
    color: '#D50000'
}) as typeof FormHelperText;


export interface ICheckboxComponent extends CheckboxProps {
    disabled?: boolean,
    model: ModelParam<boolean>,
    labelStyle?: CSSProperties,
    onMouseLeave?: () => void,
    onMouseEnter?: () => void
}

const CheckboxComponent = (props: ICheckboxComponent) => {
    const { model, style, labelStyle, disabled, onChange, onMouseLeave, onMouseEnter, ...rest } = props;
    const error = model.isValid !== false ? {} : { color: '#D50000' }
    const mergedStyles = style ? { ...style, ...error } : error;

    return (<>
        {model.isValid === false && <ErrorFormHelperText error={!model.isValid}>{model.validationError}</ErrorFormHelperText>}
        <StyledFormControlLabel
            control={
                <SimpleCheckbox
                    {...rest}
                    style={mergedStyles}
                    checked={model.value}
                    color="default"
                    onChange={disabled ? undefined : onChange}
                    id={props.id ? "cb_" + props.id : "cb_" + model.idkey}
                />
            }
            label={<StyledSpan onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} style={{...error, ...labelStyle}}>{model.label}</StyledSpan>}
        />
    </>);
}

export default CheckboxComponent;