// @ts-expect-error not converted yet
import { checkIsNotNullOrWhitespace } from "../../../../helper/Validator";
import { ModelParam, Translate } from "../../../Types/Types";

export interface IMassEmailNotificationsModel {
    sendToFesto: ModelParam<boolean>,
    sendToExternal: ModelParam<boolean>,
    sendToProjectUsers: ModelParam<boolean>,
    emailSubject: ModelParam<string>,
    emailBody: ModelParam<string>,
    emailSignature: ModelParam<string>,
}

export const MassEmailNotificationsModel = (t: Translate) : IMassEmailNotificationsModel=> ({
    sendToFesto: { label: t("SendToFesto"), idkey: "sendToFesto", value: false, isValid: true, validators: [], validationError: "" },
    sendToExternal: { label: t("SendToExternal"), idkey: "sendToExternal", value: false, isValid: true, validators: [], validationError: "" },
    sendToProjectUsers: { label: t("SendToProjectUsers"), idkey: "sendToProjectUsers", value: false, isValid: true, validators: [], validationError: "" },
    emailSubject: { label: t("EmailSubject"), value: "", isValid: true, validators: [checkIsNotNullOrWhitespace], idkey: "", validationError: "" },
    emailBody: { label: t("EmailBody"), value: "", isValid: true, validators: [checkIsNotNullOrWhitespace], idkey: "", validationError: "" },
    emailSignature: { label: t("EmailSignature"), value: '', isValid: true, validators: [checkIsNotNullOrWhitespace], idkey: "", validationError: "" },
});
