import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { TableContainer, TableHead, Table, TableCell, TableRow, TableBody, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
    savingsEliminatingTheLeakagesTable,
    savingsShuttingOffMainAirSupplyAtNonProductionTimeTable,
    savingsReducingThePressureDropTable,
    savingsReducingThePressureLevelOfTheSystemTable,
    savingsOptimizingTheAirConsumptionAtSingleApplicationsTable,
    cumulativeHighestSavingsPerYearTable,
    electricityConsumptionkWh,
    airConsumptionNm3,
    co2,
    energyCosts,
    totalCosts,
    level,
    timeAirSupplyPerYearHours,
    pressureDropBar,
    flowRateNlmin,
    pressureLevel
} from '../Constants/Constants';
import * as Fmt from '../../../helper/Formatters';

const CostSavingsTable = (props) => {
    const [headers, setHeaders] = useState([]);
    const { data, costSavingType, index, hidePressureLevel } = props;
    const { t } = useTranslation();
    const { country } = useParams();

    useEffect(() => {
        setHeaders(generateHeaderNames(costSavingType, t))
    }, [headers.length > 0, t])

    const actual = data.find(x => x.costSavingMeasure == 'Actual' && x.costSavingType == costSavingType)
    const future = data.find(x => x.costSavingMeasure == 'Future' && x.costSavingType == costSavingType)
    const savings = data.find(x => x.costSavingMeasure == 'Savings' && x.costSavingType == costSavingType)

    const generateHeaderNames = (tableName, t) => {
        let headerArray = [];

        if (tableName == savingsEliminatingTheLeakagesTable)
            headerArray.push(t(flowRateNlmin) + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t))
        if (tableName == savingsShuttingOffMainAirSupplyAtNonProductionTimeTable)
            headerArray.push(t(timeAirSupplyPerYearHours) + Fmt.getUnitInBracket("hours", t))
        if (hidePressureLevel != true) {
            if (tableName == savingsReducingThePressureDropTable)
                headerArray.push(t(pressureDropBar) + Fmt.getUnitInBracket("pressure", t))
            if (tableName == savingsReducingThePressureLevelOfTheSystemTable)
                headerArray.push(t(pressureLevel) + Fmt.getUnitInBracket("pressure", t))
        }
        if (tableName == savingsOptimizingTheAirConsumptionAtSingleApplicationsTable)
            headerArray.push(t(flowRateNlmin) + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t))

        headerArray.push(t(electricityConsumptionkWh) + Fmt.getUnitInBracket("powerPerHour", t));
        headerArray.push(t(airConsumptionNm3) + Fmt.getUnitInBracket("cubicMeterOfGas", t));
        headerArray.push(t(co2) + Fmt.getUnitInBracket("co2Wheight", t));
        headerArray.push(t(energyCosts) + Fmt.getCurrency());
        headerArray.push(t(totalCosts) + Fmt.getCurrency());
        headerArray.push(t(level) + Fmt.getPercentage());
        return headerArray;
    }

    const applyCustomRounding = (table, value) => {
        switch (table) {
            case savingsReducingThePressureDropTable:
                return Fmt.round(value, country, 2);
            case savingsReducingThePressureLevelOfTheSystemTable:
                return Fmt.round(value, country, 2);
            case savingsOptimizingTheAirConsumptionAtSingleApplicationsTable:
                return Fmt.round(value, country, 1)
            default:
                return Fmt.round(value, country, 0)
        }
    }

    return (
        <TableContainer component={Paper} >
            <Table aria-label="spanning table">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        {headers.map((header, index) =>
                            <TableCell key={`CostSavingsTable${index}`} className={header == t(totalCosts) + Fmt.getCurrency() ? "font-bold" : ""} align="center">{header}</TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>

                    <TableRow key={1}>
                        <TableCell>{t("Actual")}</TableCell>
                        {costSavingType != cumulativeHighestSavingsPerYearTable && !hidePressureLevel &&
                            <TableCell id={"td_CS-actual-staticConsmuption" + index} align="center">{applyCustomRounding(costSavingType, actual.staticConsmuption)}</TableCell>
                        }
                        <TableCell id={"td_CS-actual-electricityConsumption_" + index} align="center">{Fmt.round(actual.electricityConsumption, country, 0)}</TableCell>
                        <TableCell id={"td_CS-actual-airConsumption_" + index} align="center">{Fmt.round(actual.airConsumption, country, 0)}</TableCell>
                        <TableCell id={"td_CS-actual-cO2_" + index} align="center">{Fmt.round(actual.cO2, country, 0)}</TableCell>
                        <TableCell id={"td_CS-actual-energyCosts_" + index} align="center">{Fmt.round(actual.energyCosts, country, 0)}</TableCell>
                        <TableCell className="font-bold" id={"td_CS-actual-totalCosts_" + index} align="center">{Fmt.round(actual.totalCosts, country, 0)}</TableCell>
                        <TableCell id={"td_CS-actual-level_" + index} align="center">{Fmt.round(actual.level, country, 0)}</TableCell>
                    </TableRow>

                    <TableRow key={2}>
                        <TableCell>{t("Future")}</TableCell>
                        {costSavingType != cumulativeHighestSavingsPerYearTable && !hidePressureLevel &&
                            <TableCell id={"td_CS-actual-staticConsmuption" + index} align="center">{applyCustomRounding(costSavingType, future.staticConsmuption)}</TableCell>
                        }
                        <TableCell id={"td_CS-future-electricityConsumption_" + index} align="center">{Fmt.round(future.electricityConsumption, country, 0)}</TableCell>
                        <TableCell id={"td_CS-future-airConsumption_" + index} align="center">{Fmt.round(future.airConsumption, country, 0)}</TableCell>
                        <TableCell id={"td_CS-future-cO2_" + index} align="center">{Fmt.round(future.cO2, country, 0)}</TableCell>
                        <TableCell id={"td_CS-future-energyCosts_" + index} align="center">{Fmt.round(future.energyCosts, country, 0)}</TableCell>
                        <TableCell className="font-bold" id={"td_CS-future-totalCosts_" + index} align="center">{Fmt.round(future.totalCosts, country, 0)}</TableCell>
                        <TableCell id={"td_CS-future-level_" + index} align="center">{Fmt.round(future.level, country, 0)}</TableCell>

                    </TableRow>
                    <TableRow key={3}>
                        <TableCell><b>{t("Savings")}</b></TableCell>
                        {costSavingType != cumulativeHighestSavingsPerYearTable && !hidePressureLevel &&
                            <TableCell id={"td_CS-actual-staticConsmuption" + index} align="center">{applyCustomRounding(costSavingType, savings.staticConsmuption)}</TableCell>
                        }
                        <TableCell id={"td_CS-savings-electricityConsumption_" + index} align="center">{Fmt.round(savings.electricityConsumption, country, 0)}</TableCell>
                        <TableCell id={"td_CS-savings-airConsumption_" + index} align="center">{Fmt.round(savings.airConsumption, country, 0)}</TableCell>
                        <TableCell id={"td_CS-savings-cO2_" + index} align="center">{Fmt.round(savings.cO2, country, 0)}</TableCell>
                        <TableCell id={"td_CS-savings-energyCosts_" + index} align="center">{Fmt.round(savings.energyCosts, country, 0)}</TableCell>
                        <TableCell className="font-bold" id={"td_CS-savings-totalCosts_" + index} align="center">{Fmt.round(savings.totalCosts, country, 0)}</TableCell>
                        <TableCell id={"td_CS-savings-level_" + index} align="center">{Fmt.round(savings.level, country, 0)}</TableCell>

                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default CostSavingsTable;