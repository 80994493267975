import React from "react";
import ReactExport from "react-xlsx-wrapper";
import { useTranslation } from 'react-i18next';
import { ButtonComponent } from '../../Reusable/';
import * as borders from '../../../helper/ExcelHelpers/Constants';
import { GenerateTopHeader, GenerateRows } from '../../../helper/ExcelHelpers/ExcelHelper';
import _ from 'lodash';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const coulumnStyleRules = {
    0: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    1: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    2: {
        first: borders.topRightThick,
        middle: borders.rightThick,
        last: borders.bottomRightThick,
    },
    3: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    4: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    5: {
        first: borders.topRightThick,
        middle: borders.rightThick,
        last: borders.bottomRightThick,
    },
    6: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    7: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    8: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    9: {
        first: borders.topRightThick,
        middle: borders.rightThick,
        last: borders.bottomRightThick,
    },
    10: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    11: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    12: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    13: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    14: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    15: {
        first: borders.topRightThick,
        middle: borders.rightThick,
        last: borders.bottomRightThick,
    }
};


export const SparePartsToExcel = (props) => {
    const { data } = props;
    const { t } = useTranslation();

    const generateDataSet = () => {
        return [
            GenerateTopHeader("ReplacementPartsReport", t),
            {
                ySteps: 2,
                columns: [
                    { title: "" },
                    { title: "" },
                    {
                        title: "",
                        style: {
                            border: borders.leftThickOnly,
                        },
                    },
                    { title: "" },
                    { title: t("Location") },
                    {
                        title: "",
                        style: {
                            border: borders.leftThickOnly,
                        },
                    },
                    { title: "" },
                    { title: t("DefectedDevice") },
                    { title: "" },
                    {
                        title: "",
                        style: {
                            border: borders.leftThickOnly,
                        },
                    },
                    { title: "" },
                    { title: "" },
                    { title: t("ReplacementDevice") },
                    { title: "" },
                    { title: "" },
                    {
                        title: "",
                        style: {
                            border: borders.leftThickOnly,
                        },
                    },
                ],
                data: [],
            },
            {
                columns: [
                    {
                        title: t("Service"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        },
                    },
                    {
                        title: t("Nr"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        },
                    },
                    {
                        title: t("MeasurementId"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.rightThick,
                        },
                    },
                    {
                        title: t("Building"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        },
                    },
                    {
                        title: t("Department"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        },
                    },
                    {
                        title: t("Machine"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.rightThick,
                        },
                    },
                    {
                        title: t("PartNumber"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        },
                    },
                    {
                        title: t("TypeCode"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        },
                    },
                    {
                        title: t("Component"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        },
                    },
                    {
                        title: t("Manufacturer"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.rightThick,
                        },
                    },
                    {
                        title: t("PartNumber"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        },
                    },
                    {
                        title: t("TypeCode"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        },
                    },
                    {
                        title: t("Component"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        },
                    },
                    {
                        title: t("Manufacturer"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        },
                    },
                    {
                        title: t("Quantity"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        },
                    },
                    {
                        title: t("OrderSparePart"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.rightThick,
                        },
                    },
                ],
                data: GenerateRows(_.cloneDeep(data).map(x => {
                    x.newPartNumber.value = x.replaceWithDifferent.value ? x.newPartNumber.value : x.partNumber.value,
                    x.newTypeCode.value = x.replaceWithDifferent.value ? x.newTypeCode.value : x.typeCode.value,
                    x.newComponent.value = x.replaceWithDifferent.value ? x.newComponent.value : x.component.value,
                    x.newManufacturer.value = x.replaceWithDifferent.value ? x.newManufacturer.value : x.manufacturer.value,
                    x.orderSparePart.value = x.orderSparePart.value === true ? t("Yes") : t("No");
                    x.service.value = t(x.service.value);
                    return x;
                }), coulumnStyleRules),
            },
        ];
    };

    return (
        <ExcelFile
            filename="Replacement_parts_list"
            element={
                <ButtonComponent
                    id="RPCART-generatecsv"
                    value={t("GenerateSparePartsList")}
                    color="primary"
                />
            }
        >
            <ExcelSheet dataSet={generateDataSet()} name="SpareParts List" />
        </ExcelFile>
    );
};
