import { checkIsInteger, checkIsNotNullOrWhitespace, checkIsAnythingSelectedObject } from '../../helper/Validator';

export const ReplacementPartModel = t => ({
    id: { value: 0, isValid: true, validationError: "", validators: [], disabled: true },
    no: { idkey: "RPART-No", value: 0, isValid: true, validationError: "", validators: [], disabled: true, headerName: t('Nr') },
    partNumber: { label: t("PartNumber"), idkey: "RPART-PartNumber", value: "", isValid: true, validationError: "", validators: [] },
    component: { label: t("Component"), idkey: "RPART-Component", value: "", isValid: true, validationError: "", validators: [checkIsAnythingSelectedObject] },
    manufacturer: { label: t("Manufacturer"), idkey: "RPART-Manufacturer", value: "", isValid: true, validationError: "", validators: [checkIsNotNullOrWhitespace] },
    typeCode: { label: t("TypeCode"), idkey: "RPART-TypeCode", value: "", isValid: true, validationError: "", validators: [] },
    newPartNumber: { label: t("PartNumber"), idkey: "RPART-NewPartNumber", value: "", isValid: true, validationError: "", validators: [], headerName: t('Replacement') },
    newTypeCode: { label: t("TypeCode"), idkey: "RPART-NewTypeCode", value: "", isValid: true, validationError: "", validators: [] },
    newManufacturer: { label: t("Manufacturer"), idkey: "RPART-NewManufacturer", value: "", isValid: true, validationError: "", validators: [] },
    replaceWithDifferent: { label: t("ReplaceWithDifferentPart"), idkey: "RPART-ReplaceWithDifferent", value: false, isValid: true, validationError: "", validators: [] },
    quantity: { label: t("Quantity"), idkey: "RPART-Quantity", value: 1, isValid: true, validationError: "", validators: [checkIsInteger] },
    orderSparePart: { label: t("OrderSparePart"), idkey: "RPART-OrderSparePart", value: true, isValid: true, validationError: "", validators: [] },
})