import { checkIsInteger, checkPercentage, checkIsDecimal } from "../../helper/Validator";

export const CompressedAirGenerationModelFactory = (t) =>
({
    id: { value: "", isValid: true, validationError: "", validators: [] },
    no: { value: "", isValid: true, validationError: "", validators: [] },
    isElectricityAndAirConsumptionMeasured: { label: t("ElectricityAndAirConsumption"), idkey: "CAG-ElectricityAndAirConsumption", value: false, isValid: true, validationError: "", validators: [] },
    electricityConsumptionPerYear: { value: "", idkey: "CAG-electricityConsumptionPerYear", isValid: true, validationError: "", validators: [] },
    electricityConsumptionTimePeriod: { value: "", idkey: "CAG-electricityConsumptionTimePeriod", isValid: true, validationError: "", validators: [] },
    electricityConsumptionValue: { value: "", idkey: "CAG-electricityConsumptionValue", isValid: true, validationError: "PleaseVerifyYourEntriesError", validators: [checkIsNotMeasuredOrDecimal] },
    airConsumptionPerYear: { value: "", idkey: "CAG-airConsumptionPerYear", isValid: true, validationError: "", validators: [] },
    airConsumptionTimePeriod: { value: "", idkey: "CAG-airConsumptionTimePeriod", isValid: true, validationError: "", validators: [] },
    airConsumptionValue: { value: "", idkey: "CAG-airConsumptionValue", isValid: true, validationError: "PleaseVerifyYourEntriesError", validators: [checkIsNotMeasuredOrDecimal] },
    expectedLeakages: { label: "", value: "", idkey: "CAG-expectedLeakages", isValid: true, validationError: "PleaseVerifyYourEntriesError", validators: [checkPercentage] },
    staticConsumption: { label: "", value: "", idkey: "CAG-staticConsumption", isValid: true, validationError: "PleaseVerifyYourEntriesError", validators: [checkPercentage] },
    unloadCyclesPerHour: { label: t("UnloadCyclesPerHour"), value: "", idkey: "CAG-unloadCyclesPerHour", isValid: true, validationError: "PleaseVerifyYourEntriesError", validators: [checkIsInteger] },
    unloadTime: { label: t("UnloadTimeInSeconds"), idkey: "CAG-unloadTime", value: "", isValid: true, validationError: "PleaseVerifyYourEntriesError", validators: [checkIsInteger] },
    comment: { value: "", idkey: "CAG-comment", isValid: true, validationError: "", validators: [] },
    compressorList: { value: "", idkey: "CAG-compressorList", isValid: true, validationError: "", validators: [] },
    guid: { value: "", idkey: "CAG-guid", isValid: true, validationError: "", validators: [] },
    leakagePercentage: { value: "", idkey: "CAG-leakagePercentage", isValid: true, validationError: "", validators: [] }
});

const checkIsNotMeasuredOrDecimal = (value, model) => !model.isElectricityAndAirConsumptionMeasured.value || checkIsDecimal(value);