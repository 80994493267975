import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import ImageViewer from '../../ImageViewer/ImageViewer';
import * as Mapper from '../../../helper/Mappers';
import './PressureDropAnalysis.scss';
import { TextFieldComponent, NumberInputFieldComponent, HeadingComponent, ButtonComponent, DatePickerComponent, AutocompleteComponent, CheckboxComponent, SimpleCheckbox, ListPreviousNext, ModalComponent } from '../../../components/Reusable/';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextareaAutosize, FormHelperText } from '@mui/material';
import { API } from '../../../helper/ApiHelper';
import { PressureDropAnalysisModelFactory } from './PressureDropAnalysisModel';
import ReplacementParts from '../../ReplacementParts/ReplacementParts';
import MeasurementId from '../../MeasurementId/MeasurementId';
import * as Fmt from '../../../helper/Formatters';
import * as ImageBlobConstants from '../../ImageViewer/Constants';
import './PressureDropAnalysis.scss';
import { OpenGlobalSnackbar, ScrollerToTag } from '../../../helper/GlobalVariables';
import { ExecutorStore, DirtyStore } from '../../../store';
import { validateSingleProperty, checkAndValidateModel } from '../../../helper/Validator';
import * as InputRestrictions from '../../../helper/InputRestrictions';
import CostSavingsTable from "../../CostSavings/CostSavingTable/CostSavingTable";
import { NavigationOption } from '../../../helper/ConstantRepository';

export default function PressureDropAnalysis(props) {
    const { denyEdit } = props;
    const anUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "air-network-analysis";
    let history = useHistory();
    const { t } = useTranslation();
    const { country, lng, id, cid } = useParams();
    const [mainModel, setMainModel] = useState(PressureDropAnalysisModelFactory(t));
    const [replacementPartList, setReplacementPartList] = useState([]);
    const [executorUsersOptions, setexecutorUsersOptions] = useState([]);
    const [compressorList, setCompressorList] = useState([]);
    const [assignedCompressorList, setAssignedCompressorsList] = useState([]);
    const [compressorListValid, setCompressorListValid] = useState(true);
    const [calcs, setCalcs] = useState({});
    const [modalModel, setModalModel] = useState({ open: false, message: "" });

    useEffect(() => {
        Mapper.updateExistingModelFormattingLabels(mainModel, PressureDropAnalysisModelFactory(t), setMainModel);
    }, [t]);

    useEffect(() => {
        loadAvailableCompressorRooms();
        if (cid > 0) loadExistingData();
        if (cid == 0) loadCleanPage();
    }, [cid]);

    useEffect(() => {
        let execSub = ExecutorStore.executorSettings.subscribe(executorSettings => {
            if (executorSettings && executorSettings.executorUsers) setexecutorUsersOptions(executorSettings.executorUsers);
        })
        return () => execSub.unsubscribe();
    }, []);

    const loadAvailableCompressorRooms = () => {
        API.get(`${anUrl}/${id}/pressure-drop-analysis/${cid}/available-compressor-rooms`).then(resp => {
            setCompressorList(resp);
        })
    }

    const loadExistingData = () => {
        API.get(`${anUrl}/${id}/pressure-drop-analysis/${cid}`).then(resp => {
            let mappedModel = Mapper.mapDataToModelValues(resp.data, PressureDropAnalysisModelFactory(t));
            let calcMappedModel = Mapper.mapMachingDataToModelValues(resp.pressureDropCalcResult, PressureDropAnalysisModelFactory(t));
            setMainModel({ ...mappedModel, ...calcMappedModel });
            setReplacementPartList(resp.data.replacementPartList);
            setAssignedCompressorsList(resp.data.assignedCompressorRooms.map(x => x.compressorRoomId));
            setCalcs(resp.pressureDropCalcResult);
            ScrollerToTag();
        })
    }

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const loadCleanPage = async () => {
        let guid = Mapper.getNewGuid();
        let newModel = _.cloneDeep(PressureDropAnalysisModelFactory(t));
        newModel.guid.value = guid;
        setMainModel(newModel);
        setReplacementPartList([]);
        scrollTop();
    }

    const copyForm = async () => {
        Mapper.copyForm(mainModel, setMainModel);
        replacementPartList.forEach(x => {
            x.id = 0;
            x.guid = null;
        });
        setAssignedCompressorsList([]);
        scrollTop();
    }

    const clickCancel = () => {
        history.push("/" + country + "/" + lng + '/project/' + id + '/AN' + "#PressureDropAnalysis");
    }

    const postForm = async (navigationOption) => {
        let objectPostModel = Mapper.extractValuesFromModel(mainModel);
        objectPostModel.replacementPartList = replacementPartList;
        objectPostModel.assignedCompressorRooms = assignedCompressorList.map(x => { return { id: 0, compressorRoomId: x } });
        let postModel = {
            ProjectId: id,
            PressureDropAnalysis: objectPostModel
        };
        let method = mainModel.id.value == 0 ? "post" : "put";

        API({
            url: `${anUrl}/${id}/pressure-drop-analysis/${mainModel.id.value}`,
            method,
            data: postModel,
        }).then(async (componentId) => {
            DirtyStore.setIsDirty(false);
            OpenGlobalSnackbar("success", t('SaveSuccessful'));
            switch (navigationOption) {
                case NavigationOption.Reload:
                    history.push(`/${country}/${lng}/project/${id}/AN/PressureDropAnalysis/${method === "post" ? componentId: cid}`);
                    break;
                case NavigationOption.Create:
                    if (cid == 0) loadCleanPage();
                    history.push(`/${country}/${lng}/project/${id}/AN/PressureDropAnalysis/0`);
                    break;
                case NavigationOption.Copy:
                    await copyForm();
                    break;
                default:
                    break;
            }
        });
    }

    const validateCompressorList = (compressors) => {
        let isValid = compressors.length > 0;
        setCompressorListValid(isValid);
        return isValid;
    }

    const updateAssignedCompressorList = (checked, roomId) => {
        let compressors = checked ? [...assignedCompressorList, roomId] : assignedCompressorList.filter(x => x != roomId);
        setAssignedCompressorsList(compressors);
        validateCompressorList(compressors);
    }

    const updateModel = (property, value) => {
        mainModel[property].value = value;
        validateSingleProperty(mainModel, property);
        setMainModel({ ...mainModel });
        DirtyStore.setIsDirty(clickSaveOpenModal);
    }

    const clickSaveOpenModal = navigationOption => {
        let [isValid, validatedModel] = checkAndValidateModel(mainModel, true);
        setMainModel(validatedModel);
        isValid = validateCompressorList(assignedCompressorList);
        if (isValid) {
            if (warnCompressorRooms()) {
                modalModel.open = true;
                modalModel.okText = t('Ok');
                modalModel.message = t('WarningPleaseGenerateNewServiceEelementsToAllocateCompressorRooms');
                modalModel.clickOk = () => postForm(navigationOption);
                setModalModel({ ...modalModel });
                return false;
            }
            else {
                postForm(navigationOption);
            }
        }
        else {
            OpenGlobalSnackbar("danger", t('ModelInvalidError'));
        }
        return isValid;
    }

    const warnCompressorRooms = () => assignedCompressorList.length !== compressorList.length;

    return (
        <div className="pressure-drop">
            <ModalComponent model={modalModel} />
            <Grid container spacing={3} direction="column">
                <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item id="#PressureDropAnalysis">
                            <HeadingComponent value={t('PressureDropAnalysis')} size="h6" />
                        </Grid>
                        <Grid item>
                            <ListPreviousNext />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MeasurementId disabled={denyEdit} element={mainModel.measurementId} onChange={e => updateModel("measurementId", e)} />
                </Grid>
                <Grid item>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextFieldComponent disabled={denyEdit} t={t} model={mainModel.nameOfTheAirNetwork} onChange={e => { updateModel("nameOfTheAirNetwork", e.target.value) }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <DatePickerComponent
                                disabled={denyEdit}
                                t={t}
                                model={mainModel.dateOfDetection}
                                onChange={e => updateModel("dateOfDetection", e)}
                                maxDate={new Date()}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AutocompleteComponent disabled={denyEdit} options={executorUsersOptions} t={t} model={mainModel.detectedBy} onInputChange={(e, value) => { updateModel("detectedBy", value); }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <CheckboxComponent model={mainModel.isRepairDone} onChange={e => { updateModel("isRepairDone", e.target.checked); }} />
                </Grid>
                {mainModel && mainModel.isRepairDone && mainModel.isRepairDone.value &&
                    <Grid item>
                        <Grid container direction="row" spacing={3}>
                            <Grid item xs={12} md={6}>
                                <DatePickerComponent
                                    t={t}
                                    model={mainModel.repairDate}
                                    onChange={e => updateModel("repairDate", e)}
                                    maxDate={new Date()}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <AutocompleteComponent options={executorUsersOptions} t={t} model={mainModel.repairedBy} onInputChange={(e, value) => { updateModel("repairedBy", value); }} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextareaAutosize
                                    id='textarea-PDAEDIT-repairComment'
                                    placeholder={t("Comment")}
                                    width="100%"
                                    minRows="5"
                                    type="text"
                                    value={mainModel.repairComment.value}
                                    onChange={e => updateModel("repairComment", e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                }
                <Grid item>
                    <HeadingComponent value={t('AssignedCompressorRooms')} size="h6" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TableContainer component={Paper} >
                        <Table aria-label="spanning table">
                            <TableHead>
                                <TableRow className="table-head-row">
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left">{t('CompressorRoomNo')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {compressorList != undefined && compressorList.length > 0 &&
                                    compressorList.map(roomId =>
                                        <TableRow id={'pressure-drop-assigned-compressors' + roomId} key={'pressure-drop-assigned-compressors' + roomId} className="table-head-row">
                                            <TableCell align="middle">
                                                <SimpleCheckbox
                                                    disabled={denyEdit}
                                                    id={'pressure-drop-assigned-compressors-checkbox' + roomId}
                                                    defaultChecked={assignedCompressorList.includes(roomId)}
                                                    key={Math.random()}
                                                    color="default"
                                                    onChange={e => updateAssignedCompressorList(e.target.checked, roomId)}
                                                />
                                            </TableCell>
                                            <TableCell id={"td_PRESSUREEDIT-No" + roomId} align="left">
                                                {roomId}
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {compressorListValid === false &&
                        <FormHelperText error={true}>{t("AtLeastOneCompressorMustBeSelected")}</FormHelperText>
                    }
                </Grid>
                <Grid item id="#PressureLevelAnalysis">
                    <HeadingComponent value={t('PressureLevelAnalysis')} size="h6" />
                </Grid>
                <Grid item>
                    <TableContainer component={Paper} >
                        <Table aria-label="spanning table">
                            <TableHead>
                                <TableRow className="table-head-row">
                                    <TableCell align="left">{t('SetpointPressureAtOutput')}</TableCell>
                                    <TableCell align="left">{t('MaximalSuggested') + Fmt.getUnitInBracket("pressure", t)}</TableCell>
                                    <TableCell align="left">{t('MinimalPressure') + Fmt.getUnitInBracket("pressure", t)}</TableCell>
                                    <TableCell align="left">{t('MaximalPressure') + Fmt.getUnitInBracket("pressure", t)}</TableCell>
                                    <TableCell align="left">{t('AveragePressure') + Fmt.getUnitInBracket("pressure", t)}</TableCell>
                                    <TableCell align="left">{t('PressureLevel') + Fmt.getUnitInBracket("pressure", t)}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className="table-head-row">
                                    <TableCell align="left">{t('SetpointPressureRel')}</TableCell>
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                    <TableCell align="left">
                                        <NumberInputFieldComponent disabled={denyEdit} t={t} model={{ ...mainModel.pressureSetpointPressureRel, label: "" }}
                                            onChange={e => updateModel("pressureSetpointPressureRel", InputRestrictions.Decimal(e.target.value))}
                                        />
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                                <TableRow className="table-head-row">
                                    <TableCell align="left" className="no-border">{t('BeforeDryerAndFilterAbsolute')}</TableCell>
                                    <TableCell className="no-border" />
                                    <TableCell align="left" className="no-border">
                                        <NumberInputFieldComponent disabled={denyEdit} t={t} model={mainModel.pressureBeforeDryersAbsMin} onChange={e => updateModel("pressureBeforeDryersAbsMin", InputRestrictions.Decimal(e.target.value))} />
                                    </TableCell>
                                    <TableCell align="left" className="no-border">
                                        <NumberInputFieldComponent disabled={denyEdit} t={t} model={mainModel.pressureBeforeDryersAbsMax} onChange={e => updateModel("pressureBeforeDryersAbsMax", InputRestrictions.Decimal(e.target.value))} />
                                    </TableCell>
                                    <TableCell align="left" className="no-border">
                                        <NumberInputFieldComponent disabled={denyEdit} t={t} model={mainModel.pressureBeforeDryersAbs} onChange={e => updateModel("pressureBeforeDryersAbs", InputRestrictions.Decimal(e.target.value))} />
                                    </TableCell>
                                </TableRow>
                                <TableRow className="table-head-row">
                                    <TableCell align="left">{t('BeforeDryerAndFilterRelative')}</TableCell>
                                    <TableCell align="left" />
                                    <TableCell align="left">{Fmt.round(mainModel.pressureBeforeDryersRelMin.value, country, 1)}</TableCell>
                                    <TableCell align="left">{Fmt.round(mainModel.pressureBeforeDryersRelMax.value, country, 1)} </TableCell>
                                    <TableCell align="left">{Fmt.round(mainModel.pressureBeforeDryersRel.value, country, 1)}</TableCell>
                                    <TableCell align="left">{Fmt.round(mainModel.pressureDropBefore.value, country, 2)}</TableCell>
                                </TableRow>
                                <TableRow className="table-head-row">
                                    <TableCell align="left" className="no-border">{t('AfterDryerAndFilterAbsulute')}</TableCell>
                                    <TableCell className="no-border" />
                                    <TableCell align="left" className="no-border">
                                        <NumberInputFieldComponent disabled={denyEdit} t={t} model={mainModel.pressureAfterDryersAbsMin} onChange={e => updateModel("pressureAfterDryersAbsMin", InputRestrictions.Decimal(e.target.value, 2))} />
                                    </TableCell>
                                    <TableCell align="left" className="no-border">
                                        <NumberInputFieldComponent disabled={denyEdit} t={t} model={mainModel.pressureAfterDryersAbsMax} onChange={e => updateModel("pressureAfterDryersAbsMax", InputRestrictions.Decimal(e.target.value))} />
                                    </TableCell>
                                    <TableCell align="left" className="no-border">
                                        <NumberInputFieldComponent disabled={denyEdit} t={t} model={mainModel.pressureAfterDryersAbs} onChange={e => updateModel("pressureAfterDryersAbs", InputRestrictions.Decimal(e.target.value))} />
                                    </TableCell>
                                </TableRow>
                                <TableRow className="table-head-row">
                                    <TableCell align="left">{t('AfterDryerAndFilterRelative')}</TableCell>
                                    <TableCell align="left">
                                        <NumberInputFieldComponent disabled={denyEdit} t={t} model={mainModel.pressureBeforeDropMax} onChange={e => updateModel("pressureBeforeDropMax", InputRestrictions.Decimal(e.target.value, 2))} />
                                    </TableCell>
                                    <TableCell align="left">{Fmt.round(mainModel.pressureAfterDryersRelMin.value, country, 1)}</TableCell>
                                    <TableCell align="left">{Fmt.round(mainModel.pressureAfterDryersRelMax.value, country, 1)}</TableCell>
                                    <TableCell align="left">{Fmt.round(mainModel.pressureAfterDryersRel.value, country, 1)}</TableCell>
                                    <TableCell align="left" className={mainModel.pressureDropAfter.value > mainModel.pressureBeforeDropMax.value ? "mark-red" : ""}>
                                        {Fmt.round(mainModel.pressureDropAfter.value, country, 2)}
                                    </TableCell>
                                </TableRow>
                                <TableRow className="table-head-row">
                                    <TableCell align="left" className="no-border">{t('ProductionAbsolute')}</TableCell>
                                    <TableCell className="no-border" />
                                    <TableCell align="left" className="no-border">
                                        <NumberInputFieldComponent disabled={denyEdit} t={t} model={mainModel.pressureProductionAbsMin} onChange={e => updateModel("pressureProductionAbsMin", InputRestrictions.Decimal(e.target.value, 2))} />
                                    </TableCell>
                                    <TableCell align="left" className="no-border">
                                        <NumberInputFieldComponent disabled={denyEdit} t={t} model={mainModel.pressureProductionAbsMax} onChange={e => updateModel("pressureProductionAbsMax", InputRestrictions.Decimal(e.target.value))} />
                                    </TableCell>
                                    <TableCell align="left" className="no-border">
                                        <NumberInputFieldComponent disabled={denyEdit} t={t} model={mainModel.pressureProductionAbs} onChange={e => updateModel("pressureProductionAbs", InputRestrictions.Decimal(e.target.value))} />
                                    </TableCell>
                                </TableRow>
                                <TableRow className="table-head-row">
                                    <TableCell align="left">{t('ProductionRelative')}</TableCell>
                                    <TableCell align="left">
                                        <NumberInputFieldComponent disabled={denyEdit} t={t} model={mainModel.pressureAfterDropMax} onChange={e => updateModel("pressureAfterDropMax", InputRestrictions.Decimal(e.target.value, 2))} />
                                    </TableCell>
                                    <TableCell align="left">{Fmt.round(mainModel.pressureProductionRelMin.value, country, 1)}</TableCell>
                                    <TableCell align="left">{Fmt.round(mainModel.pressureProductionRelMax.value, country, 1)}</TableCell>
                                    <TableCell align="left">{Fmt.round(mainModel.pressureProductionRel.value, country, 1)}</TableCell>
                                    <TableCell align="left" className={mainModel.pressureDropProduction.value > mainModel.pressureAfterDropMax.value ? "mark-red" : ""}>
                                        {Fmt.round(mainModel.pressureDropProduction.value, country, 2)}
                                    </TableCell>
                                </TableRow>
                                <TableRow className="table-head-row">
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left">{Fmt.round(mainModel.pressureDropMax.value, country, 2)}</TableCell>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left" className={mainModel.pressureDropTOTAL.value > mainModel.pressureDropMax.value ? "mark-red" : ""} >{Fmt.round(mainModel.pressureDropTOTAL.value, country, 2)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item id="#SavingsReducingThePressureLevel">
                    <HeadingComponent value={t('SavingsReducingThePressureLevel')} size="h6" />
                </Grid>
                <Grid item>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <NumberInputFieldComponent disabled={denyEdit} t={t} model={mainModel.pressureSetpointPressureRel} onChange={e => {
                                updateModel("pressureSetpointPressureRel", InputRestrictions.Decimal(e.target.value))
                            }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <NumberInputFieldComponent disabled={denyEdit} t={t} model={mainModel.suggestedSetpointPressureRel} onChange={e => {
                                updateModel("suggestedSetpointPressureRel", InputRestrictions.Decimal(e.target.value))
                            }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item id="#ReplacementParts">
                    <HeadingComponent value={t('ReplacementParts')} size="h6" />
                </Grid>
                <Grid item>
                    <ReplacementParts
                        setIsDirty={() => DirtyStore.setIsDirty(clickSaveOpenModal)}
                        disabled={denyEdit}
                        replacementPartList={replacementPartList}
                        setReplacementPartList={setReplacementPartList}
                    />
                </Grid>
                <Grid item>
                    <HeadingComponent value={t('SavingsReducingThePressureDropTable')} size="h6" />
                </Grid>
                <Grid item xs={12}>
                    {calcs.reducingPressureDropCalcs &&
                        <CostSavingsTable data={calcs.reducingPressureDropCalcs} costSavingType="SavingsReducingThePressureDropTable" index={0} />
                    }
                </Grid>
                <Grid item>
                    <HeadingComponent value={t('SavingsReducingThePressureLevelOfTheSystemTable')} size="h6" />
                </Grid>
                <Grid item xs={12}>
                    {calcs.reducingPressureLevelCalcs &&
                        <CostSavingsTable data={calcs.reducingPressureLevelCalcs} costSavingType="SavingsReducingThePressureLevelOfTheSystemTable" index={0} />
                    }
                </Grid>
                <Grid item>
                    <HeadingComponent value={t('Comment')} size="h6" />
                </Grid>
                <Grid item>
                    <TextareaAutosize disabled={denyEdit} id="textarea_PDAEDIT-comment" type="text" value={mainModel.comment.value} minRows="5"
                        onChange={e => {
                            updateModel("comment", e.target.value)
                        }}
                    />
                </Grid>
                <Grid item>
                    {mainModel.guid.value && <ImageViewer disabled={denyEdit} groupId={ImageBlobConstants.PreassureDropAnalysisPrefix + mainModel.guid.value} />}
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item><ButtonComponent id="PDAEDIT-Cancel" value={t('Cancel')} color="secondary" onChange={clickCancel}></ButtonComponent></Grid>
                    <Grid item><ButtonComponent id="PDAEDIT-Save" value={t('Save')} color="primary" onChange={() => clickSaveOpenModal(NavigationOption.Reload)}></ButtonComponent></Grid>
                    {!denyEdit && <Grid item><ButtonComponent id="PDAEDIT-SaveAndCopy" value={t('SaveAndCopy')} color="primary" onChange={() => clickSaveOpenModal(NavigationOption.Copy)}></ButtonComponent></Grid>}
                    {!denyEdit && <Grid item><ButtonComponent id="PDAEDIT-SaveAndNew" value={t('SaveAndNew')} color="primary" onChange={() => clickSaveOpenModal(NavigationOption.Create)}></ButtonComponent></Grid>}
                </Grid>
            </Grid>
        </div>
    )
}

