import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { StyleTable } from '../PdfStyles';
import {
    savingsReducingThePressureDropTable,
    electricityConsumptionkWh,
    airConsumptionNm3,
    co2,
    energyCosts,
    totalCosts,
    level,
    pressureDropBar
} from '../../../CostSavings/Constants/Constants';
import * as Fmt from '../../../../helper/Formatters';
import { SplitText } from '../../../../helper/PdfTested';

const SavingsReducingThePressureDropTable = (props) => {
    const { t } = useTranslation();

    const { data, showPressureLevel, country } = props;

    const future = (costSavingTypes) => {
        return data.find(x => x.costSavingMeasure == 'Future' && x.costSavingType == costSavingTypes)
    }

    const actual = (costSavingTypes) => {
        return data.find(x => x.costSavingMeasure == 'Actual' && x.costSavingType == costSavingTypes)
    }

    const savings = (costSavingTypes) => {
        return data.find(x => x.costSavingMeasure == 'Savings' && x.costSavingType == costSavingTypes)
    }

    return (
        <View style={StyleTable.table} wrap={false}>
            <View style={StyleTable.tableRow}>
                <View style={StyleTable.tableColRowZero}>
                    <Text style={StyleTable.tableCell}></Text>
                </View>
                {showPressureLevel === true &&
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t(pressureDropBar) + Fmt.getUnitInBracket("pressure", t)}</Text>
                    </View>
                }
                <View style={StyleTable.tableColRowZero}>
                    <Text style={StyleTable.tableCell}>{SplitText(t(electricityConsumptionkWh), 10) + Fmt.getUnitInBracket("powerPerHour", t)}</Text>
                </View>
                <View style={StyleTable.tableColRowZero}>
                    <Text style={StyleTable.tableCell}>{t(airConsumptionNm3) + Fmt.getUnitInBracket("cubicMeterOfGas", t)}</Text>
                </View>
                <View style={StyleTable.tableColRowZero}>
                    <Text style={StyleTable.tableCell}>{t(co2) + Fmt.getUnitInBracket("co2Wheight", t)}</Text>
                </View>
                <View style={StyleTable.tableColRowZero}>
                    <Text style={StyleTable.tableCell}>{SplitText(t(energyCosts), 10) + Fmt.getCurrency()}</Text>
                </View>
                <View style={StyleTable.tableColRowZero}>
                    <Text style={StyleTable.tableCellTotal}>{t(totalCosts) + Fmt.getCurrency()}</Text>
                </View>
                <View style={StyleTable.tableColRowZero}>
                    <Text style={StyleTable.tableCell}>{t(level) + Fmt.getPercentage()}</Text>
                </View>
            </View>
            <View style={StyleTable.tableRow}>
                <View style={StyleTable.tableCol}>
                    <Text style={StyleTable.tableCell}>{SplitText(t('Actual'), 10)}</Text>
                </View>
                {showPressureLevel === true &&
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(actual(savingsReducingThePressureDropTable).staticConsmuption, country, 2)}</Text>
                    </View>
                }
                <View style={StyleTable.tableCol}>
                    <Text style={StyleTable.tableCell}>{Fmt.round(actual(savingsReducingThePressureDropTable).electricityConsumption, country, 0)}</Text>
                </View>
                <View style={StyleTable.tableCol}>
                    <Text style={StyleTable.tableCell}>{Fmt.round(actual(savingsReducingThePressureDropTable).airConsumption, country, 0)}</Text>
                </View>
                <View style={StyleTable.tableCol}>
                    <Text style={StyleTable.tableCell}>{Fmt.round(actual(savingsReducingThePressureDropTable).cO2, country, 0)}</Text>
                </View>
                <View style={StyleTable.tableCol}>
                    <Text style={StyleTable.tableCell}>{Fmt.round(actual(savingsReducingThePressureDropTable).energyCosts, country, 0)}</Text>
                </View>
                <View style={StyleTable.tableCol}>
                    <Text style={StyleTable.tableCellTotal}>{Fmt.round(actual(savingsReducingThePressureDropTable).totalCosts, country, 0)}</Text>
                </View>
                <View style={StyleTable.tableCol}>
                    <Text style={StyleTable.tableCell}>{Fmt.round(actual(savingsReducingThePressureDropTable).level, country, 0)}</Text>
                </View>
            </View>
            <View style={StyleTable.tableRow}>
                <View style={StyleTable.tableCol}>
                    <Text style={StyleTable.tableCell}>{SplitText(t('Future'), 10)}</Text>
                </View>
                {showPressureLevel === true &&
                    <View style={StyleTable.tableCol}>
                    <Text style={StyleTable.tableCell}>{Fmt.round(future(savingsReducingThePressureDropTable).staticConsmuption, country, 2)}</Text>
                    </View>
                }
                <View style={StyleTable.tableCol}>
                    <Text style={StyleTable.tableCell}>{Fmt.round(future(savingsReducingThePressureDropTable).electricityConsumption, country, 0)}</Text>
                </View>
                <View style={StyleTable.tableCol}>
                    <Text style={StyleTable.tableCell}>{Fmt.round(future(savingsReducingThePressureDropTable).airConsumption, country, 0)}</Text>
                </View>
                <View style={StyleTable.tableCol}>
                    <Text style={StyleTable.tableCell}>{Fmt.round(future(savingsReducingThePressureDropTable).cO2, country, 0)}</Text>
                </View>
                <View style={StyleTable.tableCol}>
                    <Text style={StyleTable.tableCell}>{Fmt.round(future(savingsReducingThePressureDropTable).energyCosts, country, 0)}</Text>
                </View>
                <View style={StyleTable.tableCol}>
                    <Text style={StyleTable.tableCellTotal}>{Fmt.round(future(savingsReducingThePressureDropTable).totalCosts, country, 0)}</Text>
                </View>
                <View style={StyleTable.tableCol}>
                    <Text style={StyleTable.tableCell}>{Fmt.round(future(savingsReducingThePressureDropTable).level, country, 0)}</Text>
                </View>
            </View>
            <View style={StyleTable.tableRow}>
                <View style={StyleTable.tableColRowZero}>
                    <Text style={StyleTable.tableCell}>{SplitText(t('SavingsCompressedAirGeneration'), 10)}</Text>
                </View>
                {showPressureLevel === true &&
                    <View style={StyleTable.tableColRowZero}>
                    <Text style={StyleTable.tableCell}>{Fmt.round(savings(savingsReducingThePressureDropTable).staticConsmuption, country, 2)}</Text>
                    </View>
                }
                <View style={StyleTable.tableColRowZero}>
                    <Text style={StyleTable.tableCell}>{Fmt.round(savings(savingsReducingThePressureDropTable).electricityConsumption, country, 0)}</Text>
                </View>
                <View style={StyleTable.tableColRowZero}>
                    <Text style={StyleTable.tableCell}>{Fmt.round(savings(savingsReducingThePressureDropTable).airConsumption, country, 0)}</Text>
                </View>
                <View style={StyleTable.tableColRowZero}>
                    <Text style={StyleTable.tableCell}>{Fmt.round(savings(savingsReducingThePressureDropTable).cO2, country, 0)}</Text>
                </View>
                <View style={StyleTable.tableColRowZero}>
                    <Text style={StyleTable.tableCell}>{Fmt.round(savings(savingsReducingThePressureDropTable).energyCosts, country, 0)}</Text>
                </View>
                <View style={StyleTable.tableColRowZero}>
                    <Text style={StyleTable.tableCellTotal}>{Fmt.round(savings(savingsReducingThePressureDropTable).totalCosts, country, 0)}</Text>
                </View>
                <View style={StyleTable.tableColRowZero}>
                    <Text style={StyleTable.tableCell}>{Fmt.round(savings(savingsReducingThePressureDropTable).level, country, 0)}</Text>
                </View>
            </View>
        </View>
    );
}

export default SavingsReducingThePressureDropTable;