import React from 'react';
import { useTranslation } from 'react-i18next';
import * as borders from '../../../../../helper/ExcelHelpers/Constants';
// @ts-expect-error not converted yet
import { GenerateRows, GenerateTopHeader } from '../../../../../helper/ExcelHelpers/ExcelHelper';
import * as Fmt from '../../../../../helper/Formatters';
import { ExcelFile, ExcelSheet } from 'react-xlsx-wrapper';
import { ICO2Report } from '../ReportGeneration';

const styleRulesFullReport = [
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topRightThick, middle: borders.rightThick, last: borders.bottomRightThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0.0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topRightThick, middle: borders.rightThick, last: borders.bottomRightThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0.0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topRightThick, middle: borders.rightThick, last: borders.bottomRightThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0.0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topRightThick, middle: borders.rightThick, last: borders.bottomRightThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0.0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topRightThick, middle: borders.rightThick, last: borders.bottomRightThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topRightThick, middle: borders.rightThick, last: borders.bottomRightThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
];

const styleRulesLeakageLevelCosts = [
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0.0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topRightThick, middle: borders.rightThick, last: borders.bottomRightThick, numFmt: "0", },
];

const styleRulesDetectedLeakages = [
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0.0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topRightThick, middle: borders.rightThick, last: borders.bottomRightThick, numFmt: "0", },
];

const styleRulesCumulatedTotalSavings = [
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topThick, middle: borders.basicBorder, last: borders.bottomThick, numFmt: "0", },
    { first: borders.topRightThick, middle: borders.rightThick, last: borders.bottomRightThick, numFmt: "0", },
];

interface IProps {
    data: ICO2Report[]
}

export const ReportToExcel = (props:IProps) => {
    const { data } = props;
    const button: React.RefObject<HTMLButtonElement> = React.createRef();

    React.useEffect(() => {
        button.current?.click();
    }, []);

    const { t } = useTranslation();
    const headerArray = [
        { title: "" },
        { title: "" },
        { title: "" },
        { title: "" },
        { title: "" },
        { title: "" },
        { title: t("ProjectInformation"), },
        { title: "" },
        { title: "" },
        { title: "" },
        { title: "" },
        { title: "" },
        { title: "" },
        { title: "", style: { border: borders.leftThickOnly, }, },
        { title: "" },
        { title: "" },
        { title: t("LeakageLevelCosts"), },
        { title: "" },
        { title: "" },
        { title: "", style: { border: borders.leftThickOnly, }, },
        { title: "" },
        { title: "" },
        { title: "" },
        { title: t("DetectedLeakages"), },
        { title: "" },
        { title: "" },
        { title: "", style: { border: borders.leftThickOnly, }, },
        { title: "" },
        { title: "" },
        { title: "" },
        { title: t("RepairedLeakages"), },
        { title: "" },
        { title: "" },
        { title: "", style: { border: borders.leftThickOnly, }, },
        { title: "" },
        { title: "" },
        { title: "" },
        { title: t("OpenLeakages"), },
        { title: "" },
        { title: "" },
        { title: "", style: { border: borders.leftThickOnly, }, },
        { title: t("RepairTime"), },
        { title: "", style: { border: borders.leftThickOnly, }, },
        { title: "" },
        { title: "" },
        { title: t("CumulatedTotalSavings"), },
        { title: "" },
        { title: "" },
    ];

    const labelArray = [
        { title: t("ProjectNo"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("Status"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("ExecutorName"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("ProjectName"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("ChooseCustomer"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("CompanyName"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("Address"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("Postcode"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("City"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("PhoneNumber"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("CustomerCountry"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("ServiceType"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("CreationDate"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("Currecy"), width: { wch: borders.titleWidth }, style: { border: borders.rightThick, }, },
        { title: t("AverageAirFlow") + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("ElectricityConsumption") + Fmt.getUnitInBracket("powerPerHour", t), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("AirConsumption") + Fmt.getUnitInBracket("cubicMeterOfGas", t), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("CO2") + Fmt.getUnitInBracket("co2Wheight", t), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("EnergyCost") + " (*)", width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("TotalCosts"), width: { wch: borders.titleWidth }, style: { border: borders.rightThick, }, },
        { title: t("Quantity"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("Leakages") + Fmt.getUnitInBracket("volumeFlowRate", t), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("AirVolume") + Fmt.getUnitInBracket("cubicMeter", t), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("LeakageLevel") + Fmt.getPercentage(), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("ElectricityConsumption") + Fmt.getUnitInBracket("powerPerHour", t), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("CO2PerYear") + Fmt.getUnitInBracket("co2Wheight", t), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("CostPerYear") + " (*)", width: { wch: borders.titleWidth }, style: { border: borders.rightThick, }, },
        { title: t("Quantity"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("Leakages") + Fmt.getUnitInBracket("volumeFlowRate", t), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("AirVolume") + Fmt.getUnitInBracket("cubicMeter", t), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("LeakageLevel") + Fmt.getPercentage(), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("ElectricityConsumption") + Fmt.getUnitInBracket("powerPerHour", t), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("CO2PerYear") + Fmt.getUnitInBracket("co2Wheight", t), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("CostPerYear") + " (*)", width: { wch: borders.titleWidth }, style: { border: borders.rightThick, }, },
        { title: t("Quantity"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("Leakages") + Fmt.getUnitInBracket("volumeFlowRate", t), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("AirVolume") + Fmt.getUnitInBracket("cubicMeter", t), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("LeakageLevel") + Fmt.getPercentage(), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("ElectricityConsumption") + Fmt.getUnitInBracket("powerPerHour", t), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("CO2PerYear") + Fmt.getUnitInBracket("co2Wheight", t), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("CostPerYear") + " (*)", width: { wch: borders.titleWidth }, style: { border: borders.rightThick, }, },
        { title: t("Estimated"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("Repaired"), width: { wch: borders.titleWidth }, style: { border: borders.rightThick, }, },
        { title: t("ElectricityConsumption") + Fmt.getUnitInBracket("powerPerHour", t), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("AirConsumption") + Fmt.getUnitInBracket("cubicMeterOfGas", t), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("CO2") + Fmt.getUnitInBracket("co2Wheight", t), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("EnergyCost") + " (*)", width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
        { title: t("TotalCosts"), width: { wch: borders.titleWidth }, style: { border: borders.basicBorder, }, },
    ];

    const generateFullReport = () => [
        GenerateTopHeader("CO2Report", t),
        {
            ySteps: 2,
            columns: headerArray,
            data: [],
        },
        {
            columns: labelArray,
            data: GenerateRows(data.map(({ pi_nr,
                pi_status,
                pi_executor,
                pi_projectName,
                pi_customerNumber,
                pi_companyName,
                pi_address,
                pi_postcode,
                pi_city,
                pi_phoneNumber,
                pi_country,
                pi_serviceType,
                pi_createdDate,
                pi_currency,
                la_averageAirFlow,
                la_electricity,
                la_air,
                la_co2,
                la_energyCosts,
                la_totalCosts,
                ldar_detected_quantity,
                ldar_detected_flowTotal,
                ldar_detected_airVolume,
                ldar_detected_percentage,
                ldar_detected_electricityConsumption,
                ldar_detected_co2,
                ldar_detected_costs,
                ldar_repaired_quantity,
                ldar_repaired_flowTotal,
                ldar_repaired_airVolume,
                ldar_repaired_percentage,
                ldar_repaired_electricityConsumption,
                ldar_repaired_co2,
                ldar_repaired_costs,
                ldar_open_quantity,
                ldar_open_flowTotal,
                ldar_open_airVolume,
                ldar_open_percentage,
                ldar_open_electricityConsumption,
                ldar_open_co2,
                ldar_open_costs,
                ldar_repairTimePlanned,
                ldar_repairTimeRepaired,
                cs_electricityConsuption,
                cs_airConsumption,
                cs_co2,
                cs_energyCosts,
                cs_totalCosts
            }) => ({
                pi_nr,
                pi_status,
                pi_executor,
                pi_projectName,
                pi_customerNumber,
                pi_companyName,
                pi_address,
                pi_postcode,
                pi_city,
                pi_phoneNumber,
                pi_country,
                pi_serviceType,
                pi_createdDate,
                pi_currency,
                la_averageAirFlow,
                la_electricity,
                la_air,
                la_co2,
                la_energyCosts,
                la_totalCosts,
                ldar_detected_quantity,
                ldar_detected_flowTotal,
                ldar_detected_airVolume,
                ldar_detected_percentage,
                ldar_detected_electricityConsumption,
                ldar_detected_co2,
                ldar_detected_costs,
                ldar_repaired_quantity,
                ldar_repaired_flowTotal,
                ldar_repaired_airVolume,
                ldar_repaired_percentage,
                ldar_repaired_electricityConsumption,
                ldar_repaired_co2,
                ldar_repaired_costs,
                ldar_open_quantity,
                ldar_open_flowTotal,
                ldar_open_airVolume,
                ldar_open_percentage,
                ldar_open_electricityConsumption,
                ldar_open_co2,
                ldar_open_costs,
                ldar_repairTimePlanned,
                ldar_repairTimeRepaired,
                cs_electricityConsuption,
                cs_airConsumption,
                cs_co2,
                cs_energyCosts,
                cs_totalCosts
            }))
                .map(x => {
                    x.pi_serviceType = Array.isArray(x.pi_serviceType) ? x.pi_serviceType.map(serviceType => t(serviceType)).join("; ") : "";
                    return x;
                }), styleRulesFullReport),
        },
    ];

    const generateLeakageLevelCosts = () =>
        [
            GenerateTopHeader("LeakageLevelCosts", t),
            {
                ySteps: 2,
                columns: [headerArray[0], ...headerArray.slice(14, 20)],
                data: [],
            },
            {
                columns: [labelArray[0], ...labelArray.slice(14, 20)],
                data: GenerateRows(data.map(({ pi_nr, la_averageAirFlow, la_electricity, la_air, la_co2, la_energyCosts, la_totalCosts }) => ({ pi_nr, la_averageAirFlow, la_electricity, la_air, la_co2, la_energyCosts, la_totalCosts })),
                    styleRulesLeakageLevelCosts),
            },
        ];

    const generateDetectedLeakages = () => [
        GenerateTopHeader("DetectedLeakages", t),
        {
            ySteps: 2,
            columns: [headerArray[0], ...headerArray.slice(20, 27)],
            data: [],
        },
        {
            columns: [labelArray[0], ...labelArray.slice(20, 27)],
            data: GenerateRows(data.map(({ pi_nr,
                ldar_detected_quantity,
                ldar_detected_flowTotal,
                ldar_detected_airVolume,
                ldar_detected_percentage,
                ldar_detected_electricityConsumption,
                ldar_detected_co2,
                ldar_detected_costs
            }) => ({
                pi_nr,
                ldar_detected_quantity,
                ldar_detected_flowTotal,
                ldar_detected_airVolume,
                ldar_detected_percentage,
                ldar_detected_electricityConsumption,
                ldar_detected_co2,
                ldar_detected_costs
            })
            ),
                styleRulesDetectedLeakages),
        },
    ];

    const generateCumulatedTotalSavings = () => [
        GenerateTopHeader("CumulatedTotalSavings", t),
        {
            ySteps: 2,
            columns: [...headerArray.slice(43, 47),
            { title: "" },
            {
                title: "",
                style: {
                    border: borders.leftThickOnly,
                },
            }],
            data: [],
        },
        {
            columns: [labelArray[0], ...labelArray.slice(43, 47), {
                title: t("TotalCosts"),
                width: { wch: borders.titleWidth },
                style: {
                    border: borders.rightThick,
                },
            }],
            data: GenerateRows(data.map(({ pi_nr, cs_electricityConsuption, cs_airConsumption, cs_co2, cs_energyCosts, cs_totalCosts }) => ({ pi_nr, cs_electricityConsuption, cs_airConsumption, cs_co2, cs_energyCosts, cs_totalCosts })),
                styleRulesCumulatedTotalSavings),
        },
    ];

    return (
        <ExcelFile
            filename="FESS-Portal Report"
            element={<button className='hidden' ref={button}/>}
        >
            <ExcelSheet dataSet={generateFullReport()} name="Report" />
            <ExcelSheet dataSet={generateLeakageLevelCosts()} name="LeakageLevelCosts" />
            <ExcelSheet dataSet={generateDetectedLeakages()} name="DetectedLeakages" />
            <ExcelSheet dataSet={generateCumulatedTotalSavings()} name="CumulatedTotalSavings" />
        </ExcelFile>
    );
};
