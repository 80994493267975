import React from "react";
import { useTranslation } from 'react-i18next';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { StyledTooltip } from '../MaterialUIStyles/Styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Tooltip, TextField, styled } from '@mui/material';
import * as DateHelper from '../../../helper/DateHelper';

const StyledDatePicker = styled(DatePicker)((props) => ({
    margin: 0,
    width: "100%",
    height: '49px',
    '& .Mui-disabled': {
        color: props.disabled && 'rgba(0, 0, 0, 0.87)'
    },
    '& .MuiInputLabel-root.Mui-error': {
        color: "#D50000 !important"
    },
    '& label': {
        marginTop: '2px',
    },
    '& input': {
        padding: "6.5px 0px 4px 0px",
    },
    '& fieldset': {
        border: "none",
        borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
        borderRadius: 0
    }
}));

function TextFieldWithTooltip(props) {
    const { model } = props;

    let title = (model.isValid && model.validationError) ? "" : model.validationError
    return (
        <Tooltip title={title} arrow classes={StyledTooltip} placement="bottom-end">
            <TextField
                variant="standard"
                {...props}
                required={model.validators?.length > 0 && !model.notRequired}
            />
        </Tooltip>
    );
}

const DatePickerComponent = (props) => {
    const { t } = useTranslation();
    const [error, setError] = React.useState(null);
    const [isOpen, setIsOpen] = React.useState(false);

    const memoDatePicker = React.useMemo(() => {
        const { model, onChange, disabled, id } = props;
        const onChangeWrapper = (e) => {
            let date = e;
            if (date != undefined && date != "Invalid Date") {
                date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
            }
            onChange(date);
            setIsOpen(false);
        };

        return <StyledDatePicker
            {...props}
            {...(!model.isValid && model.value === null && { error: true, helperText: '' })}
            fullWidth
            disableToolbar
            variant="inline"
            format={DateHelper.LocaleDateFormat}
            margin="normal"
            label={model.label}
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            value={typeof model.value?.getMonth === 'function' ? model.value : new Date(model.value).getFullYear() !== 1970 ? new Date(model.value) : null}
            onError={(newError) => newError === null ? setError(model.notRequired !== true ? 'invalidDate' : newError) : setError(newError)}
            onChange={!disabled && (e => onChangeWrapper(e))}
            slots={{
                textField: TextFieldWithTooltip
            }}
            slotProps={{
                textField: {
                    ...props,
                    error: !model.isValid,
                    id: id ? "date_" + id : "date_" + model.idkey,
                    helperText: () => {
                        switch (error) {
                            case 'invalidDate':
                            case 'maxDate':
                            case 'minDate': {
                                return t("PleaseVerifyYourEntriesError");
                            }
                            default: {
                                return '';
                            }
                        }
                    }
                },
            }}
        />
    }, [props, error, isOpen]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            {memoDatePicker}
        </LocalizationProvider>
    );
}

export default DatePickerComponent;
