import React from "react";
import ReactExport from "react-xlsx-wrapper";
import { useTranslation } from 'react-i18next';
import * as Fmt from '../../../helper/Formatters'
import * as borders from '../../../helper/ExcelHelpers/Constants'
import { ButtonComponent } from '../../Reusable/';
import { GenerateTopHeader, GenerateRows } from '../../../helper/ExcelHelpers/ExcelHelper';
import * as DateHelper from '../../../helper/DateHelper';
import _ from 'lodash';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const styleRules = {
    0: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    1: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    2: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    3: {
        first: borders.topRightThick,
        middle: borders.rightThick,
        last: borders.bottomRightThick,
    },
    4: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    5: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    6: {
        first: borders.topRightThick,
        middle: borders.rightThick,
        last: borders.bottomRightThick,
    },
    7: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    8: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
        numFmt: "0.00"
    },
    9: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
        numFmt: "0.00"
    },
    10: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
        numFmt: "0.00"
    },
    11: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    12: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    13: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
        numFmt: "0.00"
    },
    14: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    15: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    16: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    17: {
        first: borders.topRightThick,
        middle: borders.rightThick,
        last: borders.bottomRightThick,
    },
    18: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    19: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    20: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    21: {
        first: borders.topRightThick,
        middle: borders.rightThick,
        last: borders.bottomRightThick,
    },
    22: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    23: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    24: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    25: {
        first: borders.topRightThick,
        middle: borders.rightThick,
        last: borders.bottomRightThick,
    },
    26: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    27: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    28: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    29: {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    },
    30: {
        first: borders.topRightThick,
        middle: borders.rightThick,
        last: borders.bottomRightThick,
    },
    31: {
        first: borders.topRightThick,
        middle: borders.rightThick,
        last: borders.bottomRightThick,
    }
};


export const LeakageToExcel = (props) => {
    const { data } = props;
    const { t } = useTranslation();

    const generateDataSet = () => {
        return [
            GenerateTopHeader("ReportLeakageDetection", t),
            {
                ySteps: 2,
                columns: [
                    { title: "" },
                    {
                        title: t("LeakageIdentification"),
                    },
                    { title: "" },
                    {
                        title: "",
                        style: {
                            border: borders.leftThickOnly,
                        },
                    },
                    { title: "" },
                    { title: t("Location") },
                    {
                        title: "",
                        style: {
                            border: borders.leftThickOnly,
                        },
                    },
                    { title: "" },
                    { title: "" },
                    { title: "" },
                    { title: "" },
                    { title: t("LeakageDetails") },
                    { title: "" },
                    { title: "" },
                    { title: "" },
                    { title: "" },
                    { title: "" },
                    {
                        title: "",
                        style: {
                            border: borders.leftThickOnly,
                        },
                    },
                    { title: "" },
                    { title: t("LeakageRepair") },
                    { title: "" },
                    {
                        title: "",
                        style: {
                            border: borders.leftThickOnly,
                        },
                    },
                    { title: "" },
                    { title: t("DefectedDevice") },
                    { title: "" },
                    {
                        title: "",
                        style: {
                            border: borders.leftThickOnly,
                        },
                    },
                    { title: "" },
                    { title: "" },
                    { title: t("Replacement") },
                    { title: "" },
                    {
                        title: "",
                        style: {
                            border: borders.leftThickOnly,
                        },
                    },
                    {
                        title: "",
                        style: {
                            border: borders.leftThickOnly,
                        },
                    },
                ],
                data: [],
            },
            {
                columns: [
                    {
                        title: t("LeakageNr"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        },
                    },
                    {
                        title: t("MeasurementId"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        },
                    },
                    {
                        title: t("EntryDate"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        },
                    },
                    {
                        title: t("DetectedBy"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.rightThick,
                        },
                    },
                    {
                        title: t("Building"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        },
                    },
                    {
                        title: t("Department"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        },
                    },
                    {
                        title: t("Machine"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.rightThick,
                        },
                    },
                    {
                        title: t("LeakageInputDevice"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        },
                    },
                    {
                        title: t("LeakageInputValue"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        },
                    },
                    {
                        title:
                            t("AirFlow") +
                            Fmt.getUnitInBracket(
                                "volumeFlowRate",
                                t
                            ),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        },
                    },
                    {
                        title:
                            t("VolumePerYear") +
                            Fmt.getUnitInBracket(
                                "cubicMeterOfGas",
                                t
                            ),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        },
                    },
                    {
                        title: t("EnergyConsumptionPerYear") + Fmt.getUnitInBracket("powerPerHour", t),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        },
                    },
                    {
                        title: t("CO2EmissionsPerYear") + Fmt.getUnitInBracket("co2Wheight", t),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        },
                    },
                    {
                        title: t("CostPerYear") + Fmt.getCurrency(),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        },
                    },
                    {
                        title: t("LeakageAction"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        }
                    },
                    {
                        title: t("RepairTime") + Fmt.addBrackets("min", t),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        }
                    },
                    {
                        title: t("LeakageOptimization"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        }
                    },
                    {
                        title: t("LeakageRepearableProdTime"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.rightThick,
                        },
                    },
                    {
                        title: t("LeakageRepaired"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        }
                    },
                    {
                        title: t("RepairedBy"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        }
                    },
                    {
                        title: t("RepairDate"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        }
                    },
                    {
                        title: t("LeakageRepairTimeRepairedInput"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.rightThick,
                        },
                    },
                    {
                        title: t("PartNumber"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        }
                    },
                    {
                        title: t("TypeCode"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        }
                    },
                    {
                        title: t("Component"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        }
                    },
                    {
                        title: t("Manufacturer"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.rightThick,
                        },
                    },
                    {
                        title: t("PartNumber"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        }
                    },
                    {
                        title: t("TypeCode"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        }
                    },
                    {
                        title: t("Component"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        }
                    },
                    {
                        title: t("Manufacturer"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.basicBorder,
                        },
                    },
                    {
                        title: t("Quantity"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.rightThick,
                        }
                    },
                    {
                        title: t("Comment"),
                        width: { wch: borders.titleWidth },
                        style: {
                            border: borders.rightThick,
                        },
                    },
                ],
                data: GenerateRows(_.cloneDeep(data).map(({
                    leackageNumber,
                    measurementId,
                    entryDate,
                    detectedBy,
                    building,
                    department,
                    machine,
                    leakageInputDevice,
                    leakageInputValue,
                    airFlow,
                    volumePerYear,
                    energyPerYear,
                    emissionsPerYear,
                    costPerYear,
                    leakageAction,
                    repairTimeEstimated,
                    leakageOptimization,
                    leakageRepearableProdTime,
                    leakageRepaired,
                    repairedBy,
                    repairDate,
                    leakageRepairTimeRepairedInput,
                    articleNrDefected,
                    typeCodeDefected,
                    componentDefected,
                    manufacturerDefected,
                    articleNrReplacement,
                    typeCodeReplacement,
                    newComponent,
                    manufacturerReplacement,
                    quantity,
                    leakageComment
                }) => ({
                    leackageNumber,
                    measurementId,
                    entryDate,
                    detectedBy,
                    building,
                    department,
                    machine,
                    leakageInputDevice,
                    leakageInputValue,
                    airFlow,
                    volumePerYear,
                    energyPerYear,
                    emissionsPerYear,
                    costPerYear,
                    leakageAction,
                    repairTimeEstimated,
                    leakageOptimization,
                    leakageRepearableProdTime,
                    leakageRepaired,
                    repairedBy,
                    repairDate,
                    leakageRepairTimeRepairedInput,
                    articleNrDefected,
                    typeCodeDefected,
                    componentDefected,
                    manufacturerDefected,
                    articleNrReplacement,
                    typeCodeReplacement,
                    newComponent,
                    manufacturerReplacement,
                    quantity,
                    leakageComment
                })).map(el => {
                    el.entryDate = DateHelper.FormatDate(el.entryDate);
                    el.repairDate = el.leakageRepaired == 'Yes'? DateHelper.FormatDate(el.repairDate) : "";
                    el.leakageInputDevice = t(el.leakageInputDevice);
                    el.leakageAction = t(el.leakageAction);
                    el.componentDefected = t(el.componentDefected);
                    el.newComponent = t(el.newComponent);
                    return el;
                }), styleRules),
            },
        ];
    };

    return (
        <ExcelFile
            filename="Leakage_list"
            element={
                <ButtonComponent id="btn_LDAR-Excel-leakages" value={t("LeakagesExcel")} color="primary"></ButtonComponent>
            }
        >
            <ExcelSheet dataSet={generateDataSet()} name="Leakages List" />
        </ExcelFile>
    );
};
