export const first = "first";
export const middle = "middle";
export const last = "last";

export const titleWidth = 15;

export const leftThickOnly = {
    right: { style: "thick", fgColor: { rgb: "FFFF0000" } }
}

export const basicBorder = {
    top: { style: "thin", fgColor: { rgb: "FFFF0000" } },
    bottom: { style: "thin", fgColor: { rgb: "FFFF0000" } },
    left: { style: "thin", fgColor: { rgb: "FFFF0000" } },
    right: { style: "thin", fgColor: { rgb: "FFFF0000" } },
  };
  
  export const leftThick = {
    top: { style: "thin", fgColor: { rgb: "FFFF0000" } },
    bottom: { style: "thin", fgColor: { rgb: "FFFF0000" } },
    left: { style: "thick", fgColor: { rgb: "FFFF0000" } },
    right: { style: "thin", fgColor: { rgb: "FFFF0000" } },
  };
  export const rightThick = {
    top: { style: "thin", fgColor: { rgb: "FFFF0000" } },
    bottom: { style: "thin", fgColor: { rgb: "FFFF0000" } },
    left: { style: "thin", fgColor: { rgb: "FFFF0000" } },
    right: { style: "thick", fgColor: { rgb: "FFFF0000" } },
  };
  
  export const bottomThick = {
    top: { style: "thin", fgColor: { rgb: "FFFF0000" } },
    bottom: { style: "thick", fgColor: { rgb: "FFFF0000" } },
    left: { style: "thin", fgColor: { rgb: "FFFF0000" } },
    right: { style: "thin", fgColor: { rgb: "FFFF0000" } },
  };
  
  export const topThick = {
    top: { style: "thick", fgColor: { rgb: "FFFF0000" } },
    bottom: { style: "thin", fgColor: { rgb: "FFFF0000" } },
    left: { style: "thin", fgColor: { rgb: "FFFF0000" } },
    right: { style: "thin", fgColor: { rgb: "FFFF0000" } },
  };
  
  export const topRightThick = {
    top: { style: "thick", fgColor: { rgb: "FFFF0000" } },
    bottom: { style: "thin", fgColor: { rgb: "FFFF0000" } },
    left: { style: "thin", fgColor: { rgb: "FFFF0000" } },
    right: { style: "thick", fgColor: { rgb: "FFFF0000" } },
  };
  
  export const bottomRightThick = {
    top: { style: "thin", fgColor: { rgb: "FFFF0000" } },
    bottom: { style: "thick", fgColor: { rgb: "FFFF0000" } },
    left: { style: "thin", fgColor: { rgb: "FFFF0000" } },
    right: { style: "thick", fgColor: { rgb: "FFFF0000" } },
  };

