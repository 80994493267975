import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { TextFieldComponent, ButtonComponent, SingleSelectComponent } from '../../../../Reusable';
import { MoveLicenseFactory } from './MoveLicenseModel';
import { API } from '../../../../../helper/ApiHelper';
// @ts-expect-error not converted yet
import { updateModel, updateSelectModel, extractValuesFromModel } from '../../../../../helper/Mappers';
// @ts-expect-error not converted yet
import { checkAndValidateModel } from '../../../../../helper/Validator';
import { OpenGlobalSnackbar } from '../../../../../helper/GlobalVariables';
import { ExecutorStore } from '../../../../../store';
import { IdParam } from '../../../../Types/Types';
import { IAccountSelection } from '../../../../../store/AccountStore';

interface IProps {
    licenseId: number,
    refreshLicenses: () => void,
}

export default function MoveLicense(props: IProps) {
    const { licenseId, refreshLicenses } = props;

    const { t } = useTranslation();
    const { id } = useParams<IdParam>();

    const [mainModel, setMainModel] = useState(MoveLicenseFactory(t));
    const [open, setOpen] = useState(false);
    const [executorList, setExecutorList] = useState<IAccountSelection[]>([]);

    useEffect(() => {
        const execSub = ExecutorStore.executorList.subscribe(exList => {
            setExecutorList(exList.filter(x => x.id > 0));
        })
        return () => execSub.unsubscribe();
    }, []);

    useEffect(() => {
        if (open) {
            const licenseRequestModel = MoveLicenseFactory(t);
            setMainModel(licenseRequestModel)
        }
    }, [open])

    const getMoveLicenseUrl = () => process.env.REACT_APP_DEVFESSSERVICE_BASE + "account-management/account/" + id + "/move-license";

    const moveLicense = async () => {
        const [isValid, validatedModel] = checkAndValidateModel(mainModel, true);
        setMainModel(validatedModel);
        if (isValid) {
            const extractedModel = extractValuesFromModel(validatedModel);
            extractedModel.licenseId = licenseId;
            extractedModel.executorReceiverId = extractedModel.executorReceiverId?.id;
            await API.post(getMoveLicenseUrl(), extractedModel);
            refreshLicenses();
            setOpen(false);
        }
        else {
            OpenGlobalSnackbar("danger", t('ModelInvalidError'));
        }
    }

    return (
        <React.Fragment>
            <Dialog
                aria-labelledby="transition-modal-title"
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
            >
                <DialogTitle>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <h3 style={{ margin: "0px" }}>{t('MoveLicenseToOtherAccount')}</h3>
                        </Grid>
                        <Grid item>
                            <IconButton color="inherit" onClick={() => setOpen(false)} size="large">
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container direction="column" spacing={3}>
                        <Grid item xs={12}>
                            <SingleSelectComponent
                                model={mainModel.executorReceiverId}
                                t={t}
                                listFromDb={executorList}
                                onChange={(e: { target: { value: any; }; }) => updateSelectModel("executorReceiverId", e.target.value, executorList, mainModel, setMainModel)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldComponent
                                t={t}
                                model={mainModel.quantity}
                                onChange={e => updateModel("quantity", e.target.value, mainModel, setMainModel)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container direction="row" justifyContent="flex-end">
                        <Grid item>
                            <ButtonComponent value={t('Cancel')} color="secondary" onChange={() => setOpen(false)} id="MoveLicense-cancel"></ButtonComponent>
                        </Grid>
                        <Grid item>
                            <ButtonComponent value={t('Move')} color="primary" onChange={moveLicense} id="MoveLicense-move"></ButtonComponent>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            <ButtonComponent
                className="btn-no-left-margin"
                value={t("MoveLicenseToOtherAccount")}
                color="primary"
                onChange={() => setOpen(true)}
                id="executor-MoveLicense"
            />
        </React.Fragment >
    );
}
