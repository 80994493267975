import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalComponent } from '../../components/Reusable/'
import { useHistory, Prompt } from "react-router-dom";
import { DirtyStore } from '../../store';

export default function DirtyPrompt() {
    const { t } = useTranslation();
    let history = useHistory();

    const [modalModel, setModalModel] = useState({
        open: false,
        message: ""
    });

    useEffect(() => {
        let dirtyStoreSub = DirtyStore.DirtyModel.subscribe(isDirty => { if (isDirty !== false) DirtyStore.setConfirmNavigation(true) });
        return () => dirtyStoreSub.unsubscribe();
    }, []);

    const navigate = async (save, nextPath) => {
        DirtyStore.setConfirmNavigation(false);
        let isValid = true;
        if (save === true) {
            var fn = DirtyStore.DirtyModel.value;
            isValid = await fn();
        }
        if (isValid !== false) {
            history.push(nextPath);
        }
        DirtyStore.setIsDirty(false);
       
    }

    const handleBlockedNavigation = (nextLocation) => {
        if (DirtyStore.DirtyModel.value !== false && DirtyStore.ConfirmNavigationModel.value === true) {
            modalModel.open = true;
            modalModel.okText = t('Discard');
            modalModel.cancelText = t('SaveChanges');
            modalModel.message = t('UnsavedChangesPrompt');
            modalModel.clickOk = () => navigate(false, nextLocation.pathname);
            modalModel.clickCancel = () => navigate(true, nextLocation.pathname);
            modalModel.clickClose = () => DirtyStore.setConfirmNavigation(false);
            setModalModel({ ...modalModel });
            return false;
        }
        return true;
    };

    return (
        <div className="dirtyPrompt">
            <Prompt
                message={handleBlockedNavigation}
            />
            <ModalComponent
                model={modalModel}
            />
        </div>
    );
}