import React from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { usePdfStyles } from './PdfStyles';

export const PreAuditPdf = (props) => {
    const { t } = useTranslation();
    var PdfStyles = usePdfStyles();

    return (
        <Page size={props.pageSize} style={PdfStyles.page}>
            <View style={PdfStyles.header}>
                <View style={PdfStyles.logoWrapper}>
                    {props.logo && <Image style={PdfStyles.logo} src={props.logo} />}
                </View>
            </View>
            <View style={PdfStyles.pageBody}>
                <Text style={PdfStyles.heading}>{t('PercentegeofCompressedAir')}</Text>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('PneumaticTools')}</Text>
                    </View>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('LastMaintenance')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('PneumaticMachinery')}</Text>
                    </View>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('LastMaintenanceFrequency')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Process')}</Text>
                    </View>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('LastLeackageDetection')}</Text>
                    </View>
                </View>

                <View style={PdfStyles.flexRowStart}>
                    <View style={PdfStyles.flexItemNoGrow}>
                        <View style={PdfStyles.checkbox}></View>
                    </View>
                    <View style={PdfStyles.flexItemNoGrow}>
                        <Text style={PdfStyles.label}>{t('IsMaintanancePlanAvailable')}</Text>
                    </View>
                </View>

                <Text style={PdfStyles.heading}>{t('ProcessHeat')}</Text>
                <View style={PdfStyles.flexRowStart}>
                    <View style={PdfStyles.flexItemNoGrow}>
                        <View style={PdfStyles.checkbox}></View>
                    </View>
                    <View style={PdfStyles.flexItemNoGrow}>
                        <Text style={PdfStyles.label}>{t('IsMaintanancePlanAvailable')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRowStart}>
                    <View style={PdfStyles.flexItemNoGrow}>
                        <Text style={PdfStyles.label}>{t('HeatRecoverySystemQuestion')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRowStart}>
                    <View style={PdfStyles.flexItemNoGrow}>
                        <View style={PdfStyles.checkbox}></View>
                    </View>
                    <View style={PdfStyles.flexItemNoGrow}>
                        <Text style={PdfStyles.label}>{t('Heating')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRowStart}>
                    <View style={PdfStyles.flexItemNoGrow}>
                        <View style={PdfStyles.checkbox}></View>
                    </View>
                    <View style={PdfStyles.flexItemNoGrow}>
                        <Text style={PdfStyles.label}>{t('HotWaterGeneration')}</Text>
                    </View>
                </View>
                <Text style={PdfStyles.heading}>{t('Comment')}</Text>
                <View style={{ height: 100, border: "1px solid rgba(0, 0, 0, 1)" }} ></View>
            </View>

        </Page>
    );
}