import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { Grid, Button } from "@mui/material";
import PdfReport from "../PdfReport/PdfReport";
import { Check, ShoppingCart, Print, PlaylistAddCheck } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import PdfChecklistDialog from "../ProjectQrCodesAndTemplates/PdfChecklistDialog";
import * as DateHelper from '../../helper/DateHelper';
import { Typography } from "@mui/material";
import * as Consts from '../../helper/ConstantRepository';
import { ProjectStore, ExecutorStore } from '../../store';
import ReopenProjectDialog from './ReopenProjectDialog';
import QrCodeSearch from '../LandingPageView/QrCodeSearch';
import QrCodeGeneration from '../LandingPageView/QrCodeGeneration';


export default function ProjectView(props) {
    const { menuData, checklist } = props;

    const { t } = useTranslation();
    let history = useHistory();
    let { country, lng, id } = useParams();

    const [denyEdit, setDenyEdit] = useState(true);
    const [reopenProjectDialogOpen, setReopenProjectDialogOpen] = useState(false);
    const [pdfChecklistDialogOpen, setPdfChecklistDialogOpen] = useState(false);
    const [projectInfo, setProjectInfo] = useState({});
    const [executorSettings, setExecutorSettings] = useState({});

    useEffect(() => {
        const execSub = ExecutorStore.executorSettings.subscribe(exec => setExecutorSettings(exec));
        const projSub1 = ProjectStore.ProjectInfo.subscribe(x => setProjectInfo(x));
        const projSub2 = ProjectStore.DenyEdit.subscribe(x => setDenyEdit(x));
        return () => {
            projSub1.unsubscribe();
            projSub2.unsubscribe();
            execSub.unsubscribe();
        }
    }, []);

    const generateInfoSection = (name, address, city, propsCountry, contactPersom, telephone, email) => {
        return (
            <Grid item md={12} className="projectMenu-firsttext">
                <Grid container className="inner-text">
                    <Grid item className="inner-text-festo">
                        {name}
                    </Grid>
                    <Grid item className="inner-text-bodytext">
                        <p>{address}</p>
                        <p>{city}</p>
                        <br />
                        <p>{propsCountry}</p>
                        <p>{contactPersom}</p>
                        <p>{telephone}</p>
                        <p>{email}</p>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    var infoView = {}
    if (Object.keys(menuData).length > 0) {
        var name = menuData.companyName
        var address = menuData.address
        var city = menuData.city
        var propsCountry = menuData.country
        var contactPerson = menuData.contactPerson
        var phoneNumber = menuData.phoneNumber
        var email = menuData.email
        infoView = generateInfoSection(name, address, city, propsCountry, contactPerson, phoneNumber, email)

    } else {
        infoView = null;
    }

    const clickOpenCloseProject = () => {
        setReopenProjectDialogOpen(true);
    }

    return (
        <div className="project-view">
            <PdfChecklistDialog
                open={pdfChecklistDialogOpen}
                setOpen={setPdfChecklistDialogOpen}
                checklist={checklist}
            />
            <ReopenProjectDialog
                open={reopenProjectDialogOpen}
                setOpen={setReopenProjectDialogOpen}
                projectInfo={projectInfo}
            />
            <Grid container justifyContent="center" spacing={3} alignItems="center" direction="row" >
                <Grid item md={7}>
                    <Grid container justifyContent="center" alignItems="center" direction="column" >
                        <Grid item md={12}>
                            {infoView}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={5}>
                    <Grid container justifyContent="center" alignItems="center" direction="column" >
                        <Grid item md={12}>
                            <div className="projectMenu-secondtext">
                                {
                                    menuData.status == Consts.FINISHED ? (
                                        <Grid item className="project-finished">
                                            <Grid container direction="column" justifyContent="flex-end">
                                                <Grid item>
                                                    <Typography variant="caption" display="block">
                                                        {t("ProjectIsCompleted")}
                                                    </Typography>
                                                    <Typography variant="caption" display="block">
                                                        {t("CompletionFrom") + ": " + menuData.projectFinishUsername}
                                                    </Typography>
                                                    <Typography variant="caption" display="block">
                                                        {t("CompletionDate") + ": " + DateHelper.FormatDate(menuData.projectFinishDate)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>) : (<Grid item md={4} className="projectMenu-secondtext-top-spacer"></Grid>)
                                }
                                {(Object.keys(projectInfo).length > 0 &&
                                    (projectInfo.projectInfo.hideButtonReplacementPartsList === false ||
                                        projectInfo.projectRights.isSuperAdmin ||
                                        projectInfo.projectRights.isExecutorAdmin ||
                                        projectInfo.projectRights.isTechnician)
                                ) &&
                                    <Grid item>
                                        <Button
                                            onClick={e => { e.preventDefault(); history.push("/" + country + "/" + lng + "/project/" + id + "/order"); }}
                                            id="btn_Menu-Cart"
                                            className="projectMenu-button"
                                        >
                                            <ShoppingCart />
                                            {t("Cart")}
                                        </Button>
                                    </Grid>
                                }
                                <Grid item>
                                    <PdfReport />
                                </Grid>
                                {(Object.keys(projectInfo).length > 0 && projectInfo.projectRights.isReader) || denyEdit ? null :
                                    <QrCodeGeneration executorSettings={executorSettings} showPrintExistingCodes={true} />
                                }
                                {(Object.keys(projectInfo).length > 0 && projectInfo.projectRights.isReader) || denyEdit ? null :
                                    <Grid item>
                                        <Button
                                            onClick={e => { e.preventDefault(); setPdfChecklistDialogOpen(true); }}
                                            id="btn_Menu-Print-Template"
                                            className="projectMenu-button"
                                        >
                                            <Print />
                                            {t("PrintProjectTemplatesQrCodes")}
                                        </Button>
                                    </Grid>
                                }
                                {projectInfo?.projectRights?.hasEditRights === true &&
                                    <Grid item>
                                        <QrCodeSearch />
                                    </Grid>
                                }
                                {(Object.keys(projectInfo).length > 0 && projectInfo.projectRights.isReader) || denyEdit ? null :
                                    <Grid item>
                                        <Button
                                            onClick={e => { e.preventDefault(); history.push("/" + country + "/" + lng + "/project/" + id + "/validate"); }}
                                            id="btn_Menu-Validate-Project"
                                            className="projectMenu-button"
                                        >
                                            <PlaylistAddCheck />
                                            {t("ValidateProject")}
                                        </Button>
                                    </Grid>
                                }
                                {projectInfo?.projectRights?.hasEditRights === true &&
                                    <Grid item>
                                        <Button
                                            onClick={clickOpenCloseProject}
                                            id="btn_Menu-Finish-Project"
                                            className="projectMenu-button"
                                        >
                                            <Check />
                                            {menuData.status == Consts.RUNNING ? t("FinishProject") : t("BackToInitial")}
                                        </Button>
                                    </Grid>
                                }
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
