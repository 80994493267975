import React from 'react';
import { Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { usePdfStyles, StyleTable, StyleTableNoBorder } from './PdfStyles';
import { useTranslation } from 'react-i18next';
import * as Fmt from '../../../helper/Formatters';
import Watermark from './Watermark';
import { SplitText } from '../../../helper/PdfTested';

const styles = StyleSheet.create({
    noBorderCol: {
        borderBottom: "3px solid black",
        flexGrow: 1,
        flexBasis: 0
    },
});

export const LeakageAnalysisPdf = (props) => {
    const { country, data, inquiry } = props;

    const { t } = useTranslation();
    var PdfStyles = usePdfStyles();

    const staticConsumption = props.calcs?.consumptionCalcResults?.find(x => x.consumptionType == "Static");
    const yearlyConsumption = props.calcs?.consumptionCalcResults?.find(x => x.consumptionType == "Yearly");
    const futureConsumption = props.calcs?.consumptionCalcResults?.find(x => x.consumptionType == "Future");
    const leakageConsumption = props.calcs?.consumptionCalcResults?.find(x => x.consumptionType == "Leakage");

    const detectionRepair = props.calcs?.repairCalcResults?.find(x => x.repairType == "Detection");
    const laborRepair = props.calcs?.repairCalcResults?.find(x => x.repairType == "Labor");
    const sparePartsRepair = props.calcs?.repairCalcResults?.find(x => x.repairType == "SpareParts");
    const lumpSumRepair = props.calcs?.repairCalcResults?.find(x => x.repairType == "LumpSum");
    const travelTimeRepair = props.calcs?.repairCalcResults?.find(x => x.repairType == "TravelTime");
    const travelRepair = props.calcs?.repairCalcResults?.find(x => x.repairType == "Travel");
    const totalRepair = props.calcs?.repairCalcResults?.find(x => x.repairType == "Total");

    const AnnualSavings = props.calcs?.annualSavingsCalcResult;

    return (
        <Page size={props.pageSize} style={PdfStyles.page}>
            {inquiry === false && <Watermark projectData={data} />}
            <Text style={PdfStyles.pageNumbers} render={({ pageNumber, totalPages }) => (pageNumber + ' ' + t('of') + ' ' + totalPages)} fixed />
            <View wrap={false}>
                <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["Leakage"] = pageNumber }} />
                <Text style={PdfStyles.boldHeading}>{props.tocValues.tocNumbering.leakageAnalysis}. {t('LeakageAnalysis')}</Text>
            </View>
            <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.leakageAnalysis}.1 {t('CostsPerUnit')}</Text>
            <Text style={PdfStyles.spacer}></Text>
            <View style={StyleTable.table} wrap={false}>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}></Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('EnergyCost') + Fmt.getUnitWithCurrency("powerPerHour", t)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('CompressedAirCosts') + Fmt.getUnitWithCurrency("cubicMeterOfGas", t)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{t('Electricity')}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.costPerKwh, country, 2)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.costElectricityPerm, country, 3)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCellTotal}>{t('TotalCosts')}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCellTotal}>{Fmt.round(props.calcs?.costPerm, country, 3)}</Text>
                    </View>
                </View>
            </View>
            {/**------------------------------------------------------------------------ */}
            <Text style={PdfStyles.spacer}></Text>
            <View wrap={false}>
                <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["LeakageLevelCosts"] = pageNumber }} />
                <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.leakageAnalysis}.2 {t('LeakageLevelCosts')}</Text>
            </View>
            <Text style={PdfStyles.spacer}></Text>
            <View style={StyleTable.table} wrap={false}>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}></Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('Level') + Fmt.getPercentage()}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('AverageAirFlow') + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{SplitText(t('ElectricityConsumption') + Fmt.getUnitInBracket("powerPerHour", t), 12)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('AirConsumption') + Fmt.getUnitInBracket("cubicMeterOfGas", t)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('CO2') + Fmt.getUnitInBracket("co2Wheight", t)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCellTotal}>{t('TotalCosts') + Fmt.getCurrency()}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{t('YearlyConsumption')}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(yearlyConsumption?.airLevel, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(yearlyConsumption.averageAirFlow, country, 1)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(yearlyConsumption.electricity, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(yearlyConsumption.air, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(yearlyConsumption.co2, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCellTotal}>{Fmt.round(yearlyConsumption.totalCosts, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{t('StaticConsumption')}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(staticConsumption.airLevel, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(staticConsumption.averageAirFlow, country, 1)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(staticConsumption.electricity, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(staticConsumption.air, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(staticConsumption.co2, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCellTotal}>{Fmt.round(staticConsumption.totalCosts, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{t('ExpectedLeakages')}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(leakageConsumption.airLevel, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(leakageConsumption.averageAirFlow, country, 1)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(leakageConsumption.electricity, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(leakageConsumption.air, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(leakageConsumption.co2, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCellTotal}>{Fmt.round(leakageConsumption.totalCosts, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('FutureStaticConsumption')}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(futureConsumption.airLevel, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(futureConsumption.averageAirFlow, country, 1)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(futureConsumption.electricity, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(futureConsumption.air, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(futureConsumption.co2, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCellTotal}>{Fmt.round(futureConsumption.totalCosts, country, 0)}</Text>
                    </View>
                </View>
            </View>
            <Text style={PdfStyles.text}>* {t("InclusinveElectricityMaintenanceInvestmentCosts")}</Text>
            <Text style={PdfStyles.text}>** 25% {t('OfTheTotalCosts')}</Text>
            {/**------------------------------------------------------------------------ */}
            <Text style={PdfStyles.spacer}></Text>
            <View wrap={false}>
                <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["LeakageTotalCosts"] = pageNumber }} />
                <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.leakageAnalysis}.3 {t('TotalCosts')}</Text>
                <Text style={PdfStyles.spacer}></Text>
                <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.leakageAnalysis}.3.1 {t('LeakageDetectionAndRepairCosts')}</Text>
            </View>
            <Text style={PdfStyles.spacer}></Text>
            <View style={StyleTable.table} wrap={false}>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}></Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('Units')}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCell}>{t('CostPerUnit') + Fmt.getCurrency()}</Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCellTotal}>{t('TotalCosts') + Fmt.getCurrency()}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{t('LeakageDetectionCostsHeader')}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(detectionRepair.units, country, 0)} {t('UnitDay')}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(detectionRepair.costPerUnit, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCellTotal}>{Fmt.round(detectionRepair.totalCosts, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{t('LaborCostsRepairLeakages')}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(laborRepair.units, country, 0)} {t('UnitHour')}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(laborRepair.costPerUnit, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCellTotal}>{Fmt.round(laborRepair.totalCosts, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{t('SparePartsCost')}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(sparePartsRepair.units, country, 0)} {t('UnitLeakage')}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(sparePartsRepair.costPerUnit, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCellTotal}>{Fmt.round(sparePartsRepair.totalCosts, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{t('LumpSumNoSuffix')}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(lumpSumRepair.units, country, 0)} {t('UnitDay')}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(lumpSumRepair.costPerUnit, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCellTotal}>{Fmt.round(lumpSumRepair.totalCosts, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{t('TravelTimeCost')}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(travelTimeRepair.units, country, 0)} {t('UnitHour')}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(travelTimeRepair.costPerUnit, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCellTotal}>{Fmt.round(travelTimeRepair.totalCosts, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{t('TravelCost')}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(travelRepair.units, country, 0) + Fmt.getUnitInBracket("unitKmMile", t)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCell}>{Fmt.round(travelRepair.costPerUnit, country, 0)}</Text>
                    </View>
                    <View style={StyleTable.tableCol}>
                        <Text style={StyleTable.tableCellTotal}>{Fmt.round(travelRepair.totalCosts, country, 0)}</Text>
                    </View>
                </View>
                <View style={StyleTable.tableRow}>
                    <View style={styles.noBorderCol}>
                        <Text style={StyleTable.tableCell}>{t('LeakageDetectionAndRepairCosts')}</Text>
                    </View>
                    <View style={styles.noBorderCol}>
                        <Text style={StyleTable.tableCell}></Text>
                    </View>
                    <View style={styles.noBorderCol}>
                        <Text style={StyleTable.tableCell}></Text>
                    </View>
                    <View style={StyleTable.tableColRowZero}>
                        <Text style={StyleTable.tableCellTotal}>{Fmt.round(totalRepair.totalCosts, country, 0)}</Text>
                    </View>
                </View>
            </View>
            {/**------------------------------------------------------------------------ */}

            <Text style={PdfStyles.spacer}></Text>
            <View style={PdfStyles.content} >
                <View wrap={false} break={true}>
                    <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["AnnualSavingsAfterTaxes"] = pageNumber }} />
                    <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.leakageAnalysis}.3.2. {t('AnnualSavingsAfterTaxes')} </Text>
                </View>
                <Text style={PdfStyles.spacer}></Text>
                <View style={StyleTableNoBorder.table} wrap={false}>
                    <View style={StyleTableNoBorder.tableRow}>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{t('ServiceLifeOfEquipment')}</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableColColon}>
                            <Text style={StyleTableNoBorder.tableCell}>:</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{Fmt.round(AnnualSavings.serviceLife, country, 0)}</Text>
                        </View>
                    </View>
                    <View style={StyleTableNoBorder.tableRow}>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{t('AnnualFiscalDeprecation') + Fmt.getCurrency()}</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableColColon}>
                            <Text style={StyleTableNoBorder.tableCell}>:</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{Fmt.round(AnnualSavings.fiscalDeprecation, country, 0)}</Text>
                        </View>
                    </View>
                    <View style={StyleTableNoBorder.tableRow}>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{t('TaxableSavings') + Fmt.getCurrency()}</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableColColon}>
                            <Text style={StyleTableNoBorder.tableCell}>:</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{Fmt.round(AnnualSavings.taxSaving, country, 0)}</Text>
                        </View>
                    </View>
                    <View style={StyleTableNoBorder.tableRow}>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{t('TaxOnProfit')} {AnnualSavings.settingsTaxOnProfit}%{Fmt.getCurrency()}</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableColColon}>
                            <Text style={StyleTableNoBorder.tableCell}>:</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{Fmt.round(AnnualSavings.taxOnProfit, country, 0)}</Text>
                        </View>
                    </View>
                </View>
                <Text style={PdfStyles.spacer}></Text>
                <View style={StyleTableNoBorder.table}>
                    <View style={StyleTableNoBorder.tableRow}>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{t('LeakageCostsPerYear') + Fmt.getCurrency()}</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableColColon}>
                            <Text style={StyleTableNoBorder.tableCell}>:</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{Fmt.round(AnnualSavings.costPerYear, country, 0)}</Text>
                        </View>
                    </View>
                    <View style={StyleTableNoBorder.tableRow}>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{t('TaxOnProfit')} {AnnualSavings.settingsTaxOnProfit}%{Fmt.getCurrency()}</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableColColon}>
                            <Text style={StyleTableNoBorder.tableCell}>:</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{Fmt.round(AnnualSavings.taxOnProfit, country, 0)}</Text>
                        </View>
                    </View>
                    <View style={StyleTableNoBorder.tableRow}>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{t('AnnualSavingsAfterTaxes') + Fmt.getCurrency()}</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableColColon}>
                            <Text style={StyleTableNoBorder.tableCell}>:</Text>
                        </View>
                        <View style={StyleTableNoBorder.tableCol}>
                            <Text style={StyleTableNoBorder.tableCell}>{Fmt.round(AnnualSavings.annualSavingsAfterTaxes, country, 0)}</Text>
                        </View>
                    </View>
                </View>
                {/**------------------------------------------------------------------------ */}
                <Text style={PdfStyles.spacer}></Text>
                <View wrap={false}>
                    <View style={PdfStyles.content} >
                        <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["LeakageAnalysisPaybackTime"] = pageNumber }} />
                        <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.leakageAnalysis}.3.3. {t('PaybackTime')} </Text>
                        <Text style={PdfStyles.spacer}></Text>
                        <View style={StyleTableNoBorder.table}>
                            <View style={StyleTableNoBorder.tableRow}>
                                <View style={StyleTableNoBorder.tableCol}>
                                    <Text style={StyleTableNoBorder.tableCell}>{t('PaybackTime')}</Text>
                                </View>
                                <View style={StyleTableNoBorder.tableColColon}>
                                    <Text style={StyleTableNoBorder.tableCell}>:</Text>
                                </View>
                                <View style={StyleTableNoBorder.tableCol}>
                                    <Text style={StyleTableNoBorder.tableCell}>{Fmt.round(AnnualSavings.paybackTime, country, 1)} {t('Months')}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <Text style={PdfStyles.spacer}></Text>
                    <Image src={props.chart} />
                </View>
            </View>
        </Page>
    )
}