import React from 'react';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import * as Fmt from '../../../helper/Formatters';

const StyledResponsiveContainer = styled(ResponsiveContainer)({
    '& .recharts-surface': {
        overflow: 'visible',
        paddingLeft: '2rem'
    },
    '& .recharts-legend-wrapper': {
        '& .recharts-surface': {
            boxSizing: 'content-box'
        }
    }
});

export const DetectedLeakagesChart = (props) => {
    const { data } = props;
    const { t } = useTranslation();
    const generateChartData = () => {
        let chartData = [{
            name: t('QuantityOfDetectedLeakages'),
            LeakageQuantityHigh: data.leakageQuantityHigh,
            LeakageQuantityMedium: data.leakageQuantityMedium,
            LeakageQuantityLow: data.leakageQuantityLow,
            LeakageQuantityOptimizations: data.leakageQuantityOptimizations
        }];
        return chartData;
    }
    return (
        <StyledResponsiveContainer id="ldar-detected-leakages-chart" width={props?.width ? props.width : "100%"} height={props?.height ? props.height : 300}>
            <BarChart data={generateChartData()} margin={{ top: 5, right: 5, left: 30, bottom: 5, }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis label={{ value: `[${t("Quantity")}]`, angle: -90, position: 'left', offset: 20 }} />
                <Tooltip />
                <Legend />
                <Bar dataKey="LeakageQuantityHigh" name={t('HighLeakages')} fill="#ff0000" />
                <Bar dataKey="LeakageQuantityMedium" name={t('MediumLeakages')} fill="#dfd920" />
                <Bar dataKey="LeakageQuantityLow" name={t('LowLeakages')} fill="#808080" />
                <Bar dataKey="LeakageQuantityOptimizations" name={t('Optimizations')} fill="#82ca9d" />
            </BarChart>
        </StyledResponsiveContainer>
    );
}

export const RepairProcessChart = (props) => {
    const { data } = props;

    const { t } = useTranslation();

    const generateChartData = () => {
        let chartData = [{
            name: t('RepairProcess'),
            LeakageQuantityRepairedTOTAL: data.leakageQuantityRepairedTOTAL,
            LeakageQuantityOpenTOTAL: data.leakageQuantityOpenTOTAL
        }];
        return chartData;
    }

    return (
        <StyledResponsiveContainer id="ldar-repair-process-chart" width={props?.width ? props.width : "100%"} height={props?.height ? props.height : 300}>
            <BarChart data={generateChartData()} margin={{ top: 5, right: 5, left: 30, bottom: 5, }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis label={{ value: `[${t("Quantity")}]`, angle: -90, position: 'left', offset: 20 }} />
                <Tooltip />
                <Legend />
                <Bar dataKey="LeakageQuantityRepairedTOTAL" name={t('Done')} fill="#82ca9d" />
                <Bar dataKey="LeakageQuantityOpenTOTAL" name={t('Open')} fill="#ff0000" />
            </BarChart>
        </StyledResponsiveContainer>
    )
}

export const CostsAndSavingsChart = (props) => {
    const { data } = props;
    const { t } = useTranslation();
    const generateChartData = () => {
        let chartData = [{
            name: t('CostsAndSavingofTheLeakages'),
            LeakageDetectedCostsTOTAL: Fmt.roundNumber(data.leakageDetectedCostsTOTAL, 0),
            LeakageRepairedCostsTOTAL: Fmt.roundNumber(data.leakageRepairedCostsTOTAL, 0),
            LeakageOpenCostsTOTAL: Fmt.roundNumber(data.leakageOpenCostsTOTAL, 0)
        }];
        return chartData;
    }
    return (
        <StyledResponsiveContainer id="ldar-costsandsavings-chart" width={props?.width ? props.width : "100%"} height={props?.height ? props.height : 300}>
            <BarChart data={generateChartData()} margin={{ top: 5, right: 30, left: 20, bottom: 5, }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis label={{ value: Fmt.getCurrency(), angle: -90, position: 'left', offset: 30 }} />
                <Tooltip />
                <Legend />
                <Bar dataKey="LeakageDetectedCostsTOTAL" name={t('Detected')} fill="#dfd920" />
                <Bar dataKey="LeakageRepairedCostsTOTAL" name={t('Repaired')} fill="#82ca9d" />
                <Bar dataKey="LeakageOpenCostsTOTAL" name={t('Open')} fill="#ff0000" />
            </BarChart>
        </StyledResponsiveContainer>
    )
}

export const CO2Chart = (props) => {
    const { data } = props;
    const { t } = useTranslation();
    const generateChartData = () => {
        let chartData = [{
            name: t('CO2EmmissionsAndSavings'),
            LeakageCO2TOTAL: Fmt.roundNumber(data.leakageCO2TOTAL, 0),
            LeakageCO2RepairedTOTAL: Fmt.roundNumber(data.leakageCO2RepairedTOTAL, 0),
            LeakageCO2OpenTOTAL: Fmt.roundNumber(data.leakageCO2OpenTOTAL, 0)
        }];
        return chartData;
    }
    return (
        <StyledResponsiveContainer id="ldar-co2-chart" width={props?.width ? props.width : "100%"} height={props?.height ? props.height : 300}>
            <BarChart data={generateChartData()} margin={{ top: 5, right: 30, left: 20, bottom: 5, }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis label={{ value: Fmt.getUnitInBracket("co2Wheight", t), angle: -90, position: 'left', offset: 20 }} />
                <Tooltip />
                <Legend />
                <Bar dataKey="LeakageCO2TOTAL" name={t('Detected')} fill="#dfd920" />
                <Bar dataKey="LeakageCO2RepairedTOTAL" name={t('Repaired')} fill="#82ca9d" />
                <Bar dataKey="LeakageCO2OpenTOTAL" name={t('Open')} fill="#ff0000" />
            </BarChart>
        </StyledResponsiveContainer>
    )
}
