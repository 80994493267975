import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import * as Fmt from '../../helper/Formatters'

export default function RepairTable(props) {
    const { t } = useTranslation();
    const { country } = useParams();

    const { Repairs } = props

    const detectionRepair = Repairs.find(x => x.repairType == "Detection");
    const laborRepair = Repairs.find(x => x.repairType == "Labor");
    const sparePartsRepair = Repairs.find(x => x.repairType == "SpareParts");
    const lumpSumRepair = Repairs.find(x => x.repairType == "LumpSum");
    const travelTimeRepair = Repairs.find(x => x.repairType == "TravelTime");
    const travelRepair = Repairs.find(x => x.repairType == "Travel");
    const totalRepair = Repairs.find(x => x.repairType == "Total");

    return (
        <TableContainer component={Paper}>
            {Repairs.length > 0 ?
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left"></TableCell>
                            <TableCell align="right">{t('Units')}</TableCell>
                            <TableCell align="right">{t('CostPerUnit') + Fmt.getCurrency()}</TableCell>
                            <TableCell className="font-bold" align="right">{t('TotalCosts') + Fmt.getCurrency()}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">{t('LeakageDetectionCostsHeader')}</TableCell>
                            <TableCell id="td_detectionR-units" align="right">{Fmt.round(detectionRepair.units, country, 0)} {t('UnitDay')}</TableCell>
                            <TableCell id="td_detectionR-costPerUnit" align="right">{Fmt.round(detectionRepair.costPerUnit, country, 0)}</TableCell>
                            <TableCell className="font-bold" id="td_detectionR-totalCosts" align="right">{Fmt.round(detectionRepair.totalCosts, country, 0)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">{t('LaborCostsRepairLeakages')}</TableCell>
                            <TableCell id="td_laborR-UnitHour" align="right">{Fmt.round(laborRepair.units, country, 0)} {t('UnitHour')}</TableCell>
                            <TableCell id="td_laborR-costPerUnit" align="right">{Fmt.round(laborRepair.costPerUnit, country, 0)}</TableCell>
                            <TableCell className="font-bold" id="td_laborR-totalCosts" align="right">{Fmt.round(laborRepair.totalCosts, country, 0)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">{t('SparePartsCost')}</TableCell>
                            <TableCell id="td_sparePartsR-units" align="right">{Fmt.round(sparePartsRepair.units, country, 0)} {t('UnitLeakage')}</TableCell>
                            <TableCell id="td_sparePartsR-costPerUnit" align="right">{Fmt.round(sparePartsRepair.costPerUnit, country, 0)}</TableCell>
                            <TableCell className="font-bold" id="td_sparePartsR-totalCosts" align="right">{Fmt.round(sparePartsRepair.totalCosts, country, 0)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">{t('LumpSumNoSuffix')}</TableCell>
                            <TableCell id="td_lumpSumR-units" align="right">{Fmt.round(lumpSumRepair.units, country, 0)} {t('UnitDay')}</TableCell>
                            <TableCell id="td_lumpSumR-costPerUnit" align="right">{Fmt.round(lumpSumRepair.costPerUnit, country, 0)}</TableCell>
                            <TableCell className="font-bold" id="td_lumpSumR-totalCosts" align="right">{Fmt.round(lumpSumRepair.totalCosts, country, 0)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">{t('TravelTimeCost')}</TableCell>
                            <TableCell id="td_travelTimeR-units" align="right">{Fmt.round(travelTimeRepair.units, country, 0)} {t('UnitHour')}</TableCell>
                            <TableCell id="td_travelTimeR-costPerUnit" align="right">{Fmt.round(travelTimeRepair.costPerUnit, country, 0)}</TableCell>
                            <TableCell className="font-bold" id="td_travelTimeR-totalCosts" align="right">{Fmt.round(travelTimeRepair.totalCosts, country, 0)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">{t('TravelCost')}</TableCell>
                            <TableCell id="td_travelR-units" align="right">{Fmt.round(travelRepair.units, country, 0) + Fmt.getUnitInBracket("unitKmMile", t)}</TableCell>
                            <TableCell id="td_travelR-costPerUnit" align="right">{Fmt.round(travelRepair.costPerUnit, country, 0)}</TableCell>
                            <TableCell className="font-bold" id="td_travelR-totalCosts" align="right">{Fmt.round(travelRepair.totalCosts, country, 0)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><b>{t('LeakageDetectionAndRepairCosts')}</b></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell className="font-bold" id="totalR-totalCosts" align="right"><b>{Fmt.round(totalRepair.totalCosts, country, 0)}</b></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                : null}
        </TableContainer>
    )
}