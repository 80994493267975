import { useEffect } from 'react'
import { setGlobalScroller } from '../../../helper/GlobalVariables';

export default function ScrollinViewComponent() {
    useEffect(() => {
        initiateGlobalVariables();
    }, []);

    const initiateGlobalVariables = async () => {
        await setGlobalScroller(handleScroll);
    }

    const handleScroll = () => {
        const path = window.location.href.split("/");
        let value = "";
    
        if (path.length >= 10) {
            value = path[9];
        } else if (path.length >= 8) {
            value = path[7];
        }
    
        const isFound = value.indexOf("#");
    
        if (isFound !== -1) {
            const elementId = value.slice(isFound + 1);
            const element = document.getElementById(elementId);
    
            if (element !== null) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }
}
