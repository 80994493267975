import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextFieldComponent, HeadingComponent, CheckboxComponent, SingleSelectComponent } from '../Reusable/';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { Search } from '@mui/icons-material';
import './ReplacementPartDialogBody.scss';
import MaterialSearchDialog from './MaterialSearchDialog';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import * as Consts from '../../helper/ConstantRepository';
import { ReplacementPartComponents } from '../../helper/GlobalVariables';

export default function ReplacementPartDialogBody(props) {
    const { disabled, insertSearchMaterial, model, updateModel } = props;
    const { t } = useTranslation();
    const [showSearchDialog, setShowSearchDialog] = useState(false);
    const [currentPartType, setCurrentPartType] = useState(Consts.ReplacementPartOriginal);

    return (
        <div className="ReplacementPartDialog_body">
            <Grid container spacing={1} direction="column">
                <MaterialSearchDialog
                    open={showSearchDialog}
                    setOpen={setShowSearchDialog}
                    type={currentPartType}
                    model={model}
                    insertSearchMaterial={insertSearchMaterial}
                />
                <Grid item>
                    <Grid container spacing={1} direction="row">
                        <Grid item xs={10}>
                            <TextFieldComponent t={t} disabled={disabled} model={model.partNumber} onChange={e => updateModel("partNumber", e.target.value)} />
                        </Grid>
                        <Grid item xs={1}>
                            {!disabled &&
                                <Tooltip title={t("Search")}>
                                    <IconButton
                                        onClick={() => { setShowSearchDialog(true); setCurrentPartType(Consts.ReplacementPartOriginal); }}
                                        id="btn_RPEDIT-Search"
                                        color="inherit"
                                        size="large"
                                    >
                                        <Search />
                                    </IconButton>
                                </Tooltip>
                            }
                        </Grid>
                        <Grid item xs={1}>
                            {!disabled &&
                                <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_CRT_URL}>
                                    <Tooltip title={t("OpenCrt")}>
                                        <IconButton id="btn_RPEDIT-OpenInNew" color="inherit" size="large">
                                            <OpenInNewIcon />
                                        </IconButton>
                                    </Tooltip>
                                </a>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <TextFieldComponent t={t} disabled={disabled} model={model.typeCode} onChange={e => updateModel("typeCode", e.target.value)} />
                </Grid>
                <Grid item>
                    <SingleSelectComponent
                        disabled={disabled}
                        model={model.component}
                        label={t("Component")}
                        t={t}
                        listFromDb={ReplacementPartComponents}
                        onChange={e => updateModel("component", e.target.value)}
                    />
                </Grid>
                <Grid item>
                    <TextFieldComponent t={t} disabled={disabled} model={model.manufacturer} onChange={e => updateModel("manufacturer", e.target.value)} />
                </Grid>
                <Grid item>
                    <TextFieldComponent t={t} disabled={disabled} model={model.quantity} onChange={e => updateModel("quantity", e.target.value)} />
                </Grid>
                <Grid item>
                    <CheckboxComponent disabled={disabled} model={model.replaceWithDifferent} onChange={e => updateModel("replaceWithDifferent", e.target.checked)} />
                </Grid>
                <Grid item>
                    <CheckboxComponent disabled={disabled} model={model.orderSparePart} onChange={e => updateModel("orderSparePart", e.target.checked)} />
                </Grid>
                {model.replaceWithDifferent.value &&
                    <React.Fragment>
                        <Grid item>
                            <HeadingComponent value={t("Replacement")} size="h6" />
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1} direction="row">
                                <Grid item xs={11}>
                                    <TextFieldComponent id="NewPartNumber" t={t} disabled={disabled} model={model.newPartNumber} onChange={e => updateModel("newPartNumber", e.target.value)} />
                                </Grid>
                                <Grid item xs={1}>
                                    {!disabled &&
                                        <Tooltip title={t("Search")}>
                                            <IconButton
                                                onClick={() => { setShowSearchDialog(true); setCurrentPartType(Consts.ReplacementPartDifferent); }}
                                                id="btn_RPEDIT-Search"
                                                color="inherit"
                                                size="large"
                                            >
                                                <Search />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <TextFieldComponent id="NewTypeCode" t={t} disabled={disabled} model={model.newTypeCode} onChange={e => updateModel("newTypeCode", e.target.value)} />
                        </Grid>
                        <Grid item>
                            <TextFieldComponent id="NewManufacturer" t={t} disabled={disabled} model={model.newManufacturer} onChange={e => updateModel("newManufacturer", e.target.value)} />
                        </Grid>
                    </React.Fragment>
                }
            </Grid>
        </div>
    );


}