import { TableCell, TableSortLabel } from '@mui/material';
import React, { useEffect, useState, Fragment } from 'react';
import { sortArray } from "../../../helper/Sorter";

const TableHeadCells = (props) => {
    const { propertyList, styleList, data, setData, tableName, model, defaultSortProperty, defaultSortOrderAscending } = props;

    const [sortField, setSortField] = useState(0);
    const [sortOrderAsc, setSortOrderAsc] = useState(false);
    const [isDefaultSorted, setIsDefaultSorted] = useState(false);

    useEffect(() => defaultSort(), [defaultSortProperty, defaultSortOrderAscending, data])

    const defaultSort = () => {
        if (data && data.length > 0 && defaultSortProperty != undefined && defaultSortOrderAscending != undefined && isDefaultSorted === false) {
            setIsDefaultSorted(true);
            setData(sortArray([...data], defaultSortProperty, defaultSortOrderAscending, true));
            setSortOrderAsc(defaultSortOrderAscending);
            setSortField(defaultSortProperty);
        }
    }

    const sortRequest = async (evnt, name) => {
        evnt.preventDefault();
        if (data && data.length > 0) {
            let sortAsc = name == sortField ? !sortOrderAsc : false;
            setData(sortArray([...data], name, sortAsc, true));
            setSortOrderAsc(sortAsc);
            setSortField(name);
        }
    }

    const getColumnName = (property) => {
        return property.headerName ?? property.label;
    }

    return (<Fragment>{
        propertyList.map((p, index) => {
            let property = model[p] ?? { headerName: "" };
            let name = property.name ?? p;
            let style = styleList != undefined && styleList[index] != undefined ? styleList[index] : {};

            return <TableCell width={style.width} className={style.className} key={property.idkey != null ? property.idkey : `empty-${index}`}>
                {property.sortable === false || data == undefined ?
                    getColumnName(property)
                    :
                    <TableSortLabel
                        onClick={e => sortRequest(e, name)}
                        active={sortField === name}
                        direction={sortField == name && sortOrderAsc ? 'desc' : 'asc'}
                        id={`srt_${tableName}-${property.idkey}`}>
                        {getColumnName(property)}
                    </TableSortLabel>
                }
            </TableCell>
        })
    }</Fragment>)
}

export default TableHeadCells;