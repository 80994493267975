// @ts-expect-error not converted yet
import { checkIsStringNotEmpty, checkIsDecimal, checkIsInteger, isRepairedByFilledValidator, checkIsDateInPastUTC, isRepairedDateFilledAndPast } from "../../../helper/Validator";
import * as Fmt from '../../../helper/Formatters';
import { UserStore } from '../../../store'
import { ModelParam, Translate } from "../../Types/Types";
import { IUser } from "../../../store/UserStore";
import { nowUTC } from "../../../helper/DateHelper";

let user: IUser = {};
UserStore.user.subscribe(usr => {
    user = usr;
})

export interface IPressureDropAnalysisModelFactory {
    id: ModelParam<number>
    no: ModelParam<number>
    guid: ModelParam<string | null>;
    measurementId: ModelParam<string>;

    dateOfDetection: ModelParam<Date>
    detectedBy: ModelParam<string>

    isRepairDone: ModelParam<boolean>;
    repairedBy: ModelParam<string>;
    repairDate: ModelParam<Date | null>;

    comment: ModelParam<string>;
    repairComment: ModelParam<string>;

    nameOfTheAirNetwork: ModelParam<string>;
    pressureBeforeDryersAbsMin: ModelParam<string>;
    pressureBeforeDryersAbsMax: ModelParam<string>;
    pressureBeforeDryersAbs: ModelParam<string>;
    pressureBeforeDryersRelMin: ModelParam<number>;
    pressureBeforeDryersRelMax: ModelParam<number>;
    pressureBeforeDryersRel: ModelParam<number>;
    pressureDropBefore: ModelParam<number>;
    pressureAfterDryersAbsMin: ModelParam<string>;
    pressureAfterDryersAbsMax: ModelParam<string>;
    pressureAfterDryersAbs: ModelParam<string>;
    pressureAfterDryersRelMin: ModelParam<number>;
    pressureAfterDryersRelMax: ModelParam<number>;
    pressureAfterDryersRel: ModelParam<number>;
    pressureDropAfter: ModelParam<number>;
    pressureProductionAbsMin: ModelParam<string>;
    pressureProductionAbsMax: ModelParam<string>;
    pressureProductionAbs: ModelParam<string>;
    pressureProductionRelMin: ModelParam<number>;
    pressureProductionRelMax: ModelParam<number>;
    pressureProductionRel: ModelParam<number>;
    pressureSetpointPressureRel: ModelParam<string>;
    pressureDropProduction: ModelParam<number>;
    pressureDropTOTAL: ModelParam<number>;
    pressureBeforeDropMax: ModelParam<string>;
    pressureAfterDropMax: ModelParam<string>;
    pressureDropMax: ModelParam<string>;
    suggestedSetpointPressureRel: ModelParam<string>;
    spareParts: ModelParam<string>;
}

export const PressureDropAnalysisModelFactory = (t: Translate): IPressureDropAnalysisModelFactory => ({
    id: { label: t("Id"), idkey: "PDAEDIT-Id", value: 0, isValid: true, validationError: "", validators: [], disabled: true },
    no: { label: t('Nr'), idkey: "PDAEDIT-No", value: 0, isValid: true, validationError: "", validators: [], disabled: true },
    guid: { label: "", idkey: "", value: "", isValid: true, validationError: "", validators: [], disabled: true },
    measurementId: { label: t("MeasurementId"), idkey: "PDAEDIT-MeasurementId", value: "", isValid: true, validationError: "", validators: [checkIsInteger] },
    nameOfTheAirNetwork: { label: t("NameOfTheAirNetwork"), idkey: "PDAEDIT-nameOfTheAirNetwork", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsStringNotEmpty], disabled: true },
    dateOfDetection: { label: t("EntryDate"), idkey: "PDAEDIT-EntryDate", value: nowUTC(), isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDateInPastUTC] },
    detectedBy: { label: t("DetectedBy"), idkey: "PDAEDIT-DetectedBy", value: user.firstName + " " + user.lastName, isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsStringNotEmpty] },
    isRepairDone: { label: t("OptimizationIsDone"), idkey: "PDAEDIT-RepairIsDone", value: false, isValid: true, validationError: "", validators: [] },
    repairedBy: { label: t("OptimizedBy"), idkey: "PDAEDIT-RepairedBy", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [isRepairedByFilledValidator] },
    repairDate: { label: t("OptimizationDate"), idkey: "PDAEDIT-RepairDate", value: null, isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [isRepairedDateFilledAndPast] },
    pressureBeforeDryersAbsMin: { label: "", idkey: "PDAEDIT-pressureBeforeDryersAbsMin", value: "", isValid: true, validationError: "", validators: [checkIsDecimal] },
    pressureBeforeDryersAbsMax: { label: "", idkey: "PDAEDIT-pressureBeforeDryersAbsMax", value: "", isValid: true, validationError: "", validators: [checkIsDecimal] },
    pressureBeforeDryersAbs: { label: "", idkey: "PDAEDIT-pressureBeforeDryersAbs", value: "", isValid: true, validationError: "", validators: [checkIsDecimal] },
    pressureBeforeDryersRelMin: { label: "", idkey: "PDAEDIT-pressureBeforeDryersRelMin", value: 0, isValid: true, validationError: "", validators: [] },
    pressureBeforeDryersRelMax: { label: "", idkey: "PDAEDIT-pressureBeforeDryersRelMax", value: 0, isValid: true, validationError: "", validators: [] },
    pressureBeforeDryersRel: { label: "", idkey: "PDAEDIT-pressureBeforeDryersRel", value: 0, isValid: true, validationError: "", validators: [] },
    pressureDropBefore: { label: "", idkey: "PDAEDIT-pressureDropBefore", value: 0, isValid: true, validationError: "", validators: [] },
    pressureAfterDryersAbsMin: { label: "", idkey: "PDAEDIT-pressureAfterDryersAbsMin", value: "", isValid: true, validationError: "", validators: [checkIsDecimal] },
    pressureAfterDryersAbsMax: { label: "", idkey: "PDAEDIT-pressureAfterDryersAbsMax", value: "", isValid: true, validationError: "", validators: [checkIsDecimal] },
    pressureAfterDryersAbs: { label: "", idkey: "PDAEDIT-pressureAfterDryersAbs", value: "", isValid: true, validationError: "", validators: [checkIsDecimal] },
    pressureAfterDryersRelMin: { label: "", idkey: "PDAEDIT-pressureAfterDryersRelMin", value: 0, isValid: true, validationError: "", validators: [] },
    pressureAfterDryersRelMax: { label: "", idkey: "PDAEDIT-pressureAfterDryersRelMax", value: 0, isValid: true, validationError: "", validators: [] },
    pressureAfterDryersRel: { label: "", idkey: "PDAEDIT-pressureAfterDryersRel", value: 0, isValid: true, validationError: "", validators: [] },
    pressureDropAfter: { label: "", idkey: "PDAEDIT-pressureDropAfter", value: 0, isValid: true, validationError: "", validators: [] },
    pressureProductionAbsMin: { label: "", idkey: "PDAEDIT-pressureProductionAbsMin", value: "", isValid: true, validationError: "", validators: [checkIsDecimal] },
    pressureProductionAbsMax: { label: "", idkey: "PDAEDIT-pressureProductionAbsMax", value: "", isValid: true, validationError: "", validators: [checkIsDecimal] },
    pressureProductionAbs: { label: "", idkey: "PDAEDIT-pressureProductionAbs", value: "", isValid: true, validationError: "", validators: [checkIsDecimal] },
    pressureProductionRelMin: { label: "", idkey: "PDAEDIT-pressureProductionRelMin", value: 0, isValid: true, validationError: "", validators: [] },
    pressureProductionRelMax: { label: "", idkey: "PDAEDIT-pressureProductionRelMax", value: 0, isValid: true, validationError: "", validators: [] },
    pressureProductionRel: { label: "", idkey: "PDAEDIT-pressureProductionRel", value: 0, isValid: true, validationError: "", validators: [] },
    pressureSetpointPressureRel: { label: t("SetpointPressureRel") + Fmt.getUnitInBracket("pressure", t), idkey: "PDAEDIT-pressureSetpointPressureRel", value: "", isValid: true, validationError: "", validators: [checkIsDecimal] },
    pressureDropProduction: { label: "", idkey: "PDAEDIT-pressureDropProduction", value: 0, isValid: true, validationError: "", validators: [] },
    pressureDropTOTAL: { label: "", idkey: "PDAEDIT-pressureDropTOTAL", value: 0, isValid: true, validationError: "", validators: [checkIsDecimal], headerName: t('MeasuredPressureDrop') + Fmt.getUnitInBracket("pressure", t) },
    pressureBeforeDropMax: { label: "", idkey: "PDAEDIT-pressureBeforeDropMax", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [checkIsDecimal] },
    pressureAfterDropMax: { label: "", idkey: "PDAEDIT-pressureAfterDropMax", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [checkIsDecimal] },
    pressureDropMax: { label: "", idkey: "PDAEDIT-pressureDropMax", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [], headerName: t('MaximalSuggestedDrop') + Fmt.getUnitInBracket("pressure", t) },
    suggestedSetpointPressureRel: { label: t("SuggestedSetpointPressureRel") + Fmt.getUnitInBracket("pressure", t), idkey: "PDAEDIT-SuggestedSetpointPressureRel", value: "", isValid: true, validationError: t("ValidNumberError"), validators: [checkIsDecimal] },
    comment: { label: "", idkey: "PDAEDIT-comment", value: "", isValid: true, validationError: "", validators: [] },
    spareParts: { label: "", idkey: "PDAEDIT-spareParts", value: "", isValid: true, validationError: "", validators: [], disabled: true },
    repairComment: { label: t("RepairComment"), idkey: "PDAEDIT-RepairComment", value: "", isValid: true, validationError: "", validators: [] },
})

export interface IPressureDropAnalysisTableFilterModelFactory {
    pressureDropMax: ModelParam<number>;
    pressureDropTOTAL: ModelParam<number>;
    pressureDropSavings: ModelParam<number>;
    suggestedSetpointPressureRel: ModelParam<number>;
    pressureSetpointPressureRel: ModelParam<number>;
    pressureLevelSavings: ModelParam<number>;
}

export const PressureDropAnalysisTableFilterModelFactory = (t: Translate): IPressureDropAnalysisTableFilterModelFactory => ({
    pressureDropMax: { label: t('Recommended') + Fmt.getUnitInBracket("pressure", t), idkey: "PDAEDIT-pressureDropMax", value: 0, isValid: true, validationError: "", validators: [] },
    pressureDropTOTAL: { label: t('Obtained') + Fmt.getUnitInBracket("pressure", t), idkey: "PDAEDIT-pressureDropTOTAL", value: 0, isValid: true, validationError: "", validators: [] },
    pressureDropSavings: { label: t('Savings') + Fmt.getCurrency(), idkey: "PDAEDIT-pressureDropSavings", value: 0, isValid: true, validationError: "", validators: [] },
    suggestedSetpointPressureRel: { label: t('Recommended') + Fmt.getUnitInBracket("pressure", t), idkey: "PDAEDIT-SuggestedSetpointPressureRel", value: 0, isValid: true, validationError: "", validators: [] },
    pressureSetpointPressureRel: { label: t("Obtained") + Fmt.getUnitInBracket("pressure", t), idkey: "PDAEDIT-pressureSetpointPressureRel", value: 0, isValid: true, validationError: "", validators: [] },
    pressureLevelSavings: { label: t('Savings') + Fmt.getCurrency(), idkey: "PDAEDIT-pressureLevelSavings", value: 0, isValid: true, validationError: "", validators: [] }
})
