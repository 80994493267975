import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { API } from './ApiHelper'
import { SpinnerComponent } from '../components/Reusable';

export const GlobalSpinner = () => {
    const [counter, setCounter] = useState<number>(0);
    const inc = useCallback((config: any) => setCounter2(config), [setCounter]); // add to counter
    const dec = useCallback(() => setCounter(counter => counter - 1), [setCounter]); // remove from counter

    const setCounter2 = (config: any) => {
        if (!config.isBackground)
            setCounter(counter => counter + 1)
    }

    const interceptors = useMemo(() => ({
        request: (config: any) => (inc(config), config),
        response: (response: any) => (dec(), response),
        error: (error: any) => (dec(), Promise.reject(error)),
    }), [inc, dec]); // create the interceptors

    useEffect(() => {
        // add request interceptors
        const reqInterceptor = API.interceptors.request.use(
            interceptors.request,
            interceptors.error
        );
        // add response interceptors
        const resInterceptor = API.interceptors.response.use(interceptors.response, interceptors.error);
        return () => {
            // remove all intercepts when done
            API.interceptors.request.eject(reqInterceptor);
            API.interceptors.response.eject(resInterceptor);
        };
    }, [interceptors]);

    return <SpinnerComponent open={counter > 0} />
}
