import React, { useState, useEffect } from 'react';
import { ButtonGroup, Button, Tooltip, styled } from '@mui/material';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import { API } from '../../../helper/ApiHelper';
import { CidParam, CountryParam, IdParam, LanguageParam, ListNameParam, TabParam } from '../../Types/Types';

const StyledButton = styled(Button)({
    color: "#0091DC",
    borderColor: "#0091DC",
    '&:hover': {
        borderColor: "#0091DC",
    }
});

export default function ListPreviousNext() {
    const anUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "tab-list-ids/";
    const { country, lng, id, cid, tab, listName } = useParams<CountryParam & LanguageParam & IdParam & CidParam & TabParam & ListNameParam>();
    const { t } = useTranslation();
    const history = useHistory();

    const [ids, setIds] = useState<number[]>([]);
    const [prevDisabled, setPrevDisabled] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(true);

    useEffect(() => {
        getIds();
    }, [])

    useEffect(() => {
        checkDisabled();
    }, [cid, ids])

    const getIds = async () => {
        const resp = await API.get(anUrl + id + "/" + listName);
        setIds(resp);
    }

    const checkDisabled = () => {
        const currentId = ids.indexOf(parseInt(cid));
        if (currentId == -1) {
            setPrevDisabled(true);
            setNextDisabled(true);
        }
        else if (prevDisabled == false || nextDisabled == false || ids.length != 0) {
            const checkPrev = currentId == 0;
            setPrevDisabled(checkPrev);
            const checkNext = currentId == ids.length - 1;
            setNextDisabled(checkNext);
        }
    }

    const go = (isPrev : boolean) => {
        let currentId = ids.indexOf(parseInt(cid));
        const nextId = ids[isPrev ? --currentId : ++currentId];
        const path = `/${country}/${lng}/project/${id}/${tab}/${listName}/${nextId}`;
        history.push(path);
    }

    return (<ButtonGroup color="primary" aria-label="outlined primary button group">
        <Tooltip title={t("Previous")}>
            <StyledButton
                disabled={prevDisabled}
                onClick={() => go(true)}
            >
                <NavigateBefore />
            </StyledButton>
        </Tooltip>
        <Tooltip title={t("Next")}>
            <StyledButton
                disabled={nextDisabled}
                onClick={() => go(false)}
            >
                <NavigateNext />
            </StyledButton>
        </Tooltip>
    </ButtonGroup>);
}