import { BehaviorSubject } from "rxjs";
import { API } from '../helper/ApiHelper';
import { ExecutorSelectionNotInitialized, Reader } from '../helper/ConstantRepository'
import { IDropdownItem } from "../components/Types/Types";

export interface IAccountSettings {
    id?: number;
    countryID?: string;
    measurementSystem?: IDropdownItem;
    printSize?: IDropdownItem;
    currency?: string;
    co2GridAverage?: number;
    subsidiaryFestoName?: string;
    subsidiaryAddress?: string;
    subsidiaryCity?: string;
    subsidiaryTelephone?: string;
    leakageDetectionCosts?: number;
    repairLabourCosts?: number;
    travelTimeCostsPerHour?: number;
    sparePartsCosts?: number;
    costsPerDistanceUnit?: number;
    lumpSum?: number;
    equipmentServiceLife?: number;
    taxOnProfit?: number;
    paginationMaxRowCount?: number;
    settingsLeakageDetectedDayFlow?: number;
    settingsLeakageDetectedDayPerTechnician?: number;
    settingsRepairTimePerLeakage?: number;
    settingsWorkingHoursPerDayPerDetection?: number;
    settingsSavings1barReduction?: number;
    festoExecutor?: boolean;
    hideButtonReplacementPartsList?: boolean;
    hideButtonBuyReplacementParts?: boolean;
    tenantId?: string;
    isDefaultExecutor?: boolean;
    executorUsers?: string[];
    licenseType?: IDropdownItem;

    salutation?: number;
    firstName?: string;
    lastName?: string;
    email?: string;
}

export interface IAccountSelection {
    id: number;
    value: string;
    currency: string;
    isDefault: boolean;
    festoExecutor: boolean;
    subsidiaryAddress: string | null;
    subsidiaryCountry: string | null;
    subsidiaryCity: string | null;
    subsidiaryTelephone: string | null;

    salutation: number | null;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
}

const execUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "account-management";
const executorList = new BehaviorSubject<Array<IAccountSelection>>([]);
const executorSelection = new BehaviorSubject<number | undefined>(ExecutorSelectionNotInitialized);
const executorSettings = new BehaviorSubject<IAccountSettings>({});

export const ExecutorStore = {
    executorSettings,
    executorSelection,
    executorList,
    setExecutorSelection: (id: number | undefined) => {
        if (executorSelection.value != id) {
            executorSelection.next(id);
            if (id !== undefined && id > 0)
                API.get<IAccountSettings>(`${execUrl}/account/${id}/settings`)
                    .then(response => executorSettings.next(response));
            else
                executorSettings.next({})
        }
    },
    initExecutorList: function () {
        API.get<Array<IAccountSelection>>(`${execUrl}/account-user-list`).then((response) => {
            executorList.next(response);
            let defaultExecutorId = response.find(x => x.isDefault == true)?.id;
            if (!defaultExecutorId)
                defaultExecutorId = response.find(x => x.id > 0)?.id;
            if (defaultExecutorId)
                this.setExecutorSelection(defaultExecutorId);
            else
                this.setExecutorSelection(Reader);
        })
    },
    setDefaultExecutor: (executorId: number) => {
        API.put(`${execUrl}/account/${executorId}/default`)
    }
}