import { checkEmail, checkIsNotNullOrWhitespace, isFestoEmail } from '../../../helper/Validator';
import { ExecutorStore, ProjectStore } from '../../../store';
import { isAfter } from "date-fns";
import { september16 } from '../../../helper/DateHelper';

export const UserRoleModel = t => ({
    id: { label: "", value: "", isValid: true, validationError: "", validators: [] },
    project: { label: "", value: "", isValid: true, validationError: "", validators: [] },
    email: { label: t("Email"), value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkProjectUserEmail] },
    isEditor: { label: "", value: false, isValid: true, validationError: "", validators: [] },
    isChecked: { label: "", value: false, isValid: true, validationError: "", validators: [] },
    isExpanded: { label: "", value: false, isValid: true, validationError: "", validators: [] }
})

const checkProjectUserEmail = (value) => {
    if (ExecutorStore.executorSettings.value.festoExecutor === true && isAfter(new Date(ProjectStore.ProjectInfo.value.projectInfo.createdDate), september16))
        return checkIsNotNullOrWhitespace(value) && checkEmail(value) && isFestoEmail(value);
    else
        return checkIsNotNullOrWhitespace(value) && checkEmail(value);
}