export const ReplacementPartsCartModel = {
    service: { value: '', isValid: true },
    no: { value: 0, isValid: true },
    measurementId: { value: 0, isValid: true },
    building: { value: "", isValid: true },
    department: { value: "", isValid: true },
    machine: { value: "", isValid: true },
    partNumber: { value: '', isValid: true },
    typeCode: { value: '', isValid: true },
    component: { value: '', isValid: true },
    manufacturer: { value: '', isValid: true },
    newPartNumber: { value: "", isValid: true },
    newTypeCode: { value: "", isValid: true },
    newComponent: { value: "", isValid: true },
    newManufacturer: { value: "", isValid: true },
    quantity: { value: "", isValid: true },
    orderSparePart: { value: "", isValid: true },
    replaceWithDifferent: { value: false, isValid: true }
}

export const ReplacementPartsCartHeadings = ['Service', 'Nr', 'MeasurementId', 'Building', 'Department', 'Machine', 'PartNumber', 'TypeCode', 'Component', 'Manufacturer', 'PartNumber', 'TypeCode', 'Component', 'Manufacturer', 'Quantity', 'OrderSparePart']