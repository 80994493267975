import React, { useState } from 'react';
import { StyledTooltip, StyledTextField } from '../MaterialUIStyles/Styles';
import { useParams } from "react-router-dom";
import { StandardTextFieldProps } from '@mui/material';
import { CountryParam, ModelParam } from '../../Types/Types';


interface INumberInputFieldComponent extends StandardTextFieldProps  {
    t: (s: string | undefined) => string,
    id?: string,
    model: ModelParam<string>,
    label?: string
}

const NumberInputFieldComponent = (props: INumberInputFieldComponent) => {
    const { model, t, id, label } = props;

    const { country } = useParams<CountryParam>();
    const [isEditing, setIsEditing] = useState(false);

    const toggleEditing = () => {
        setIsEditing(!isEditing)
    }

    return (
        <StyledTooltip
            arrow
            placement="bottom-end"
            //classes={StyledTooltip}
            title={model.isValid === false ? t(model.validationError) : ""}
        >
            {isEditing ? (
                <StyledTextField
                    variant="standard"
                    {...props}
                    required={!model.notRequired && model.validators?.length > 0}
                    error={model.isValid === false}
                    id={id ? "input_" + id : "input_" + model.idkey}
                    label={label ? label : model.label ? model.label : t ? t(model.tkey) : model.tkey}
                    value={(model.value)}
                    onBlur={toggleEditing}
                    fullWidth
                />
            ) : (
                <StyledTextField
                    variant="standard"
                    required={!model.notRequired && model.validators?.length > 0}
                    error={model.isValid === false}
                    id={id ? "input_" + id : "input_" + model.idkey}
                    label={label ? label : model.label ? model.label : t ? t(model.tkey) : model.tkey}
                    value={(isNaN(parseFloat(model.value)) ? '' : parseFloat(model.value).toLocaleString(country))}
                    onFocus={toggleEditing}
                    //readOnly doesn't exit
                    fullWidth
                />
            )}
        </StyledTooltip>
    );
}

export default NumberInputFieldComponent;