import React, { useEffect, useState } from "react";
import { Switch, useParams } from "react-router-dom";
import { Container } from '@mui/material';
import { PrivateRoute } from "../PrivateRoute/PrivateRoute";
import GeneralInformation from "../GeneralInformation/GeneralInformation";
import ProjectSettings from '../ProjectSettings/ProjectSettings'
import AirQuality from "../AirQuality/AirQuality";
import IndividualDryer from "../AirQuality/IndividualDryer/IndividualDryer";
import AirQualityMeasurement from "../AirQuality/AirQualityMeasurement/AirQualityMeasurement";
import AirNetwork from '../AirNetwork/AirNetwork';
import CompressedAirGeneration from '../CompressedAirGeneration/CompressedAirGeneration';
import EditIndividualCompressorInformation from '../CompressedAirGeneration/EditIndividualCompressorInformation/EditIndividualCompressorInformation';
import ProjectMenu from "../ProjectMenu/ProjectMenu";
import LeakageAnalysis from "../LeakageAnalysis/LeakageAnalysis";
import MACAC from "../MACAC/MACAC";
import LDAR from "../LeakageDetectionAndRepair/LDAR";
import MassEditLDAR from "../MassEdit/MassEditLDAR";
import Leakage from "../LeakageDetectionAndRepair/Leakage/Leakage";
import DistributionLine from "../AirNetwork/DistributionLine/DistributionLine";
import AirReceiver from "../AirNetwork/AirReceiver/AirReceiver";
import PressureDropAnalysis from "../AirNetwork/PressureDropAnalysis/PressureDropAnalysis";
import CostSavings from '../CostSavings/CostSavings';
import Application from "../MACAC/Application/Application";
import DirtyPrompt from './DirtyPrompt';
import * as Roles from '../../helper/AuthorizationHelper/AuthConstatants';
import ScrollinViewComponent from "../Reusable/Scroll/ScrollinViewComponent";
import ContentNavWrapper from "./ContentNavWrapper";
import { ExecutorStore, ProjectStore } from '../../store';
import { TranslationLanguages, UsedCountryList } from '../../helper/CountryLanguages/CountryLanguages';

export function Project(props) {
    let { id } = useParams();

    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [denyEdit, setDenyEdit] = useState(true);
    const [projectInfo, setProjectInfo] = useState({});

    useEffect(() => {
        let projectSub1 = ProjectStore.ProjectInfo.subscribe(projInfo => {
            if (Object.keys(projInfo).length > 0) {
                setProjectInfo(projInfo.projectInfo);
                ExecutorStore.setExecutorSelection(projInfo.projectInfo.executorId)
                setIsDataLoaded(true);
            }
        })
        let projectSub2 = ProjectStore.DenyEdit.subscribe(deny => setDenyEdit(deny));
        ProjectStore.initProjectInfo(id);

        return () => {
            projectSub1.unsubscribe();
            projectSub2.unsubscribe();
        }
    }, [id]);

    useEffect(() => {
        props.handleSideBar(true, true)
    })

    return (
        <div style={{ marginBottom: "30px" }}>
            <ProjectMenu menuData={projectInfo} />
            <DirtyPrompt />
            <ScrollinViewComponent />
            <Container>
                {
                    isDataLoaded &&
                    <ContentNavWrapper showContent={props.showContent} menuData={projectInfo}>
                        <Switch>
                            <PrivateRoute
                                path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/project/:id?/:tab(GI)`}
                                denyEdit={denyEdit}
                                component={GeneralInformation}
                                allowedRoles={[Roles.SuperAdmin, Roles.AccountAdmin, Roles.Technician, Roles.Reader, Roles.Editor]}
                            />
                            <PrivateRoute
                                path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/project/:id?/:tab(CAG)/:listName(AddEditCompressor)/:cid?`}
                                denyEdit={denyEdit}
                                component={EditIndividualCompressorInformation}
                                allowedRoles={[Roles.SuperAdmin, Roles.AccountAdmin, Roles.Technician, Roles.Reader, Roles.Editor]}
                            />
                            <PrivateRoute
                                path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/project/:id?/:tab(CAG)`}
                                denyEdit={denyEdit}
                                menuData={projectInfo}
                                component={CompressedAirGeneration}
                                allowedRoles={[Roles.SuperAdmin, Roles.AccountAdmin, Roles.Technician, Roles.Reader, Roles.Editor]}
                            />
                            <PrivateRoute
                                path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/project/:id?/:tab(AQ)/:listName(IndividualDryer)/:cid`}
                                denyEdit={denyEdit}
                                component={IndividualDryer}
                                allowedRoles={[Roles.SuperAdmin, Roles.AccountAdmin, Roles.Technician, Roles.Reader, Roles.Editor]}
                            />
                            <PrivateRoute
                                path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/project/:id?/:tab(AQ)/:listName(AirQualityMeasurement)/:cid`}
                                denyEdit={denyEdit}
                                component={AirQualityMeasurement}
                                allowedRoles={[Roles.SuperAdmin, Roles.AccountAdmin, Roles.Technician, Roles.Reader, Roles.Editor]}
                            />
                            <PrivateRoute
                                path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/project/:id?/:tab(AQ)`}
                                denyEdit={denyEdit}
                                component={AirQuality}
                                allowedRoles={[Roles.SuperAdmin, Roles.AccountAdmin, Roles.Technician, Roles.Reader, Roles.Editor]}
                            />
                            <PrivateRoute
                                path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/project/:id?/:tab(AN)/:listName(DistributionLine)/:cid?`}
                                denyEdit={denyEdit}
                                component={DistributionLine}
                                allowedRoles={[Roles.SuperAdmin, Roles.AccountAdmin, Roles.Technician, Roles.Reader, Roles.Editor]}
                            />
                            <PrivateRoute
                                path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/project/:id?/:tab(AN)/:listName(AirReceiver)/:cid?`}
                                denyEdit={denyEdit}
                                component={AirReceiver}
                                allowedRoles={[Roles.SuperAdmin, Roles.AccountAdmin, Roles.Technician, Roles.Reader, Roles.Editor]}
                            />
                            <PrivateRoute
                                path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/project/:id?/:tab(AN)/:listName(PressureDropAnalysis)/:cid?`}
                                denyEdit={denyEdit}
                                component={PressureDropAnalysis}
                                allowedRoles={[Roles.SuperAdmin, Roles.AccountAdmin, Roles.Technician, Roles.Reader, Roles.Editor]}
                            />
                            <PrivateRoute
                                path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/project/:id?/:tab(AN)`}
                                denyEdit={denyEdit}
                                component={AirNetwork}
                                allowedRoles={[Roles.SuperAdmin, Roles.AccountAdmin, Roles.Technician, Roles.Reader, Roles.Editor]}
                            />
                            <PrivateRoute
                                path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/project/:id?/:tab(LA)`}
                                denyEdit={denyEdit}
                                component={LeakageAnalysis}
                                allowedRoles={[Roles.SuperAdmin, Roles.AccountAdmin, Roles.Technician, Roles.Reader, Roles.Editor]}
                            />
                            <PrivateRoute
                                path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/project/:id?/:tab(MACAC)/:listName(Application)/:cid?`}
                                denyEdit={denyEdit}
                                component={Application}
                                allowedRoles={[Roles.SuperAdmin, Roles.AccountAdmin, Roles.Technician, Roles.Reader, Roles.Editor]}
                            />
                            <PrivateRoute
                                path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/project/:id?/:tab(MACAC)`}
                                denyEdit={denyEdit}
                                component={MACAC}
                                allowedRoles={[Roles.SuperAdmin, Roles.AccountAdmin, Roles.Technician, Roles.Reader, Roles.Editor]}
                            />
                            <PrivateRoute
                                path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/project/:id?/:tab(LDAR)/:listName(Leakage)/:cid?`}
                                denyEdit={denyEdit}
                                component={Leakage}
                                allowedRoles={[Roles.SuperAdmin, Roles.AccountAdmin, Roles.Technician, Roles.Reader, Roles.Editor]}
                            />
                            <PrivateRoute
                                path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/project/:id?/:tab(LDAR)`}
                                denyEdit={denyEdit}
                                component={LDAR}
                                allowedRoles={[Roles.SuperAdmin, Roles.AccountAdmin, Roles.Technician, Roles.Reader, Roles.Editor]}
                            />
                            <PrivateRoute
                                path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/project/:id?/:tab(MassEditLDAR)`}
                                denyEdit={denyEdit}
                                component={MassEditLDAR}
                                allowedRoles={[Roles.SuperAdmin, Roles.AccountAdmin, Roles.Technician, Roles.Reader, Roles.Editor]}
                            />
                            <PrivateRoute
                                path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/project/:id?/:tab(CS)`}
                                denyEdit={denyEdit}
                                component={CostSavings}
                                allowedRoles={[Roles.SuperAdmin, Roles.AccountAdmin, Roles.Technician, Roles.Reader, Roles.Editor]}
                            />
                            <PrivateRoute
                                path={[`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/project/:id?/:tab(PS)`,
                                `/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/project/:id?`]}
                                denyEdit={denyEdit}
                                component={ProjectSettings}
                                allowedRoles={[Roles.SuperAdmin, Roles.AccountAdmin, Roles.Technician]}
                            />
                        </Switch>
                    </ContentNavWrapper>
                }
            </Container>
        </div>
    );
}
