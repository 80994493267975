import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Fmt from '../../../helper/Formatters';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';

const AirProfileChart = (props) => {
    const { t } = useTranslation();
    const { data } = props;

    const [chartData, setChartData] = useState([]);

    useEffect(() => prepareGraphData(), [data]);

    const prepareGraphData = () => {
        let graphData = []
        let i;
        for (var day of [t("Monday"), t("Tuesday"), t("Wednesday"), t("Thursday"), t("Friday")]) {
            for (i = 0; i < 24; i++) {
                graphData.push({
                    Day: day,
                    Hour: i,
                    GraphicAirFlow: Fmt.roundNumber(data.graphicAirFlowMondayFriday[i], 2),
                    GraphicLeaks: Fmt.roundNumber(data.graphicLeaksMondayFriday[i], 2),
                    FAD: Fmt.roundNumber(data.mfadOperatingConditionsTOTAL, 2),
                    AverageAirFlow: Fmt.roundNumber(data.rYearlyConsumptionAirFlowTOTAL, 2)
                });
            }
        }
        for (i = 0; i < 24; i++) {
            graphData.push({
                Day: t("Saturday"),
                Hour: i,
                GraphicAirFlow: Fmt.roundNumber(data.graphicAirFlowSaturday[i], 2),
                GraphicLeaks: Fmt.roundNumber(data.graphicLeaksSaturday[i], 2),
                FAD: Fmt.roundNumber(data.mfadOperatingConditionsTOTAL, 2),
                AverageAirFlow: Fmt.roundNumber(data.rYearlyConsumptionAirFlowTOTAL, 2)
            });
        }
        for (i = 0; i < 24; i++) {
            graphData.push({
                Day: t("Sunday"),
                Hour: i,
                GraphicAirFlow: Fmt.roundNumber(data.graphicAirFlowSunday[i], 2),
                GraphicLeaks: Fmt.roundNumber(data.graphicLeaksSunday[i], 2),
                FAD: Fmt.roundNumber(data.mfadOperatingConditionsTOTAL, 2),
                AverageAirFlow: Fmt.roundNumber(data.rYearlyConsumptionAirFlowTOTAL, 2)
            });
        }
        return setChartData(graphData);
    };

    return (
        <ResponsiveContainer width="100%" height={300} id="air-profile-chart">
            <LineChart data={chartData}>
                <Line type="monotone" dataKey="FAD" name={t("AirConsumptionGraphicFAD")} stroke="#C5C7C7" dot={false} strokeWidth="3" activeDot={false} />
                <Line type="monotone" dataKey="GraphicAirFlow" name={t("AirConsumptionGraphicAirFlow")} stroke="#0091DC" dot={false} strokeWidth="3" activeDot={false} />
                <Line type="monotone" dataKey="AverageAirFlow" name={t("AirConsumptionGraphicFADAverageAirFlow")} stroke="#606464" dot={false} strokeWidth="3" activeDot={false} strokeDasharray="10 10" />
                <Line type="monotone" dataKey="GraphicLeaks" name={t("AirConsumptionGraphicExpectedLeakages")} stroke="#00486E" dot={false} strokeWidth="3" activeDot={false} />
                <CartesianGrid stroke="#ccc" />
                <XAxis dataKey="Day" ticks={[t("Monday"), t("Tuesday"), t("Wednesday"), t("Thursday"), t("Friday"), t("Saturday"), t("Sunday")]} />
                <YAxis>
                    <Label value="Nm&sup3;/min" angle={-90} dx={-20} />
                </YAxis>
                <Tooltip />
                <Legend verticalAlign="middle" align="right" layout="vertical" />
            </LineChart>
        </ResponsiveContainer>
    )
}

export default AirProfileChart