import React from 'react';
import { IconButton, TextField, InputAdornment, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { API } from '../../helper/ApiHelper';
import { CheckCircle, Cancel, Edit } from '@mui/icons-material';
import './ImageViewer.scss';
import { OpenGlobalSnackbar } from '../../helper/GlobalVariables';
// @ts-expect-error not converted yet
import { doesNameAlreadyExist } from '../../helper/Validator';
import { decodeImageName } from '../../helper/Formatters';
import { ProjectImage } from './ImageViewer';
import { IdParam } from '../Types/Types';

const StyledTextField = styled(TextField)({
    width: "100%",
    "& .MuiFilledInput-adornedEnd": {
        padding: 0
    },
    "& .MuiFilledInput-input": {
        paddingLeft: 0
    },
    "& .MuiFilledInput-root": {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0
    },
    "& .MuiFilledInput-inputHiddenLabel.MuiFilledInput-inputMarginDense": {
        marginTop: "3px",
        paddingTop: "10px",
        paddingBottom: "10px"
    },
    "& .Mui-disabled:before": {
        borderBottom: 'none !important'
    }
});

const StyledIconButton = styled(IconButton)({
    padding: 0
});

interface IProps {
    index: number,
    groupId: string,
    images: ProjectImage[],
    inEditMode: number | null,
    setImages: React.Dispatch<React.SetStateAction<ProjectImage[]>>,
    setInEditMode: React.Dispatch<React.SetStateAction<number | null>>,
}

export default function ImageNameEditingInput(props: IProps) {
    const { images, setImages, groupId, inEditMode, setInEditMode, index } = props;
    const blobUrl = process.env.REACT_APP_DEVFESSIMAGESERVICES_BASE + "blob";
    const { id } = useParams<IdParam>();
    const { t } = useTranslation();

    let editedImageName = "";
    const editImageValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        editedImageName = e.target.value;
    }

    const updateImageTitle = async (index: number) => {
        if (editedImageName === "") {
            OpenGlobalSnackbar("danger", t('PleaseEnterFileName'));
        } else if (doesNameAlreadyExist(editedImageName, images)) {
            OpenGlobalSnackbar("danger", t('ErrorNameAlreadyExists'));
        } else {
            const data = {
                groupId: groupId,
                OldImageName: images[index].image.name,
                NewImageName: encodeURIComponent(editedImageName)
            };

            await API.post(`${blobUrl}/project/${id}/images/update-name`, data);
            OpenGlobalSnackbar("success", t('ImageNameChangedSuccessfully'));
            const imagesCopy = images;
            imagesCopy[index].image = {
                ...imagesCopy[index].image,
                name: editedImageName
            };
            setImages([...imagesCopy])
            setInEditMode(null);
        }
    }

    return (<>
        <StyledTextField
            key={index}
            disabled={inEditMode !== index}
            margin="none"
            hiddenLabel
            id="filled-hidden-label-small"
            defaultValue={decodeImageName(images[index].image.name)}
            onChange={editImageValue}
            variant="filled"
            size="small"
            InputProps={{
                endAdornment: inEditMode === index ?
                    (
                        <InputAdornment position="end" >
                            <StyledIconButton
                                onClick={() => updateImageTitle(index)}
                                size="large"
                            >
                                <CheckCircle fontSize="small" />
                            </StyledIconButton>
                            <StyledIconButton
                                onClick={() => setInEditMode(null)}
                                size="large"
                            >
                                <Cancel fontSize="small" />
                            </StyledIconButton>
                        </InputAdornment>
                    )
                    :
                    (
                        <InputAdornment position="end" >
                            <StyledIconButton
                                onClick={() => setInEditMode(index)}
                                size="large"
                            >
                                <Edit fontSize="small" />
                            </StyledIconButton>
                        </InputAdornment>
                    )
            }}
        />
    </>);
}