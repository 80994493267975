import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import PaybackTimeChart from '../LeakageAnalysis/PaybackTimeChart';
import * as Fmt from '../../helper/Formatters'

const StyledChartDialog = styled('div')((props) => ({
    display: !props.open && 'none',
    position: 'fixed',
    zIndex: 5,
    left: 0,
    width: '1600px',
    marginTop: '150%',
    backgroundColor: '#fff'
}));

export default function ChartDialog(props) {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        setOpen(props.open);
    }, [props.open])
    return (
        <StyledChartDialog {...props} className={'chart-dialog'} open={open}>
            {props.open && Object.keys(props.data).length > 0 &&
                <Grid container direction="column">
                    <Grid item>
                        <PaybackTimeChart ChartModel={props.data.leakageAnalysisCalcResult.paybackChartResults} Currency={Fmt.getPercentage()} />
                    </Grid>
                </Grid>
            }
        </StyledChartDialog>
    );
}