import _ from 'lodash';
import { IInquiryModel } from './InquiryModel';

export const servicesForValidation: (keyof IInquiryModel)[] = [
  "isFreeCalculationOfLeakagesAndSavingsPotentialSelected",
  "isFreeTestLicenseForFestoEnergySavingsServicesSelected",
  "isCompressedAirEnergyEfficiencyPreAuditSelected",
  "isAnalysisOfTheCompressedAirGenerationSelected",
  "isCompressedAirQualityAnalysisSelected",
  "isAirNetworkAnalysisSelected",
  "isMachineAnalysisForEnergyEfficiencySelected",
  "isLeakageDetectionAndDocumentationSelected",
  "isSubscriptionLeakageDetectionSelected"
];

export const servicesForMarking : (keyof IInquiryModel)[] = [
  "isCompressedAirEnergyEfficiencyPreAuditSelected",
  "isAnalysisOfTheCompressedAirGenerationSelected",
  "isCompressedAirQualityAnalysisSelected",
  "isAirNetworkAnalysisSelected",
  "isMachineAnalysisForEnergyEfficiencySelected",
  "isLeakageDetectionAndDocumentationSelected"
];


export const isAnyServiceSelectedValidator = (model: IInquiryModel) => {
  return servicesForValidation.some(key => model[key]?.value === true);
};

export const validateExceptionFields = (model: IInquiryModel) => {
  const clonedModel = _.cloneDeep(model);
  const exceptions: (keyof IInquiryModel)[] = [
    "temperatureSpring",
    "temperatureSummer",
    "temperatureAutumn",
    "temperatureWinter",
    "humiditySpring",
    "humiditySummer",
    "humidityAutumn",
    "humidityWinter",
    "altitudeAboveSeaLevel",
    "isTheAirSupllyShuttedOff",
    "costsPerkWh",
    "mondayToFridayWorkingTime",
    "saturdayWorkingTime",
    "sundayWorkingTime",
    "staticConsumption",
    "leakageLevel",
    "isTermsAndConditionsApproved"
  ];
  exceptions.forEach(prop => {
    if (!clonedModel[prop]?.value) {
      clonedModel[prop].isValid = true;
    }
  });

  return clonedModel;
};

export const markOrUnmarkCompressorChecks = (property: string, value: any, model: any, isInquiry: boolean) => {
  if (property === "isCompressedAirEnergyEfficiencyPreAuditSelected" && isInquiry) {
    const clonedModel = _.cloneDeep(model);
    servicesForMarking.forEach(prop => {
      clonedModel[prop].value = value;
    });
    return clonedModel;
  }

  return model;
};
