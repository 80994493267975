import React from 'react';
import { Page, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { usePdfStyles, StyleTable, StyleTableNoBorder } from './PdfStyles';
import * as DateHelper from '../../../helper/DateHelper';
import * as Fmt from '../../../helper/Formatters';
import Watermark from './Watermark';
import { SplitText } from '../../../helper/PdfTested';
import SavingsReducingThePressureDropTable from './PdfTables/SavingsReducingThePressureDropTable';
import SavingsReducingThePressureLevelTable from './PdfTables/SavingsReducingThePressureLevelTable';

export const AirNetworkPdf = (props) => {
    const { calcs, country, projectData } = props;
    const { t } = useTranslation();
    var PdfStyles = usePdfStyles();

    return (
        <Page size={props.pageSize} style={PdfStyles.page}>
            <Watermark projectData={projectData} />
            <Text style={PdfStyles.pageNumbers} render={({ pageNumber, totalPages }) => (pageNumber + ' ' + t('of') + ' ' + totalPages)} fixed />
            <View wrap={false}>
                <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["AirNetwork"] = pageNumber }} />
                <Text style={PdfStyles.boldHeading}>{props.tocValues.tocNumbering.airNetwork}. {t('AirNetwork')}</Text>
            </View>
            {props.data.distributionLineList.length > 0 &&
                <View>
                    <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.airNetwork}.{props.tocValues.tocNumbering.DistributionLine} {t('DistributionLine')}</Text>
                    <Text style={PdfStyles.spacer}></Text>
                    <View style={StyleTable.table}>
                        <View style={StyleTable.tableRow} wrap={false} fixed>
                            <View style={[StyleTable.tableColRowZero, { flexGrow: 0.6 }]}>
                                <Text style={StyleTable.tableCell}>{t('MeasurementId')}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t('Building')}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t('NameOfTheAirNetwork')}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t('PipeDiameter')}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t('DistributionLineLayout')}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t('PipeMaterial')}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{SplitText(t('TotalPipeLength'), 11)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t('PipeVolume')} {Fmt.getUnitInBracket("cubicMeter", t)}</Text>
                            </View>
                        </View>
                        {props.data.distributionLineList.map((el, i) =>
                            <View key={'DistributionLine' + i} wrap={false}>
                                <View style={StyleTable.tableRow}>
                                    <View style={i == props.data.distributionLineList.length - 1 ? [StyleTable.tableColRowZero, { flexGrow: 0.6 }] : [StyleTable.tableCol, { flexGrow: 0.6 }]}>
                                        <Text style={StyleTable.tableCell}>{el.measurementId}</Text>
                                    </View>
                                    <View style={i == props.data.distributionLineList.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{SplitText(el.building, 11)}</Text>
                                    </View>
                                    <View style={i == props.data.distributionLineList.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{SplitText(el.name, 11)}</Text>
                                    </View>
                                    <View style={i == props.data.distributionLineList.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{Fmt.round(el.pipeDiameter, country, 0)}</Text>
                                    </View>
                                    <View style={i == props.data.distributionLineList.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{t(el.distributionLineLayout?.value)}</Text>
                                    </View>
                                    <View style={i == props.data.distributionLineList.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{SplitText(el.pipeMaterial, 11)}</Text>
                                    </View>
                                    <View style={i == props.data.distributionLineList.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{Fmt.round(el.totalPipeLength, country, 2)}</Text>
                                    </View>
                                    <View style={i == props.data.distributionLineList.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{Fmt.round(calcs?.distributionLineResults[i].pipeVolume, country, 1)}</Text>
                                    </View>
                                </View>
                                {i === props.data.distributionLineList.length - 1 &&
                                    <View style={StyleTable.tableRow}>
                                        <View style={[StyleTable.tableColRowZero, { flexGrow: 0.6 }]}></View>
                                        <View style={StyleTable.tableColRowZero}></View>
                                        <View style={StyleTable.tableColRowZero}></View>
                                        <View style={StyleTable.tableColRowZero}></View>
                                        <View style={StyleTable.tableColRowZero}></View>
                                        <View style={StyleTable.tableColRowZero}>
                                            <Text style={StyleTable.tableCellTotal}>{t('Total')}</Text>
                                        </View>
                                        <View style={StyleTable.tableColRowZero}>
                                            <Text style={StyleTable.tableCellTotal}>{Fmt.round(calcs?.totalDistributionLinePipeLength, country, 0)}</Text>
                                        </View>
                                        <View style={StyleTable.tableColRowZero}>
                                            <Text style={StyleTable.tableCellTotal}>{Fmt.round(calcs?.totalDistributionLinePipeVolume, country, 1)}</Text>
                                        </View>
                                    </View>
                                }
                            </View>
                        )}
                    </View>
                    <Text style={PdfStyles.spacer}></Text>
                </View>
            }
            {props.data.airReceiverList.length > 0 &&
                <View>
                    <View>
                        <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["AirReceivers"] = pageNumber }} />
                        <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.airNetwork}.{props.tocValues.tocNumbering.AirReceivers} {t('AirReceivers')}</Text>
                        <Text style={PdfStyles.spacer}></Text>
                    </View>
                    <View style={StyleTable.table}>
                        <View style={StyleTable.tableRow} wrap={false} fixed>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t('MeasurementId')}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t('Building')} / {t('Department')} / {t('Machine')}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t('Volume')} {Fmt.getUnitInBracket("volumeLiterGalon", t)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t('WorkingPressure')}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t('Year')}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t('LsInspection')}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t('Comment')}</Text>
                            </View>
                        </View>
                        {props.data.airReceiverList.map((el, i) =>
                            <View key={'AirReceivers' + i} wrap={false}>
                                <View style={StyleTable.tableRow} >
                                    <View style={i == props.data.airReceiverList.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{el.measurementId}</Text>
                                    </View>
                                    <View style={i == props.data.airReceiverList.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{el.building + ' / ' + el.department + ' / ' + el.machine}</Text>
                                    </View>
                                    <View style={i == props.data.airReceiverList.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{Fmt.round(el.volume, country, 0)}</Text>
                                    </View>
                                    <View style={i == props.data.airReceiverList.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{Fmt.round(el.workingPressure, country, 2)}</Text>
                                    </View>
                                    <View style={i == props.data.airReceiverList.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{el.year}</Text>
                                    </View>
                                    <View style={i == props.data.airReceiverList.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{DateHelper.FormatDate(el.lsInspection)}</Text>
                                    </View>
                                    <View style={i == props.data.airReceiverList.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{el.comment}</Text>
                                    </View>
                                </View>
                                {i === props.data.airReceiverList.length - 1 &&
                                    <View style={StyleTable.tableRow}>
                                        <View style={StyleTable.tableColRowZero}></View>
                                        <View style={StyleTable.tableColRowZero}>
                                            <Text style={StyleTable.tableCellTotal}>{t('Total')}</Text>
                                        </View>
                                        <View style={StyleTable.tableColRowZero}>
                                            <Text style={StyleTable.tableCellTotal}>{Fmt.round(calcs?.totalAirReceiversVolume, country, 0)}</Text>
                                        </View>
                                        <View style={StyleTable.tableColRowZero}></View>
                                        <View style={StyleTable.tableColRowZero}></View>
                                        <View style={StyleTable.tableColRowZero}></View>
                                        <View style={StyleTable.tableColRowZero}></View>
                                    </View>
                                }
                            </View>
                        )}
                    </View>
                    <Text style={PdfStyles.spacer}></Text>
                </View>
            }
            <View style={PdfStyles.content}>
                <View wrap={false}>
                    <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["StorageAirCapacity"] = pageNumber }} />
                    <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.airNetwork}.{props.tocValues.tocNumbering.StorageAirCapacity} {t('StorageAirCapacity')}</Text>
                    <Text style={PdfStyles.spacer}></Text>
                    <View style={StyleTableNoBorder.table}>
                        <View style={StyleTableNoBorder.tableRow}>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCellTotal}>{t('TotalVolumeNetwork')} {Fmt.getUnitInBracket("cubicMeter", t)}</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableColColon}>
                                <Text style={StyleTableNoBorder.tableCellTotal}>:</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCellTotal}>{Fmt.round(calcs?.totalVolumeNetwork, country, 1)}</Text>
                            </View>
                        </View>
                        <View style={StyleTableNoBorder.tableRow}>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCellTotal}>{t('TotalVolumeAirReceivers')} {Fmt.getUnitInBracket("cubicMeter", t)}</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableColColon}>
                                <Text style={StyleTableNoBorder.tableCellTotal}>:</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCellTotal}>{Fmt.round(calcs?.totalVolumeAirReceivers, country, 1)}</Text>
                            </View>
                        </View>
                        <View style={StyleTableNoBorder.tableRow}>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCellTotal}>{t('Total')} {Fmt.getUnitInBracket("cubicMeter", t)}</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableColColon}>
                                <Text style={StyleTableNoBorder.tableCellTotal}>:</Text>
                            </View>
                            <View style={StyleTableNoBorder.tableCol}>
                                <Text style={StyleTableNoBorder.tableCellTotal}>{Fmt.round(calcs?.total, country, 1)}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
            {props.data.pressureDropAnalysisList.length > 0 &&
                <View>
                    <Text style={PdfStyles.spacer}></Text>
                    <View wrap={false}>
                        <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["pressureDropParts"] = pageNumber }} />
                        <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.airNetwork}.{props.tocValues.tocNumbering.PressureDropAnalysis} {t('PressureDropAnalysis')}</Text>
                        <Text style={PdfStyles.spacer}></Text>
                    </View>
                    <View style={StyleTable.table}>
                        <View style={StyleTable.tableRow} wrap={false} fixed>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t('MeasurementId')}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t('NameOfTheAirNetwork')}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t('MaximalSuggestedDrop')} {Fmt.getUnitInBracket("pressure", t)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t('MeasuredPressureDrop')} {Fmt.getUnitInBracket("pressure", t)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t('SuggestedSetpointPressureRel')} {Fmt.getUnitInBracket("pressure", t)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t('SetpointPressureRel')} {Fmt.getUnitInBracket("pressure", t)}</Text>
                            </View>
                        </View>
                        {props.data.pressureDropAnalysisList.map((el, i) =>
                            <View key={'PressureDrops' + i} style={StyleTable.tableRow} wrap={false}>
                                <View style={i == props.data.pressureDropAnalysisList.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>{el.measurementId}</Text>
                                </View>
                                <View style={i == props.data.pressureDropAnalysisList.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>{el.nameOfTheAirNetwork}</Text>
                                </View>
                                <View style={i == props.data.pressureDropAnalysisList.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>{Fmt.round(calcs?.pressureDropResults[i]?.pressureDropMax, country, 1)}</Text>
                                </View>
                                <View style={i == props.data.pressureDropAnalysisList.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                    <Text style={[StyleTable.tableCell, calcs?.pressureDropResults[i]?.pressureDropTOTAL > calcs?.pressureDropResults[i]?.pressureDropMax ? { color: '#D50000' } : {}]}>
                                        {Fmt.round(calcs?.pressureDropResults[i]?.pressureDropTOTAL, country, 1)}
                                    </Text>
                                </View>
                                <View style={i == props.data.pressureDropAnalysisList.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                    <Text style={StyleTable.tableCell}>
                                        {Fmt.round(el.suggestedSetpointPressureRel, country, 2)}
                                    </Text>
                                </View>
                                <View style={i == props.data.pressureDropAnalysisList.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                    <Text style={[StyleTable.tableCell, el.pressureSetpointPressureRel > el.suggestedSetpointPressureRel ? { color: '#D50000' } : {}]}>
                                        {Fmt.round(el.pressureSetpointPressureRel, country, 1)}
                                    </Text>
                                </View>
                            </View>
                        )}
                    </View>
                </View>
            }
            <Text style={PdfStyles.spacer}></Text>
            {props.data.pressureDropAnalysisList.map((el, i) =>
                <View key={'PressureDropsReducingTable' + i} wrap={false}>
                    <View wrap={false}>
                        <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.airNetwork}.{props.tocValues.tocNumbering.PressureDropAnalysis}.{i + 1} {el.nameOfTheAirNetwork}</Text>
                        <Text style={PdfStyles.spacer}></Text>
                        <Text style={PdfStyles.text}>{t('SavingsReducingThePressureDropTable')}</Text>
                        <Text style={PdfStyles.spacer}></Text>
                        <SavingsReducingThePressureDropTable
                            data={calcs.pressureDropResults[i].reducingPressureDropCalcs}
                            showPressureLevel
                            country={country}
                        />
                    </View>
                    <Text style={PdfStyles.spacer}></Text>
                    <View wrap={false}>
                        <Text style={PdfStyles.text}>{t('SavingsReducingThePressureLevelOfTheSystemTable')}</Text>
                        <Text style={PdfStyles.spacer}></Text>
                        <SavingsReducingThePressureLevelTable
                            data={calcs.pressureDropResults[i].reducingPressureLevelCalcs}
                            country={country}
                            showPressureLevel
                        />
                    </View>
                    <Text style={PdfStyles.spacer}></Text>
                </View>
            )}
            {props.data.pressureDropAnalysisList.length > 0 &&
                <View>
                    <View wrap={false}>
                        <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.airNetwork}.{props.tocValues.tocNumbering.PressureDropAnalysis}.{props.data.pressureDropAnalysisList.length + 1} {t("Savings")}</Text>
                        <Text style={PdfStyles.spacer}></Text>
                        <Text style={PdfStyles.text}>{t('SavingsReducingThePressureDropTable')}</Text>
                        <Text style={PdfStyles.spacer}></Text>
                        <SavingsReducingThePressureDropTable
                            data={calcs.reducingPressureDropTotalCalcs}
                            country={country}
                        />
                    </View>
                    <Text style={PdfStyles.spacer}></Text>
                    <View wrap={false}>
                        <Text style={PdfStyles.text}>{t('SavingsReducingThePressureLevelOfTheSystemTable')}</Text>
                        <Text style={PdfStyles.spacer}></Text>
                        <SavingsReducingThePressureLevelTable
                            data={calcs.reducingPressureLevelTotalCalcs}
                            country={country}
                        />
                    </View>
                </View>
            }
        </Page>
    );
}