import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, styled } from '@mui/material';
import AirProfileChart from '../../CompressedAirGeneration/AirProfileChart/AirProfileChart';
import SavingsBarChartComponent from '../../CostSavings/SavingsBarChart/SavingsBarChart';
import { SavingsBarChartModelFactory } from '../../CostSavings/Models/SavingsBarChartModel';
import PaybackTimeChart from '../../LeakageAnalysis/PaybackTimeChart';
import { DetectedLeakagesChart, RepairProcessChart, CostsAndSavingsChart, CO2Chart } from '../../LeakageDetectionAndRepair/LDARCharts/LDARCharts';
import * as Fmt from '../../../helper/Formatters'

const StyleChartDialog = styled('div')((props) => ({
    display: !props.open && 'none',
    position: 'fixed',
    zIndex: 5,
    left: 0,
    width: '1600px',
    marginTop: '150%',
    backgroundColor: '#fff'
}));

export default function ChartDialog(props) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    useEffect(() => {
        setOpen(props.open);
    }, [props.open])
    return (
        <StyleChartDialog {...props} className={'chart-dialog'} open={open}>
            {props.open && Object.keys(props.data).length > 0 &&
                <Grid container direction="column" spacing={5}>
                    <Grid item>
                        <AirProfileChart data={props.data.cagCalculationResult} />
                    </Grid>
                    <Grid item>
                        <SavingsBarChartComponent data={SavingsBarChartModelFactory(props.data.costSavingsResult, t)} />
                    </Grid>
                    <Grid item>
                        <PaybackTimeChart ChartModel={props.data.leakageAnalysisCalcResult.paybackChartResults} Currency={Fmt.getPercentage()} />
                    </Grid>
                    <Grid item>
                        <DetectedLeakagesChart data={props.data.ldarCalcResult} width={375} height={300} />
                    </Grid>
                    <Grid item>
                        <RepairProcessChart data={props.data.ldarCalcResult} width={375} height={300} />
                    </Grid>
                    <Grid item>
                        <CostsAndSavingsChart data={props.data.ldarCalcResult} width={375} height={300} />
                    </Grid>
                    <Grid item>
                        <CO2Chart data={props.data.ldarCalcResult} width={375} height={300} />
                    </Grid>
                </Grid>
            }
        </StyleChartDialog>
    );
}