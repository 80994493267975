import React from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { usePdfStyles } from './PdfStyles';

export const GeneralInformationPdf = (props) => {
    const { t } = useTranslation();
    var PdfStyles = usePdfStyles();

    return (
        <Page size={props.pageSize} style={PdfStyles.page}>
            <View style={PdfStyles.header}>
                <View style={PdfStyles.logoWrapper}>
                    {props.logo && <Image style={PdfStyles.logo} src={props.logo} />}
                </View>
            </View>
            <View style={PdfStyles.pageBody}>
                <View style={PdfStyles.flexRow}>
                    <View style={PdfStyles.flexItem}>
                        <View style={PdfStyles.flexColumn}>
                            <View style={PdfStyles.flexColumnItem}>
                                <Text style={PdfStyles.heading}>{t('GeneralInformation')}</Text>
                            </View>
                            <View style={{ marginTop: 10 }}>
                            </View>
                            <View style={[PdfStyles.inputContainer, PdfStyles.flexColumnItem]}>
                                <Text style={PdfStyles.inputLabel}>{t('CompanyName')}</Text>
                            </View>
                            <View style={[PdfStyles.inputContainer, PdfStyles.flexColumnItem]}>
                                <Text style={PdfStyles.inputLabel}>{t('Address')}</Text>
                            </View>
                            <View style={[PdfStyles.inputContainer, PdfStyles.flexColumnItem]}>
                                <Text style={PdfStyles.inputLabel}>{t('City')}</Text>
                            </View>
                            <View style={[PdfStyles.inputContainer, PdfStyles.flexColumnItem]}>
                                <Text style={PdfStyles.inputLabel}>{t('Country')}</Text>
                            </View>
                            <View style={[PdfStyles.inputContainer, PdfStyles.flexColumnItem]}>
                                <Text style={PdfStyles.inputLabel}>{t('ContactPerson')}</Text>
                            </View>
                            <View style={[PdfStyles.inputContainer, PdfStyles.flexColumnItem]}>
                                <Text style={PdfStyles.inputLabel}>{t('PhoneNumber')}</Text>
                            </View>
                            <View style={[PdfStyles.inputContainer, PdfStyles.flexColumnItem]}>
                                <Text style={PdfStyles.inputLabel}>{t('Email')}</Text>
                            </View>
                            <View style={[PdfStyles.inputContainer, PdfStyles.flexColumnItem]}>
                                <Text style={PdfStyles.inputLabel}>{t('NumberOfEmployeesInThePlant')}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={PdfStyles.flexItem}>
                        <View style={PdfStyles.flexColumn}>
                            <View style={PdfStyles.flexColumnItem}>
                                <Text style={PdfStyles.heading}>{t('WorkingTimeInHours')}</Text>
                            </View>
                            <View style={{ marginTop: 7 }}>
                            </View>
                            <View style={PdfStyles.flexColumnItem}>
                                <View style={PdfStyles.flexRow}>
                                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                                        <Text style={PdfStyles.inputLabel}>{t('MondayToFriday')}</Text>
                                    </View>
                                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                                        <Text style={PdfStyles.inputLabel}>{t('Saturday')}</Text>
                                    </View>
                                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                                        <Text style={PdfStyles.inputLabel}>{t('Sunday')}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={PdfStyles.flexColumnItem}>
                                <Text style={PdfStyles.heading}>{t('AmbientClimaticConditionsHeader')}</Text>
                            </View>
                            <View style={PdfStyles.flexColumnItem}>
                            </View>
                            <View style={PdfStyles.flexColumnItem}>
                                <View style={PdfStyles.flexRow}>
                                    <View style={PdfStyles.flexItem}>
                                        <Text style={{ fontSize: 7, paddingTop: 6 }}>{t('Temperature')}</Text>
                                    </View>
                                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                                        <Text style={PdfStyles.inputLabel}>{t('Spring')}</Text>
                                    </View>
                                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                                        <Text style={PdfStyles.inputLabel}>{t('Summer')}</Text>
                                    </View>
                                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                                        <Text style={PdfStyles.inputLabel}>{t('Autumn')}</Text>
                                    </View>
                                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                                        <Text style={PdfStyles.inputLabel}>{t('Winter')}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={PdfStyles.flexColumnItem}>
                                <View style={PdfStyles.flexRow}>
                                    <View style={PdfStyles.flexItem}>
                                        <Text style={{ fontSize: 7, paddingTop: 6 }}>{t('Humidity')}</Text>
                                    </View>
                                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                                        <Text style={PdfStyles.inputLabel}>{t('Spring')}</Text>
                                    </View>
                                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                                        <Text style={PdfStyles.inputLabel}>{t('Summer')}</Text>
                                    </View>
                                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                                        <Text style={PdfStyles.inputLabel}>{t('Autumn')}</Text>
                                    </View>
                                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                                        <Text style={PdfStyles.inputLabel}>{t('Winter')}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{ marginTop: 22 }}>
                            </View>
                            <View style={[PdfStyles.inputContainer, PdfStyles.flexColumnItem]}>
                                <Text style={PdfStyles.inputLabel}>{t('AltitudeAboveSeaLevel')}</Text>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={PdfStyles.flexRow}>
                    <View style={PdfStyles.flexItem}>
                        <View style={PdfStyles.flexColumn}>
                            <View style={PdfStyles.flexColumnItem}>
                                <Text style={PdfStyles.heading}>{t('EnergyCost')}</Text>
                            </View>
                            <View style={{ marginTop: 10 }}>
                            </View>
                            <View style={PdfStyles.flexColumnItem}>
                                <View style={PdfStyles.flexRow}>
                                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                                        <Text style={PdfStyles.inputLabel}>{t('Currency')}</Text>
                                    </View>
                                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                                        <Text style={PdfStyles.inputLabel}>{t('CostsPerkWh')}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={PdfStyles.flexColumnItem}>
                                <Text style={PdfStyles.heading}>{t('AirSupplyTime')}</Text>
                            </View>
                            <View style={{ marginTop: 10 }}>
                            </View>
                            <View style={PdfStyles.flexRowStart}>
                                <View style={PdfStyles.flexItemNoGrow}>
                                    <View style={PdfStyles.checkbox}></View>
                                </View>
                                <View style={PdfStyles.flexItemNoGrow}>
                                    <Text style={PdfStyles.label}>{t('IsTheAirSupllyShuttedOff')}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={PdfStyles.flexItem}>
                    </View>
                </View>
            </View>
        </Page>
    );
}