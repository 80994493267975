import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import { API } from '../../helper/ApiHelper';
import "./AirQuality.scss";
import ImageViewer from '../ImageViewer/ImageViewer';
import { HeadingComponent, ButtonComponent, SimpleCheckbox, ModalComponent, TableHeadCells, LinkComponent } from '../Reusable/';
import { Edit } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, IconButton, TextareaAutosize, TablePagination } from '@mui/material';
import * as Fmt from '../../helper/Formatters';
import * as ImageBlobConstants from '../ImageViewer/Constants';
import { OpenGlobalSnackbar, ScrollerToTag, AirQualityMeasurementParticleClass, AirQualityMeasurementGasClass, AirQualityMeasurementOilClass } from '../../helper/GlobalVariables';
import TableHeadingContent from '../Reusable/TableHeadingContent/TableHeadingContent';
import { FilterModel } from "../Reusable/TableHeadingContent/GlobalFilterModel";
import { useGlobalState } from 'state-pool';
import { AirQualityMeasurementModelFactory } from './AirQualityMeasurement/AirQualityMeasurementModel';
import { IndividualDryerModelFactory, AirQualityCalcModelFactory } from './IndividualDryer/IndividualDryerModel';
import ImportFullDialog from '../ImportDryerCompressorFull/ImportFullDialog';
import * as Consts from '../../helper/ConstantRepository';
import { DirtyStore } from '../../store';

export default function AirQuality(props) {
    const { denyEdit } = props;
    const aqUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "air-quality";
    const { t } = useTranslation();
    let { country, lng, id } = useParams();
    let history = useHistory();
    const [comment, setComment] = useState(null);
    const [dataIndD, setDataIndD] = useState(null);
    const [dataAQM, setDataAQM] = useState(null);
    const [dataCountAQM, setDataCountAQM] = useState(0);
    const [dataCountIndD, setDataCountIndD] = useState(0);
    const PageS = 10;
    const [checkedArray, setCheckedArray] = useState([]);
    const [checkedArrayAQM, setCheckedArrayAQM] = useState([]);
    const [page, setPage] = useGlobalState("DryerPage" + id, { default: 0 });
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page2, setPage2] = useGlobalState("AQMPage" + id, { default: 0 });
    const [rowsPerPage2, setRowsPerPage2] = useState(10);
    const [airQualityCalcResults, setAirQualityCalcResults] = useState({})
    const [filterDataIndD, setFilterDataIndD] = useState([]);
    const [filterDataAQM, setFilterDataAQM] = useState([]);
    const [importOpen, setImportOpen] = useState(false);
    const [modalModel, setModalModel] = useState({
        open: false,
        message: ""
    });

    const individualDryerModel = { ...IndividualDryerModelFactory(t), ...AirQualityCalcModelFactory(t) };
    const airQualityMeasurementModel = AirQualityMeasurementModelFactory(t);

    useEffect(() => {
        if (id > 0) {
            fetchData(true);
        }
    }, []);

    const clickCancel = () => {
        history.push("/" + country + "/" + lng + '/projectsandrequests');
    }

    const clickDelete = (deleteFunc) => {
        if (
            (deleteFunc == deleteRequest && (checkedArrayAQM == undefined || checkedArrayAQM.length == 0))
            || (deleteFunc == deleteRequestIndD && (checkedArray == undefined || checkedArray.length == 0))
        )
            OpenGlobalSnackbar("danger", t('NoEntriesCheckedError'));
        else
            clickDeleteOpenModal(deleteFunc);
    }

    const clickDeleteOpenModal = (deleteFunc) => {
        modalModel.open = true;
        modalModel.okText = t('Yes');
        modalModel.cancelText = t('No');
        modalModel.message = t('DeleteSelectedRowsQuestion');
        modalModel.clickOk = deleteFunc;
        setModalModel({ ...modalModel });
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage2 = (event, newPage) => {
        setPage2(newPage);
    };

    const handleChangeRowsPerPage2 = (event) => {
        setRowsPerPage2(parseInt(event.target.value, 10));
        setPage2(0);
    };

    const handleCheck = (itemId) => {
        let isAlready = checkedArray.indexOf(itemId);
        if (isAlready < 0) {
            checkedArray.push(itemId);
        }
        else {
            checkedArray.splice(isAlready, 1);
        }
        checkedArray.sort(function (a, b) { return b - a });
        setCheckedArray(checkedArray);
    }

    const handleCheckAQM = (itemId) => {
        let isAlready = checkedArrayAQM.indexOf(itemId);
        if (isAlready < 0) {
            checkedArrayAQM.push(itemId);
        }
        else {
            checkedArrayAQM.splice(isAlready, 1);
        }
        checkedArrayAQM.sort(function (a, b) { return b - a });
        setCheckedArrayAQM(checkedArrayAQM);
    }

    const fetchData = (initialValue) => {
        API.get(aqUrl + '/' + id).then(result => {
            setComment(result.comment);
            if (result.measurements != undefined && result.measurements.length > 0) {
                setDataAQM(result.measurements.map((item, no) => ({ ...item, no })));
                setDataCountAQM(result.measurements.length / PageS > Number.parseInt(result.measurements.length / PageS) ? Number.parseInt(result.measurements.length / PageS) + 1 : Number.parseInt(result.measurements.length / PageS));
                setFilterDataAQM(result.measurements);
            }
            if (result.individualDryers != undefined && result.individualDryers.length > 0) {
                setDataIndD(result.individualDryers.map((item, no) => ({ ...item, no })));
                setDataCountIndD(result.individualDryers.length / PageS > Number.parseInt(result.individualDryers.length / PageS) ? Number.parseInt(result.individualDryers.length / PageS) + 1 : Number.parseInt(result.individualDryers.length / PageS));
                setFilterDataIndD(result.individualDryers);
            }

            setAirQualityCalcResults(result.airQualityCalcResults)
            if (initialValue) ScrollerToTag();
        })
    };

    const postForm = () => {
        var dataUpload = {
            ProjectId: id > 0 ? id : 0,
            Comment: comment
        };
        API.put(aqUrl + '/' + id, dataUpload).then(() => {
            DirtyStore.setIsDirty(false);
            OpenGlobalSnackbar("success", t('SaveSuccessful'));
            fetchData();
        })
        return true;
    };

    const deleteRequest = async () => {
        var dataUpload = {
            ProjectId: id,
            Ids: checkedArrayAQM
        };
        API.post(aqUrl + `/${id}/measurement/`, dataUpload).then(() => {
            setCheckedArrayAQM([]);
            fetchData();
            OpenGlobalSnackbar("success", t("DeleteSuccesfull"));
        })
    };

    const deleteRequestIndD = async () => {
        var dataUpload = {
            ProjectId: id,
            Ids: checkedArray
        };
        API.post(aqUrl + `/${id}/dryer/`, dataUpload).then(() => {
            setCheckedArray([]);
            fetchData();
            OpenGlobalSnackbar("success", t("DeleteSuccesfull"));
        })
    };

    const addTableLine = (tableName, cid = 0) => {
        pushHistoryForTableEdit(tableName, cid);
    }

    const pushHistoryForTableEdit = (tableName, cid) => {
        history.push(getTableLineLink(tableName, cid));
    }

    const getTableLineLink = (tableName, cid) => "/" + country + "/" + lng + '/project/' + id + '/AQ/' + tableName + '/' + cid;

    const filterFunction = (tempdata) => {
        setDataIndD(tempdata);
        setPage(0);
    }

    const filterFunctionMeasurment = (tempdata) => {
        setDataAQM(tempdata);
        setPage2(0);
    }

    return (
        <div className="air-quality table-layout-fix">
            <ModalComponent model={modalModel} />
            <ImportFullDialog
                open={importOpen}
                setOpen={setImportOpen}
                viewList={[Consts.ProjectImportDryer]}
                reloadData={fetchData}
            />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <HeadingComponent value={t('AnalysisOfDryers')} size="h6" />
                </Grid>
                <Grid item md={3} lg={2}>
                    <Grid item>
                        <h6>{t('SummerTemperature') + Fmt.getUnitInBracket("temperature", t)}</h6>
                    </Grid>
                    <Grid item>
                        <h6>{t('SetpointPressure') + Fmt.getUnitInBracket("pressure", t)}</h6>
                    </Grid>
                </Grid>
                <Grid item md={3}>
                    <Grid item>
                        <h6>{Fmt.round(airQualityCalcResults.summerTemperature, country, 2)}</h6>
                    </Grid>
                    <Grid item>
                        <h6>{Fmt.round(airQualityCalcResults.setPointPressureAvg, country, 2)}</h6>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={5} direction="column">
                <Grid item id="#InstalledDryers">
                    <HeadingComponent value={t('InstalledDryers')} size="h6" />
                </Grid>
                <Grid item>
                    <TableHeadingContent
                        id="IndividualDryerTableHeading"
                        clickImport={() => setImportOpen(true)}
                        clickDelete={() => clickDelete(deleteRequestIndD)}
                        clickAdd={() => addTableLine("IndividualDryer")}
                        defaultData={dataIndD}
                        filterData={filterDataIndD}
                        onFilterFunction={filterFunction}
                        filterModel={FilterModel.InstalledDryers}
                        denyEdit={denyEdit}
                        tableId="IndividualDryer"
                    />
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table >
                    <TableHead>
                        <TableRow >
                            {!denyEdit && <TableCell></TableCell>}
                            <TableHeadCells
                                model={individualDryerModel}
                                propertyList={["id", "measurementId", "compressorRoomNo", "typeOfDryer", "manufacturer", "modelSerialNumber", "year", "flowRangeOfDryer", "temperatureOftheDryer", "dryerCapacityCorrectedOperatingConditions", "assignedCompressorsNumbers", "dryerFlowAssignedCompressor", "dryerReserve"]}
                                tableName={"InstalledDryers"}
                                data={dataIndD}
                                setData={setDataIndD}
                            />
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    {Object.keys(airQualityCalcResults).length > 0 && dataIndD && dataIndD.length > 0 && airQualityCalcResults.dryerCalculations != undefined && airQualityCalcResults.dryerCalculations.length > 0 &&
                        <TableBody>
                            {dataIndD.slice(page * rowsPerPage, rowsPerPage + (page * rowsPerPage)).map((item, index) =>

                                <TableRow className="table-body-row" key={index}>
                                    {!denyEdit &&
                                        <TableCell>
                                            <SimpleCheckbox
                                                id={'dd_Dryers-checkbox' + index}
                                                key={Math.random()}
                                                color="default"
                                                onChange={() => handleCheck(item.id)}
                                                defaultChecked={checkedArray.includes(item.id)}
                                            />
                                        </TableCell>
                                    }
                                    <TableCell id={"td_DRYERS-index" + index}>
                                        {item.no + 1}
                                    </TableCell>
                                    <TableCell id={"td_DRYERS-measurementId" + index}>
                                        <LinkComponent
                                            link={getTableLineLink("IndividualDryer", item.id + "#Tabs")}
                                            innerComponent={item.measurementId}
                                        />
                                    </TableCell>
                                    <TableCell id={"td_DRYERS-compressorRoomNo" + index}>
                                        {item.compressorRoomNo}
                                    </TableCell>
                                    <TableCell id={"td_DRYERS-typeOfDryer" + index}>
                                        {item.typeOfDryer ? t(item.typeOfDryer.value) : null}
                                    </TableCell>
                                    <TableCell id={"td_DRYERS-manufacturer" + index}>
                                        {item.manufacturer}</TableCell>
                                    <TableCell id={"td_DRYERS-modelSerialNumber" + index}>
                                        {item.modelSerialNumber}</TableCell>
                                    <TableCell id={"td_DRYERS-year" + index}>
                                        {item.year}
                                    </TableCell>
                                    <TableCell id={"td_DRYERS-flowRangeOfDryer" + index}>
                                        {item.flowRangeOfDryer}
                                    </TableCell>
                                    <TableCell id={"td_DRYERS-temperatureOftheDryer" + index}>
                                        {Fmt.round(item.temperatureOftheDryer, country, 0)}
                                    </TableCell>
                                    <TableCell id={"td_DRYERS-dryerCapacityCorrectedOperatingConditions" + index}>
                                        {Fmt.round(airQualityCalcResults.dryerCalculations.slice(page * rowsPerPage, rowsPerPage + (page * rowsPerPage))[index]?.dryerCapacityCorrectedOperatingConditions, country, 1)}
                                    </TableCell>
                                    <TableCell id={"td_DRYERS-assignedCompressorsNumbers" + index}>
                                        {airQualityCalcResults.dryerCalculations.slice(page * rowsPerPage, rowsPerPage + (page * rowsPerPage))[index]?.assignedCompressorsNumbers}
                                    </TableCell>
                                    <TableCell id={"td_DRYERS-dryerFlowAssignedCompressor" + index}>
                                        {Fmt.round(airQualityCalcResults.dryerCalculations.slice(page * rowsPerPage, rowsPerPage + (page * rowsPerPage))[index]?.dryerFlowAssignedCompressor, country, 1)}
                                    </TableCell>
                                    <TableCell
                                        id={"td_DRYERS-dryerReserve" + index}
                                        className={airQualityCalcResults.dryerCalculations.slice(page * rowsPerPage, rowsPerPage + (page * rowsPerPage))[index]?.dryerReserve < 0 && "mark-red"}
                                    >
                                        {Fmt.round(airQualityCalcResults.dryerCalculations.slice(page * rowsPerPage, rowsPerPage + (page * rowsPerPage))[index]?.dryerReserve, country, 1)}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={() => addTableLine("IndividualDryer", item.id + "#Tabs")}
                                            href={getTableLineLink("IndividualDryer", item.id + "#Tabs")}
                                            id={'btn_DRYERS-edit-button' + index}
                                            size="large"
                                        >
                                            <Edit />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )}
                            <TableRow>
                                {!denyEdit && <TableCell></TableCell>}
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell className="font-bold" id="td_DRYERS-dryerCapacityCorrectedOperatingConditions">{Fmt.round(airQualityCalcResults.dryerCalculations.reduce((total, individualDryer) => total + individualDryer.dryerCapacityCorrectedOperatingConditions, 0), country, 1)}</TableCell>
                                <TableCell></TableCell>
                                <TableCell className="font-bold" id="td_DRYERS-dryerFlowAssignedCompressor">{Fmt.round(airQualityCalcResults.dryerCalculations.reduce((total, individualDryer) => total + individualDryer.dryerFlowAssignedCompressor, 0), country, 1)}</TableCell>
                                <TableCell
                                    id="td_DRYERS-dryerReserve"
                                    className={(airQualityCalcResults.dryerCalculations.reduce((total, individualDryer) => total + individualDryer.dryerReserve, 0) < 0 && "mark-red") + " font-bold"}
                                >
                                    {Fmt.round(airQualityCalcResults.dryerCalculations.reduce((total, individualDryer) => total + individualDryer.dryerReserve, 0), country, 1)}
                                </TableCell>
                            </TableRow>

                        </TableBody>
                    }
                </Table>
            </TableContainer>

            {dataCountIndD > 0 ?
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25]}
                    component="div"
                    count={dataIndD && dataIndD.length > 0 ? dataIndD.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> : null}
            <Grid container spacing={5} direction="column">
                <Grid item id="#AirQualityMeasurement">
                    <HeadingComponent value={t('AirQualityMeasurement')} size="h6" />
                </Grid>
                <Grid item>
                    <TableHeadingContent
                        id="AirQualityMeasurementTableHeading"
                        clickDelete={() => clickDelete(deleteRequest)}
                        clickAdd={() => addTableLine("AirQualityMeasurement")}
                        defaultData={dataAQM}
                        filterData={filterDataAQM}
                        onFilterFunction={filterFunctionMeasurment}
                        filterModel={FilterModel.AQMeasurment}
                        denyEdit={denyEdit}
                        tableId="AirQualityMeasurement"
                    />
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table >
                    <TableHead >
                        <TableRow>
                            <TableCell colSpan={denyEdit ? 6 : 7} ></TableCell>
                            <TableCell colSpan={4} style={{ textAlign: "center" }}>{t('Particle')}</TableCell>
                            <TableCell colSpan={4} style={{ textAlign: "center" }}>{t('Water')} </TableCell>
                            <TableCell colSpan={4} style={{ textAlign: "center" }}>{t('Oil')}</TableCell>
                            <TableCell colSpan={1} style={{ textAlign: "center" }}></TableCell>
                        </TableRow>
                        <TableRow>
                            {!denyEdit && <TableCell></TableCell>}
                            <TableHeadCells
                                model={airQualityMeasurementModel}
                                propertyList={["no", "measurementId", "building", "department", "machine", "", "particleRequiredPG", "particleExpectedPG", "particleMeasuredPG", "particleResultClass", "gasRequiredPG", "gasExpectedPG", "gasMeasuredPG", "gasResultClass", "oilRequiredClass", "oilExpectedPG", "oilMeasuredPG", "oilResultClass"]}
                                styleList={[{}, {}, {}, {}, {}, { className: "table-rightborder content-class-header" }, {}, {}, {}, { className: "table-rightborder" }, {}, {}, {}, { className: "table-rightborder" }, {className: "content-class-header"}, {}, {}, {}, {}]}
                                tableName={"AirQualityMeasurement"}
                                data={dataAQM}
                                setData={setDataAQM}
                            />
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            dataAQM && dataAQM.length > 0 ? dataAQM.slice(page2 * rowsPerPage2, page2 * rowsPerPage2 + rowsPerPage2).map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <TableRow>
                                            {!denyEdit &&
                                                <TableCell rowSpan="2">
                                                    <SimpleCheckbox
                                                        id={'dd_Measurment-checkbox' + index}
                                                        key={Math.random()}
                                                        color="default"
                                                        onChange={() => handleCheckAQM(item.id)}
                                                        defaultChecked={checkedArrayAQM.includes(item.id)}
                                                    />
                                                </TableCell>
                                            }
                                            <TableCell id={"td_MEAS-index" + index} rowSpan="2" >{item.no + 1}</TableCell>
                                            <TableCell id={"td_MEAS-measurementId" + index} rowSpan="2">
                                                <LinkComponent
                                                    link={getTableLineLink("AirQualityMeasurement", item.id + "#Tabs")}
                                                    innerComponent={item.measurementId}
                                                />
                                            </TableCell>
                                            <TableCell id={"td_MEAS-building" + index} rowSpan="2">{item.building}</TableCell>
                                            <TableCell id={"td_MEAS-building" + index} rowSpan="2">{item.department}</TableCell>
                                            <TableCell id={"td_MEAS-building" + index} rowSpan="2">{item.machine}</TableCell>
                                            <TableCell className="table-rightborder no-bottom-border">Content</TableCell>
                                            <TableCell id={"td_MEAS-particleRequiredPG" + index} className="no-bottom-border" >{Fmt.replaceUnitString(item.particleRequiredPG ?? "", t)}</TableCell>
                                            <TableCell id={"td_MEAS-particleExpectedPG" + index} className="no-bottom-border" >{Fmt.replaceUnitString(item.particleExpectedPG ?? "", t)}</TableCell>
                                            <TableCell id={"td_MEAS-particleMeasuredPG" + index} className="no-bottom-border" >{(item.particleMeasuredPG ?? "")}</TableCell>
                                            <TableCell className="table-rightborder no-bottom-border"></TableCell>
                                            <TableCell id={"td_MEAS-gasRequiredPG" + index} className="no-bottom-border" >{Fmt.replaceUnitString(item.gasRequiredPG ?? "", t)}</TableCell>
                                            <TableCell id={"td_MEAS-gasExpectedPG" + index} className="no-bottom-border">{Fmt.replaceUnitString(item.gasExpectedPG ?? "", t)}</TableCell>
                                            <TableCell id={"td_MEAS-gasMeasuredPG" + index} className="no-bottom-border">{(item.gasMeasuredPG ?? "")}</TableCell>
                                            <TableCell className="table-rightborder no-bottom-border"></TableCell>
                                            <TableCell id={"td_MEAS-oilRequiredPG" + index} className="no-bottom-border">{Fmt.replaceUnitString(item.oilRequiredPG ?? "", t)}</TableCell>
                                            <TableCell id={"td_MEAS-oilExpectedPG" + index} className="no-bottom-border">{Fmt.replaceUnitString(item.oilExpectedPG ?? "", t)}</TableCell>
                                            <TableCell id={"td_MEAS-oilMeasuredPG" + index} className="no-bottom-border">{(item.oilMeasuredPG ?? "")}</TableCell>
                                            <TableCell className="no-bottom-border"></TableCell>
                                            <TableCell rowSpan="2">
                                                <IconButton
                                                    onClick={() => addTableLine("AirQualityMeasurement", item.id + "#Tabs")}
                                                    href={getTableLineLink("AirQualityMeasurement", item.id + "#Tabs")}
                                                    id={'td_MEAS-edit-button' + index}
                                                    size="large"
                                                >
                                                    <Edit />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell className="table-rightborder">Class</TableCell>
                                            <TableCell id={"td_MEAS-particleRequiredClass" + index}>{AirQualityMeasurementParticleClass.find(x => x.id == item.particleRequiredClassId)?.idValue}</TableCell>
                                            <TableCell id={"td_MEAS-particleExpectedClass" + index}>{AirQualityMeasurementParticleClass.find(x => x.id == item.particleExpectedClassId)?.idValue}</TableCell>
                                            <TableCell id={"td_MEAS-particleMeasuredClass" + index}>{item.particleMeasuredClass ?? ""}</TableCell>
                                            <TableCell id={"td_MEAS-particleResultClass" + index} className="table-rightborder">{item.particleResultClass}</TableCell>
                                            <TableCell id={"td_MEAS-gasRequiredClass" + index}>{AirQualityMeasurementGasClass.find(x => x.id == item.gasRequiredClassId)?.idValue}</TableCell>
                                            <TableCell id={"td_MEAS-gasExpectedClass" + index}>{AirQualityMeasurementGasClass.find(x => x.id == item.gasExpectedClassId)?.idValue}</TableCell>
                                            <TableCell id={"td_MEAS-gasMeasuredClass" + index}>{item.gasMeasuredClass}</TableCell>
                                            <TableCell id={"td_MEAS-gasResultClass" + index} className="table-rightborder">{item.gasResultClass}</TableCell>
                                            <TableCell id={"td_MEAS-oilRequiredClass" + index}>{AirQualityMeasurementOilClass.find(x => x.id == item.oilRequiredClassId)?.idValue}</TableCell>
                                            <TableCell id={"td_MEAS-oilExpectedClass" + index}>{AirQualityMeasurementOilClass.find(x => x.id == item.oilExpectedClassId)?.idValue}</TableCell>
                                            <TableCell id={"td_MEAS-oilMeasuredClass" + index}>{item.oilMeasuredClass ?? ""}</TableCell>
                                            <TableCell id={"td_MEAS-oilResultClass" + index}>{item.oilResultClass}</TableCell>
                                        </TableRow>

                                    </React.Fragment>
                                );
                            })
                                : null
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {dataCountAQM > 0 ?
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25]}
                    component="div"
                    count={dataAQM && dataAQM.length > 0 ? dataAQM.length : 0}
                    rowsPerPage={rowsPerPage2}
                    page={page2}
                    onPageChange={handleChangePage2}
                    onRowsPerPageChange={handleChangeRowsPerPage2}
                /> : null}
            <Grid item xs={12} className="aqm-explnationimage">
                <img
                    src={"/pictures/AQMeasurementExplanationTable.png"}
                    alt=""
                />
            </Grid>
            <Grid container spacing={5} direction="column" className="header-margin">
                <Grid item>
                    <HeadingComponent value={t('Comment')} size="h6" />
                </Grid>
                <Grid item>
                    <TextareaAutosize
                        disabled={denyEdit}
                        type="text"
                        value={comment != undefined ? comment : ""}
                        minRows="5"
                        onChange={e => {
                            let evalue = e.target.value;
                            DirtyStore.setIsDirty(postForm);
                            setComment(evalue);
                        }}
                        id="textarea_AQ-comment"
                    />
                </Grid>
                <Grid item>
                    <ImageViewer disabled={denyEdit} groupId={ImageBlobConstants.AirQuality} />
                </Grid>
                <Grid item>
                    <Grid container direction="row" justifyContent="flex-end">
                        <Grid item> <ButtonComponent value={t('Cancel')} color="secondary" onChange={clickCancel} id="btn_AQ-Cancel"></ButtonComponent></Grid>
                        {!denyEdit &&
                            <Grid item>
                                <ButtonComponent value={t('Save')} color="primary" onChange={() => postForm()} id="btn_AQ-Save" />
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </div >
    );
}

