import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { ReplacementPartModel } from './ReplacementPartModel';
import { ButtonComponent, HeadingComponent } from '../Reusable/';
import { Grid, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Close } from '@mui/icons-material';
import ReplacementPartDialogBody from './ReplacementPartDialogBody';
import * as Consts from '../../helper/ConstantRepository';
import * as Mapper from '../../helper/Mappers';
import { ReplacementPartComponents, OpenGlobalSnackbar } from '../../helper/GlobalVariables';
import { checkAndValidateModel } from '../../helper/Validator';

export default function ReplacementPartDialog(props) {
    const { disabled, isEdit, setIsEdit, replacementForEdit, addNewReplacement, updateReplacement, showDialog, setShowDialog } = props;

    const { t } = useTranslation();
    const [mainModel, setMainModel] = useState(ReplacementPartModel(t));

    useEffect(() => {
        if (showDialog)
            resetDialog(isEdit);
    }, [showDialog]);

    function resetDialog(loadEditable) {
        if (loadEditable)
            setMainModel(_.cloneDeep(replacementForEdit));
        else
            setMainModel(ReplacementPartModel(t));
    }

    const save = () => {
        let [isValid, validatedModel] = checkAndValidateModel(mainModel);
        setMainModel({ ...validatedModel });
        if (!isValid) {
            OpenGlobalSnackbar("danger", t('ModelInvalidError'));
            return;
        }
        saveData();
        setShowDialog(false);
    };

    const cancel = () => {
        setShowDialog(false);
    };

    const saveAndInsertNew = () => {
        let [isValid, validatedModel] = checkAndValidateModel(mainModel);
        setMainModel({ ...validatedModel });
        if (!isValid) {
            OpenGlobalSnackbar("danger", t('ModelInvalidError'));
            return;
        }
        saveData();
        setIsEdit(false);
        resetDialog(false);
    };

    const saveData = () => {
        let model = Mapper.extractValuesFromModel(mainModel);
        if (isEdit)
            updateReplacement(model);
        else
            addNewReplacement(model);
    }

    const updateModel = (property, value) => {
        if (property == 'component') {
            mainModel.component.value = {};
            mainModel.component.value.id = value;
            mainModel.component.value.value = ReplacementPartComponents.find(el => el.id == value)?.value ?? "";
        }
        else mainModel[property].value = value;
        let [, validatedModel] = checkAndValidateModel(mainModel);
        setMainModel({ ...validatedModel });
    }

    const insertSearchMaterial = (materialModel, type) => {
        if (type == Consts.ReplacementPartOriginal) {
            mainModel.partNumber.value = materialModel.materialNo;
            mainModel.typeCode.value = materialModel.typecode;
            mainModel.manufacturer.value = "Festo";
        }
        else {
            mainModel.newPartNumber.value = materialModel.materialNo;
            mainModel.newTypeCode.value = materialModel.typecode;
            mainModel.newManufacturer.value = "Festo";
        }
        setMainModel({ ...mainModel });
    }

    return (
        <Dialog open={showDialog} aria-labelledby="contained-modal-title-vcenter" onClose={() => setShowDialog(false)}>
            <DialogTitle >
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <HeadingComponent value={t('DefectedDevice')} size="h6" />
                    </Grid>
                    <Grid item>
                        <IconButton onClick={() => setShowDialog(false)} size="large">
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <ReplacementPartDialogBody
                    disabled={disabled}
                    model={mainModel}
                    updateModel={updateModel}
                    insertSearchMaterial={insertSearchMaterial}
                />
            </DialogContent>
            <DialogActions>
                <Grid container direction="row" justifyContent="center" >
                    <Grid item><ButtonComponent value={t('Cancel')} id="Dialogcancel" color="secondary" onChange={cancel}></ButtonComponent></Grid>
                    {!disabled && <Grid item><ButtonComponent id="DialogSave" value={t('Save')} color="primary" onChange={save}></ButtonComponent></Grid>}
                    {!disabled && <Grid item><ButtonComponent id="DialogSaveAndNew" value={t('SaveAndNew')} color="primary" onChange={saveAndInsertNew}></ButtonComponent></Grid>}
                </Grid>
            </DialogActions>
        </Dialog>
    );
}