import React from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import { usePdfStyles, StyleTable } from './PdfStyles';
import { useTranslation } from 'react-i18next';
import * as Fmt from '../../../helper/Formatters';
import Watermark from './Watermark';
import { SplitText } from '../../../helper/PdfTested';
import { AirQualityMeasurementParticleClass, AirQualityMeasurementGasClass, AirQualityMeasurementOilClass } from '../../../helper/GlobalVariables';

export const AirQualityPdf = (props) => {
    const { data, country } = props;

    const { t } = useTranslation();
    var PdfStyles = usePdfStyles();

    return (
        <Page size={props.pageSize} style={PdfStyles.page}>
            <Watermark projectData={data} />
            <Text style={PdfStyles.pageNumbers} render={({ pageNumber, totalPages }) => (pageNumber + " " + t("of") + " " + totalPages)} fixed />
            <View>
                <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["AirQuality"] = pageNumber }} />
                <Text style={PdfStyles.boldHeading}>{props.tocValues.tocNumbering.airQuality}. {t("AirQuality")}</Text>
            </View>
            {data.individualDryersList.length > 0 &&
                <View>
                    <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.airQuality}.{props.tocValues.tocNumbering.InstalledDryers} {t("InstalledDryers")}</Text>
                    <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["InstalledDryers"] = pageNumber }} />
                    <Text style={PdfStyles.spacer}></Text>
                    <View style={StyleTable.table}>
                        <View style={StyleTable.tableRow} fixed>
                            <View style={[StyleTable.tableColRowZero, { flexGrow: 0.5 }]}>
                                <Text style={StyleTable.tableCell}>{t("Nr")}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("MeasurementId")}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{SplitText(t("CompressorRoomNo"), 8)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{SplitText(t("TypeOfDryer"), 8)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{SplitText(t("Manufacturer"), 8)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={StyleTable.tableCell}>{t("ModelSerialNumber")}</Text>
                            </View>
                            <View style={[StyleTable.tableColRowZero, { flexGrow: 0.5 }]}>
                                <Text style={StyleTable.tableCell}>{t("Year")}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={{ fontSize: 10 }}>{SplitText(t('FlowRangeOfDryersAtOperatingnm3'), 8) + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={{ fontSize: 10 }}>{SplitText(t('FlowRangeOfAssignednm3'), 8) + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t)}</Text>
                            </View>
                            <View style={StyleTable.tableColRowZero}>
                                <Text style={{ fontSize: 10 }}>{SplitText(t("Reserve"), 8) + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t)}</Text>
                            </View>
                        </View>
                        {data.individualDryersList.map((item, index) =>
                            <View key={"Dryers" + index} wrap={false}>
                                <View style={StyleTable.tableRow} key={"Dryers" + index}>
                                    <View style={index == data.individualDryersList.length - 1 ? [StyleTable.tableColRowZero, { flexGrow: 0.5 }] : [StyleTable.tableCol, { flexGrow: 0.5 }]}>
                                        <Text style={StyleTable.tableCell}>{index + 1}</Text>
                                    </View>
                                    <View style={index == data.individualDryersList.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{item.measurementId}</Text>
                                    </View>
                                    <View style={index == data.individualDryersList.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{item.compressorRoomNo}</Text>
                                    </View>
                                    <View style={index == data.individualDryersList.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{t(item.typeOfDryer?.value)}</Text>
                                    </View>
                                    <View style={index == data.individualDryersList.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{item.manufacturer}</Text>
                                    </View>
                                    <View style={index == data.individualDryersList.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{item.modelSerialNumber}</Text>
                                    </View>
                                    <View style={index == data.individualDryersList.length - 1 ? [StyleTable.tableColRowZero, { flexGrow: 0.5 }] : [StyleTable.tableCol, { flexGrow: 0.5 }]}>
                                        <Text style={StyleTable.tableCell}>{item.year}</Text>
                                    </View>
                                    <View style={index == data.individualDryersList.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.dryerCalculations[index]?.dryerCapacityCorrectedOperatingConditions, country, 1)}</Text>
                                    </View>
                                    <View style={index == data.individualDryersList.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.dryerCalculations[index]?.dryerFlowAssignedCompressor, country, 1)}</Text>
                                    </View>
                                    <View style={index == data.individualDryersList.length - 1 ? StyleTable.tableColRowZero : StyleTable.tableCol}>
                                        <Text style={StyleTable.tableCell}>{Fmt.round(props.calcs?.dryerCalculations[index]?.dryerReserve, country, 1)}</Text>
                                    </View>
                                </View>
                                {index === data.individualDryersList.length - 1 &&
                                    <View style={StyleTable.tableRow}>
                                        <View style={[StyleTable.tableColRowZero, { flexGrow: 0.5 }]}></View>
                                        <View style={StyleTable.tableColRowZero}></View>
                                        <View style={StyleTable.tableColRowZero}></View>
                                        <View style={StyleTable.tableColRowZero}></View>
                                        <View style={StyleTable.tableColRowZero}></View>
                                        <View style={StyleTable.tableColRowZero}></View>
                                        <View style={[StyleTable.tableColRowZero, { flexGrow: 0.5 }]}></View>
                                        <View style={StyleTable.tableColRowZero}>
                                            <Text style={StyleTable.tableCellTotal}>{Fmt.round(props.calcs?.dryerCalculations?.reduce((total, individualDryer) => total + individualDryer.dryerCapacityCorrectedOperatingConditions, 0), country, 1)}</Text>
                                        </View>
                                        <View style={StyleTable.tableColRowZero}>
                                            <Text style={StyleTable.tableCellTotal}>{Fmt.round(props.calcs?.dryerCalculations?.reduce((total, individualDryer) => total + individualDryer.dryerFlowAssignedCompressor, 0), country, 1)}</Text>
                                        </View>
                                        <View style={StyleTable.tableColRowZero}>
                                            <Text style={StyleTable.tableCellTotal}>{Fmt.round(props.calcs?.dryerCalculations?.reduce((total, individualDryer) => total + individualDryer.dryerReserve, 0), country, 1)}</Text>
                                        </View>
                                    </View>
                                }
                            </View>
                        )}

                    </View>
                </View>
            }
            {/**-------------------------------------------------------------------------------------------- */}
            {data.airQualityMeasurementList.length > 0 &&
                <View>
                    <Text style={PdfStyles.spacer}></Text>
                    <Text style={PdfStyles.text}>{props.tocValues.tocNumbering.airQuality}.{props.tocValues.tocNumbering.AirQualityMeasurement} {t("AirQualityMeasurement")}</Text>
                    <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["AirQualityMeasurement"] = pageNumber }} />
                    <Text style={PdfStyles.spacer}></Text>
                    <View style={StyleTable.table}>
                        <View style={[StyleTable.tableRow, { borderBottom: "3px solid black", }]} wrap={false} fixed>
                            <View style={{ flexGrow: 1, flexBasis: 0, borderRight: "1px solid black" }}>
                                <Text style={StyleTable.tableCell}>{t("Nr")}</Text>
                            </View>
                            <View style={{ flexGrow: 1, flexBasis: 0, borderRight: "1px solid black" }}>
                                <Text style={StyleTable.tableCell}>{t("MeasurementId")}</Text>
                            </View>
                            <View style={{ flexGrow: 1, flexBasis: 0, borderRight: "1px solid black" }}>
                                <Text style={StyleTable.tableCell}>{t("Building")} / {t("Department")} / {t("Machine")}</Text>
                            </View>
                            <View style={{ flexGrow: 4.75, flexBasis: 0, display: "flex", flexDirection: "column", alignItems: "stretch" }}>
                                <View style={{ flexGrow: 1, flexDirection: "row", alignItems: "stretch" }}>
                                    <View style={StyleTable.tableColRowZero}>
                                        <Text style={[StyleTable.tableCell, { textAlign: "center" }]}>{t("Particle")}</Text>
                                    </View>
                                </View>
                                <View style={{ flexGrow: 1, flexDirection: "row", alignItems: "stretch" }}>
                                    <View style={StyleTable.colNoBottomBorder}>
                                        <Text style={StyleTable.tableCell}></Text>
                                    </View>
                                    <View style={StyleTable.colNoBottomBorder}>
                                        <Text style={StyleTable.tableCell}>{t("Required")}</Text>
                                    </View>
                                    <View style={StyleTable.colNoBottomBorder}>
                                        <Text style={StyleTable.tableCell}>{t("Expected")}</Text>
                                    </View>
                                    <View style={StyleTable.colNoBottomBorder}>
                                        <Text style={StyleTable.tableCell}>{t("Measured")}</Text>
                                    </View>
                                    <View style={StyleTable.colNoBottomBorder}>
                                        <Text style={StyleTable.tableCell}>{t("Result")}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                        {data.airQualityMeasurementList.map((item, index) =>
                            <View style={[StyleTable.tableRow, { borderBottom: index == data.airQualityMeasurementList.length - 1 ? "3px solid black" : "1px solid black" }]} key={"MeasurmentPT" + index} wrap={false}>
                                <View style={{ flexGrow: 1, flexBasis: 0, borderRight: "1px solid black" }}>
                                    <Text style={StyleTable.tableCell}>{index + 1}</Text>
                                </View>
                                <View style={{ flexGrow: 1, flexBasis: 0, borderRight: "1px solid black" }}>
                                    <Text style={StyleTable.tableCell}>{item.measurementId}</Text>
                                </View>
                                <View style={{ flexGrow: 1, flexBasis: 0, borderRight: "1px solid black" }}>
                                    <Text style={StyleTable.tableCell}>{SplitText(`${item.building} / ${item.department} / ${item.machine}`, 10)}</Text>
                                </View>
                                <View style={{ flexGrow: 4.75, flexBasis: 0, display: "flex", flexDirection: "column", alignItems: "stretch" }}>
                                    <View style={{ flexGrow: 1, flexDirection: "row", alignItems: "stretch" }}>
                                        <View style={StyleTable.tableCol}>
                                            <Text style={[StyleTable.tableCell]}>{t("Content")}</Text>
                                        </View>
                                        <View style={StyleTable.tableCol}>
                                            <Text style={[StyleTable.tableCell]}>{SplitText(Fmt.replaceUnitString(item.particleRequiredPG, t), 11, ",")}</Text>
                                        </View>
                                        <View style={StyleTable.tableCol}>
                                            <Text style={[StyleTable.tableCell]}>{SplitText(Fmt.replaceUnitString(item.particleExpectedPG, t), 11, ",")}</Text>
                                        </View>
                                        <View style={StyleTable.tableCol}>
                                            <Text style={[StyleTable.tableCell]}>{item.particleMeasuredPG}</Text>
                                        </View>
                                        <View style={StyleTable.tableCol}>
                                            <Text style={[StyleTable.tableCell]}></Text>
                                        </View>
                                    </View>
                                    <View style={{ flexGrow: 1, flexDirection: "row", alignItems: "stretch" }}>
                                        <View style={StyleTable.colNoBottomBorder}>
                                            <Text style={StyleTable.tableCell}>{t("Class")}</Text>
                                        </View>
                                        <View style={StyleTable.colNoBottomBorder}>
                                            <Text style={StyleTable.tableCell}>{AirQualityMeasurementParticleClass.find(x => x.id == item.particleRequiredClassId)?.idValue}</Text>
                                        </View>
                                        <View style={StyleTable.colNoBottomBorder}>
                                            <Text style={StyleTable.tableCell}>{AirQualityMeasurementParticleClass.find(x => x.id == item.particleExpectedClassId)?.idValue}</Text>
                                        </View>
                                        <View style={StyleTable.colNoBottomBorder}>
                                            <Text style={StyleTable.tableCell}>{SplitText(item.particleMeasuredClass, 9)}</Text>
                                        </View>
                                        <View style={StyleTable.colNoBottomBorder}>
                                            <Text style={StyleTable.tableCell}>{SplitText(item.particleResultClass, 9)}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        )}
                    </View>
                    {/**-------------------------------------------------------------------------------------------- */}
                    <Text style={PdfStyles.spacer}></Text>
                    <View style={StyleTable.table}>
                        {data.airQualityMeasurementList.map((item, index) =>
                            <View key={"MeasurmentWater" + index} wrap={false}>
                                {index === 0 &&
                                    <View style={[StyleTable.tableRow, { borderBottom: "3px solid black", }]} fixed>
                                        <View style={{ flexGrow: 1, flexBasis: 0, borderRight: "1px solid black" }}>
                                            <Text style={StyleTable.tableCell}>{t("Nr")}</Text>
                                        </View>
                                        <View style={{ flexGrow: 1, flexBasis: 0, borderRight: "1px solid black" }}>
                                            <Text style={StyleTable.tableCell}>{t("MeasurementId")}</Text>
                                        </View>
                                        <View style={{ flexGrow: 1, flexBasis: 0, borderRight: "1px solid black" }}>
                                            <Text style={StyleTable.tableCell}>{t("Building")} / {t("Department")} / {t("Machine")}</Text>
                                        </View>
                                        <View style={{ flexGrow: 4.75, flexBasis: 0, display: "flex", flexDirection: "column", alignItems: "stretch" }}>
                                            <View style={{ flexGrow: 1, flexDirection: "row", alignItems: "stretch" }}>
                                                <View style={StyleTable.tableColRowZero}>
                                                    <Text style={[StyleTable.tableCell, { textAlign: "center" }]}>{t("Water")}</Text>
                                                </View>
                                            </View>
                                            <View style={{ flexGrow: 1, flexDirection: "row", alignItems: "stretch" }}>
                                                <View style={StyleTable.colNoBottomBorder}>
                                                    <Text style={StyleTable.tableCell}></Text>
                                                </View>
                                                <View style={StyleTable.colNoBottomBorder}>
                                                    <Text style={StyleTable.tableCell}>{t("Required")}</Text>
                                                </View>
                                                <View style={StyleTable.colNoBottomBorder}>
                                                    <Text style={StyleTable.tableCell}>{t("Expected")}</Text>
                                                </View>
                                                <View style={StyleTable.colNoBottomBorder}>
                                                    <Text style={StyleTable.tableCell}>{t("Measured")}</Text>
                                                </View>
                                                <View style={StyleTable.colNoBottomBorder}>
                                                    <Text style={StyleTable.tableCell}>{t("Result")}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                }
                                <View style={[StyleTable.tableRow, { borderBottom: index == data.airQualityMeasurementList.length - 1 ? "3px solid black" : "1px solid black" }]}>
                                    <View style={{ flexGrow: 1, flexBasis: 0, borderRight: "1px solid black" }}>
                                        <Text style={StyleTable.tableCell}>{index + 1}</Text>
                                    </View>
                                    <View style={{ flexGrow: 1, flexBasis: 0, borderRight: "1px solid black" }}>
                                        <Text style={StyleTable.tableCell}>{item.measurementId}</Text>
                                    </View>
                                    <View style={{ flexGrow: 1, flexBasis: 0, borderRight: "1px solid black" }}>
                                        <Text style={StyleTable.tableCell}>{SplitText(`${item.building} / ${item.department} / ${item.machine}`, 10)}</Text>
                                    </View>
                                    <View style={{ flexGrow: 4.75, flexBasis: 0, display: "flex", flexDirection: "column", alignItems: "stretch" }}>
                                        <View style={{ flexGrow: 1, flexDirection: "row", alignItems: "stretch" }}>
                                            <View style={StyleTable.tableCol}>
                                                <Text style={StyleTable.tableCell}>{t("Content")}</Text>
                                            </View>
                                            <View style={StyleTable.tableCol}>
                                                <Text style={StyleTable.tableCell}>{Fmt.replaceUnitString(item.gasRequiredPG, t)}</Text>
                                            </View>
                                            <View style={StyleTable.tableCol}>
                                                <Text style={StyleTable.tableCell}>{Fmt.replaceUnitString(item.gasExpectedPG, t)}</Text>
                                            </View>
                                            <View style={StyleTable.tableCol}>
                                                <Text style={StyleTable.tableCell}>{item.gasMeasuredPG}</Text>
                                            </View>
                                            <View style={StyleTable.tableCol}>
                                                <Text style={StyleTable.tableCell}></Text>
                                            </View>
                                        </View>
                                        <View style={{ flexGrow: 1, flexDirection: "row", alignItems: "stretch" }}>
                                            <View style={StyleTable.colNoBottomBorder}>
                                                <Text style={StyleTable.tableCell}>{t("Class")}</Text>
                                            </View>
                                            <View style={StyleTable.colNoBottomBorder}>
                                                <Text style={StyleTable.tableCell}>{AirQualityMeasurementGasClass.find(x => x.id == item.gasRequiredClassId)?.idValue}</Text>
                                            </View>
                                            <View style={StyleTable.colNoBottomBorder}>
                                                <Text style={StyleTable.tableCell}>{AirQualityMeasurementGasClass.find(x => x.id == item.gasExpectedClassId)?.idValue}</Text>
                                            </View>
                                            <View style={StyleTable.colNoBottomBorder}>
                                                <Text style={StyleTable.tableCell}>{SplitText(item.gasMeasuredClass, 9)}</Text>
                                            </View>
                                            <View style={StyleTable.colNoBottomBorder}>
                                                <Text style={StyleTable.tableCell}>{SplitText(item.gasResultClass, 9)}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        )}
                    </View>
                    {/**-------------------------------------------------------------------------------------------- */}
                    <Text style={PdfStyles.spacer}></Text>
                    <View style={StyleTable.table}>
                        <View style={[StyleTable.tableRow, { borderBottom: "3px solid black", }]} wrap={false} fixed>
                            <View style={{ flexGrow: 1, flexBasis: 0, borderRight: "1px solid black" }}>
                                <Text style={StyleTable.tableCell}>{t("Nr")}</Text>
                            </View>
                            <View style={{ flexGrow: 1, flexBasis: 0, borderRight: "1px solid black" }}>
                                <Text style={StyleTable.tableCell}>{t("MeasurementId")}</Text>
                            </View>
                            <View style={{ flexGrow: 1, flexBasis: 0, borderRight: "1px solid black" }}>
                                <Text style={StyleTable.tableCell}>{t("Building")} / {t("Department")} / {t("Machine")}</Text>
                            </View>
                            <View style={{ flexGrow: 6, flexBasis: 0, display: "flex", flexDirection: "column", alignItems: "stretch" }}>
                                <View style={{ flexGrow: 1, flexDirection: "row", alignItems: "stretch" }}>
                                    <View style={StyleTable.tableColRowZero}>
                                        <Text style={[StyleTable.tableCell, { textAlign: "center" }]}>{t("Oil")}</Text>
                                    </View>
                                </View>
                                <View style={{ flexGrow: 1, flexDirection: "row", alignItems: "stretch" }}>
                                    <View style={StyleTable.colNoBottomBorder}>
                                        <Text style={StyleTable.tableCell}></Text>
                                    </View>
                                    <View style={StyleTable.colNoBottomBorder}>
                                        <Text style={StyleTable.tableCell}>{t("Required")}</Text>
                                    </View>
                                    <View style={StyleTable.colNoBottomBorder}>
                                        <Text style={StyleTable.tableCell}>{t("Expected")}</Text>
                                    </View>
                                    <View style={StyleTable.colNoBottomBorder}>
                                        <Text style={StyleTable.tableCell}>{t("Measured")}</Text>
                                    </View>
                                    <View style={StyleTable.colNoBottomBorder}>
                                        <Text style={StyleTable.tableCell}>{t("Result")}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                        {data.airQualityMeasurementList.map((item, index) =>
                            <View style={[StyleTable.tableRow, { borderBottom: index == data.airQualityMeasurementList.length - 1 ? "3px solid black" : "1px solid black" }]} key={"MeasurmentOil" + index} wrap={false}>
                                <View style={{ flexGrow: 1, flexBasis: 0, borderRight: "1px solid black" }}>
                                    <Text style={StyleTable.tableCell}>{index + 1}</Text>
                                </View>
                                <View style={{ flexGrow: 1, flexBasis: 0, borderRight: "1px solid black" }}>
                                    <Text style={StyleTable.tableCell}>{item.measurementId}</Text>
                                </View>
                                <View style={{ flexGrow: 1, flexBasis: 0, borderRight: "1px solid black" }}>
                                    <Text style={StyleTable.tableCell}>{SplitText(`${item.building} / ${item.department} / ${item.machine}`, 9)}</Text>
                                </View>
                                <View style={{ flexGrow: 6, flexBasis: 0, display: "flex", flexDirection: "column" }}>
                                    <View style={{ flexGrow: 1, flexDirection: "row", borderBottom: "1px solid black" }}>
                                        <View style={StyleTable.colNoBottomBorder}>
                                            <Text style={StyleTable.tableCell}>{t("Content")}</Text>
                                        </View>
                                        <View style={StyleTable.colNoBottomBorder}>
                                            <Text style={StyleTable.tableCell}>{Fmt.replaceUnitString(item.oilRequiredPG, t)}</Text>
                                        </View>
                                        <View style={StyleTable.colNoBottomBorder}>
                                            <Text style={StyleTable.tableCell}>{Fmt.replaceUnitString(item.oilExpectedPG, t)}</Text>
                                        </View>
                                        <View style={StyleTable.colNoBottomBorder}>
                                            <Text style={StyleTable.tableCell}>{item.oilMeasuredPG} {Fmt.getUnit("densityInQubicMeter", t)}</Text>
                                        </View>
                                        <View style={StyleTable.colNoBottomBorder}>
                                            <Text style={StyleTable.tableCell}></Text>
                                        </View>
                                    </View>
                                    <View style={{ flexGrow: 1, flexDirection: "row" }}>
                                        <View style={StyleTable.colNoBottomBorder}>
                                            <Text style={StyleTable.tableCell}>{t("Class")}</Text>
                                        </View>
                                        <View style={StyleTable.colNoBottomBorder}>
                                            <Text style={StyleTable.tableCell}>{AirQualityMeasurementOilClass.find(x => x.id == item.oilRequiredClassId)?.idValue}</Text>
                                        </View>
                                        <View style={StyleTable.colNoBottomBorder}>
                                            <Text style={StyleTable.tableCell}>{AirQualityMeasurementOilClass.find(x => x.id == item.oilExpectedClassId)?.idValue}</Text>
                                        </View>
                                        <View style={StyleTable.colNoBottomBorder}>
                                            <Text style={StyleTable.tableCell}>{SplitText(item.oilMeasuredClass, 9)}</Text>
                                        </View>
                                        <View style={StyleTable.colNoBottomBorder}>
                                            <Text style={StyleTable.tableCell}>{SplitText(item.oilResultClass, 9)}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        )}
                    </View>
                </View>
            }
            <View>
                <Text style={PdfStyles.spacer}></Text>
                <Image src={'/pictures/AQMeasurementExplanationTable.png'} />
            </View>
        </Page >
    )
}