import React, { useState } from 'react';
import { IconButton, Table, Grid, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Delete, AddCircleOutline, Edit } from '@mui/icons-material';
import { UserRoleModel } from './UserRoleModel';
import { TextFieldComponent, SimpleCheckbox, ModalComponent } from '../../../components/Reusable/';
import _ from 'lodash';
import { ExecutorStore, ProjectStore } from '../../../store';
import { useTranslation } from 'react-i18next';
import { OpenGlobalSnackbar } from '../../../helper/GlobalVariables';
import { isAfter } from "date-fns";
import { september16 } from '../../../helper/DateHelper';

function Row(props) {
    const { t } = useTranslation();
    const { row, index, updateRow, denyEdit } = props;

    const updateModel = (property, value) => {
        row[property].value = value;
        if (row[property].validators.length > 0) {
            row[property].isValid = true;
            row[property].validators.forEach(f => {
                if (f(row[property].value) == false) {
                    row[property].isValid = false;
                }
            });
        }
        updateRow(row, index);
    }

    const changeRowMode = (property, value) => {
        let valid = false;
        Object.keys(row).forEach(key => row[key].validators.forEach(v => {
            if (v(row[key].value) === false) {
                valid = false;
                row[key].isValid = false;
            }
        }));
        if (!valid && !value) {
            row.isExpanded.value = value;
            updateRow(row, index);
        } else {
            updateModel(property, value);
        }
    }

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>
                    <SimpleCheckbox
                        disabled={denyEdit}
                        id={'cb_markUserRole_' + index}
                        onChange={e => updateModel("isChecked", e.target.checked)}
                    />
                </TableCell>
                <TableCell>
                    {index + 1}
                </TableCell>
                <TableCell id={'td_userRole_' + index} style={{ overflowWrap: 'anywhere' }}>
                    {
                        row.isExpanded.value ?
                            <TextFieldComponent
                                id={'userEmail_' + index}
                                t={t}
                                model={row.email}
                                onChange={e => updateModel("email", e.target.value)}
                            />
                            :
                            row.email.value
                    }
                </TableCell>
                {(ExecutorStore.executorSettings.value.festoExecutor === true || isAfter(new Date(ProjectStore.ProjectInfo.value.projectInfo.createdDate), september16)) &&
                    <TableCell>
                        <SimpleCheckbox
                            defaultChecked={row.isEditor.value}
                            disabled={denyEdit}
                            id={'cb_markIsEditor_' + index}
                            onChange={e => updateModel("isEditor", e.target.checked)}
                        />
                    </TableCell>
                }
                <TableCell>
                    {
                        !denyEdit &&
                        <IconButton
                            id={'btn_userRoleExpander_' + index}
                            onClick={() => changeRowMode("isExpanded", !row.isExpanded.value)}
                            size="large"
                        >
                            <Edit fontSize="medium" />
                        </IconButton>
                    }
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function UserRoleTable(props) {
    const { t } = useTranslation();
    const { data, updateData, denyEdit, deleteData, isUserListValid } = props;
    const [modalModel, setModalModel] = useState({
        open: false,
        message: ""
    });

    function createNewRow() {
        return _.cloneDeep(UserRoleModel(t));
    }

    const deleteRow = () => {
        const filtered = data.filter(r => r.isChecked.value == false);
        const deleted = data.filter(r => r.isChecked.value == true && r.id.value > 0);
        deleteData(deleted);
        updateData(filtered);
    }

    const addRow = () => {
        if (isUserListValid()) {
            var newRow = createNewRow();
            newRow.id.value = 0;
            newRow.isExpanded.value = true;
            data.push(newRow);
            updateData(data);
        }
    }

    const updateRow = (row, index) => {
        data[index] = row;
        updateData(data);
    }

    const clickDelete = () => {
        let checkedArray = data.filter(r => r.isChecked.value == true);
        if (checkedArray == undefined || checkedArray.length == 0)
            OpenGlobalSnackbar("danger", t('NoEntriesCheckedError'));
        else
            clickDeleteOpenModal();
    }

    const clickDeleteOpenModal = () => {
        modalModel.open = true;
        modalModel.okText = t('Yes');
        modalModel.cancelText = t('No');
        modalModel.message = t('DeleteSelectedRowsQuestion');
        modalModel.clickOk = deleteRow;
        setModalModel({ ...modalModel });
    }

    return (
        <React.Fragment>
            <ModalComponent model={modalModel} />
            {
                !denyEdit &&
                <Grid container direction="row" justifyContent="flex-end" >
                    <IconButton id="btn_deleteUserRole" onClick={clickDelete} size="large">
                        <Delete fontSize="medium" />
                    </IconButton>
                    <IconButton id="btn_addUserRole" onClick={() => addRow()} size="large">
                        <AddCircleOutline fontSize="medium" />
                    </IconButton>
                </Grid>
            }
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: 50 }}></TableCell>
                            <TableCell style={{ width: 50 }}>{t('Nr')}</TableCell>
                            <TableCell>{t("Email")}</TableCell>
                            {(ExecutorStore.executorSettings.value.festoExecutor === true || isAfter(new Date(ProjectStore.ProjectInfo.value.projectInfo.createdDate), september16)) &&
                                <TableCell>{t("WriteAccess")}</TableCell>
                            }
                            <TableCell style={{ width: 50 }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => (
                            <Row key={row.id} denyEdit={denyEdit} row={row} index={index} updateRow={updateRow} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
}