import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { usePdfStyles } from './PdfStyles';
import { FlexTable } from '../../../PdfReport/PdfContent/PdfStyles';
import * as Fmt from '../../../../helper/Formatters';

export default function VolumeCostsTable(props) {
    const { itemCalc, country } = props;
    const { t } = useTranslation();
    var PdfStyles = usePdfStyles();

    return (
        <View style={[FlexTable.column, { borderLeft: "1px solid black", borderTop: "1px solid black" }]}>
            <View style={[FlexTable.row, { borderBottom: "1px solid black" }]}>
                <View style={[FlexTable.rowItem, { flexGrow: 1, flexBasis: 0, borderRight: "1px solid black" }]}>
                    <Text style={[FlexTable.rowItemText, PdfStyles.bold, { textAlign: "center" }]}>{t('LeakageFlowConverted') + Fmt.getUnitInBracket("volumeFlowRate", t)}</Text>
                </View>
                <View style={[FlexTable.rowItem, { flexGrow: 1, flexBasis: 0, borderRight: "1px solid black" }]}>
                    <Text style={[FlexTable.rowItemText, PdfStyles.bold, { textAlign: "center" }]}>{t("VolumePerYear") + Fmt.getUnitInBracket("cubicMeterOfGas", t)}</Text>
                </View>
                <View style={[FlexTable.rowItem, { flexGrow: 1, flexBasis: 0, borderRight: "1px solid black" }]}>
                    <Text style={[FlexTable.rowItemText, PdfStyles.bold, { textAlign: "center" }]}>{t("CostPerYear") + Fmt.getCurrency()}</Text>
                </View>
            </View>
            <View style={[FlexTable.row, { borderBottom: "1px solid black" }]}>
                <View style={FlexTable.rowItem}>
                    <Text style={FlexTable.rowItemText}>{itemCalc?.leakageFlowConverted ? Fmt.round(itemCalc.leakageFlowConverted, country, 2) : ""}</Text>
                </View>
                <View style={FlexTable.rowItem}>
                    <Text style={FlexTable.rowItemText}>{Fmt.roundNumber(itemCalc.volumePerYear, 2)}</Text>
                </View>
                <View style={FlexTable.rowItem}>
                    <Text style={FlexTable.rowItemText}>{Fmt.roundNumber(itemCalc.costPerYear, 0)}</Text>
                </View>
            </View>
        </View>
    )
}
