import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TableCell, TableRow, TableHead, TableBody, Table, TableContainer, Paper, TablePagination } from '@mui/material';
import { API } from '../../../../helper/ApiHelper';
import { ButtonComponent, TextFieldComponent, ModalComponent } from '../../../Reusable';
import { OpenGlobalSnackbar } from '../../../../helper/GlobalVariables';
// @ts-expect-error not converted yet
import { checkIsNotNullOrWhitespace } from "../../../../helper/Validator";
import * as DateHelper from '../../../../helper/DateHelper';
import LicenseRequestReport from './LicenseRequestReport';
import { IDropdownItem } from '../../../Types/Types';

export interface InternalLicenseTableEntry {
    id: number;
    createdDate: string;
    createdBy: string;
    executorRequestorName: string;
    executorReceiverName?: string | null;
    license: License;
    quantity: string;
    customerPurchaseOrderNumber: string;
    approvedBy: string;
    licenseRequestStatus: IDropdownItem;
    isValid: boolean;
}

interface License {
    id: number;
    name: string;
    partNumber?: string | null;
}

export default function LicenseRequests() {
    const apiUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "licenses/internal-license-request"

    const { t } = useTranslation();

    const [mainModel, setMainModel] = useState<InternalLicenseTableEntry[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [modalModel, setModalModel] = useState<{
        clickOk: () => Promise<void>,
        open: boolean,
        message: string,
        cancelText: string,
        okText: string,
      }>({
        clickOk: () => Promise.resolve(),
        open: false,
        message: t("AreYouSure"),
        cancelText: t("No"),
        okText: t("Yes"),
      });

    useEffect(() => {
        loadExistingData();
    }, []);

    const loadExistingData = async () => {
        const data = await API.get(apiUrl);
        setMainModel(data);
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const confirmApprove = (id: number, approve: boolean) => {
        setModalModel({
          ...modalModel,
          open: true,
          clickOk: () => sendApprove(id, approve),
        });
      };

    const sendApprove = async (id: number, approve: boolean) => {
        const request = mainModel.find(x => x.id === id);
        const postObject = {
            id: id,
            customerPurchaseOrderNumber: request?.customerPurchaseOrderNumber,
            approve: approve
        }
        if (approve === true && !checkIsNotNullOrWhitespace(request?.customerPurchaseOrderNumber)) {
            OpenGlobalSnackbar("danger", t("PleaseVerifyYourEntriesError"));
            updateOrderNr(id, "");
        }
        else {
            await API.post(apiUrl, postObject);
            OpenGlobalSnackbar("success", t("SaveSuccessful"));
            loadExistingData();
        }
    }

    const updateOrderNr = (id: number, value: string) => {
        const request = mainModel.find(x => x.id === id);
        if (request != undefined) {
            request.customerPurchaseOrderNumber = value;
            request.isValid = checkIsNotNullOrWhitespace(value);
            setMainModel([...mainModel]);
        }
    }

    return (
        <div className="license-requests">
            <LicenseRequestReport licenseRequests={mainModel} />
            <ModalComponent model={modalModel} />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("OrderNr")}</TableCell>
                            <TableCell>{t("Date")}</TableCell>
                            <TableCell>{t("ExecutorRequestorName")}</TableCell>
                            <TableCell>{t("ExecutorReceiverName")}</TableCell>
                            <TableCell>{t("OrderedBy")}</TableCell>
                            <TableCell>{t("License")}</TableCell>
                            <TableCell>{t("Quantity")}</TableCell>
                            <TableCell>{t("CustomerPurchaseOrderNumber")}</TableCell>
                            <TableCell>{t("Status")}</TableCell>
                            <TableCell>{t("ApprovedBy")}</TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {mainModel.length > 0 &&
                            mainModel.slice(page * rowsPerPage, rowsPerPage + page * rowsPerPage)
                                .map((item, index) => (
                                    <TableRow key={index + 1}>
                                        <TableCell id={"id" + index}>
                                            {item.id}
                                        </TableCell>
                                        <TableCell id={"createdDate" + index}>
                                            {DateHelper.FormatDate(item.createdDate)}
                                        </TableCell>
                                        <TableCell id={"executorRequestorName" + index}>
                                            {item.executorRequestorName}
                                        </TableCell>
                                        <TableCell id={"executorReceiverName" + index}>
                                            {item.executorReceiverName}
                                        </TableCell>
                                        <TableCell id={"createdBy" + index}>
                                            {item.createdBy}
                                        </TableCell>
                                        <TableCell id={"license" + index}>
                                            {item.license.name}
                                        </TableCell>
                                        <TableCell id={"quantity" + index}>
                                            {item.quantity}
                                        </TableCell>
                                        <TableCell id={"customerPurchaseOrderNumber" + index}>
                                            <TextFieldComponent
                                                t={t}
                                                model={{
                                                    value: item.customerPurchaseOrderNumber,
                                                    isValid: item.isValid,
                                                    idkey: "",
                                                    label: "",
                                                    validationError: "",
                                                    validators: []
                                                }}
                                                onChange={e => updateOrderNr(item.id, e.target.value)}
                                                disabled={[2, 3].includes(item.licenseRequestStatus?.id)}
                                            />
                                        </TableCell>
                                        <TableCell id={"licenseRequestStatus" + index}>
                                            {item.licenseRequestStatus?.value}
                                        </TableCell>
                                        <TableCell id={"approvedBy" + index}>
                                            {item.approvedBy}
                                        </TableCell>
                                        <TableCell width="1%">
                                            {![2, 3].includes(item.licenseRequestStatus?.id) &&
                                                <ButtonComponent customStyle={{ backgroundColor: "green" }} id="license-Approve" value="Approve" color="primary" onChange={() => confirmApprove(item.id, true)} />
                                            }
                                        </TableCell>
                                        <TableCell width="1%">
                                            {![2, 3].includes(item.licenseRequestStatus?.id) &&
                                                <ButtonComponent customStyle={{ backgroundColor: "red" }} id="license-Approve" value="Disapprove" color="primary" onChange={() => confirmApprove(item.id, false)} />
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20, 25]}
                component="div"
                count={mainModel && mainModel.length > 0 ? mainModel.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
}
