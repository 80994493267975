import { AuthenticationContext, withAdalLogin } from "react-adal";

export const adalConfig = {
    instance: process.env.REACT_APP_INSTANCE_ADAL,
    tenant: "adfs",
    clientId: process.env.REACT_APP_CLIENT_ID as string,
    endpoints: {
        "adfs": "https://login.festo.com",
    },
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    popUp: true,
};

export const authContext = new AuthenticationContext(adalConfig);
  
export const withAdalLoginApi = withAdalLogin(
    authContext,
    adalConfig.endpoints.adfs
);

export const logOut = (logOutUrl : string) => {
    const idToken = sessionStorage.getItem('adal.idtoken');
    Object.entries(sessionStorage)
        .map(x => x[0])
        .filter(x => x.substring(0, 4) == "adal")
        .map(x => sessionStorage.removeItem(x));
    window.location.href = process.env.REACT_APP_INSTANCE_ADAL + "adfs/oauth2/logout?id_token_hint=" + idToken + "&post_logout_redirect_uri=" + logOutUrl;
}