import React from "react"
import { Button, ButtonProps, styled } from '@mui/material';

interface IButtonComponent extends ButtonProps {
    id?: string,
    value: string,
    icon?: React.ReactNode,
    customStyle?: { backgroundColor?: string, margin?: number },
}

const labelColors = {
    primary: '#FFFFFF', secondary: '#333333',
    inherit: '#FFFFFF', success: '#FFFFFF', error: '#FFFFFF', info: '#FFFFFF', warning: '#FFFFFF'
}

const StyledButton = styled(Button)(({ margin, ...props }: ButtonProps & { margin?: number }) => ({
    margin: margin !== undefined ? margin : 10,
    minWidth: '136px',
    '& >span': {
        font: "Normal 1rem/1.5rem Meta Pro",
        letterSpacing: 0,
        textTransform: 'none',
        color: labelColors[props.color !== undefined ? props.color : "primary"]
    },
    '& .MuiSvgIcon-root': {
        marginRight: '0.3rem',
        fontSize: '1.3rem',
        color: 'rgba(0, 0, 0, 0.87)'
    }
})) as typeof Button;

const ButtonComponent = (props: IButtonComponent) => {
    const { value, onChange, icon, id, customStyle, ...rest } = props;
    return (
        <StyledButton
            {...rest}
            style={{ backgroundColor: props.color == "primary" ? "#0091DC" : "#D8DCE1", ...customStyle }}
            className={(props.className != undefined ? ` ${props.className}` : ``)}
            id={id !== undefined ? "btn_" + id : ""}
            variant="contained"
            onClick={onChange}
            disabled={props.disabled}
        >
            {icon}
            <span>{value}</span>
        </StyledButton>
    );
}

export default ButtonComponent;