import React, { useState, useEffect } from 'react';
import * as Fmt from '../../helper/Formatters';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { Grid, Button } from '@mui/material';
import { HeadingComponent } from '../Reusable';
import { API } from '../../helper/ApiHelper';
import { CountryParam, ILeakage, IProjectLDARResults, IdParam, LanguageParam } from '../Types/Types';
import { LeakageModelFactory } from '../LeakageDetectionAndRepair/Leakage/LeakageModel'
import { LeakageActions, LeakageInputDevices, LeakageRepairTimeEstimations, OpenGlobalSnackbar, ScrollerToTag } from '../../helper/GlobalVariables';
import { DataGrid, GridRowModel, GridColDef, GridRowsProp, useGridApiRef, GridRowId, GridValidRowModel, GridActionsCellItem, GridSlots, GridToolbarContainer } from '@mui/x-data-grid';
import { datesColDef, itemsColDef, inputColDef, valueColDef, yesNoColDef } from './MassEditHelper';
import { IUser, UserStore } from '../../store/UserStore';
import RestoreIcon from '@mui/icons-material/Restore';
import BackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuidv4 } from 'uuid';
import "./MassEdit.scss";
import _ from 'lodash';
import { nowUTC } from '../../helper/DateHelper';

type rowRecord = Record<GridRowId, GridValidRowModel>;

export default function MassEditLDAR(props: { denyEdit: boolean }) {
    const { t } = useTranslation();
    const ldUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "leakage-detection";
    const massUpsertLDARUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "mass-upsert-leakages";
    const { country, lng, id } = useParams<CountryParam & LanguageParam & IdParam>();
    const [hasUnsavedRows, setHasUnsavedRows] = React.useState(false);
    const defaultRefValue = { unsavedRows: {}, rowsBeforeChange: {} };
    const [data, setData] = useState<(ILeakage)[]>([]);
    const leakageModel = LeakageModelFactory(t);
    const unsavedChangesRef = React.useRef<{ unsavedRows: rowRecord, rowsBeforeChange: rowRecord }>(defaultRefValue);
    const [isSaving, setIsSaving] = React.useState(false);
    const [user, setUser] = useState<IUser>({});
    const apiRef = useGridApiRef();


    useEffect(() => {
        if (parseInt(id) > 0) {
            fetchData(true);
        }
        const userSub = UserStore.user.subscribe(user => setUser(user));
        return () => {
            userSub.unsubscribe();
        }
    }, []);

    const fetchData = (initialValue?: boolean) => {
        try {
            API.get<IProjectLDARResults>(ldUrl + '/' + id).then(resp => {
                if (resp.ldar.leakages !== null) {
                    const leakagesWithCalcs = resp.ldar.leakages.map((leakage: ILeakage, index: number) => ({ ...leakage, leakageFlowConverted: resp.ldarCalcResults.indLeakageCalcResults[index].leakageFlowConverted }));
                    setData(leakagesWithCalcs);
                }
                if (initialValue) ScrollerToTag();
            })
        } catch (error) {
            OpenGlobalSnackbar("danger", t('error'));
        }
    };

    const processRowUpdate = React.useCallback(async (newRow: GridRowModel, oldRow: GridRowModel) => {
        const rowId = newRow.gridId;
        unsavedChangesRef.current.unsavedRows[rowId] = newRow;
        if (!unsavedChangesRef.current.rowsBeforeChange[rowId] && !(oldRow.id === 0)) {
            unsavedChangesRef.current.rowsBeforeChange[rowId] = oldRow;
        }
        setHasUnsavedRows(true);
        return newRow;
    }, []);

    const columns = React.useMemo<GridColDef[]>(() => [
        {
            type: 'actions',
            field: 'Actions',
            headerClassName: 'datagrid-bold',
            headerName: t('Actions') ?? 'Actions',
            getActions: ({ id, row, columns }) => [
                <GridActionsCellItem
                    key={'RestoreGridCell'}
                    icon={<RestoreIcon />}
                    label="Discard changes"
                    disabled={unsavedChangesRef.current.unsavedRows[id] === undefined || unsavedChangesRef.current.unsavedRows[id]?.id === 0}
                    onClick={() => {
                        apiRef.current.updateRows([unsavedChangesRef.current.rowsBeforeChange[id]]);
                        delete unsavedChangesRef.current.rowsBeforeChange[id];
                        delete unsavedChangesRef.current.unsavedRows[id];
                        columns.forEach((item) => {
                            if (apiRef.current.getCellMode(id, item.field) === 'edit') {
                                apiRef.current.stopCellEditMode({ id: id, field: item.field, ignoreModifications: true });
                            }
                        });
                        setHasUnsavedRows(Object.keys(unsavedChangesRef.current.unsavedRows).length > 0);
                    }}
                />,
                <GridActionsCellItem
                    key={'DeleteGridCell'}
                    icon={<DeleteIcon />}
                    label={t('Delete')}
                    onClick={() => {
                        if (unsavedChangesRef.current.unsavedRows[id]?.id === 0) {
                            unsavedChangesRef.current.unsavedRows[id] = { ...row, _action: 'delete' };
                            apiRef.current.updateRows([unsavedChangesRef.current.unsavedRows[id]]);
                        }
                        else {
                            unsavedChangesRef.current.unsavedRows[id] = { ...row, _action: 'delete' };
                            if (!unsavedChangesRef.current.rowsBeforeChange[id]) { unsavedChangesRef.current.rowsBeforeChange[id] = row; }
                            setHasUnsavedRows(true);
                            apiRef.current.updateRows([row]);
                        }
                    }}
                />,
            ],
        },
        valueColDef(leakageModel, t, "leackageNumber", { minWidth: 20 }),
        valueColDef(leakageModel, t, "measurementId"),
        datesColDef(leakageModel, t, "detectedDate"),
        inputColDef(leakageModel, t, "detectedBy"),

        inputColDef(leakageModel, t, "building"),
        inputColDef(leakageModel, t, "department"),
        inputColDef(leakageModel, t, "machine"),

        itemsColDef(leakageModel, t, "leakageInputDevice", LeakageInputDevices),
        inputColDef(leakageModel, t, "leakageInputValue"),
        valueColDef(leakageModel, t, "leakageFlowConverted", { valueFormatter: (value: number) => Fmt.round(value, country, 2) }),
        itemsColDef(leakageModel, t, "leakageAction", LeakageActions),
        itemsColDef(leakageModel, t, "leakageRepairTimeEstimated", LeakageRepairTimeEstimations),
        yesNoColDef(leakageModel, t, "leakageOptimization"),
        yesNoColDef(leakageModel, t, "leakageRepearableProdTime"),

        yesNoColDef(leakageModel, t, "leakageRepaired"),
        inputColDef(leakageModel, t, "repairedBy"),
        datesColDef(leakageModel, t, "repairDate"),
        inputColDef(leakageModel, t, "leakageRepairTimeRepairedInput"),
        inputColDef(leakageModel, t, "leakageComment")
    ], [unsavedChangesRef, apiRef]);

    const rows: GridRowsProp<ILeakage> = React.useMemo(() => data.filter(x => x.id > 0).map((item) => {
        return ({ gridId: uuidv4(), ...item });
    }), [data]);

    const addLeakage = () => {
        setHasUnsavedRows(true);
        const allRows = apiRef.current.getSortedRows();
        const leackageNumber = Math.max(...allRows.map(x => x.leackageNumber)) + 1;
        const row = {
            id: 0,
            gridId: uuidv4(),
            leackageNumber: leackageNumber,
            detectedDate: nowUTC(),
            detectedBy: `${user.firstName} ${user.lastName}`,
            measurementId: null,
            leakageRepaired: false,
            repairedBy: "",
            repairComment: "",
            repairDate: null,
            leakageRepairTimeRepairedInput: null,
            building: "",
            department: "",
            machine: "",
            leakageInputDevice: LeakageInputDevices[0],
            leakageInputValue: 0,
            leakageFlowConverted: 0,
            leakageAction: LeakageActions[0],
            leakageClasification: "",
            leakageRepairTimeEstimated: LeakageRepairTimeEstimations[0],
            leakageRepearableProdTime: false,
            leakageOptimization: false,
            leakageComment: ""
        }
        unsavedChangesRef.current.unsavedRows[row.gridId] = row;
        apiRef.current.updateRows([row]);
    };
    const saveChanges = async () => {
        try {
            setIsSaving(true);
            await API.put<IProjectLDARResults>(massUpsertLDARUrl + '/' + id, {
                ToAdd: Object.values(unsavedChangesRef.current.unsavedRows).filter((row) => row.id === 0),
                ToDelete: Object.values(unsavedChangesRef.current.unsavedRows).filter((row) => row._action === 'delete'),
                ToUpdate: Object.values(unsavedChangesRef.current.unsavedRows).filter((row) => row.id !== 0 && row._action !== 'delete')
            }).then(resp => {
                setData(resp.ldar.leakages);
                OpenGlobalSnackbar("success", t('SaveSuccesfull'))
            })
            setIsSaving(false);
            unsavedChangesRef.current = defaultRefValue;
            setHasUnsavedRows(false);
        }
        catch (error) {
            setIsSaving(false);
        }
    };
    const discardChanges = () => {
        apiRef.current.getAllRowIds().forEach((id) => {
            columns.forEach((item) => {
                if (apiRef.current.getCellMode(id, item.field) === 'edit') {
                    apiRef.current.stopCellEditMode({ id: id, field: item.field, ignoreModifications: true });
                }
            });
        });
        [unsavedChangesRef.current.unsavedRows].forEach((item) => {
            Object.values(item).forEach((row) => {
                if (row.id === 0) {
                    row._action = 'delete';
                    apiRef.current.updateRows([row]);
                }
            });
        });
        [unsavedChangesRef.current.rowsBeforeChange].forEach((item) => {
            Object.values(item).forEach((row) => {
                if (!_.isEmpty(row)) {
                    apiRef.current.updateRows([row]);
                }
            });
        });
        unsavedChangesRef.current = defaultRefValue;
        setHasUnsavedRows(false);
    };

    const goBack = () => {
        window.location.href = `/${country}/${lng}/project/${id}/LDAR`;
    }

    function EditToolbar() {
        return (
            <GridToolbarContainer>
                <Grid container alignContent={"space-between"}>
                    <Grid item xs={11}>
                        <Button disabled={isSaving} onClick={addLeakage} startIcon={<AddIcon />}>{t('AddLeakage')}</Button>
                        <LoadingButton disabled={!hasUnsavedRows} onClick={saveChanges} startIcon={<SaveIcon />} loading={isSaving} loadingPosition="start">{t('Save')}</LoadingButton>
                        <Button disabled={!hasUnsavedRows || isSaving} onClick={discardChanges} startIcon={<RestoreIcon />}>{t('DiscardAllChanges')}</Button>
                    </Grid>
                    <Grid item xs={1} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button onClick={goBack} startIcon={<BackIcon />}>{t('Back')}</Button>
                    </Grid>
                </Grid>
            </GridToolbarContainer>
        );
    }

    return (
        <div style={{ width: '100%', height: 600 }}>
            <div style={{ height: 500 }}>
                <Grid item xs={12} id="#LeakageDetection">
                    <HeadingComponent value={t('ListOfLeakages')} size="h6" />
                </Grid>
                <DataGrid
                    rows={rows}
                    apiRef={apiRef}
                    columns={columns}
                    loading={isSaving}
                    processRowUpdate={processRowUpdate}
                    getRowId={(row: GridValidRowModel) => row?.gridId}
                    isCellEditable={() => !props.denyEdit}
                    slots={{ toolbar: EditToolbar as GridSlots['toolbar'] }}
                    autosizeOptions={{ includeHeaders: true }}
                    autosizeOnMount={true}
                    density='compact'
                    sx={{
                        '& .MuiDataGrid-row.row--added': { backgroundColor: 'rgba(207, 255, 171, 0.3)' },
                        '& .MuiDataGrid-row.row--edited': { backgroundColor: 'rgba(255, 254, 176, 0.3)' },
                        '& .MuiDataGrid-row.row--removed': { backgroundColor: 'rgba(255, 170, 170, 0.3)' },
                    }}

                    getRowClassName={({ id }) => {
                        const unsavedRow = unsavedChangesRef.current.unsavedRows[id];
                        if (unsavedRow) {
                            if (unsavedRow._action === 'delete') {
                                return 'row--removed';
                            }
                            if (unsavedRow.id === 0) {
                                return 'row--added';
                            }
                            return 'row--edited';
                        }
                        return '';
                    }}
                />
            </div>
        </div>
    );
}