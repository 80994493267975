import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { API } from '../../../../helper/ApiHelper';
import { ButtonComponent, HeadingComponent } from '../../../Reusable';
import { OpenGlobalSnackbar } from '../../../../helper/GlobalVariables';

export default function TranslationsSettings() {
    const postExcelToJsonUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "translation-management";
    const resetCountriesUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "translation-management/reset-countries";
    const { t } = useTranslation();
    const [file, setFile] = useState<File | null>(null);
    const [fileName, setFileName] = useState<string | null>(null);

    const uploadExcelFile = () => {
        if (file == undefined) {
            OpenGlobalSnackbar("danger", t('NoFileChosen'));
            return;
        }
        const formData = new FormData();
        formData.append("xlsx", file);
        API.post(`${postExcelToJsonUrl}/excel-to-json`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(() => OpenGlobalSnackbar("success", t("SaveSuccessful")));
        setFileName(null);
        setFile(null);
    }

    const selectFile = (event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        let file: File | null = null;
        if ('target' in event) {
            const fileInput = event.target as HTMLInputElement;
            if (fileInput.files === null || fileInput.files.length < 1) return;
            file = fileInput.files[0];
        }
        else {
            const fileInput = document.getElementById('choose-file-button') as HTMLInputElement;
            if (fileInput.files === null || fileInput.files.length < 1) return;
            file = fileInput.files[0];
        }

        if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            setFile(file);
            setFileName(file.name);
        } else {
            OpenGlobalSnackbar('danger', t('OnlyXlsxFilesAllowed'));
            setFileName(null);
            setFile(null);
        }
    };

    const resetCountries = () => {
        API.get(resetCountriesUrl);
    }

    return (
        <Grid container>
            <Grid item md={12}>
                <HeadingComponent value={t('UploadTranslationsExcelFile')} size="h6" />
            </Grid>
            <Grid item md={12}>
                <Grid container>
                    <input style={{ display: "none" }} type="file" name="choose-file-button" id="choose-file-button" onChange={e => selectFile(e)} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                    <label htmlFor="choose-file-button">
                        <Button
                            variant="contained"
                            component="span"
                            onClick={e => selectFile(e)}
                            id="tranlations_choosefile"
                        >
                            {t('ChooseFile')}
                        </Button>
                    </label>
                    <div style={{ paddingLeft: "15px" }}>{fileName || t('NoFileChosen')}</div>
                    <Grid container style={{ marginTop: "30px" }}>
                        <Grid item>
                            <ButtonComponent value={t('Upload')} color="primary" onChange={() => uploadExcelFile()} id="tranlations_upload"></ButtonComponent>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={12}>
                <HeadingComponent value={t('ResetCountries')} size="h6" />
            </Grid>
            <Grid item md={12}>
                <ButtonComponent value={t('ResetCountries')} color="primary" onChange={resetCountries} id="reset_countries"></ButtonComponent>
            </Grid>
        </Grid>
    );
}
