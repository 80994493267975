import { IDropdownItem, IDropdownValueId, KeyValue, ILeakageRepairTimeEstimated } from '../components/Types/Types';
import { API } from './ApiHelper';

const inqUrl = process.env.REACT_APP_DEVFESSINQUIRYSERVICES_BASE + "inquiry-management"

export let MeasurementUnits: MeasurementUnit[] = [];
export let DistributionLineLayouts: IDropdownItem[] = [];
export let IndividualDryerTypes: IDropdownItem[] = [];
export let CompressorTypes: IDropdownItem[] = [];
export let CompressorControlTypes: IDropdownItem[] = [];
export let LeakageInputDevices: IDropdownItem[] = [];
export let LeakageActions: IDropdownItem[] = [];
export let Languages: Language[] = [];
export let LicenseTypes: IDropdownItem[] = [];
export let MeasurementSystems: IDropdownItem[] = [];
export let MaintenanceFrequencySelects: IDropdownItem[] = [];
export let AlertTypes: KeyValue[] = [];
export let ReplacementPartComponents: IDropdownItem[] = [];
export let PrintSizes: IDropdownItem[] = [];
export let UserRoles: UserRole[] = [];
export let AirQualityMeasurementParticleClass: IDropdownValueId[] = [];
export let AirQualityMeasurementGasClass: IDropdownValueId[] = [];
export let AirQualityMeasurementOilClass: IDropdownValueId[] = [];
export let LeakageRepairTimeEstimations: ILeakageRepairTimeEstimated[] = [];
export let Salutations: IDropdownItem[] = [];
export let OpenGlobalSnackbar: ((title:string, message:string) => void);
export let ScrollerToTag: (() => void);

export interface DropdownListsDTO {
    replacementPartComponents: IDropdownItem[];
    compressorTypes: IDropdownItem[];
    compressorControlTypes: IDropdownItem[];
    individualDryerTypes: IDropdownItem[];
    leakageInputDevices: IDropdownItem[];
    leakageActions: IDropdownItem[];
    distributionLineLayouts: IDropdownItem[];
    languages: Language[];
    measurementSystems: IDropdownItem[];
    maintenanceFrequencySelects: IDropdownItem[];
    printSizes: IDropdownItem[];
    userRoles: UserRole[];
    measurementUnits: MeasurementUnit[];
    airQualityMeasurementParticleClass: IDropdownValueId[];
    airQualityMeasurementGasClass: IDropdownValueId[];
    airQualityMeasurementOilClass: IDropdownValueId[];
    salutation: IDropdownItem[];
    leakageRepairTimeEstimated: ILeakageRepairTimeEstimated[];
    licenseTypes: IDropdownItem[];
    alertTypes: KeyValue[];
}

export interface Language {
    id: number;
    value: string;
    countryCode: string;
}

export interface UserRole {
    id: number;
    value: string;
    assignedRoles: AssignedRole[];
}

export interface AssignedRole {
    id: number;
}

export interface MeasurementUnit {
    id: number;
    key: string;
    metric: string;
    imperial: string;
    fraction: number;
}

export const FestchDropDownLists = async () => {
    const response = await API.get<DropdownListsDTO>(`${inqUrl}/drop-downs`);
    DistributionLineLayouts = response.distributionLineLayouts ?? [];
    IndividualDryerTypes = response.individualDryerTypes ?? [];
    CompressorTypes = response.compressorTypes ?? [];
    CompressorControlTypes = response.compressorControlTypes ?? [];
    LeakageInputDevices = response.leakageInputDevices ?? [];
    LeakageActions = response.leakageActions ?? [];
    Languages = response.languages ?? [];
    LicenseTypes = response.licenseTypes ?? [];
    MeasurementSystems = response.measurementSystems ?? [];
    MaintenanceFrequencySelects = response.maintenanceFrequencySelects ?? [];
    AlertTypes = response.alertTypes ?? [];
    ReplacementPartComponents = response.replacementPartComponents ?? [];
    PrintSizes = response.printSizes;
    UserRoles = response.userRoles;
    MeasurementUnits = response.measurementUnits;
    AirQualityMeasurementParticleClass = response.airQualityMeasurementParticleClass;
    AirQualityMeasurementGasClass = response.airQualityMeasurementGasClass;
    AirQualityMeasurementOilClass = response.airQualityMeasurementOilClass;
    Salutations = response.salutation;
    LeakageRepairTimeEstimations = response.leakageRepairTimeEstimated;
    return true;
}

export const setOpenGlobalSnackbar = async (openSnack: () => void) => {
    OpenGlobalSnackbar = openSnack;
}

export const setGlobalScroller = async (scrollerMethod: () => void) => {
    ScrollerToTag = scrollerMethod;
}