import React from 'react';
import FormHelperText from '@mui/material/FormHelperText';

interface IHelperTextComponent {
    value: string,
    error: boolean | undefined,
}

const HelperTextComponent = (props: IHelperTextComponent) => {
    return (
        <FormHelperText error={props.error}>{props.value}</FormHelperText>
    )
}


export default HelperTextComponent;