import React from "react";
import { Select, FormControl, InputLabel, MenuItem, Tooltip, styled } from '@mui/material';
import { StyledTooltip } from '../MaterialUIStyles/Styles';

const StyledFormControl = styled(FormControl)((props) => ({
    '& .MuiInput-underline': {
        '&::before': {
            borderBottom: props.disabled ? '1px dotted rgba(0, 0, 0, 0.42)' : '1px solid rgba(0, 0, 0, 0.42)'
        }
    },
}));
const StyledSelect = styled(Select)((props) => ({
    pointerEvents: props.disabled && 'none',
    paddingTop: 4,
    '& > div': {
        paddingBottom: 1,
        transitionDuration: " 10s"
    },
    transitionDuration: " 10s"
}));
const StyledInputLabel = styled(InputLabel)((props) => ({
    color: props.disabled && 'rgba(0, 0, 0, 0.87)',
}));

const SingleSelect = (props) => {
    const { listFromDb, model, onChange, t, disabled, noFirstEmpty, ...rest } = props;


    let id = props.id ? props.id : model.idkey;
    return (
        <StyledFormControl
            {...rest}
            variant="standard"
            fullWidth
            style={{ transitionDuration: "10s!important" }}>
            {
                model &&
                <React.Fragment>
                    <StyledInputLabel
                        {...rest}
                        required={model.validators?.length > 0}
                        error={!model.isValid}
                        shrink={!!model.value} id={id + "Label"}>{model.label}
                    </StyledInputLabel>
                    <Tooltip title={model.isValid ? "" : model.validationError} arrow classes={StyledTooltip} placement="bottom-end">
                        <StyledSelect
                            {...rest}
                            variant="standard"
                            labelId={id + "_label"}
                            id={"dd_" + id}
                            error={!model.isValid}
                            value={model.value && (model.value.Id || model.value.id || model.value || model.id)}
                            onChange={!disabled ? onChange : null}
                            inputProps={{
                                id: id
                            }}
                            MenuProps={
                                {
                                    transitionDuration: 0
                                }
                            }>
                            {(noFirstEmpty == false || noFirstEmpty == undefined) ? <MenuItem id={("menuitem_" + id ?? '') + 'empty-select'} value={0}>&nbsp;</MenuItem> : null}
                            {model.values &&
                                Object.keys(model.values).map(key => (<MenuItem key={key} id={("menuitem_" + id) + key + model.values[key]} value={key}>{t ? t(model.values[key]) : model.values[key]}</MenuItem>))}
                            {listFromDb &&
                                listFromDb.map((e) => <MenuItem
                                    key={e.Id || e.id}
                                    id={("menuitem_" + id) + (e.Id || e.id) + (e.Value || e.value)}
                                    value={e.Id || e.id}
                                >
                                    {(e.id > 0 || e.Id > 0) ? (t ? t(e.Value || e.value) : (e.Value || e.value))
                                        : <b>{t ? t(e.Value || e.value) : (e.Value || e.value)}</b>}
                                </MenuItem>
                                )}
                        </StyledSelect>
                    </Tooltip>
                </React.Fragment>
            }
        </StyledFormControl>
    );
}

export default SingleSelect;