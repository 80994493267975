// @ts-expect-error not converted yet
import { checkIsNotNullOrWhitespace, checkNumberMoreZero } from "../../../../helper/Validator";
import { ModelParam, Translate } from "../../../Types/Types";
import { IAssignedRole } from "./UserManagement";

export interface IUserModelFactory {
    id: ModelParam<number>
    isEmailNotification:ModelParam<boolean>
    userRoles: ModelParam<IAssignedRole[]>

    salutation: ModelParam<number>
    firstName: ModelParam<string>
    lastName:ModelParam<string>
    email:ModelParam<string>
}

export interface IUserList {
    id: number;
    email: string;
    salutation?: number;
    firstName?: string;
    lastName?: string;
    isEmailNotification: boolean;
    roles: IAssignedRole[];
    reducedRoles: string;
}

export const UserModelFactory = (t: Translate) : IUserModelFactory => ({
    id: { disabled: true, idkey: "usm_id", isValid: true, label: "", validationError: "", validators: [], value: 0 },
    isEmailNotification: { idkey: "usm_emailNotification", isValid: true, label: t("IsEmailNotification"), validationError: t("PleaseVerifyYourEntriesError"), validators: [], value: false },
    userRoles: { idkey: "usm_roles", isValid: true, label: t("UserRole"), validationError: t("PleaseVerifyYourEntriesError"), validators: [], value: [] },

    salutation: { value: 0, idkey: "INQ-Salutation", label: t("Salutation"), isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkNumberMoreZero] },
    firstName: { idkey: "usm_name", isValid: true, label: t("FirstName"), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace], value: "" },
    lastName: { idkey: "usm_lastName", isValid: true, label: t("LastName"), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace], value: "" },
    email: { idkey: "usm_email", isValid: true, label: t("Email"), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace], value: "" }
})