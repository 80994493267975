import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Link } from '@mui/material';
import { AutocompleteDropdown } from '../Reusable';
import Alert, { AlertColor } from '@mui/material/Alert';
import LanguageIcon from '@mui/icons-material/Language';
import { IUser, UserStore } from "../../store/UserStore";
import { ExecutorStore, IAccountSelection } from "../../store/AccountStore";
// @ts-expect-error not converted yet
import UserDialog from "./UserDialog";
import { AlertStore } from '../../store/AlertStore';
import { API } from '../../helper/ApiHelper';
import { useGlobalState } from 'state-pool';
import Grid from '@mui/material/Unstable_Grid2';
import "./MainHeader.scss";
import { CountryParam, IAlert, LanguageParam } from "../Types/Types";

interface IProps {
    show: boolean
}

export function MainHeader(props: IProps) {
    const { country, lng } = useParams<CountryParam & LanguageParam>();
    const imgBlobUrl = process.env.REACT_APP_DEVFESSIMAGESERVICES_BASE + "blob/account/";
    const [initLanguage, setInitLanguage] = useGlobalState("InitLanguage", { default: false, persist: true });
    const [user, setUser] = useState<IUser | null>(null);
    const [userExecutors, setUserExecutors] = useState<IAccountSelection[]>([]);
    const [logoUrl, setLogoUrl] = useState("");
    const [chosenExecutor, setChosenExecutor] = useState<number | undefined>(undefined);
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const [alertModel, setAlertModel] = useState<IAlert>({});

    useEffect(() => {
        i18n.changeLanguage(lng ?? "en");
        if (lng == undefined)
            setInitLanguage(true);

        const userSub = UserStore.user.subscribe(user => {
            if (Object.keys(user).length > 0) {
                setUser(user);
            }
        });

        const execSub1 = ExecutorStore.executorList.subscribe(executors => setUserExecutors(executors));
        const execSub2 = ExecutorStore.executorSelection.subscribe(executorId => setChosenExecutor(executorId));
        const alertSub = AlertStore.AlertModel.subscribe(alert => setAlertModel(alert));

        AlertStore.initAlertModel();

        return () => {
            alertSub.unsubscribe();
            userSub.unsubscribe();
            execSub1.unsubscribe();
            execSub2.unsubscribe();
        }
    }, []);

    useEffect(() => {
        const exec = userExecutors.find(el => el.id == chosenExecutor);
        if (exec != undefined && exec.festoExecutor === false && exec.id > 0) {
            API.get(`${imgBlobUrl}${exec.id}/image`).then(resp => setLogoUrl(resp));
        }
    }, [chosenExecutor]);


    const handleClick = () => {
        if (initLanguage === true && lng != "")
            setInitLanguage(false);
        else
            setInitLanguage(true);
    };

    const isAlertShown = () => alertModel && alertModel.message && alertModel.alertType && alertModel.alertType.value;

    const getAlertMessage = () => {
        let translation = t(alertModel.message ?? "");
        alertModel?.alertParameters?.forEach((item) => {
            if (item.value) {
                translation = translation.replace("{" + item.index + "}", item.value);
            }
        });
        return translation;
    };

    const getHomeLink = () => {
        return "/" + country + "/" + lng + "/";
    };

    const handleExecutorDropDown = (e: IAccountSelection) => {
        ExecutorStore.setExecutorSelection(e.id);
        history.push("/" + country + "/" + lng + "/projectsandrequests")
    }

    const getAlertSeverity = (): AlertColor => {
        switch (alertModel?.alertType?.value?.toLowerCase()) {
            case 'success':
                return "success";
            case 'info':
                return "info";
            case 'warning':
                return "warning";
            case 'error':
                return "error";
            default:
                return "info";
        }
    }

    return (
        <div className="fixed-header">
            {isAlertShown() && <Alert id="alert-header" className="alert" severity={getAlertSeverity()}>{getAlertMessage()}</Alert>}
            <Grid container className="main-header" direction="row" justifyContent="space-between">
                <Grid container className="fessnav-content" pl={3}>
                    {!props.show &&
                        <Grid className="fessnav-logo">
                            <Link
                                id="nav-logo-header"
                                className="nav-logo"
                                href={getHomeLink()}
                                onClick={e => { e.preventDefault(); history.push(getHomeLink()); }}
                                underline="hover"
                            >
                                <img
                                    className="logoIMG"
                                    src={"/pictures/EnergySavingServices_final.svg"}
                                    alt="#"
                                />
                            </Link>
                        </Grid>
                    }
                    {logoUrl?.length > 0 && props.show &&
                        <Grid>
                            <img src={logoUrl} alt="logo" id="executor-logo" style={{ maxHeight: "35px" }} />
                        </Grid>
                    }
                </Grid>
                <Grid container direction="row" alignItems="center" justifyContent="flex-end" className="fessnav-content" pr={3}>
                    <Grid className="fessnav-user">
                        <UserDialog user={user} />
                    </Grid>
                    {
                        userExecutors.length > 0 &&
                        <Grid className="fessnav-executorSelect">
                            <AutocompleteDropdown
                                model={{ label: t("Executor"), idkey: "header-executorselect", value: chosenExecutor, isValid: true }}
                                options={userExecutors}
                                optionName="value"
                                optionKey="id"
                                onChange={(e: any) => e != null ? handleExecutorDropDown(e) : ""}
                                portaldisable={true}
                                TranslateFirstTwo={true}
                            />
                        </Grid>
                    }
                    <Grid className="fessnav-language" pr={1}>
                        <Button
                            startIcon={<LanguageIcon />}
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                            id="btn_header-translations"
                        >
                            {country + " | " + lng}
                        </Button>
                    </Grid>
                    <Grid className="fess-logo-wrapper" paddingLeft={9}>
                        <a
                            id="festo-logo-link"
                            href="https://www.festo.com/"
                        >
                            <img
                                id="festo-logo-link-image"
                                className="fess-logo"
                                src={"/pictures/logo_blue.svg"}
                                alt="#"
                            />
                        </a>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
