export const Decimal = (value: string, decimalPlaces: (number | null) = null) => {
    let returnValue = "";
    if (value != undefined && value.length > 0) {
        returnValue = value.replace(",", ".");
        const decimalIndex = returnValue.indexOf(".");
        if (decimalIndex > -1) {
            const decimals = returnValue.slice(decimalIndex + 1);
            if (decimalPlaces != null && decimals.length > decimalPlaces)
                returnValue = returnValue.substring(0, decimalIndex + decimalPlaces + 1);
        }
    }
    return returnValue;
}