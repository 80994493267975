export const GenerateBreadcrumbsFromPath = (path: string): string[] => {
    const START_POSITION = 4;
    const crumbs = path.split("/").slice(START_POSITION);
    return InsertProjectsBreadcrumb(crumbs);
}

export const PathKeyNamePairs: { [key: string]: string } = {
    //Path : Translation Key
    AN: "AirNetwork",
    PS: "ProjectSettings",
    GI: "GeneralInformation",
    CAG: "CompressedAirGeneration",
    MACAC: "MA/CACTabName",
    LA: "LeakageAnalysis",
    LDAR: "LeakageDetectionAndRepair",
    CS: "CostSavings",
    AQ: "AirQuality",
    order: "Cart",
    validate: "ValidateProject",
    project: "Project",
    projects: "Projects",
    requests: "Requests",
    projectsandrequests: "ProjectsAndRequests"
}

export const GetRoutingLink = (breadCrumb: string, path: string): string | undefined => {
    if (IsRoutingPathValid(breadCrumb)) {
        return "/" + GetRoutingPath(breadCrumb, path);
    }
}

export const BreadCrumbExistsInPath = (breadCrumb: string, path: string): boolean => path.indexOf(breadCrumb) !== -1;

export const GetRoutingPath = (breadCrumb: string, path: string): string => {
    if (breadCrumb.indexOf('project') !== -1)
        return path.split("/").slice(1, 3).join("/") + "/projectsandrequests";

    return path.split("/").splice(1, path.split("/").indexOf(breadCrumb)).join("/");
}

export const InsertProjectsBreadcrumb = (breadCrumbs: string[]): string[] => {
    if (breadCrumbs.some(bc => BreadCrumbExistsInPath("projectsandrequests", bc))) {
        return breadCrumbs;
    }
    return ["projectsandrequests", ...breadCrumbs];
}

export const IsRoutingPathValid = (breadcrumb: string): boolean => {
    let isValid = true;

    if (!isNaN(Number(breadcrumb))) isValid = false;
    else if (exceptionPaths.some(e => e.toLocaleLowerCase() === breadcrumb.toLocaleLowerCase())) isValid = false;

    return isValid;
}

const exceptionPaths: string[] =
    [
        "SettingsPanel",
        "AddEditCompressor",
        "IndividualDryer",
        "AirQualityMeasurement",
        "DistributionLine", "AirReceiver",
        "PressureDropAnalysis",
        "Application",
        "Leakage",
        "AddEditExecutor",
        "Project"
    ];
