import React, { useState } from 'react'
import { IconButton, Accordion, AccordionSummary, AccordionDetails, Tooltip, Popper, ClickAwayListener, TextField, Grid } from '@mui/material';
import { FilterList, ExpandMore } from '@mui/icons-material';
import { SimpleCheckbox } from '../';
import { useTranslation } from 'react-i18next';
import { handleFilterFunc, handleFilterDelete } from "./FilterHelper/FilterHelper"

export default function FilterIconComponent(props) {
    const { iconFilterFields, defaultData, onFilterFunction, handleChipArray, chipsArray, tableId, filterData } = props;

    const { t } = useTranslation();

    const [expanded, setExpanded] = useState(false);
    const [searchValue, setSearchValue] = useState("")
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClick = event => setAnchorEl(anchorEl ? null : event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    const handleChangeAccordion = panel => (event, isExpanded) => setExpanded(isExpanded ? panel : false);

    const checkStructure = (item, value) => {
        if (item[value] != null && typeof item[value] == "object") {
            if (item[value].value != null && typeof item[value].value == "object")
                return item[value].value.value;
            else
                return item[value].value;
        }
        else
            return item[value];
    }

    const handleFilter = (keyItem, value, checked) => {
        let tempdata;
        if (checked) {
            let tempChips = [{ name: t(value), key: [keyItem] }, ...chipsArray];
            tempdata = defaultData;
            for (const chip of tempChips) {
                tempdata = handleFilterFunc(tempdata, chip.name, chip.key, t);
            }
            handleChipArray(tempChips);
        }
        else {
            tempdata = handleFilterDelete(chipsArray, t(value), filterData, t);
            handleChipArray((chips) => chips.filter(chip => chip.name != t(value)));
        }
        onFilterFunction(tempdata);
    }

    const checkBoolean = (property, value) => {
        switch (property) {
            case "leakageRepaired":
                return value ? t("Yes") : t("No")
            case "leakageRepearableProdTime":
                return value ? t("Yes") : t("No")
            case "isEmailNotification":
                return value ? t("Yes") : t("No")
            case "festoExecutor":
                return value ? t("Yes") : t("No")
            case "orderSparePart":
                return value ? t("Yes") : t("No")
            default:
                return value
        }
    }

    const displayDinamicItems = (value) => {
        var p = new Set();
        if (filterData != null) {
            filterData.map((i) => {
                let checkedValue = checkStructure(i, value);
                if (checkedValue != null) {
                    if (typeof checkedValue == "boolean")
                        p.add(checkBoolean(value, checkedValue));
                    else
                        p.add(checkedValue);
                }
            })
        }
        return [...p.values()];
    }

    const handleCount = (value, item) => {
        var result = defaultData.reduce((acc, o) => (acc[checkStructure(o, value)] = (acc[checkStructure(o, value)] || 0) + 1, acc), {});
        if (result.true) {
            var checkBools = item == t("Yes") ? true : item == t("No") ? false : true;
            return result[checkBools] == undefined ? 0 : result[checkBools];
        }
        else
            return result[item] == undefined ? 0 : result[item];
    }

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <div>
                <Tooltip title={t("Filter")}>
                    <IconButton id="btn_delete" onClick={handleClick} size="large">
                        <FilterList />
                    </IconButton>
                </Tooltip>
                <Popper
                    keepMounted
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    popperOptions={{
                        modifiers: [
                            {
                                name: 'flip',
                                enabled: true,
                                options: {
                                    altBoundary: false,
                                    rootBoundary: 'document',
                                    padding: 8,
                                },
                            },
                        ],
                    }}
                >
                    <div className="filter-popover">
                        <Grid container justifyContent="center">
                            <TextField label="Search..." onChange={e => setSearchValue(e.target.value)} variant="filled" />
                        </Grid>
                        {iconFilterFields.map((item, index) => {
                            return (
                                <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChangeAccordion(`panel${index}`)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        {t(item.name)}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {item.key.map((keyItem, indx) => {
                                            var filteredArray = displayDinamicItems(keyItem).filter(val => {
                                                if (searchValue == "") {
                                                    return val
                                                }
                                                else if (val.toLowerCase().includes(searchValue.toLowerCase().trim())) {
                                                    return val
                                                }
                                            });
                                            return (
                                                <React.Fragment key={indx}>
                                                    {filteredArray.length != 0 ?
                                                        filteredArray.map((dinamicItem, ind) => {
                                                            return (
                                                                <div key={ind} className="popper_dialog_text">
                                                                    <SimpleCheckbox
                                                                        id={`ref_${tableId}_${keyItem}_${t(dinamicItem)}`}
                                                                        checked={document.getElementById(`ref_${tableId}_${keyItem}_${t(dinamicItem)}`) != null && document.getElementById(`ref_${tableId}_${keyItem}_${t(dinamicItem)}`).checked}
                                                                        key={ind}
                                                                        color="default"
                                                                        onChange={e => handleFilter(keyItem, dinamicItem, e.target.checked)}
                                                                    />
                                                                    <span>{t(dinamicItem)} ({handleCount(keyItem, dinamicItem)})</span>
                                                                </div>
                                                            )
                                                        })
                                                        : <Grid container justifyContent="center">
                                                            {item.name == "ServiceType" ? ""
                                                                :
                                                                "No results found"
                                                            }
                                                        </Grid>
                                                    }
                                                </React.Fragment>
                                            )
                                        })}
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}
                    </div>
                </Popper>
            </div>
        </ClickAwayListener>
    );
}
