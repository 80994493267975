import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';

interface IProps {
	errorCode: string,
	errorMessage: string
}
export function ErrorPage(props: IProps) {
	const { t } = useTranslation();
	const { errorCode, errorMessage } = props;
	return (
		<Grid container >
			<Grid item md={12}>
				<Grid container className="content-max-height" justifyContent="center" alignItems="center" direction="column">
					<Typography variant={"h1"}>{t(errorCode)}</Typography>
					<Typography variant={"h4"}>{t(errorMessage)}</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
}