import axios from 'axios';
import { UserStore } from '../store/UserStore';

export const API = CreateInstance()

function CreateInstance() {
    const instance = axios.create({
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "ApiKey": process.env.REACT_APP_API_KEY
        }
    });

    instance.interceptors.response.use((resp) => {
        const { data } = resp;
        return data;
    });

    //Header handling interceptor
    instance.interceptors.request.use(function (config) {
        const token = UserStore.token.value;
        if (token != undefined && token.length > 0)
            config.headers.Authorization = token;
        else
            config.headers.Authorization = null;

        return config;

    });

    return instance
}