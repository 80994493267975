import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonComponent } from '../../Reusable/';
import PrintLeakagesDialog from './PrintLeakagesDialog';

export default function PrintLeakagesButton(props) {
    const { leakageData, ldarCalcResults } = props;

    const { t } = useTranslation();
    const [pdfDialogOpen, setPdfDialogOpen] = useState(false);

    return (
        <React.Fragment>
            <PrintLeakagesDialog
                open={pdfDialogOpen}
                setOpen={setPdfDialogOpen}
                leakageData={leakageData}
                ldarCalcResults={ldarCalcResults}
            />
            <ButtonComponent id="btn_LDAR-print-leakages" value={t('PrintLeakages')} color="primary" onChange={() => setPdfDialogOpen(true)} />
        </React.Fragment>
    )
}
