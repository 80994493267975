import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@mui/material';
import { TableHeadingContent, SimpleCheckbox, TableHeadCells, HeadingComponent } from '../../components/Reusable/';
import { FilterModel } from "../Reusable/TableHeadingContent/GlobalFilterModel";
import { API } from '../../helper/ApiHelper';
import { IndividualDryerModelFactory, AirQualityCalcModelFactory } from '../AirQuality/IndividualDryer/IndividualDryerModel'

export default function ImportFullDryer(props) {
    const aqUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "air-quality";
    const { projectId, selectedIds, setSelectedIds } = props;
    const { t } = useTranslation();
    const filterModel = { ...IndividualDryerModelFactory(t), ...AirQualityCalcModelFactory(t) };

    const [list, setList] = useState([]);
    const [filterList, setFilterList] = useState([]);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(5);

    useEffect(() => {
        API.get(aqUrl + '/' + projectId).then(resp => {
            if (resp.individualDryers != undefined && resp.individualDryers.length > 0) {
                let dryers = resp.individualDryers.map((item, no) => ({ ...item, no }));
                setList(dryers);
                setFilterList(dryers);
                setSelectedIds(dryers.map(x => x.id));
            }
        })
    }, []);

    const filterFunction = (tempdata) => {
        setList(tempdata);
        setPage(0);
    }

    const changeRows = (e) => {
        setRows(e.target.value);
        setPage(0);
    }

    const handleCheck = (id) => {
        let existingIndex = selectedIds.indexOf(id);
        if (existingIndex < 0)
            setSelectedIds([id, ...selectedIds]);
        else
            setSelectedIds(selectedIds.filter(x => x != id));
    }

    return <Grid container spacing={3} direction="column">
        <Grid item>
            <HeadingComponent value={t('InstalledDryers')} size="h6" />
        </Grid>
        <Grid item>
            <TableHeadingContent
                id="import-full-dryer"
                defaultData={list}
                filterData={filterList}
                onFilterFunction={filterFunction}
                filterModel={FilterModel.ImportProject}
                NotShowAddDelete={true}
                tableId="InstalledDryers"
            />
        </Grid>
        <Grid item xs={12}>
            <TableContainer component={Paper} >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: "2rem" }}></TableCell>
                            <TableHeadCells
                                model={filterModel}
                                propertyList={["id", "measurementId", "compressorRoomNo", "typeOfDryer", "manufacturer", "modelSerialNumber", "year"]}
                                tableName={"InstalledDryers"}
                                data={list}
                                setData={setList}
                            />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            list.slice(page * rows, rows + (page * rows)).map((item, index) =>
                                <TableRow key={index}>
                                    <TableCell>
                                        <SimpleCheckbox
                                            id={'import_dryer-checkbox' + index}
                                            key={Math.random()}
                                            color="default"
                                            onChange={() => handleCheck(item.id)}
                                            defaultChecked={selectedIds.includes(item.id)}
                                        />
                                    </TableCell>
                                    <TableCell id={"import_dryer-index" + index}>{item.no + 1}</TableCell>
                                    <TableCell id={"import_dryer-measurementId" + index}>{item.measurementId}</TableCell>
                                    <TableCell id={"import_dryer-compressorRoomNo" + index}>{item.compressorRoomNo}</TableCell>
                                    <TableCell id={"import_dryer-typeOfDryer" + index}>{item.typeOfDryer ? t(item.typeOfDryer.value) : null}</TableCell>
                                    <TableCell id={"import_dryer-manufacturer" + index}>{item.manufacturer}</TableCell>
                                    <TableCell id={"import_dryer-modelSerialNumber" + index}>{item.modelSerialNumber}</TableCell>
                                    <TableCell id={"import_dryer-year" + index}>{item.year}</TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 15]}
                    count={list && list.length > 0 ? list.length : 0}
                    rowsPerPage={rows}
                    onRowsPerPageChange={changeRows}
                    page={page}
                    onPageChange={(e, newPage) => setPage(newPage)}
                />
            </TableContainer>
        </Grid>
    </Grid >
}