import React from 'react';
import { useTranslation } from 'react-i18next';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import { usePdfStyles } from './PdfStyles';

export default function QrCodes(props) {
    const { QrCodes } = props;
    const { t } = useTranslation();
    var PdfStyles = usePdfStyles();

    return (
        <Page size={props?.size == "A4" ? "A4" : "LETTER"} style={props?.size == "A4" ? PdfStyles.page : PdfStyles.pageLetter}>
            {QrCodes.map((item, index) =>
                <View style={PdfStyles.QrCode} key={index} wrap={false}>
                    <View style={props?.size == "A4" ? PdfStyles.section : PdfStyles.sectionLetter}>
                        <View style={PdfStyles.tableRow}>
                            <View style={PdfStyles.tableCol1}>
                                <Text style={{ height: 15 }}></Text>

                                <Text style={PdfStyles.boldText0}>{t('FestoEnergySavingServices')}</Text>
                                <Text style={PdfStyles.spacer}></Text>
                                <Text style={PdfStyles.boldText1}>{t('Measurementpoint')}</Text>
                                <Text style={PdfStyles.spacer}></Text>
                                <Text style={PdfStyles.qrCodeText}>{item.id}</Text>
                            </View>
                            <View style={PdfStyles.tableCol2}>
                                {props.logo && <Image style={PdfStyles.logo} src={props.logo} />}
                                <Text style={PdfStyles.spacer2}></Text>
                                <Image style={{ width: 69, height: 60, objectFit: "contain" }} source={{ uri: item.qrCodeDataUri }} />
                            </View>
                        </View>
                    </View>
                    <View style={props?.size == "A4" || (index + 1) % 7 == 0 ? { height: 0 } : { height: 9 }}></View>
                </View>
            )}
        </Page>
    )
}