export const SplitText = (text: string, length: number, splitSymbol = " ") => {
    if (typeof text != 'string' || text.length < 1) {
        return text;
    }
    else {
        const regex = new RegExp(`.{1,${length}}`, `g`);
        const returnArray = [];
        const splitArray = text.split(splitSymbol);
        for (let i = 0; i < splitArray.length; i++) {
            const el = splitArray[i];
            if (el.length > length) {
                const split = el.match(regex) ?? [];
                returnArray.push(...split);
            }
            else if (el.length > 0) {
                returnArray.push(el);
            }
        }
        const optimizedArray = OptimizeStringArray(returnArray, length);
        const returnString = optimizedArray.join('\n');
        return returnString;
    }
}

const OptimizeStringArray = (arr: string[], length: number, splitSymbol = " ") => {
    for (let i = 0; i < arr.length - 1; i++) {
        let newString = arr[i];
        const fixedI = i;
        for (let j = i + 1; j < arr.length; j++) {
            const addedString = newString + splitSymbol + arr[j];
            if (addedString.length < length) {
                newString = addedString;
                arr[fixedI] = addedString;
                arr[j] = "";
                i++;
            }
            else {
                break;
            }
        }
    }

    return arr.filter(el => el.length > 0);
}