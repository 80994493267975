// @ts-expect-error not converted yet
import { checkIsInteger, checkIsNotNullOrWhitespace, checkIsDecimal, isRepairedByFilledValidator, checkIsDateInPastUTC, isRepairedDateFilledAndPast, checkIsDateOrNull } from "../../../helper/Validator";
import * as Fmt from '../../../helper/Formatters';
import { UserStore } from '../../../store';
import { ModelParam, Translate } from "../../Types/Types";
import { IUser } from "../../../store/UserStore";
import { nowUTC } from "../../../helper/DateHelper";

let user: IUser = {};
UserStore.user.subscribe(usr => {
    user = usr;
})

export interface IAirReceiverModelFactory {
    id: ModelParam<number>
    no:ModelParam<number>
    guid: ModelParam<string | null>;
    measurementId: ModelParam<string>

    dateOfDetection: ModelParam<Date>
    detectedBy: ModelParam<string>

    isRepairDone: ModelParam<boolean>;
    repairedBy: ModelParam<string>;
    repairDate: ModelParam<Date | null>;

    comment: ModelParam<string>;
    repairComment: ModelParam<string>;

    building: ModelParam<string>;
    department: ModelParam<string>;
    machine: ModelParam<string>;

    year:  ModelParam<string>;
    volume: ModelParam<string>;
    lsInspection: ModelParam<Date | null>;
    workingPressure: ModelParam<string>;
}


export const AirReceiverModel = (t:  Translate): IAirReceiverModelFactory => ({
    id: { label: t("Id"), idkey: "AIRRECEIV-Id", value: 0, isValid: true, validationError: "", validators: [], disabled: true },
    no: { label: t('Nr'), idkey: "AIRRECEIV-No", value: 0, isValid: true, validationError: "", validators: [], disabled: true },
    guid: { label: "", idkey: "", value: "", isValid: true, validationError: "", validators: [] },
    measurementId: { label: t("MeasurementId"), idkey: "AIRRECEIV-MeasurementId", value: "", isValid: true, validationError: "", validators: [checkIsInteger] },
    dateOfDetection: { label: t("EntryDate"), idkey: "AIRRECEIV-EntryDate", value: nowUTC(), isValid: true, validationError: "", validators: [checkIsDateInPastUTC] },
    detectedBy: { default: "", headerName: t('DetectedBy'), idkey: "AIRRECEIV-detectedBy", value: user.firstName + " " + user.lastName, isValid: true, label: t("DetectedBy"), validationError: t("DetectedByError"), validators: [checkIsNotNullOrWhitespace] },
    isRepairDone: { label: t("OptimizationIsDone"), idkey: "AIRRECEIV-RepairIsDone", value: false, isValid: true, validationError: "", validators: [] },
    repairedBy: { label: t("OptimizedBy"), idkey: "AIRRECEIV-RepairedBy", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [isRepairedByFilledValidator] },
    repairDate: { label: t("OptimizationDate"), idkey: "AIRRECEIV-RepairDate", value: null, isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [isRepairedDateFilledAndPast] },
    building: { label: t("Building"), idkey: "AIRRECEIV-Building", value: "", isValid: true, validationError: "", validators: [checkIsNotNullOrWhitespace] },
    department: { label: t("Department"), idkey: "AIRRECEIV-Department", value: "", isValid: true, validationError: "", validators: [] },
    machine: { label: t("Machine"), idkey: "AIRRECEIV-Machine", value: "", isValid: true, validationError: "", validators: [] },
    volume: { label: t('Volume') + Fmt.getUnitInBracket("volumeLiterGalon", t), idkey: "AIRRECEIV-Volume", value: "", isValid: true, validationError: "", validators: [checkIsInteger] },
    workingPressure: { label: t("WorkingPressure") + Fmt.getUnitInBracket("pressure", t), idkey: "AIRRECEIV-WorkingPressure", value: "", isValid: true, validationError: "", validators: [checkIsDecimal] },
    year: { label: t("Year"), idkey: "AIRRECEIV-Year", value: "", isValid: true, validationError: "", validators: [checkIsNotNullOrWhitespace] },
    lsInspection: { label: t("LsInspection"), idkey: "AIRRECEIV-LsInspection", value: null, isValid: true, validationError: "", validators: [checkIsDateOrNull], notRequired: true },
    comment: { label: t("Comment"), idkey: "AIRRECEIV-Comment", value: "", isValid: true, validationError: "", validators: [], sortable: false },
    repairComment: { label: t("RepairComment"), idkey: "AIRRECEIV-RepairComment", value: "", isValid: true, validationError: "", validators: [] },
})