import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Tooltip, Link, TablePagination } from '@mui/material';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { SimpleCheckbox, TableHeadCells, TableHeadingContent, ButtonComponent, ModalComponent } from '../../Reusable/';
import { API } from '../../../helper/ApiHelper';
import * as Consts from '../../../helper/ConstantRepository';
import { OpenGlobalSnackbar } from '../../../helper/GlobalVariables';
import { ExecutorStore, UserStore } from '../../../store/'
import { FilterModel } from "../../Reusable/TableHeadingContent/GlobalFilterModel"
import { ProjectModelFactory, ProjectListFilterModelFactory } from '../../ProjectSettings/ProjectModel';
import { RequestsListStore } from './RequestsListStore';
import { toLocalDateString } from '../../../helper/DateHelper'

export default function Projects(props) {
    const { setIsProjectListActive } = props;

    var reqUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "request-management";

    const history = useHistory();
    let { country, lng } = useParams();
    const { t } = useTranslation();

    const [data, setData] = useState(null);
    const [rFilterData, setrFilterData] = useState(null);
    const [dataCount, setDataCount] = useState(0);
    const [rpage, setRPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [checkedArrayRequest, setCheckedArrayRequest] = useState([]);
    const [executorId, setExecutorId] = useState(null)
    const [user, setUser] = useState({});
    const [modalModel, setModalModel] = useState({
        open: false,
        message: ""
    });

    const projectFilterModel = { ...ProjectModelFactory(t), ...ProjectListFilterModelFactory(t) };

    useEffect(() => {
        setIsProjectListActive(false);
        setCheckedArrayRequest([]);

        var execSub = ExecutorStore.executorSelection.subscribe(executorId => {
            if (executorId != Consts.ExecutorSelectionNotInitialized && executorId != Consts.ExecutorNotSelected) {
                setExecutorId(executorId)
                fetchRequestData(executorId);
            }
        })

        let userSub = UserStore.user.subscribe(user => {
            setUser(user)
        })

        const requestsListSub = RequestsListStore.rowsPerPage.subscribe(x => setRowsPerPage(x));

        return () => {
            execSub.unsubscribe();
            userSub.unsubscribe();
            requestsListSub.unsubscribe();
        }
    }, [])

    const fetchRequestData = async (executorId) => {
        API.get(`${reqUrl}/requests/${executorId}`).then(result => {
            var res = JSON.parse(result);
            setData(res);
            setrFilterData(res);
            setDataCount(res.length / rowsPerPage > Number.parseInt(res.length / rowsPerPage) ? Number.parseInt(res.length / rowsPerPage) + 1 : Number.parseInt(res.length / rowsPerPage));
        })
    };

    const handleChangePage = (event, newPage) => {
        setRPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        RequestsListStore.rowsPerPage.next(parseInt(event.target.value, 10));
        setRPage(0);
    };

    const getRequestLink = id => {
        return "/" + country + "/" + lng + `/request/${id}`;
    }

    const filterFunction = (tempdata) => {
        var tempDataForFilter = tempdata == null ? [] : tempdata;
        setData(tempDataForFilter);
        setDataCount(tempDataForFilter.length / rowsPerPage > Number.parseInt(tempDataForFilter.length / rowsPerPage) ? Number.parseInt(tempDataForFilter.length / rowsPerPage) + 1 : Number.parseInt(tempDataForFilter.length / rowsPerPage));
        setRPage(0);
    }

    //delete request

    const isRequestCheckedForDeletion = id => {
        let checkedIndex = checkedArrayRequest.indexOf(id);
        if (checkedIndex < 0)
            return false;
        else
            return true;
    }

    const handleCheckRequest = (checkIndex) => {
        let checkedIndex = checkedArrayRequest.indexOf(checkIndex);
        if (checkedIndex < 0)
            checkedArrayRequest.push(checkIndex);
        else
            checkedArrayRequest.splice(checkedIndex, 1);

        checkedArrayRequest.sort(function (a, b) { return b - a });
        setCheckedArrayRequest(checkedArrayRequest);
    }

    const clickDeleteRequest = () => {
        if (checkedArrayRequest == undefined || checkedArrayRequest.length == 0)
            OpenGlobalSnackbar("danger", t('NoEntriesCheckedError'));
        else
            clickDeleteOpenModalRequest();
    }

    const clickDeleteOpenModalRequest = () => {
        modalModel.open = true;
        modalModel.okText = t('Yes');
        modalModel.cancelText = t('No');
        modalModel.message = t('DeleteSelectedRowsQuestion');
        modalModel.clickOk = deleteRequest;
        setModalModel({ ...modalModel });
    }

    const deleteRequest = async () => {
        await API.post(reqUrl + `/requests/${executorId}/delete`, checkedArrayRequest);
        setData(data.filter(x => !checkedArrayRequest.includes(x.Id)));
        setCheckedArrayRequest([]);
        OpenGlobalSnackbar("success", t("DeleteSuccesfull"));
    }

    const canDeleteRequest = () => executorId > 0;

    //delete request end

    const copyExecutorInquiryLink = () => {
        navigator.clipboard.writeText(window.location.origin + "/" + country + "/" + lng + "/contact?account=" + executorId);
        OpenGlobalSnackbar("success", t("LinkCopied"));
    }

    const tableStyles = [
        { width: "105px !important" },
        { width: "105px !important" },
        { width: "105px !important" },
        { width: "105px !important" },
        { width: "105px !important" },
        { width: "60px !important" },
        { width: "105px !important" },
        { width: "105px !important" },
    ]

    return (
        <Grid container>
            <ModalComponent model={modalModel} />
            <Grid item style={{ margin: "20px 0 0 10px" }}>
                <ButtonComponent
                    id='copy-executor-inquiry-link'
                    value={t('CopyExecutorSpecificInquiryLink')}
                    color="primary"
                    onChange={copyExecutorInquiryLink}
                />
            </Grid>
            <Grid item style={{ margin: "0 0 0 20px" }}>
                {Object.keys(user).length > 0 && (user.userRights.isSuperAdmin || user.userRights.isExecutorAdmin || user.userRights.isTechnician) &&
                    <TableHeadingContent
                        clickDelete={canDeleteRequest() && clickDeleteRequest}
                        clickAdd={() => history.push("/" + country + "/" + lng + "/request/")}
                        defaultData={data}
                        filterData={rFilterData}
                        onFilterFunction={filterFunction}
                        filterModel={FilterModel.RequestOverview}
                        tableId={"RequestOverview"}
                    />
                }
            </Grid>
            <Grid item>
                <TableContainer >
                    <Table >
                        <TableHead>
                            <TableRow >
                                {canDeleteRequest() && <TableCell width="1%" />}
                                <TableHeadCells
                                    model={projectFilterModel}
                                    propertyList={["requestNo", "executorName", "companyName", "city", "country", "serviceType", "email", "createdDate"]}
                                    tableName="ProjectOverview"
                                    data={data}
                                    setData={setData}
                                    defaultSortProperty="CreatedDate"
                                    defaultSortOrderAscending={false}
                                    styleList={tableStyles}
                                />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data && data.length > 0 ?
                                data.slice(rpage * rowsPerPage, rowsPerPage + (rpage * rowsPerPage)).map((item, index) =>
                                    <TableRow className="table-body-row" key={index} id={"tr_Request-tablerow" + index}>
                                        {canDeleteRequest() &&
                                            <TableCell>
                                                <SimpleCheckbox
                                                    id={'cb_Request-checkbox' + index}
                                                    key={Math.random()}
                                                    defaultChecked={isRequestCheckedForDeletion(item.Id)}
                                                    color="default"
                                                    onChange={() => handleCheckRequest(item.Id)}
                                                />
                                            </TableCell>
                                        }
                                        <TableCell>
                                            <Link
                                                id={"lnk_Request-ProjectNr" + index}
                                                href={getRequestLink(item.Id)}
                                                onClick={(e) => { e.preventDefault(); history.push(getRequestLink(item.Id)) }}
                                                underline="hover"
                                            >
                                                {item.ProjectId}
                                            </Link>
                                        </TableCell>
                                        <TableCell id={"td_Request-ExecutorName" + index} className="word-break-all">{item.ExecutorName}</TableCell>
                                        <TableCell id={"td_Request-CompanyName" + index} className="word-break-all">{item.CompanyName}</TableCell>
                                        <TableCell id={"td_Request-City" + index} className="word-break-all">{item.City}</TableCell>
                                        <TableCell id={"td_Request-Country" + index} className="word-break-all"> {item.Country}</TableCell>
                                        <TableCell id={"td_Request-ServiceType" + index} className="service-type">{item.IsCompressedAirEnergyEfficiencyPreAuditSelected == 1 ? <Tooltip title={t("PreAudit")}><img src={"/pictures/CompressedAirEnergyEfficiencyPreAudit.png"} alt="" /></Tooltip> : null}
                                            {item.IsAnalysisOfTheCompressedAirGenerationSelected == 1 ? <Tooltip title={t('CompressedAirGeneration')}><img src={"/pictures/AnalysisOfTheCompressedAirGeneration.png"} alt="" /></Tooltip> : null}
                                            {item.IsCompressedAirQualityAnalysisSelected == 1 ? <Tooltip title={t('AirQuality')}><img src={"/pictures/CompressedAirQualityAnalysis.png"} className="quality" alt="" /></Tooltip> : null}
                                            {item.IsMachineAnalysisForEnergyEfficiencySelected == 1 ? <Tooltip title={t('MA/CACTabName')}><img src={"/pictures/MachineAnalysisForEnergyEfficiency.png"} alt="" /></Tooltip> : null}
                                            {item.IsAirNetworkAnalysisSelected == 1 ? <Tooltip title={t('AirNetwork')}><img src={"/pictures/AirNetworkicon.png"} alt="" /></Tooltip> : null}
                                            {item.IsCompressedAirConsumtionAnalysisSelected == 1 ? <Tooltip title={t("AirConsumption")}><img src={"/pictures/CompressedAirConsumtionAnalysis.png"} alt="" /></Tooltip> : null}
                                            {item.IsLeakageDetectionAndDocumentationSelected == 1 ? <Tooltip title={t('LeakageAnalysis')}><img src={"/pictures/LeakageDetectionAndDocumentation.png"} alt="" /></Tooltip> : null}
                                            {item.IsLeakageDetectionAndDocumentationAndRepairSelected == 1 ? <Tooltip title={t('LeakageDetectionAndDocumentation')}><img src={"/pictures/LeakageDetectionAndDocumentationAndRepair.png"} alt="" /></Tooltip> : null}</TableCell>
                                        <TableCell id={"td_Request-Email" + index} className="word-break-all">{item.Email}</TableCell>
                                        <TableCell id={"td_Request-Date" + index}>{toLocalDateString(item.CreatedDate)}</TableCell>
                                    </TableRow>
                                ) : null}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableError executorId={executorId} data={data} header="NoRequestsFound" />
                {dataCount > 0 ?
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 20, 25]}
                        component="div"
                        count={data && data.length > 0 ? data.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={rpage}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    :
                    null
                }
            </Grid>
        </Grid>
    );

}
function TableError({ executorId, data, header }) {
    const { t } = useTranslation();
    return (
        <div>
            {
                data && data.length == 0 ?
                    <Grid container justifyContent="center" alignItems="center" direction="column" className="Table_error_container">
                        {executorId == 0 ?
                            <React.Fragment>
                                <SupervisedUserCircleIcon />
                                <h1>{t("Executorisnotselected")}</h1>
                                <h3>{t("Selectexecutorinpopup")}</h3>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div className="error_svg_circle">
                                    <ListRoundedIcon />
                                </div>
                                <h2>{t(header)}</h2>
                            </React.Fragment>
                        }
                    </Grid>
                    : null
            }
        </div>
    )
}