import * as React from "react";
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';

interface IProps {
	title: string,
}

const InitializeMessage = (props: IProps) => {
    const { t } = useTranslation();
    const { title } = props;
    return (
        <Grid container>
            <Grid item md={12}>
                <Grid container className="content-max-height" justifyContent="center" alignItems="center" direction="column">
                    <CircularProgress style={{ color: "#0091dc" }} />
                    <Typography variant="h4">{t(title)}</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default InitializeMessage