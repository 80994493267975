import React from "react"
import { useHistory } from "react-router-dom";
import { Link } from "@mui/material";

interface ILinkProps{
    id?: string,
    link: string,
    newTab?: boolean,
    onChange?: () => void,
    innerComponent: any
}

const LinkComponent = (props : ILinkProps) => {
    const history = useHistory();

    const { link, id, onChange, innerComponent, newTab } = props;

    const defaultOnChange = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if (newTab)
            window.open(link, "_blank");
        else
            history.push(link);
    }

    return (
        <Link
            id={id}
            href={link}
            onClick={onChange ?? defaultOnChange}
            underline="hover"
        >
            {innerComponent}
        </Link>
    );
}


export default LinkComponent;