import React, { useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { ButtonComponent, QrReaderButton, TextFieldComponent, LinkComponent } from '../Reusable';
import { API } from '../../helper/ApiHelper';
import { getLink } from '../../helper/EditLinkHelper';
import { OpenGlobalSnackbar } from '../../helper/GlobalVariables';
import { Close, Search } from '@mui/icons-material';
import { Grid, Dialog, DialogTitle, IconButton, DialogContent, DialogActions, Button, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';

export default function QrCodeSearch() {
    const url = process.env.REACT_APP_DEVFESSSERVICE_BASE + "project/service-search";

    let history = useHistory();
    const { t } = useTranslation();
    let { country, lng } = useParams();

    const [model, setModel] = useState({ label: t("Input"), value: "", isValid: true, idkey: "QrCode" });
    const [open, setOpen] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    const updateModel = (value) => {
        model.value = value;
        setModel({ ...model });
    }

    const search = async () => {
        if (model.value) {
            let result = await API.post(url, model.value);
            if (result.length < 1)
                OpenGlobalSnackbar("danger", t('NotFound'))
            setSearchResults(result);
        }
        else {
            OpenGlobalSnackbar("danger", t('EnterValue'));
        }
    };

    const handleClose = () => setOpen(false);

    const clickRowLink = (e, link) => {
        e.preventDefault();
        handleClose();
        history.push(link);
    }

    return (
        <React.Fragment>
            <Button
                onClick={e => {
                    e.preventDefault();
                    setOpen(true);
                }}
                id="btn_Component-Search"
                className="projectMenu-button"
            >
                <Search />
                {t("ComponentSearch")}
            </Button>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="lg" >
                <DialogTitle id="customized-dialog-title" >
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            {t("ComponentSearch")}
                        </Grid>
                        <Grid item>
                            <IconButton color="inherit" size="small" onClick={handleClose}>
                                <Close fontSize="small" />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container style={{ margin: "20px 0" }}>
                        <Grid item xs={4}>
                            <TextFieldComponent
                                model={model}
                                onChange={e => updateModel(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <QrReaderButton
                                setCode={code => updateModel(code)}
                                id="service-search-qr"
                            />
                        </Grid>
                        {searchResults.length > 0 &&
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{t("ProjectId")}</TableCell>
                                                <TableCell align="right">{t("MeasurementId")}</TableCell>
                                                <TableCell align="right">{t("Building")}</TableCell>
                                                <TableCell align="right">{t("Department")}</TableCell>
                                                <TableCell align="right">{t("Machine")}</TableCell>
                                                <TableCell align="right">{t("Link")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {searchResults.map((row, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {row.projectId}
                                                    </TableCell>
                                                    <TableCell align="right">{row.measurementId}</TableCell>
                                                    <TableCell align="right">{row.building}</TableCell>
                                                    <TableCell align="right">{row.department}</TableCell>
                                                    <TableCell align="right">{row.machine}</TableCell>
                                                    <TableCell align="right">
                                                        <LinkComponent
                                                            id={`service-search-result-link-${index}`}
                                                            link={getLink(row.projectId, row.editId, row.type, country, lng)}
                                                            onChange={e => clickRowLink(e, getLink(row.projectId, row.editId, row.type, country, lng))}
                                                            innerComponent={t("Link")}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent id="qrcode-search" value={t('Search')} color="primary" className="button" onChange={search}></ButtonComponent>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}