import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { FlexTable } from '../../../PdfReport/PdfContent/PdfStyles';
import { SplitText } from '../../../../helper/PdfTested';
import { usePdfStyles } from '../../../PdfReport/PdfContent/PdfStyles';

export default function ReplacementTable(props) {
    const { replacementPartList } = props;

    const { t } = useTranslation();
    var PdfStyles = usePdfStyles();

    return (
        <View style={[FlexTable.column, { borderLeft: "1px solid black", borderTop: "1px solid black" }]}>
            <View style={[FlexTable.row, { borderBottom: "1px solid black" }]}>
                <View style={[FlexTable.rowItem, { flexGrow: 1, flexBasis: 0, borderRight: "1px solid black" }]}>
                    <Text style={[FlexTable.rowItemText, PdfStyles.bold, { textAlign: "center" }]}>{t('Component')}</Text>
                </View>
                <View style={[FlexTable.rowItem, { flexGrow: 3, flexBasis: 0, borderRight: "1px solid black" }]}>
                    <Text style={[FlexTable.rowItemText, PdfStyles.bold, { textAlign: "center" }]}>{t('DefectedDevice')}</Text>
                </View>
                <View style={[FlexTable.rowItem, { flexGrow: 3, flexBasis: 0, borderRight: "1px solid black" }]}>
                    <Text style={[FlexTable.rowItemText, PdfStyles.bold, { textAlign: "center" }]}>{t('ReplacementDevice')}</Text>
                </View>
            </View>
            <View style={[FlexTable.row, { borderBottom: "1px solid black" }]}>
                <View style={FlexTable.rowItem}>
                </View>
                <View style={FlexTable.rowItem}>
                    <Text style={[FlexTable.rowItemText, PdfStyles.bold, { textAlign: "center" }]}>{t('PartNumber')}</Text>
                </View>
                <View style={FlexTable.rowItem}>
                    <Text style={[FlexTable.rowItemText, PdfStyles.bold, { textAlign: "center" }]}>{t('TypeCode')}</Text>
                </View>
                <View style={FlexTable.rowItem}>
                    <Text style={[FlexTable.rowItemText, PdfStyles.bold, { textAlign: "center" }]}>{t('Manufacturer')}</Text>
                </View>
                <View style={FlexTable.rowItem}>
                    <Text style={[FlexTable.rowItemText, PdfStyles.bold, { textAlign: "center" }]}>{t('NewPartNumber')}</Text>
                </View>
                <View style={FlexTable.rowItem}>
                    <Text style={[FlexTable.rowItemText, PdfStyles.bold, { textAlign: "center" }]}>{t('NewTypeCode')}</Text>
                </View>
                <View style={FlexTable.rowItem}>
                    <Text style={[FlexTable.rowItemText, PdfStyles.bold, { textAlign: "center" }]}>{t('NewManufacturer')}</Text>
                </View>
            </View>
            {replacementPartList.map((part, index) =>
                <View style={[FlexTable.row, { borderBottom: "1px solid black" }]} key={"leakageReplacementPartPdf" + index}>
                    <View style={FlexTable.rowItem}>
                        <Text style={FlexTable.rowItemText}>{SplitText(part.component?.value ? t(part.component.value) : "", 12)}</Text>
                    </View>
                    <View style={FlexTable.rowItem}>
                        <Text style={FlexTable.rowItemText}>{SplitText(part.partNumber, 12)}</Text>
                    </View>
                    <View style={FlexTable.rowItem}>
                        <Text style={FlexTable.rowItemText}>{SplitText(part.typeCode, 12)}</Text>
                    </View>
                    <View style={FlexTable.rowItem}>
                        <Text style={FlexTable.rowItemText}>{SplitText(part.manufacturer, 12)}</Text>
                    </View>
                    <View style={FlexTable.rowItem}>
                        <Text style={FlexTable.rowItemText}>{part.replaceWithDifferent ? SplitText(part.newPartNumber, 12) : SplitText(part.partNumber, 12)}</Text>
                    </View>
                    <View style={FlexTable.rowItem}>
                        <Text style={FlexTable.rowItemText}>{part.replaceWithDifferent ? SplitText(part.newTypeCode, 12) : SplitText(part.typeCode, 12)}</Text>
                    </View>
                    <View style={FlexTable.rowItem}>
                        <Text style={FlexTable.rowItemText}>{part.replaceWithDifferent ? SplitText(part.newManufacturer, 12) : SplitText(part.manufacturer, 12)}</Text>
                    </View>
                </View>
            )}
        </View>
    );
}
