import React from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { usePdfStyles } from './PdfStyles';

export const LeakageDetectionPdf = (props) => {
    const { t } = useTranslation();
    var PdfStyles = usePdfStyles();

    return (
        <Page size={props.pageSize} style={PdfStyles.page}>
            <View style={PdfStyles.header}>
                <View style={PdfStyles.logoWrapper}>
                    {props.logo && <Image style={PdfStyles.logo} src={props.logo} />}
                </View>
            </View>
            <View style={PdfStyles.pageBody}>
                <Text style={PdfStyles.heading}>{t('leakage')}</Text>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('DetectedDate')}</Text>
                    </View>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('DetectedBy')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('MeasurementId')}</Text>
                    </View>
                    <View style={PdfStyles.flexItem}>
                        <Text style={PdfStyles.inputLabel}></Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={PdfStyles.flexItem}>
                        <Text style={PdfStyles.label}>{t('Location')}</Text>
                    </View>
                    <View style={PdfStyles.flexItem}>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Building')}</Text>
                    </View>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Department')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Machine')}</Text>
                    </View>
                    <View style={PdfStyles.flexItem}>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={PdfStyles.flexItem}>
                        <Text style={PdfStyles.label}>{t('Details')}</Text>
                    </View>
                    <View style={PdfStyles.flexItem}>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('LeakageInputDevice')}</Text>
                    </View>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('LeakageInputValue')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('LeakageAction')}</Text>
                    </View>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('RepairTime')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRowStart}>
                    <View style={PdfStyles.flexItemNoGrow}>
                        <View style={PdfStyles.checkbox}></View>
                    </View>
                    <View style={PdfStyles.flexItemNoGrow}>
                        <Text style={PdfStyles.label}>{t('LeakageRepearableProdTime')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRowStart}>
                    <View style={PdfStyles.flexItemNoGrow}>
                        <View style={PdfStyles.checkbox}></View>
                    </View>
                    <View style={PdfStyles.flexItemNoGrow}>
                        <Text style={PdfStyles.label}>{t('LeakageOptimization')}</Text>
                    </View>
                </View>
                <View style={{ height: 30 }}>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={PdfStyles.flexItem}>
                        <Text style={PdfStyles.label}>{t('DefectedDevice')}</Text>
                    </View>
                    <View style={PdfStyles.flexItem}>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('PartNumber')}</Text>
                    </View>
                    <View style={PdfStyles.flexItem}>
                        <Text style={PdfStyles.label}>{t('Replacement')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('TypeCode')}</Text>
                    </View>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('PartNumber')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Component')}</Text>
                    </View>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('TypeCode')}</Text>
                    </View>
                </View>
                <View style={PdfStyles.flexRow}>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Manufacturer')}</Text>
                    </View>
                    <View style={[PdfStyles.inputContainer, PdfStyles.flexItem]}>
                        <Text style={PdfStyles.inputLabel}>{t('Manufacturer')}</Text>
                    </View>
                </View>
                <Text style={PdfStyles.heading}>{t('Comment')}</Text>
                <View style={{ height: 80, border: "1px solid rgba(0, 0, 0, 1)" }} ></View>
            </View>
        </Page>
    );
}