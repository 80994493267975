export const GeneralInfo = "GeneralInfo"
export const CompressedAirGen = "CompressedAirGen"
export const IndividualCompressorPrefix = "IndCompressorInfo_"
export const AirQuality = "AirQuality"
export const IndividualDryerPrefix = "IndividualDryer_"
export const AirQualityMeasurementPrefix = "AirQualityMeasurement_"
export const AirNetworkAnalysis = "AirNetworkAnalysis"
export const DirstibutionLinePrefix = "DistributionLine_"
export const AirReceiverPrefix = "AirReceiver_"
export const PreassureDropAnalysisPrefix = "PreassureDropAnalysis_"
export const MACAC = "MACAC"
export const ApplicationPrefix = "Application_"
export const LeakageAnalysis = "LeakageAnalysis"
export const LDAR = "LDAR"
export const LDARPrefix = "LDAR_"
export const CostSavings = "CostSavings"