import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, View } from '@react-pdf/renderer';
import * as Consts from '../../../helper/ConstantRepository';
// @ts-expect-error not converted yet
import { usePdfStyles } from './PdfStyles';
import { IPDFProjectData } from '../../Types/Types';


const Watermark = (props: IPDFProjectData) => {
    const { projectData } = props;
    const { t } = useTranslation();
    const PdfStyles = usePdfStyles();

    return (
        projectData.status.id == Consts.RUNNING ?
            <View style={PdfStyles.Draft} fixed>
                <Text style={PdfStyles.DraftText}>{t("Draft")}</Text>
            </View>
            :
            <View></View>
    )
}

export default Watermark;