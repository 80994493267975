import { UserRole } from "../../../../../helper/GlobalVariables";
// @ts-expect-error not converted yet
import { checkIsNotNullOrWhitespace, checkEmail, checkIsAnythingSelectedObject, checkNumberMoreZero } from "../../../../../helper/Validator";
import { ModelParam, Translate } from "../../../../Types/Types";

export interface IAccountUserFactory {
    id: ModelParam<number>,
    email: ModelParam<string>,
    role: ModelParam<UserRole>,
    isEmailNotification: ModelParam<boolean>,
    isDefaultExecutor: ModelParam<boolean>,

    salutation: ModelParam<number>,
    firstName: ModelParam<string>,
    lastName: ModelParam<string>,
}

export interface IAccountUserModel {
    id: number,
    email: string,
    role: UserRole | undefined,
    isEmailNotification: boolean,
    isDefaultExecutor: boolean,

    salutation: ModelParam<number>,
    firstName: string,
    lastName: string,
}


export const AccountUserFactory = (t: Translate): IAccountUserFactory => ({
    id: { value: 0, isValid: true, validationError: "", validators: [], disabled: true, idkey: "", label: "" },
    email: { label: t("Email"), value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkEmail], idkey: "" },
    role: {
        label: t("UserRole"), value: { id: 0, value: "", assignedRoles: [] }, isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsAnythingSelectedObject], idkey: ""
    },
    isEmailNotification: { label: t("IsEmailNotification"), value: false, isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [], idkey: "" },
    isDefaultExecutor: { label: t("IsDefaultExecutor"), value: false, isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [], idkey: "" },

    salutation: { value: 0, idkey: "INQ-Salutation", label: t("Salutation"), isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkNumberMoreZero] },
    firstName: { label: t("FirstName"), value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace], idkey: "" },
    lastName: { label: t("LastName"), value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace], idkey: "" },
})