import React, { useState, useEffect } from 'react'
import { Grid, TableCell, TableRow, TableHead, TableBody, Table, TableContainer, Paper } from '@mui/material';
import { TextFieldComponent, HeadingComponent, ButtonComponent, ModalComponent, CheckboxComponent, SingleSelectComponent2 } from '../../../Reusable'
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import { API } from '../../../../helper/ApiHelper';
// @ts-expect-error not converted yet
import * as Mapper from '../../../../helper/Mappers';
import { OpenGlobalSnackbar, Salutations } from '../../../../helper/GlobalVariables';
import { IUserModelFactory, UserModelFactory } from './UserModel'
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { authContext } from "../../../../adalConfig";
import { UserStore } from '../../../../store';
import { CountryParam, IdParam, LanguageParam, ModalType, ModalTypeInit } from '../../../Types/Types';
import { IAssignedRole } from './UserManagement';

export default function AddEditUser() {
    const url = process.env.REACT_APP_DEVFESSSERVICE_BASE + "user-management"
    const { t } = useTranslation();
    const { country, lng, id } = useParams<CountryParam & LanguageParam & IdParam>();
    const history = useHistory();

    const [mainModel, setMainModel] = useState<IUserModelFactory>(UserModelFactory(t));
    const [userRoles, setUserRoles] = useState<IAssignedRole[]>([]);
    const [modalModel, setModalModel] = useState<ModalType>(ModalTypeInit);

    const loadExistingData = () => {
        API.get(`${url}/user/${id}`).then(resp => {
            const mappedModel = Mapper.mapDataToModelValues(resp, UserModelFactory(t));
            setMainModel({ ...mappedModel });
            setUserRoles(resp.assignedRoles)
        })
    }
    useEffect(() => {
        if (Number(id) > 0)
            loadExistingData()
        else
            loadClean()
    }, []);

    useEffect(() => {
        Mapper.updateExistingModelFormattingLabels(mainModel, UserModelFactory(t), setMainModel);
    }, [t]);

    const updateModel = (property: keyof IUserModelFactory, value: number | string | boolean) => {
        mainModel[property].value = value;
        mainModel[property].isValid = true;
        if (mainModel[property].validators.length > 0)
            mainModel[property].validators.forEach((v: (value: any, p2?: void | undefined) => boolean) => {
                if (v(value) === false) {
                    mainModel[property].isValid = false;
                }
            });
        setMainModel({ ...mainModel });
    }

    const clickCancelModalOk = async (): Promise<void> => {
        history.push("/" + country + "/" + lng + '/projectsandrequests');
        scrollTop();
    };

    const validateFormModel = () => {
        let isValid = true;
        Object.values(mainModel).forEach((m: IUserModelFactory[keyof IUserModelFactory]) => {
            if (m.validators.length > 0)
                m.validators.forEach((v: (value: any, p2?: void | undefined) => boolean) => {
                    if (v(m.value) === false) {
                        isValid = false;
                        m.isValid = false;
                    }
                });
        });
        setMainModel({ ...mainModel });
        return isValid;
    }

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const loadClean = () => {
        setMainModel(mainModel)
    }

    const postForm = () => {
        const data = Mapper.extractValuesFromModel(mainModel);
        const method = Number(id) == 0 ? "post" : "put"
        API({
            url: `${url}/user/${id}`,
            method,
            data,
        }).then(() => {
            OpenGlobalSnackbar("success", t("SaveSuccessful"));
            history.push("/" + country + "/" + lng + '/SettingsPanel/UserManagement');
        })
    }

    const clickCancel = () => {
        history.push("/" + country + "/" + lng + '/SettingsPanel/UserManagement');
    }

    const getExecutorLink = (executorId: number) => {
        return "/" + country + "/" + lng + `/SettingsPanel/ExecutorManagement/${executorId}/AddEditExecutor`;
    }

    const getProjectLink = (projectId: number) => {
        return "/" + country + "/" + lng + `/project/${projectId}/PS`;
    }

    const saveForm = () => {
        if (validateFormModel() === true) postForm();
        else OpenGlobalSnackbar("danger", t('ModelInvalidError'));
    }

    const clickDelete = () => {
        setModalModel({
            open: true,
            okText: t('DeleteUser'),
            message: t('WarningDeleteUser'),
            clickOk: () => deleteUser(),
            cancelText: t('Cancel'),
        });
    }

    const deleteUser = async () => {
        await API.delete(`${url}/user/${id}`);
        OpenGlobalSnackbar("success", t("UserDeletionSuccessful"));
        await new Promise(x => setTimeout(x, 3000));
        if (Number(id) === UserStore.user.value?.id)
            authContext.logOut();
    }

    return (
        <div>
            <ModalComponent model={{ clickOk: clickCancelModalOk, ...modalModel }} />
            <div className="country-settings">
                <Grid container spacing={3} direction="column">
                    <Grid item xs={12}>
                        <HeadingComponent value={t("UserDetails")} size="h6" />
                    </Grid>
                    <Grid item xs={12}>
                        <SingleSelectComponent2
                            t={t}
                            model={mainModel.salutation}
                            listFromDb={Salutations}
                            onChange={(e) => updateModel('salutation', e.target.value as number)}
                            variant={'standard'} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldComponent
                            t={t}
                            model={mainModel.firstName}
                            onChange={e => updateModel("firstName", e.target.value)}
                            id="usersettings_name"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldComponent
                            t={t}
                            model={mainModel.lastName}
                            onChange={e =>
                                updateModel("lastName", e.target.value)
                            }
                            id="usersettings_lastName"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldComponent
                            t={t}
                            disabled={Number(id) > 0}
                            model={mainModel.email}
                            onChange={e => updateModel("email", e.target.value)}
                            id="usersettings_email"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CheckboxComponent
                            model={mainModel.isEmailNotification}
                            onChange={e =>
                                updateModel("isEmailNotification", e.target.checked)
                            }
                            id="usersettings_isEmailNotification"
                        />
                    </Grid>
                    {Number(id) != 0 && (
                        <React.Fragment>
                            <Grid item xs={12}>
                                <HeadingComponent
                                    value={t("UserAssignedRoles")}
                                    size="h6"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{t("RoleName")}</TableCell>
                                                <TableCell>{t("Executor")}</TableCell>
                                                <TableCell>
                                                    {t("IsDefaultExecutor")}
                                                </TableCell>
                                                <TableCell>{t("Project")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {userRoles.length > 0 &&
                                                userRoles.map((item, index) => (
                                                    <TableRow key={index + 1}>
                                                        <TableCell id={"td_userrole" + index}>
                                                            {item.userRole ? (t(item.userRole)) : (<CloseIcon />)}
                                                        </TableCell>
                                                        <TableCell id={"td_executorId" + index}>
                                                            {item.executorId != 0 ?
                                                                (<a href={getExecutorLink(item.executorId)} onClick={(e) => { e.preventDefault(); history.push(getExecutorLink(item.executorId)) }}>{item.executorName}</a>) :
                                                                (<CloseIcon />)}
                                                        </TableCell>
                                                        <TableCell id={"td_isDefaultExecutor" + index}> {item.isDefaultExecutor ? (<DoneIcon />) : (<CloseIcon />)}
                                                        </TableCell>
                                                        <TableCell id={"td_projectId" + index}>
                                                            {item.projectId ?
                                                                (<a href={getProjectLink(item.projectId)} onClick={(e) => { e.preventDefault(); history.push(getProjectLink(item.projectId)) }}>{item.projectId}</a>) :
                                                                (<CloseIcon />)}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </React.Fragment>
                    )}
                    <Grid item xs={12}>
                        <Grid container direction="row" justifyContent="space-between">
                            <Grid item>
                                {Number(id) > 0 &&
                                    <ButtonComponent
                                        value={t("DeleteUser")}
                                        color="secondary"
                                        onChange={clickDelete}
                                        id="usersettings_delete"
                                    />
                                }
                            </Grid>
                            <Grid item>
                                <Grid container>
                                    <Grid item>
                                        <ButtonComponent
                                            value={t("Cancel")}
                                            color="secondary"
                                            onChange={clickCancel}
                                            id="usersettings_cancel"
                                        ></ButtonComponent>
                                    </Grid>
                                    <Grid item>
                                        <ButtonComponent
                                            value={t("Save")}
                                            color="primary"
                                            onChange={saveForm}
                                            id="usersettings_save"
                                        ></ButtonComponent>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
