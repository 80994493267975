import React, { useEffect, useState } from 'react';
import { Page, View, Image, Text } from '@react-pdf/renderer';
import { usePdfStyles } from './Tables/PdfStyles';
import LeakageIdentificationTable from './Tables/LeakageIdentificationTable';
import ReplacementTable from './Tables/ReplacementTable';
import VolumeCostsTable from './Tables/VolumeCostsTable';
import * as ImageBlobConstants from '../../ImageViewer/Constants';
import ImagesPdf from './Tables/ImagesPdf';
import { ExecutorStore } from '../../../store/AccountStore';
import { useTranslation } from 'react-i18next';

export default function PrintLeakagesPdf(props) {
    const { item, dataIMG, ldarCalcResults, index } = props;

    const { t } = useTranslation();
    var PdfStyles = usePdfStyles();

    const [executorSettings, setExecutorSettings] = useState({});

    useEffect(() => {
        let execSub = ExecutorStore.executorSettings.subscribe(executorSettings => {
            setExecutorSettings(executorSettings);
        })
        return () => execSub.unsubscribe();
    })

    return (
        <Page size={executorSettings?.printSize?.value ?? "A4"} style={PdfStyles.page} key={index}>
            <View style={PdfStyles.header}>
                <View style={PdfStyles.logoWrapper}>
                    {props.logo && <Image style={PdfStyles.logo} src={props.logo} />}
                </View>
            </View>
            <View>
                <LeakageIdentificationTable item={item} />
                <View style={{ marginTop: "10px", marginBottom: "10px", borderBottom: "4px" }}></View>
                {item.replacementPartList?.length > 0 &&
                    <React.Fragment>
                        <ReplacementTable replacementPartList={item.replacementPartList} />
                        <View style={{ marginTop: "10px", marginBottom: "10px", borderBottom: "4px" }}></View>
                    </React.Fragment>
                }
                <VolumeCostsTable itemCalc={ldarCalcResults.indLeakageCalcResults.find(x => x.leakageId === item.id)} />
                {item.leakageComment?.length > 0 &&
                    <View style={{ marginTop: "5px" }}>
                        <Text style={PdfStyles.bold}>{t('Comment') + ": "}</Text><Text>{item.leakageComment}</Text>
                    </View>
                }
            </View>
            <View>
                <ImagesPdf image={dataIMG.filter(i => i.groupId == ImageBlobConstants.LDARPrefix + item.guid)} />
            </View>
        </Page>
    )
}
